export interface AdInformation {
  url: string;
  adViewId: number;
  redirectUrl?: string;
  mobileUrl?: string;
}

export enum AdCatalog {
  historiaClinica = 1,
  detalleHistoriaClinica,
  historiaClinicaPediatrica,
  detalleHistoriaClinicaPediatrica,
  receta,
  detalleReceta,
}

export enum AdTypes {
  leaderboard = 1,
  largeRectangle,
  mediumRectangle,
  mobileBanner,
  wideSkyscraper,
  video,
}

export const dimensionsByAdType: { [key in AdTypes]: { width: number; height: number } } =
  {
    [AdTypes.leaderboard]: { width: 728, height: 90 },
    [AdTypes.largeRectangle]: { width: 336, height: 280 },
    [AdTypes.mediumRectangle]: { width: 300, height: 250 },
    [AdTypes.mobileBanner]: { width: 300, height: 50 },
    [AdTypes.wideSkyscraper]: { width: 160, height: 600 },
    [AdTypes.video]: { width: 300, height: 250 },
  };
