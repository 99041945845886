import React, { SyntheticEvent, useRef, useState } from 'react';
import { GoSignOut, IoMdArrowDropdown, IoPersonCircle, MdHelp } from 'react-icons/all';
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMainProvider } from '../../../context/MainProvider';
import { mainTheme } from '../../../utils/theme/theme';
import { removeLocalstorageData } from '../../../utils/localStorage';
import { getUserImage } from '../../../utils/fns';
import fallbackImage from '../../../assets/img/profile-user.png';
import { IOSHandlerFunctions, postWebkitMessage } from '../../../interfaces/WebkitWindow';
import { previewUserID } from '../../../utils/server';

const OptionsDropdown = (): JSX.Element => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { user, setUser, setCurrentPatient, setTemporaryUsers, setQrCode } =
    useMainProvider();
  const navigate = useNavigate();

  /**
   *
   * @desc logouts a user of the application
   */
  const handleLogout = () => {
    postWebkitMessage({
      function: IOSHandlerFunctions.Logout,
    });
    setUser(undefined);
    setCurrentPatient(undefined);
    setTemporaryUsers([]);
    setQrCode('');
    removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        style={{
          backgroundColor: '#E1E1E1',
          padding: '0.1rem',
          borderRadius: mobile ? '2rem' : '2.2rem',
          textAlign: 'left',
          marginRight: '1.5rem',
        }}
      >
        {user && user.initialData ? (
          <img
            src={getUserImage(user)}
            onError={(e: SyntheticEvent<HTMLImageElement>) => {
              const target = e.target as EventTarget & HTMLImageElement;
              target.src = fallbackImage;
            }}
            alt='foto de perfil del usuario'
            style={{ width: '1.8rem', height: '1.8rem', borderRadius: '0.9rem' }}
          />
        ) : (
          <IoPersonCircle
            style={{
              fontSize: mobile ? '1.8rem' : '2rem',
              color: mainTheme.colors.uiElements,
            }}
          />
        )}
        <IoMdArrowDropdown
          style={{
            fontSize: mobile ? '0.8rem' : '1rem',
            margin: mobile ? '0 0.5rem' : '0 0.6rem 0 0.6rem',
          }}
        />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-end'
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            in={TransitionProps ? TransitionProps.in : undefined}
            onEnter={TransitionProps ? TransitionProps.onEnter : undefined}
            onExited={TransitionProps ? TransitionProps.onExited : undefined}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={
                  handleClose as unknown as (event: MouseEvent | TouchEvent) => void
                }
              >
                <MenuList
                  autoFocusItem={open}
                  aria-labelledby='composition-button'
                  onKeyDown={handleListKeyDown}
                >
                  {user && user.initialDataFilled && user.id !== previewUserID ? (
                    <MenuItem
                      onClick={() => {
                        navigate('/myprofile');
                      }}
                    >
                      <MdHelp
                        style={{
                          color: mainTheme.colors.navbarItems,
                          marginRight: '0.5rem',
                        }}
                      />{' '}
                      Mi perfil
                    </MenuItem>
                  ) : null}
                  {user && user.id !== previewUserID && (
                    <MenuItem
                      onClick={() => {
                        handleLogout();
                        navigate({
                          pathname: `/login`,
                        });
                      }}
                    >
                      <GoSignOut
                        style={{
                          color: mainTheme.colors.navbarItems,
                          marginRight: '0.5rem',
                        }}
                      />
                      Cerrar sesión
                    </MenuItem>
                  )}
                  {user && user.id === previewUserID && (
                    <MenuItem
                      onClick={() => {
                        handleLogout();
                        navigate({
                          pathname: `/register`,
                        });
                      }}
                    >
                      <GoSignOut
                        style={{
                          color: mainTheme.colors.navbarItems,
                          marginRight: '0.5rem',
                        }}
                      />
                      Crea tu cuenta
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default OptionsDropdown;
