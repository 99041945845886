import { SponsorsTableEntry } from '../../Interfaces/Sponsors';

export interface SponsorFormPayload {
  name: string;
  address: string;
  business_name: string;
  sanitary_license: string;
  fiscal_statement_url: string;
  logo_url: string;
  website_url: string | null;
  description: string;
  logoFile?: string | ArrayBuffer | null;
  logoExtension?: string;
  fiscalStatementFile?: string | ArrayBuffer | null;
  fiscalStatementExtension?: string;
}

export const initialSponsorForm: SponsorsTableEntry = {
  id: 0,
  is_active: true,
  fecha: '',
  name: '',
  address: '',
  business_name: '',
  sanitary_license: '',
  fiscal_statement_url: '',
  logo_url: '',
  website_url: '',
  description: '',
};
