import { Dialog, DialogContent, Grid } from '@mui/material';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import DialogCustomTitle from '../../../../../components/DialogCustomTitle/DialogCustomTitle';
import { SponsorCampaignFormLocation } from '../SponsorCampaigns.interfaces';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { PageContainer } from '../../../../Register/Register.styled';
import { FlexContainer } from '../../../../../components/FlexContainer/FlexContainer';
import { PrimaryTitleStart } from '../../../../../components/PrimaryTitle/PrimaryTitle';
import PrimaryButton from '../../../../../components/PrimaryButton/PrimaryButton';
import { PrimaryDescription } from '../../../../../components/PrimaryDescription/PrimaryDescription';
import { formatMoney } from '../../../../../utils/money';

export interface SponsorsLocationSelectionPopupProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  onSuccess: (selectedLocationId: number) => void;
  locations: SponsorCampaignFormLocation[];
}

const SponsorsLocationSelectionPopup = ({
  open,
  setOpen,
  onSuccess,
  locations,
}: SponsorsLocationSelectionPopupProps): JSX.Element => {
  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false);
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          setOpen(false);
        }}
        mode={CRUDModes.Update}
        title='ubicaciones disponibles'
      />
      <DialogContent>
        {open ? (
          <PageContainer style={{ paddingTop: '1.5rem' }}>
            <Grid container direction='row' justifyContent='center'>
              <Grid item xs={12} md={5}>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    exit={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3 }}
                    key='select-location'
                  >
                    <>
                      {locations.map((location) => {
                        return (
                          <div
                            key={location.id}
                            style={{
                              overflow: 'hidden',
                              borderRadius: '20px',
                              marginBottom: '2rem',
                              width: '100%',
                              boxShadow: '0px 3px 8px rgba(0, 12, 51, 0.15)',
                            }}
                          >
                            <img
                              src={location.showcase_image_url}
                              alt='Imagen de la ubicacion'
                              style={{ width: '100%' }}
                            />
                            <FlexContainer
                              direction='column'
                              alignItems='flex-start'
                              style={{ width: 'calc(100% - 3rem)', padding: '1.5rem' }}
                            >
                              <PrimaryTitleStart style={{ textAlign: 'center' }}>
                                {location.name}
                              </PrimaryTitleStart>
                              <PrimaryDescription style={{ marginBottom: '0.5rem' }}>
                                <b>Tipo de contenido:</b>{' '}
                                {location.media_type === 1 ? 'Imagen' : 'Video'}
                              </PrimaryDescription>
                              <PrimaryDescription>
                                <b>Costo por vista:</b> {formatMoney(location.view_cost)}
                                {location.media_type === 1 ? (
                                  <>
                                    <br />
                                    <b>Tamaño desktop:</b> {location.desktopSize}
                                    <br />
                                    <b>Tamaño móvil:</b> {location.mobileSize}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </PrimaryDescription>
                              {location.comments ? (
                                <PrimaryDescription>
                                  {location.comments}
                                </PrimaryDescription>
                              ) : null}
                              <PrimaryButton
                                style={{ width: '100%' }}
                                loading={false}
                                type='button'
                                onClick={() => onSuccess(location.id)}
                              >
                                Seleccionar ubicación
                              </PrimaryButton>
                            </FlexContainer>
                          </div>
                        );
                      })}
                    </>
                  </motion.div>
                </AnimatePresence>
              </Grid>
            </Grid>
          </PageContainer>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default SponsorsLocationSelectionPopup;
