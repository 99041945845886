import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, MenuItem, useMediaQuery, useTheme, Button } from '@mui/material';
import { FaUserCircle, IoIosAddCircle } from 'react-icons/all';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { mainTheme } from '../../utils/theme/theme';
import {
  MaxValidationMessage,
  RequiredMessage,
  SelectValidOption,
} from '../../utils/validationMessages';
import { Nurse } from '../../interfaces/Nurses';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import {
  saveLocalstorageData,
  getLocalstorageData,
  removeLocalstorageData,
} from '../../utils/localStorage';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import { PageContainer } from '../Register/Register.styled';
import CenteredForm from '../../index.styled';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { postWithToken } from '../../utils/server';
import { User } from '../../interfaces/User';
import { deleteNullValues, getBase64 } from '../../utils/fns';
import { Doctor } from '../../interfaces/Doctors';
import fallbackImage from '../../assets/img/profile-user.png';

interface NurseProps {
  existingUser?: User;
  initialDataMode?: boolean;
}

const initialValues = (user?: User): Nurse => {
  return {
    enf_nombre: '',
    enf_apellidop: '',
    enf_apellidom: '',
    enf_cedula_profesional: '',
    enf_institucion_expedio_cedula: '',
    enf_correo: user?.username ? user?.username : '',
    enf_especialidad_id: -1,
    enf_cedula_especialidad: '',
    enf_institucion_expidio_cedula_esp: '',
    enf_otra_especialidad: '',
    enf_consultorio: '',
    enf_no_registro: '',
    enf_domicilio: '',
    enf_colonia_consultorio: '',
    enf_cp_consultorio: '',
    enf_municipio_consultorio: '',
    enf_ciudad_consultorio: '',
    enf_estado_consultorio_id: -1,
    enf_telefono_consultorio: '',
  };
};

function NurseInitialForm({ existingUser, initialDataMode }: NurseProps): JSX.Element {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const {
    user: savedUser,
    setLoading,
    setUser,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    getNurseSpecialities,
    nurseSpecialities,
    getStates,
    states,
    loading,
    handleLogout,
  } = useMainProvider();

  const [user, setTemporalUser] = useState<User | undefined>(existingUser || savedUser);
  const [values, setValues] = useState<Nurse>(
    existingUser?.initialData
      ? (existingUser.initialData as Nurse)
      : initialValues(savedUser),
  );

  useEffect(() => {
    if (existingUser) {
      setTemporalUser(existingUser);
    } else {
      setTemporalUser(savedUser);
    }
  }, [savedUser, existingUser]);

  useEffect(() => {
    if (user && user.initialData) {
      setValues(user.initialData as Nurse);
    }
  }, [user]);

  const navigate = useNavigate();
  const [transformedFile, setTransformedFiles] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [fileExtensions, setFileExtension] = useState<string>('');
  const [imagePreview, setImagePreview] = useState('');

  const validationSchema = Yup.object({
    enf_nombre: Yup.string().required(RequiredMessage),
    enf_apellidop: Yup.string().required(RequiredMessage),
    enf_apellidom: Yup.string().required(RequiredMessage),
    enf_correo: Yup.string().required(RequiredMessage),
    enf_especialidad_id: Yup.number()
      .required(RequiredMessage)
      .test('enf_especialidad_id', SelectValidOption(0, 'estado'), (val) => {
        return val !== -1;
      }),
    enf_cedula_profesional: Yup.string().required(RequiredMessage),
    enf_cedula_especialidad: Yup.string().required(RequiredMessage),
    enf_institucion_expidio_cedula_esp: Yup.string().required(RequiredMessage),
    enf_institucion_expedio_cedula: Yup.string().required(RequiredMessage),
    enf_consultorio: Yup.string().required(RequiredMessage),
    enf_no_registro: Yup.string().required(RequiredMessage),
    enf_domicilio: Yup.string().required(RequiredMessage),
    enf_colonia_consultorio: Yup.string().required(RequiredMessage),
    enf_cp_consultorio: Yup.string()
      .required(RequiredMessage)
      .max(5, MaxValidationMessage(5)),
    enf_municipio_consultorio: Yup.string().required(RequiredMessage),
    enf_ciudad_consultorio: Yup.string().required(RequiredMessage),
    enf_estado_consultorio_id: Yup.number()
      .required(RequiredMessage)
      .test(
        'med_estado_consultorio_id',
        SelectValidOption(1, 'estado del consultorio'),
        (val) => {
          return val !== -1;
        },
      ),
    enf_telefono_consultorio: Yup.string().required(RequiredMessage),
  });

  const formik = useFormik<Nurse>({
    initialValues: values,
    validationSchema,
    enableReinitialize: true,
    /**
     * @desc logins the user with the given information
     * @param nurseValues
     */
    onSubmit: async (nurseValues) => {
      try {
        setLoading(true);
        const saveNurseValuesResult = await postWithToken<
          {
            nurse: Nurse;
            userId?: number;
            file: string | ArrayBuffer | null;
            extension: string;
          },
          Nurse
        >(
          `/nurse/${initialDataMode ? 'save' : 'update'}-nurse`,
          {
            nurse: deleteNullValues<Nurse>({
              ...nurseValues,
              enf_id_usuario_enfermeras_id: user?.id,
            }),
            userId: user?.id,
            file: transformedFile,
            extension: fileExtensions,
          },
          handleLogout,
        );
        if (saveNurseValuesResult.success) {
          const userInformation = getLocalstorageData<User>(
            'CODIGO_MEDICO_USER_INFORMATION',
          );
          if (userInformation.success) {
            const userData = userInformation.data;
            userData.initialDataFilled = true;
            userData.initialData = saveNurseValuesResult.data;
            removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
            saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', userData);
            setUser(userData);
            setLoading(false);
            setAlertType(AlertTypes.Success);
            setAlertMessage(`Información guardada con éxito`);
            setOpenAlert(true);
            if (initialDataMode) navigate('/home');
            return;
          }
        }
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al guardar información`);
        setOpenAlert(true);
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar la información de la enfermera: ${JSON.stringify(
              e.response.data,
            )} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiles(base64);
        setFileExtension(e.target.files[0].type.split('/')[1]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
      }
    } catch (err) {
      handleLogout(err);
      setLoading(false);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    getStates();
    getNurseSpecialities();
  }, [getStates, getNurseSpecialities]);

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={6}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                {!initialDataMode ? null : (
                  <>
                    <PrimaryTitle mobile={mobile}>Completa tu perfil</PrimaryTitle>
                    <PrimaryDescription mobile={mobile}>
                      Antes de comenzar necesitamos saber un poco más de ti. Añade una
                      imagen para tu foto de perfil.
                    </PrimaryDescription>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ justifyContent: 'center', display: 'flex' }}
                >
                  <>
                    <label htmlFor='upload-photo'>
                      <input
                        style={{ display: 'none' }}
                        id='upload-photo'
                        name='upload-photo'
                        type='file'
                        accept='image/png, image/jpeg'
                        onChange={handleUpload}
                      />
                      <Button
                        component='span'
                        style={{
                          width: mobile ? '100%' : 'initial',
                          marginBottom: '2rem',
                        }}
                      >
                        {imagePreview || values.enf_imagen ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={imagePreview || values.enf_imagen}
                              onError={(e: SyntheticEvent<HTMLImageElement>) => {
                                const target = e.target as EventTarget & HTMLImageElement;
                                target.src = fallbackImage;
                              }}
                              alt='img'
                              style={{
                                width: '15rem',
                              }}
                            />
                            <IoIosAddCircle
                              style={{
                                color: 'black',
                                fontSize: '3rem',
                                position: 'absolute',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ position: 'relative' }}>
                            <FaUserCircle
                              style={{
                                color: mainTheme.colors.uiElements,
                                fontSize: '10rem',
                              }}
                            />
                            <IoIosAddCircle
                              style={{
                                color: 'black',
                                fontSize: '3rem',
                                position: 'absolute',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </div>
                        )}
                      </Button>
                      <span
                        style={{
                          marginLeft: mobile ? 'initial' : '2%',
                          color: mainTheme.colors.standardText,
                          display: mobile ? 'block' : 'initial',
                        }}
                      />
                    </label>
                  </>
                </Grid>
                <PrimaryTitleStart>Datos de la enfermera</PrimaryTitleStart>
                <CMTextfield
                  id='outlined-basic'
                  label='Nombre'
                  value={formik.values.enf_nombre}
                  name='enf_nombre'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.enf_nombre && Boolean(formik.errors.enf_nombre)}
                  helperText={formik.touched.enf_nombre && formik.errors.enf_nombre}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Apellido paterno'
                  value={formik.values.enf_apellidop}
                  name='enf_apellidop'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_apellidop && Boolean(formik.errors.enf_apellidop)
                  }
                  helperText={formik.touched.enf_apellidop && formik.errors.enf_apellidop}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Apellido materno'
                  value={formik.values.enf_apellidom}
                  name='enf_apellidom'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_apellidom && Boolean(formik.errors.enf_apellidom)
                  }
                  helperText={formik.touched.enf_apellidom && formik.errors.enf_apellidom}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Cédula profesional'
                  value={formik.values.enf_cedula_profesional}
                  name='enf_cedula_profesional'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_cedula_profesional &&
                    Boolean(formik.errors.enf_cedula_profesional)
                  }
                  helperText={
                    formik.touched.enf_cedula_profesional &&
                    formik.errors.enf_cedula_profesional
                  }
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Institución de cédula profesional'
                  value={formik.values.enf_institucion_expedio_cedula}
                  name='enf_institucion_expedio_cedula'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_institucion_expedio_cedula &&
                    Boolean(formik.errors.enf_institucion_expedio_cedula)
                  }
                  helperText={
                    formik.touched.enf_institucion_expedio_cedula &&
                    formik.errors.enf_institucion_expedio_cedula
                  }
                  onChange={formik.handleChange}
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Correo'
                  value={formik.values.enf_correo}
                  name='enf_correo'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.enf_correo && Boolean(formik.errors.enf_correo)}
                  helperText={formik.touched.enf_correo && formik.errors.enf_correo}
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfieldHalfLeft
                  select
                  id='outlined-basic'
                  label='Especialidad'
                  value={formik.values.enf_especialidad_id}
                  name='enf_especialidad_id'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_especialidad_id &&
                    Boolean(formik.errors.enf_especialidad_id)
                  }
                  helperText={
                    formik.touched.enf_especialidad_id &&
                    formik.errors.enf_especialidad_id
                  }
                  onChange={formik.handleChange}
                >
                  <MenuItem value='-1' disabled>
                    Seleccione una opción
                  </MenuItem>
                  {nurseSpecialities.map((singleSpeciality) => {
                    return (
                      <MenuItem value={singleSpeciality.cat_especialidad_id}>
                        {singleSpeciality.cat_especialidad_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfieldHalfLeft>
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Cédula de especialidad'
                  value={formik.values.enf_cedula_especialidad}
                  name='enf_cedula_especialidad'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_cedula_especialidad &&
                    Boolean(formik.errors.enf_cedula_especialidad)
                  }
                  helperText={
                    formik.touched.enf_cedula_especialidad &&
                    formik.errors.enf_cedula_especialidad
                  }
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Institución de cédula de especialidad'
                  value={formik.values.enf_institucion_expidio_cedula_esp}
                  name='enf_institucion_expidio_cedula_esp'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_institucion_expidio_cedula_esp &&
                    Boolean(formik.errors.enf_institucion_expidio_cedula_esp)
                  }
                  helperText={
                    formik.touched.enf_institucion_expidio_cedula_esp &&
                    formik.errors.enf_institucion_expidio_cedula_esp
                  }
                  onChange={formik.handleChange}
                  required
                />
                {formik.values.enf_especialidad_id === 15 && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                    style={{
                      width: '100%',
                    }}
                  >
                    <CMTextfield
                      id='outlined-basic'
                      label='Otra especialidad'
                      value={formik.values.enf_otra_especialidad}
                      name='enf_otra_especialidad'
                      style={{ marginBottom: '1rem' }}
                      error={
                        formik.touched.enf_otra_especialidad &&
                        Boolean(formik.errors.enf_otra_especialidad)
                      }
                      helperText={
                        formik.touched.enf_otra_especialidad &&
                        formik.errors.enf_otra_especialidad
                      }
                      onChange={formik.handleChange}
                    />
                  </motion.div>
                )}
                <PrimaryTitleStart>Datos del consultorio</PrimaryTitleStart>
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Consultorio'
                  value={formik.values.enf_consultorio}
                  name='enf_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_consultorio &&
                    Boolean(formik.errors.enf_consultorio)
                  }
                  helperText={
                    formik.touched.enf_consultorio && formik.errors.enf_consultorio
                  }
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Número de registro'
                  value={formik.values.enf_no_registro}
                  name='enf_no_registro'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_no_registro &&
                    Boolean(formik.errors.enf_no_registro)
                  }
                  helperText={
                    formik.touched.enf_no_registro && formik.errors.enf_no_registro
                  }
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Domicilio'
                  value={formik.values.enf_domicilio}
                  name='enf_domicilio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_domicilio && Boolean(formik.errors.enf_domicilio)
                  }
                  helperText={formik.touched.enf_domicilio && formik.errors.enf_domicilio}
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Colonia'
                  value={formik.values.enf_colonia_consultorio}
                  name='enf_colonia_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_colonia_consultorio &&
                    Boolean(formik.errors.enf_colonia_consultorio)
                  }
                  helperText={
                    formik.touched.enf_colonia_consultorio &&
                    formik.errors.enf_colonia_consultorio
                  }
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Código postal'
                  value={formik.values.enf_cp_consultorio}
                  name='enf_cp_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_cp_consultorio &&
                    Boolean(formik.errors.enf_cp_consultorio)
                  }
                  helperText={
                    formik.touched.enf_cp_consultorio && formik.errors.enf_cp_consultorio
                  }
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Ciudad'
                  value={formik.values.enf_ciudad_consultorio}
                  name='enf_ciudad_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_ciudad_consultorio &&
                    Boolean(formik.errors.enf_ciudad_consultorio)
                  }
                  helperText={
                    formik.touched.enf_ciudad_consultorio &&
                    formik.errors.enf_ciudad_consultorio
                  }
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Municipio'
                  value={formik.values.enf_municipio_consultorio}
                  name='enf_municipio_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_municipio_consultorio &&
                    Boolean(formik.errors.enf_municipio_consultorio)
                  }
                  helperText={
                    formik.touched.enf_municipio_consultorio &&
                    formik.errors.enf_municipio_consultorio
                  }
                  onChange={formik.handleChange}
                  required
                />
                <CMTextfield
                  select
                  id='outlined-basic'
                  label='Estado'
                  value={formik.values.enf_estado_consultorio_id}
                  name='enf_estado_consultorio_id'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_estado_consultorio_id &&
                    Boolean(formik.errors.enf_estado_consultorio_id)
                  }
                  helperText={
                    formik.touched.enf_estado_consultorio_id &&
                    formik.errors.enf_estado_consultorio_id
                  }
                  onChange={formik.handleChange}
                  required
                >
                  <MenuItem value='-1' disabled>
                    Seleccione una opción
                  </MenuItem>
                  {states.map((singleState) => {
                    return (
                      <MenuItem value={singleState.cat_estado_id}>
                        {singleState.cat_estado_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfield>
                <CMTextfield
                  id='outlined-basic'
                  label='Teléfono'
                  value={formik.values.enf_telefono_consultorio}
                  name='enf_telefono_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.enf_telefono_consultorio &&
                    Boolean(formik.errors.enf_telefono_consultorio)
                  }
                  helperText={
                    formik.touched.enf_telefono_consultorio &&
                    formik.errors.enf_telefono_consultorio
                  }
                  onChange={formik.handleChange}
                  required
                />
                {!existingUser ? (
                  <PrimaryButton
                    loading={false}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%', marginTop: '1rem' }}
                  >
                    {initialDataMode ? 'Enviar información' : 'Actualizar información'}
                  </PrimaryButton>
                ) : null}
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

NurseInitialForm.defaultProps = {
  existingUser: undefined,
  initialDataMode: false,
};

export default NurseInitialForm;
