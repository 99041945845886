import { BloodTypes } from './BloodTypes';
import { Cie10 } from './Cie10';
import { Patient } from './Patients';

export enum NutritionQualities {
  Bad = 'MALA',
  Regular = 'REGULAR',
  Good = 'BUENA',
  Unknown = '',
}

export enum NutritionQuantities {
  Bad = 'MALA',
  Regular = 'REGULAR',
  Good = 'BUENA',
  Unknown = '',
}

export enum BirthMethods {
  Unknown = '',
  OralHormones = 'Hormonales Orales',
  InjectableHormones = 'Hormonales Inyectables',
  UnderSkinHormones = 'Hormonales Subcutaneos',
  DIU = 'Dispositivo Intrauterino',
  Barrier = 'De Barrera y Espermicidas',
  Natural = 'Naturales o de Abstinencia Periodica',
  Oclusion = 'Oclusión Tubaria Bilateral',
  Vasectomy = 'Vasectomia',
  None = 'Ninguno',
}

export interface ClinicHistory {
  id?: number;
  isActive?: boolean;
  creationDate?: string;
  patientId: number;
  doctorId: number;
  hasDiabetes: boolean;
  diabeticRelatives?: string;
  hasHipertension: boolean;
  hipertensionRelatives?: string;
  hasCancer: boolean;
  cancerRelatives?: string;
  cancerType?: string;
  hasHeartDisease: boolean;
  heartDiseaseRelatives?: string;
  hasNephropathy: boolean;
  nephropaticalRelatives?: string;
  hasMalformations: boolean;
  malformationsRelatives?: string;
  malformationType?: string;
  hasOtherFamilyDiseases: boolean;
  otherFamilyDiseases?: string;
  isSmoker: boolean;
  cigarettesPerDay?: number;
  yearsSmoking?: number;
  isExSmoker: boolean;
  isAlcoholic: boolean;
  drinksPerWeek?: number;
  yearsDrinking?: number;
  isExAlcoholic: boolean;
  hasGoodNutrition: boolean;
  nutritionQuality: NutritionQualities;
  nutritionQuantity: NutritionQuantities;
  bloodType: BloodTypes;
  hasBasicServices: boolean;
  isDrugAddict: boolean;
  isExDrugAddict: boolean;
  drugAddictionType?: string;
  yearsDrugAddict?: number;
  hasOtherSocioPersonalBackground: boolean;
  otherSocioPersonalBackground?: string;
  hasInfancyDiseases: boolean;
  infancyDiseases?: string;
  infancyDiseaseAfterEffects?: string;
  hasPreviousHospitalizations: boolean;
  previousHospitalizations?: string;
  hasQuirurgicBackground: boolean;
  quirurgicBackground?: string;
  hasPreviousTransfusions: boolean;
  previousTransfusions?: string;
  hasBoneFractures: boolean;
  hasTraumas: boolean;
  traumas?: string;
  hasOtherDiseases: boolean;
  otherDiseases?: string;
  hasAlergies: boolean;
  alergicMedicine?: string;
  otherAlergies?: string;
  hasSTDiseases: boolean;
  sTDiseases?: string;
  respiratoryDescription: string;
  cardiovascularDescription: string;
  peripheralVascularDescription: string;
  digestiveDescription: string;
  endocrineDescription: string;
  muscularDescription: string;
  genitalDescription: string;
  lymphaticDescription: string;
  skinDescription: string;
  neurologicalDescription: string;
  weightKg: number;
  height: number;
  bodyMassIndex: number;
  foodAlergies?: string;
  menarca?: string;
  ciclos_regulares?: boolean;
  ciclo_cantidad?: string;
  ciclo_periodicidad?: string;
  ultima_menstruacion?: string;
  polimenorrea?: boolean;
  hipermenorrea?: boolean;
  disiminorrea?: boolean;
  ivsa?: string;
  numero_parejas?: number;
  gesta?: string;
  para?: string;
  abortos?: string;
  cesareas?: string;
  ultima_pap?: string;
  resultado_ultima_pap?: string;
  metodo_planificacion_familiar?: string;
  patientInformation?: Patient;
  padecimiento_actual: string;
  peso_ideal: string;
  peso_habitual: string;
  temperatura: string;
  frecuencia_cardiaca: string;
  frecuencia_respiratoria: string;
  presion_arterial: string;
  habitus_exterior: string;
  cabeza: string;
  cuello: string;
  torax: string;
  abdomen: string;
  extremidades: string;
  genitales: string;
  ginecologico?: string;
  resultados_estudios: string;
  historia_clinica_diagnosticos?: Cie10[];
  pronostico: string;
  indicacion_terapeutica: string;
}

export interface ExtendedClinicHistory extends ClinicHistory {
  doctorName: string;
  patientName: string;
}

export interface SimplifiedClinicHistory {
  id?: number;
  creationDate?: string;
  doctorName: string;
  moreInformation: 'Consultar';
}
