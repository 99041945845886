import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useMainProvider } from '../../context/MainProvider';
import GenericTablePage from '../GenericTablePage/GenericTablePage';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import { setHeaders } from '../../utils/headerSetter';
import { CRUDModes } from '../../interfaces/CRUD';
import { UserTypes } from '../../interfaces/User';
import { ExtendedReceta, SimplifiedReceta } from '../../interfaces/Receta';
import RecetaForm from './RecetaForm';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { ZivotDocumentTypes } from '../../interfaces/DoctorProfile';
import { previewUserID } from '../../utils/server';
import { AdCatalog } from '../../interfaces/Ads';

const RecetaPage = (): JSX.Element => {
  const {
    setLoading,
    user,
    currentPatient,
    setOpenAlert,
    setAlertType,
    setAlertMessage,
    restartTemporayUsers,
    setLoginPopupOpen,
    handleLogout,
  } = useMainProvider();
  const [entries, setEntries] = useState<ExtendedReceta[]>([]);
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);

  const fetchEntries = async () => {
    try {
      const currentPatientId = currentPatient ? currentPatient.id : undefined;
      setLoading(true);
      const fetchResult = await axios.get<ExtendedReceta[]>(
        `${process.env.REACT_APP_SERVER_URL}/receta?patientId=${currentPatientId}`,

        setHeaders(false, user ? user.token : undefined),
      );
      if (fetchResult.status === 200) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID Nota',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha_creacion',
            displayName: 'Fecha de creación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'doctorName',
            displayName: 'Médico que elaboró',
            type: GenericColumnTypes.ShowProfile,
            sortable: true,
            documentType: ZivotDocumentTypes.recipe,
          },
          {
            name: 'patientName',
            displayName: 'Nombre Paciente',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: 'Consultar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
        ]);
        const simplifiedData: SimplifiedReceta[] = orderedRows.map((receta) => {
          return {
            id: receta.id,
            fecha_creacion: receta.fecha_creacion,
            doctorName: receta.doctorName,
            patientName: receta.patientName,
            moreInformation: 'Consultar',
          };
        });
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al obtener recetas: ${JSON.stringify(e.response.data)} `);
        setOpenAlert(true);
        if (
          e.response.data ===
          'El tiempo de acceso al Expediente Clínico del Paciente terminó, si requiere continuar, favor de escanear otro código QR de su Paciente. Gracias'
        ) {
          restartTemporayUsers();
        }
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const fetchCallback = useCallback(fetchEntries, [
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    user,
    restartTemporayUsers,
    currentPatient,
    handleLogout,
  ]);

  useEffect(() => {
    fetchCallback().then(EmptyClosure).catch(EmptyClosure);
  }, [fetchCallback]);

  return (
    <>
      <RecetaForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={
          selectedRow !== undefined && selectedRow !== -1
            ? entries[selectedRow]
            : undefined
        }
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      <GenericTablePage
        title='Recetas'
        rows={rows}
        columns={columns}
        canCreate={user && currentPatient ? user.typeId === UserTypes.DoctorType : false}
        canDelete={false}
        canEdit
        locationId={AdCatalog.receta}
        onCreate={() => {
          if (user && user.id === previewUserID) {
            setLoginPopupOpen(true);
          } else {
            setOpen(true);
            setSelectedRow(-1);
            setMode(CRUDModes.Create);
          }
        }}
      />
    </>
  );
};

export default RecetaPage;
