import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TimePicker from '@mui/lab/TimePicker';
import {
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useReactToPrint } from 'react-to-print';
import * as Yup from 'yup';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import { SurgicalNote } from '../../interfaces/SurgicalNote';
import { postWithToken } from '../../utils/server';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import SurgicalNoteDocument from './SurgicalNoteDocument';
import { Doctor } from '../../interfaces/Doctors';
import { Patient } from '../../interfaces/Patients';
import { Cie10 } from '../../interfaces/Cie10';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { canPrintNote } from '../../utils/fns';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import { RequiredMessage } from '../../utils/validationMessages';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: SurgicalNote;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: SurgicalNote = {
  tipo_cirugia: '-1',
  fecha_cirugia: '',
  hora_inicio: '',
  hora_fin: '',
  diag_preoperatorio: [],
  cirugia_programada: '',
  cirugia_efectuada: '',
  diag_postoperatorio: [],
  tecnica: '',
  dificultad_cirugia: false,
  dificultad_cirugia_descripcion: '',
  hallazgos: '',
  cuenta_gasas_compresas: false,
  cuenta_gasas_compresas_informo: '',
  complicaciones: '',
  sangrado: false,
  sangrado_cantidad: '',
  servicios_aux: '',
  envio_pat: false,
  envio_pat_desc: '',
  cirujano: '',
  ayudante: '',
  segudo_ayudante: '',
  anestesiologo: '',
  instrumentista: '',
  circulante: '',
  estado_post: '',
  pla_inm: '',
  pronostico: '-1',
  otros_llagazgos: '',
};

const validationSchema = Yup.object({
  fecha_cirugia: Yup.string().required(RequiredMessage),
  hora_inicio: Yup.string().required(RequiredMessage),
  hora_fin: Yup.string().required(RequiredMessage),
});

function SurgicalNotesForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    getCie10,
    cie10,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const [formValues, setFormValues] = useState(initialValues);
  const [doctorInformation, setDoctorInformation] = useState<Doctor | null>(null);
  const [patientInformation, setPatientInformation] = useState<Patient | null>(null);
  const [preDiag, setPreDiag] = useState<Cie10[]>([]);
  const [postDiag, setPostDiag] = useState<Cie10[]>([]);
  const printRef = useRef<HTMLInputElement>(null);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.id_medico !== user?.id,
    [mode, formValues, user?.id],
  );

  const getDoctorInformation = useCallback(async () => {
    try {
      const doctorResult = await postWithToken<
        {
          doctorId?: number;
        },
        Doctor
      >(
        '/doctor/get-doctor',
        {
          doctorId: user?.id,
        },
        handleLogout,
      );
      if (doctorResult.success) {
        setDoctorInformation(doctorResult.data);
      }
    } catch (e) {
      handleLogout(e);
      console.log(e);
    }
  }, [handleLogout, user?.id]);

  useEffect(() => {
    getCie10();
  }, [getCie10]);

  useEffect(() => {
    setPreDiag(cie10.slice(0, 100));
    setPostDiag(cie10.slice(0, 100));
  }, [cie10]);

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
        getDoctorInformation();
      }
    } else {
      setPatientInformation(values.paciente as Patient);
      setDoctorInformation(values.medico as Doctor);
      setFormValues(values);
    }
  }, [currentPatient, getDoctorInformation, values]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  const formik = useFormik<SurgicalNote>({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (surgicalNoteValues) => {
      try {
        setLoading(true);
        const surgicalNoteResult = await postWithToken<
          {
            surgicalNote: SurgicalNote;
            doctorId?: number;
            patientId?: number;
          },
          SurgicalNote
        >(
          `/notes/${mode === CRUDModes.Create ? 'save' : 'update'}-surgical-note`,
          {
            surgicalNote: {
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
              ...surgicalNoteValues,
            },
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          handleLogout,
        );
        if (surgicalNoteResult.success) {
          setLoading(false);
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e) {
        handleLogout(e);
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`${e}`);
        setOpenAlert(true);
      }
    },
  });

  const handlePreDiagnosisInput = (value: string) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    setPreDiag(filteredCie.slice(0, 100));
  };

  const handlePostDiagnosisInput = (value: string) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    setPostDiag(filteredCie.slice(0, 100));
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='nota post operatoria'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Agregar nueva nota postoperatoria
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Recuerda llenar la información correctamente
                </PrimaryDescription>

                <Grid item xs={12} md={12}>
                  <CMTextfield
                    disabled={fieldsDisabled}
                    select
                    id='tipo_cirugia'
                    label='Cirugía'
                    placeholder='Cirugía'
                    value={formik.values.tipo_cirugia}
                    name='tipo_cirugia'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value='-1' disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value='Programada'>Programada</MenuItem>
                    <MenuItem value='Urgencia'>Urgencia</MenuItem>
                  </CMTextfield>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                      disabled={fieldsDisabled}
                      label='Fecha de inicio'
                      value={formik.values.fecha_cirugia}
                      onChange={(value): void => {
                        formik.setFieldValue('fecha_cirugia', value);
                      }}
                      inputFormat='DD/MM/yyyy'
                      renderInput={(params) => (
                        <CMTextfield
                          onBlur={formik.handleBlur}
                          {...params}
                          name='fecha_cirugia'
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      disabled={fieldsDisabled}
                      label='Hora de inicio'
                      value={formik.values.hora_inicio}
                      onChange={(value): void => {
                        formik.setFieldValue('hora_inicio', value);
                      }}
                      renderInput={(params) => (
                        <CMTextfield
                          onBlur={formik.handleBlur}
                          {...params}
                          name='hora_inicio'
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <TimePicker
                      disabled={fieldsDisabled}
                      label='Hora de término'
                      value={formik.values.hora_fin}
                      onChange={(value): void => {
                        formik.setFieldValue('hora_fin', value);
                      }}
                      renderInput={(params) => (
                        <CMTextfield
                          onBlur={formik.handleBlur}
                          {...params}
                          name='hora_fin'
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    disabled={fieldsDisabled}
                    openOnFocus
                    multiple
                    id='diag_preoperatorio'
                    options={preDiag}
                    noOptionsText='No hay opciones disponibles'
                    getOptionLabel={(option) =>
                      `${option.cie_clave} ${option.cie_descripcion}`
                    }
                    onBlur={formik.handleBlur}
                    onInputChange={(_event, value) => handlePreDiagnosisInput(value)}
                    value={formik.values.diag_preoperatorio}
                    onChange={(_e, value) => {
                      formik.setFieldValue('diag_preoperatorio', value);
                    }}
                    renderInput={(params) => (
                      <CMTextfield
                        {...params}
                        label='Diagnóstico preoperatorio'
                        placeholder='Diagnóstico preoperatorio'
                        name='diag_preoperatorio'
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Operación planeada</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='cirugia_programada'
                    minRows={2}
                    onBlur={formik.handleBlur}
                    placeholder='Operación planeada'
                    value={formik.values.cirugia_programada}
                    name='cirugia_programada'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Operación realizada</CMInputLabel>
                  <CMTextArea
                    onBlur={formik.handleBlur}
                    disabled={fieldsDisabled}
                    id='cirugia_efectuada'
                    minRows={2}
                    placeholder='Operación realizada'
                    value={formik.values.cirugia_efectuada}
                    name='cirugia_efectuada'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    disabled={fieldsDisabled}
                    openOnFocus
                    multiple
                    id='diag_postoperatorio'
                    options={postDiag}
                    onBlur={formik.handleBlur}
                    noOptionsText='No hay opciones disponibles'
                    getOptionLabel={(option) =>
                      `${option.cie_clave} ${option.cie_descripcion}`
                    }
                    onInputChange={(_event, value) => handlePostDiagnosisInput(value)}
                    value={formik.values.diag_postoperatorio}
                    onChange={(_e, value) => {
                      formik.setFieldValue('diag_postoperatorio', value);
                    }}
                    renderInput={(params) => (
                      <CMTextfield
                        {...params}
                        label='Diagnóstico postoperatorio'
                        placeholder='Diagnóstico postoperatorio'
                        name='diag_postoperatorio'
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Descripción de la técnica quirúrgica</CMInputLabel>
                  <CMTextArea
                    onBlur={formik.handleBlur}
                    disabled={fieldsDisabled}
                    id='tecnica'
                    minRows={2}
                    placeholder='Descripción de la técnica quirúrgica'
                    value={formik.values.tecnica}
                    name='tecnica'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <PrimarySubtitle mobile>Dificultades y hallazgos</PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsDisabled}
                        color='primary'
                        name='dificultad_cirugia'
                        id='dificultad_cirugia'
                        checked={formik.values.dificultad_cirugia}
                        onChange={formik.handleChange}
                      />
                    }
                    label='Tuvo dificultad técnica durante el procedimiento quirúrgico'
                    style={{ marginBottom: '1rem' }}
                  />
                </Grid>
                {formik.values.dificultad_cirugia ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyCronicDeg-div'
                  >
                    <Grid item xs={12} md={12}>
                      <CMInputLabel mobile>Especifique</CMInputLabel>
                      <CMTextArea
                        onBlur={formik.handleBlur}
                        disabled={fieldsDisabled}
                        id='dificultad_cirugia_descripcion'
                        minRows={2}
                        placeholder='Especifique'
                        value={formik.values.dificultad_cirugia_descripcion}
                        name='dificultad_cirugia_descripcion'
                        onChange={formik.handleChange}
                        style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CMInputLabel mobile>Hallazgos transoperatorios</CMInputLabel>
                      <CMTextArea
                        onBlur={formik.handleBlur}
                        disabled={fieldsDisabled}
                        id='hallazgos'
                        minRows={2}
                        placeholder='Hallazgos transoperatorios'
                        value={formik.values.hallazgos}
                        name='hallazgos'
                        onChange={formik.handleChange}
                        style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      />
                    </Grid>
                  </motion.div>
                ) : null}
                <PrimarySubtitle mobile>Gasas y compresas</PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsDisabled}
                        color='primary'
                        name=''
                        id='cuenta_gasas_compresas'
                        checked={formik.values.cuenta_gasas_compresas}
                        onChange={formik.handleChange}
                      />
                    }
                    label='Cuenta de gasas y compresas completas'
                    style={{ marginBottom: '1rem' }}
                  />
                </Grid>
                {formik.values.cuenta_gasas_compresas ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyCronicDeg-div'
                  >
                    <Grid item xs={12} md={12}>
                      <CMInputLabel mobile>Quién informo</CMInputLabel>
                      <CMTextArea
                        onBlur={formik.handleBlur}
                        disabled={fieldsDisabled}
                        id='cuenta_gasas_compresas_informo'
                        minRows={2}
                        placeholder='Quién informo'
                        value={formik.values.cuenta_gasas_compresas_informo}
                        name='cuenta_gasas_compresas_informo'
                        onChange={formik.handleChange}
                        style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CMInputLabel mobile>Incidentes y accidentes</CMInputLabel>
                      <CMTextArea
                        onBlur={formik.handleBlur}
                        disabled={fieldsDisabled}
                        id='complicaciones'
                        minRows={2}
                        placeholder='Incidentes y accidentes'
                        value={formik.values.complicaciones}
                        name='complicaciones'
                        onChange={formik.handleChange}
                        style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      />
                    </Grid>
                  </motion.div>
                ) : null}
                <PrimarySubtitle mobile>Sangrado</PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsDisabled}
                        color='primary'
                        name=''
                        id='sangrado'
                        checked={formik.values.sangrado}
                        onChange={formik.handleChange}
                      />
                    }
                    label='Cuantificación de sangrado'
                    style={{ marginBottom: '1rem' }}
                  />
                </Grid>
                {formik.values.sangrado ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyCronicDeg-div'
                  >
                    <Grid item xs={12} md={12}>
                      <CMInputLabel mobile>Cantidad aproximada</CMInputLabel>
                      <CMTextArea
                        onBlur={formik.handleBlur}
                        disabled={fieldsDisabled}
                        id='sangrado_cantidad'
                        minRows={2}
                        placeholder='Cantidad aproximada'
                        value={formik.values.sangrado_cantidad}
                        name='sangrado_cantidad'
                        onChange={formik.handleChange}
                        style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <CMInputLabel mobile>
                        Estudios de servicios auxiliares de diagnóstico transoperatorios
                      </CMInputLabel>
                      <CMTextArea
                        onBlur={formik.handleBlur}
                        disabled={fieldsDisabled}
                        id='servicios_aux'
                        minRows={2}
                        placeholder='Estudios de servicios auxiliares de diagnóstico transoperatorios'
                        value={formik.values.servicios_aux}
                        name='servicios_aux'
                        onChange={formik.handleChange}
                        style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      />
                    </Grid>
                  </motion.div>
                ) : null}
                <PrimarySubtitle mobile>Patología</PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsDisabled}
                        color='primary'
                        name=''
                        id='envio_pat'
                        checked={formik.values.envio_pat}
                        onChange={formik.handleChange}
                      />
                    }
                    label='Envio de piezas a patología'
                    style={{ marginBottom: '1rem' }}
                  />
                </Grid>
                {formik.values.envio_pat ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyCronicDeg-div'
                  >
                    <Grid item xs={12} md={12}>
                      <CMInputLabel mobile>Descripción piezas</CMInputLabel>
                      <CMTextArea
                        onBlur={formik.handleBlur}
                        disabled={fieldsDisabled}
                        id='envio_pat_desc'
                        minRows={2}
                        placeholder='Descripción piezas'
                        value={formik.values.envio_pat_desc}
                        name='envio_pat_desc'
                        onChange={formik.handleChange}
                        style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      />
                    </Grid>
                  </motion.div>
                ) : null}
                <PrimarySubtitle mobile style={{ marginBottom: '1rem' }}>
                  Equipo quirúrgico
                </PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    onBlur={formik.handleBlur}
                    disabled={fieldsDisabled}
                    id='cirujano'
                    label='Nombre del cirujano'
                    placeholder='Nombre del cirujano'
                    value={formik.values.cirujano}
                    name='cirujano'
                    onChange={formik.handleChange}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    id='ayudante'
                    onBlur={formik.handleBlur}
                    label='Nombre del primer ayudante'
                    placeholder='Nombre del primer ayudante'
                    value={formik.values.ayudante}
                    name='ayudante'
                    onChange={formik.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    id='segudo_ayudante'
                    onBlur={formik.handleBlur}
                    label='Nombre del segundo ayudante'
                    placeholder='Nombre del segundo ayudante'
                    value={formik.values.segudo_ayudante}
                    name='segudo_ayudante'
                    onChange={formik.handleChange}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    id='anestesiologo'
                    onBlur={formik.handleBlur}
                    label='Nombre del anestesiólogo'
                    placeholder='Nombre del anestesiólogo'
                    value={formik.values.anestesiologo}
                    name='anestesiologo'
                    onChange={formik.handleChange}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    id='instrumentista'
                    onBlur={formik.handleBlur}
                    label='Nombre del instrumentista'
                    placeholder='Nombre del instrumentista'
                    value={formik.values.instrumentista}
                    name='instrumentista'
                    onChange={formik.handleChange}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    id='circulante'
                    onBlur={formik.handleBlur}
                    label='Nombre de circulante'
                    placeholder='Nombre de circulante'
                    value={formik.values.circulante}
                    name='circulante'
                    onChange={formik.handleChange}
                  />
                </Grid>

                <PrimarySubtitle mobile style={{ marginBottom: '1rem' }}>
                  Estado posquirúrgico
                </PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Estado posquirúrgico inmedianto</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='estado_post'
                    minRows={2}
                    onBlur={formik.handleBlur}
                    placeholder='Estado posquirúrgico inmedianto'
                    value={formik.values.estado_post}
                    name='estado_post'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>
                    Plan de Manejo y Tratamiento Posquirúrgico Inmediato
                  </CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='pla_inm'
                    minRows={2}
                    onBlur={formik.handleBlur}
                    placeholder='Plan de Manejo y Tratamiento Posquirúrgico Inmediato'
                    value={formik.values.pla_inm}
                    name='pla_inm'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfield
                    disabled={fieldsDisabled}
                    select
                    id='pronostico'
                    onBlur={formik.handleBlur}
                    label='Pronóstico'
                    placeholder='Pronóstico'
                    value={formik.values.pronostico}
                    name='pronostico'
                    onChange={formik.handleChange}
                  >
                    <MenuItem value='-1' disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value='Bueno'>Bueno</MenuItem>
                    <MenuItem value='Regular'>Regular</MenuItem>
                    <MenuItem value='Malo'>Malo</MenuItem>
                    <MenuItem value='Reservado'>Reservado</MenuItem>
                  </CMTextfield>
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Otros hallazgos</CMInputLabel>
                  <CMTextArea
                    onBlur={formik.handleBlur}
                    disabled={fieldsDisabled}
                    id='otros_llagazgos'
                    minRows={2}
                    placeholder='Otros hallazgo'
                    value={formik.values.otros_llagazgos}
                    name='otros_llagazgos'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
              </Grid>
              {values &&
              values.medico &&
              mode !== CRUDModes.Create &&
              values.paciente &&
              values.id_medico &&
              user &&
              canPrintNote(values.paciente, values.id_medico, user) ? (
                <Grid item xs={12}>
                  <div style={{ display: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <SurgicalNoteDocument
                        patient={values.paciente}
                        doctor={values.medico}
                        values={values as SurgicalNote}
                      />
                    </Grid>
                  </div>

                  {!isIOSApp() ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      style={{ width: '100%' }}
                      onClick={() => handlePrint()}
                    >
                      Imprimir
                    </PrimaryButton>
                  ) : (
                    <MobileDownloadMessage />
                  )}
                </Grid>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

SurgicalNotesForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default SurgicalNotesForm;
