import React from 'react';
import { IoCloseCircleSharp } from 'react-icons/all';
import { DialogTitle } from '@mui/material';
import { PrimaryTitle } from '../PrimaryTitle/PrimaryTitle';
import { CRUDModes } from '../../interfaces/CRUD';
import { PrimaryDescription } from '../PrimaryDescription/PrimaryDescription';

interface DialogCustomTitleProps {
  onClose: () => void;
  mode: CRUDModes;
  title: string;
  description?: string;
}

const DialogCustomTitle = ({
  onClose,
  title,
  mode,
  description,
}: DialogCustomTitleProps): JSX.Element => {
  const setTitle = (): string => {
    if (mode === CRUDModes.Create) {
      return `Nueva ${title}`;
    }
    if (mode === CRUDModes.Update) {
      return `Ver ${title}`;
    }
    return title;
  };
  return (
    <DialogTitle>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <PrimaryTitle mobile style={{ width: 'calc(100% - 2rem)' }}>
          {setTitle()}
        </PrimaryTitle>
        <IoCloseCircleSharp
          style={{
            fontSize: '2rem',
            cursor: 'Pointer',
            color: '#1c1c1c',
          }}
          onClick={() => {
            onClose();
          }}
        />
      </div>
      {description ? <PrimaryDescription mobile>{description}</PrimaryDescription> : null}
      <hr style={{ margin: '1rem 0' }} />
    </DialogTitle>
  );
};

DialogCustomTitle.defaultProps = {
  description: '',
};

export default DialogCustomTitle;
