/* eslint-disable no-nested-ternary */
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useMainProvider } from '../../context/MainProvider';

function Private({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}): JSX.Element {
  const { user, initialLoad, loadInitialData } = useMainProvider();
  const location = useLocation();

  /**
   *
   * @desc checks if user is already set in the context if
   * thats the case then checks if user already filled initial data
   */
  const authenticated = () => {
    if (!initialLoad) {
      loadInitialData();
    }

    if (user && user.token) {
      return true;
    }

    return false;
  };

  /**
   *
   * @desc checks if initial dat is already filled and redirects user if not
   * @returns the component where the usr will be redireced
   */
  const checkInitialDataFill = (): JSX.Element => {
    if (path !== '/initial-form' && !user?.initialDataFilled) {
      return <Navigate to='/initial-form' />;
    }
    if (path === '/initial-form' && user?.initialDataFilled) {
      return <Navigate to='/login' />;
    }
    if (path !== '/validate' && user?.initialDataFilled && !user?.validated) {
      return <Navigate to='/validate' />;
    }
    if (path === '/validate' && user?.validated) {
      return <Navigate to='/login' />;
    }
    return children;
  };

  return !initialLoad ? (
    <></>
  ) : authenticated() ? (
    checkInitialDataFill()
  ) : (
    <Navigate
      to={{
        pathname: '/login',
        search: location.search,
      }}
    />
  );
}

export default Private;
