import styled from 'styled-components';
import { mainTheme } from '../../utils/theme/theme';

export const StandardText = styled.p`
  font-family: ${mainTheme.fonts.text.family};
  font-weight: ${mainTheme.fonts.text.weight};
  width: 100%;
  margin: 0;
  color: ${mainTheme.colors.standardText};
`;
