import React from 'react';
import { mainTheme } from '../../../utils/theme/theme';

const HMenuItem = (
  props: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
): JSX.Element => {
  return (
    <button
      type='button'
      {...props}
      style={{
        fontFamily: mainTheme.fonts.text.family,
        width: '100%',
        padding: '1.5rem',
        border: 'none',
        borderBottom: '1px solid #e6eaea',
        color: '#1c1c1c',
        textAlign: 'start',
        transition: 'background-color 0.3s',
        fontWeight: 500,
        fontSize: '1rem',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '&:hover': {
          transition: 'background-color 0.3s',
          backgroundColor: 'rgba(0, 0, 0, 0.03)',
          cursor: 'pointer',
        },
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        ...(props.style || {}),
      }}
    />
  );
};

export default HMenuItem;
