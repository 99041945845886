import React, { useCallback, useEffect, useState } from 'react';
import { useMainProvider } from '../../context/MainProvider';
import GenericTablePage from '../GenericTablePage/GenericTablePage';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import { CRUDModes } from '../../interfaces/CRUD';
import { UserTypes } from '../../interfaces/User';
import { gettWithToken, previewUserID } from '../../utils/server';
import {
  PreOperationNote,
  SimplifiedPreOperationNote,
} from '../../interfaces/PreOperationNote';
import PreOperationNotesForm from './PreOperationNotesForm';
import { getPatientFullName } from '../../utils/fns';
import { Patient } from '../../interfaces/Patients';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import { ZivotDocumentTypes } from '../../interfaces/DoctorProfile';

const PreOperationNotePage = (): JSX.Element => {
  const {
    setLoading,
    user,
    currentPatient,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    restartTemporayUsers,
    setLoginPopupOpen,
    handleLogout,
  } = useMainProvider();
  const [entries, setEntries] = useState<PreOperationNote[]>([]);
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await gettWithToken<PreOperationNote[]>(
        `/notes/get-pre-operation-notes-doctor?patientId=${
          currentPatient ? currentPatient.id : undefined
        }`,
        handleLogout,
      );
      if (fetchResult.success) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID Nota',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha',
            displayName: 'Fecha de creación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'doctorName',
            displayName: 'Médico que Elaboró',
            type: GenericColumnTypes.ShowProfile,
            sortable: true,
            documentType: ZivotDocumentTypes.preOperationNote,
          },
          {
            name: 'doctorSpeciality',
            displayName: 'Especialidad',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'patientName',
            displayName: 'Nombre Paciente',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: isIOSApp() ? 'Consultar' : 'Consultar y/o Imprimir',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
        ]);
        const simplifiedData: SimplifiedPreOperationNote[] = orderedRows.map(
          (preOperationNote) => {
            return {
              id: preOperationNote.id,
              fecha: preOperationNote.fecha,
              doctorName: `${preOperationNote.medico?.med_nombre} ${preOperationNote.medico?.med_apellidop} ${preOperationNote.medico?.med_apellidom}`,
              doctorSpeciality:
                preOperationNote.medico?.med_especialidad?.cat_especialidad_desc,
              patientName: `${preOperationNote.paciente?.pac_nombre} ${preOperationNote.paciente?.pac_apellidop} ${preOperationNote.paciente?.pac_apellidom}`,
              moreInformation: isIOSApp() ? 'Consultar' : 'Ver',
            };
          },
        );
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener notas preoperatorias : ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
        if (
          e.response.data ===
          'El tiempo de acceso al Expediente Clínico del Paciente terminó, si requiere continuar, favor de escanear otro código QR de su Paciente. Gracias'
        ) {
          restartTemporayUsers();
        }
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  // Avoid infinite loop
  const fetchCallback = useCallback(fetchEntries, [
    currentPatient,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    restartTemporayUsers,
    handleLogout,
  ]);

  useEffect(() => {
    fetchCallback().then(EmptyClosure).catch(EmptyClosure);
  }, [fetchCallback]);

  return (
    <>
      <PreOperationNotesForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={selectedRow !== -1 ? entries[selectedRow] : undefined}
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      <GenericTablePage
        title={`Notas preoperatorias${
          currentPatient
            ? ` de ${getPatientFullName(currentPatient.initialData as Patient)}`
            : ''
        }`}
        rows={rows}
        columns={columns}
        canDelete={false}
        canEdit
        canCreate={user && currentPatient ? user.typeId === UserTypes.DoctorType : false}
        onCreate={() => {
          if (user && user.id === previewUserID) {
            setLoginPopupOpen(true);
          } else {
            setOpen(true);
            setSelectedRow(-1);
            setMode(CRUDModes.Create);
          }
        }}
      />
    </>
  );
};

export default PreOperationNotePage;
