/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { Dialog, DialogContent, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useReactToPrint } from 'react-to-print';
import { useFormik } from 'formik';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { SimpleCards } from '../../interfaces/SimpleCards';
import { Patient } from '../../interfaces/Patients';
import { postWithToken } from '../../utils/server';
import SimpleCardsDocument from './SimpleCardsDocument';
import { Doctor } from '../../interfaces/Doctors';
import { canPrintNote } from '../../utils/fns';
import PatientData from '../../components/PatientData/PatientData';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import CenteredForm from '../../index.styled';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';
import EmptyClosure from '../../utils/closures';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: SimpleCards;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: SimpleCards = {
  medico_tratante: '',
  diagnostico: '',
  resp: '',
  iden_resp: '',
  par_resp: '',
  rep_legal: '',
  iden_rep_legal: '',
  par_rep_legal: '',
  testigo1: '',
  iden_testigo1: '',
  testigo2: '',
  iden_testigo2: '',
  denegacion: false,
};

function SimpleCardsForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const printRef = useRef<HTMLInputElement>(null);
  const [date, setDate] = useState<Moment>(moment());
  const [doctorInformation, setDoctorInformation] = useState<Doctor | null>(null);
  const [patientInformation, setPatientInformation] = useState<Patient | null>(null);
  const [formValues, setFormValues] = useState(initialValues);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.id_medico !== user?.id,
    [mode, formValues, user?.id],
  );

  const getDoctorInformation = useCallback(async () => {
    try {
      const doctorResult = await postWithToken<
        {
          doctorId?: number;
        },
        Doctor
      >(
        '/doctor/get-doctor',
        {
          doctorId: user?.id,
        },
        handleLogout,
      );
      if (doctorResult.success) {
        setDoctorInformation(doctorResult.data);
      }
    } catch (e) {
      handleLogout(e);
      console.log(e);
    }
  }, [handleLogout, user?.id]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  const formik = useFormik<SimpleCards>({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: async (simpleCardValues) => {
      try {
        setLoading(true);
        setDate(moment());
        const simpleCardResult = await postWithToken<
          {
            simpleCard: SimpleCards;
            doctorId?: number;
            patientId?: number;
          },
          SimpleCards
        >(
          `/cards/${mode === CRUDModes.Create ? 'save' : 'update'}-simple-card`,
          {
            simpleCard: {
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
              ...simpleCardValues,
              id_paciente:
                currentPatient && mode === CRUDModes.Create
                  ? currentPatient.id
                  : undefined,
              id_medico: user?.id,
            },
            doctorId: user?.id,
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          handleLogout,
        );
        if (simpleCardResult.success) {
          setLoading(false);
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          setFormValues(initialValues);
          setDate(moment.utc().utcOffset(-300));
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e) {
        handleLogout(e);
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`${e}`);
        setOpenAlert(true);
      }
    },
  });

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
        getDoctorInformation().then(EmptyClosure).catch(EmptyClosure);
      }
    } else {
      setPatientInformation(values.paciente as Patient);
      setDoctorInformation(values.medico as Doctor);
      setDate(moment(values?.fecha));
      setFormValues(values);
    }
  }, [currentPatient, getDoctorInformation, values]);

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setDate(moment.utc().utcOffset(-300));
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setDate(moment.utc().utcOffset(-300));
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='carta de consentimiento simple'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={1}
          >
            <Grid item xs={12}>
              <PatientData patientInformation={patientInformation || undefined} />
            </Grid>
            <Grid item xs={12}>
              <PrimarySubtitle mobile>Carta de consentimiento simple</PrimarySubtitle>
              <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                Revise que la información del paciente y doctor sean correctas
              </PrimaryDescription>
            </Grid>

            <CenteredForm onSubmit={formik.handleSubmit}>
              {doctorInformation && patientInformation ? (
                <Grid item xs={12}>
                  <Grid
                    xs={12}
                    id='sipleCardDiv'
                    ref={printRef}
                    style={{
                      marginBottom: '1rem',
                      background: 'rgba(0,0,0,0.1)',
                      padding: '.8rem',
                      borderRadius: '10px',
                    }}
                  >
                    <SimpleCardsDocument
                      diagnostico={formik.values.diagnostico}
                      patient={patientInformation}
                      doctor={doctorInformation}
                      date={date}
                      rep={formik.values.resp}
                      repIdentification={formik.values.iden_resp}
                      par_rep={formik.values.par_resp}
                      repLegal={formik.values.rep_legal}
                      repLegalIdentification={formik.values.iden_rep_legal}
                      par_rep_legal={formik.values.par_rep_legal}
                      testigo1={formik.values.testigo1}
                      testigo1Identificacion={formik.values.iden_testigo1}
                      testigo2={formik.values.testigo2}
                      testigo2Identificacion={formik.values.iden_testigo2}
                      denegacion={formik.values.denegacion}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfield
                      id='diagnostico'
                      label='Diagnóstico(s) presuntivo(s)'
                      placeholder='Diagnóstico(s) presuntivo(s)'
                      value={formik.values.diagnostico}
                      name='diagnostico'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='resp'
                      label='Familiar responsable'
                      placeholder='Familiar responsable'
                      value={formik.values.resp}
                      name='resp'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                    <CMTextfieldHalfRight
                      id='iden_resp'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_resp}
                      name='iden_resp'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfield
                      id='par_resp'
                      label='Parentesco del familiar responsable'
                      placeholder='Parentesco del familiar responsable'
                      value={formik.values.par_resp}
                      name='par_resp'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='rep_legal'
                      label='Representante legal'
                      placeholder='Representante legal'
                      value={formik.values.rep_legal}
                      name='rep_legal'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                    <CMTextfieldHalfRight
                      id='iden_rep_legal'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_rep_legal}
                      name='iden_rep_legal'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfield
                      id='par_rep_legal'
                      label='Parentesco del representante legal'
                      placeholder='Parentesco del representante legal'
                      value={formik.values.par_rep_legal}
                      name='par_rep_legal'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='testigo1'
                      label='Testigo 1'
                      placeholder='Testigo 1'
                      value={formik.values.testigo1}
                      name='testigo1'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                    <CMTextfieldHalfRight
                      id='iden_testigo1'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_testigo1}
                      name='iden_testigo1'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='testigo2'
                      label='Testigo 2'
                      placeholder='Testigo 2'
                      value={formik.values.testigo2}
                      name='testigo2'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                    <CMTextfieldHalfRight
                      id='iden_testigo2'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_testigo2}
                      name='iden_testigo2'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          name='denegacion'
                          id='denegacion'
                          checked={formik.values.denegacion}
                          onChange={formik.handleChange}
                          disabled={fieldsDisabled}
                        />
                      }
                      label='Denegación'
                      style={{ marginBottom: '1rem' }}
                    />
                  </Grid>
                  {values &&
                  values.medico &&
                  mode !== CRUDModes.Create &&
                  values.paciente &&
                  values.id_medico &&
                  user &&
                  canPrintNote(values.paciente, values.id_medico, user) ? (
                    !isIOSApp() ? (
                      <PrimaryButton
                        loading={loading}
                        type='button'
                        style={{ width: '100%', marginBottom: '1rem' }}
                        onClick={() => handlePrint()}
                      >
                        Imprimir
                      </PrimaryButton>
                    ) : (
                      <MobileDownloadMessage />
                    )
                  ) : null}
                  {!fieldsDisabled ? (
                    <Grid item xs={12}>
                      <PrimaryButton
                        loading={loading}
                        type='button'
                        onClick={formik.handleSubmit}
                        style={{ width: '100%' }}
                      >
                        {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                      </PrimaryButton>
                      {mode === CRUDModes.Update ? (
                        <PrimaryButton
                          loading={loading}
                          type='button'
                          onClick={async () => {
                            await formik.setFieldValue('isActive', false);
                            formik.handleSubmit();
                          }}
                          style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                        >
                          Eliminar
                        </PrimaryButton>
                      ) : null}
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                    No hay información del paciente o doctor disponible
                  </PrimaryDescription>
                </Grid>
              )}
            </CenteredForm>
          </Grid>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

SimpleCardsForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default SimpleCardsForm;
