import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import DialogCustomTitle from '../../../../../components/DialogCustomTitle/DialogCustomTitle';
import { SponsorCampaign } from '../SponsorCampaigns.interfaces';
import SponsorCampaignForm from './SponsorCampaignsForm';

export interface SponsorCampaignsFormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  onSuccess: () => void;
  values?: SponsorCampaign;
  closeFunction?: () => void;
}

const SponsorsCampaignsPopup = ({
  open,
  setOpen,
  mode,
  onSuccess,
  values,
  closeFunction,
}: SponsorCampaignsFormProps): JSX.Element => {
  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='campaña'
      />
      <DialogContent>
        {open ? (
          <SponsorCampaignForm
            onSuccess={() => {
              setOpen(false);
              if (closeFunction) closeFunction();
              onSuccess();
            }}
            mode={mode}
            values={values}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default SponsorsCampaignsPopup;
