import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';

import * as Yup from 'yup';
import { ClinicPediatricHistory } from '../../interfaces/ClinicPediatricHistory';
import { CRUDModes } from '../../interfaces/CRUD';
import { BloodTypes } from '../../interfaces/BloodTypes';
import { useMainProvider } from '../../context/MainProvider';
import { setHeaders } from '../../utils/headerSetter';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { Patient } from '../../interfaces/Patients';
import { HistoriasVacunas } from '../../interfaces/HistoriasVacunas';
import { Cie10 } from '../../interfaces/Cie10';
import { Vacuna } from '../../interfaces/Vacuna';
import { vaccineDictionary, VaccinePeriods } from '../../interfaces/VaccinePeriods';
import { RequiredMessage } from '../../utils/validationMessages';
import useAds from '../../hooks/useAds';
import { AdCatalog, AdTypes } from '../../interfaces/Ads';
import { FlexContainer } from '../../components/FlexContainer/FlexContainer';
import Ad from '../../components/Ad/Ad';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: ClinicPediatricHistory;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: ClinicPediatricHistory = {
  isActive: true,
  numero_embarazo: 0,
  numero_abortos: 0,
  tipo_sangre: BloodTypes.Unknown,
  numero_gesta: 0,
  tipo_parto: '',
  apgar: '',
  cunero: '',
  peso_nacimiento: '',
  talla_nacimiento: '',
  peso: 0,
  altura: 0,
  imc: 0,
  tiene_alergias: false,
  alergias_medicamentos: '',
  alergias_otros: '',
  alergias_alimentos: '',
  craneo_cara: '',
  cuello: '',
  torax: '',
  abdomen: '',
  genitales: '',
  extremidades: '',
  columna_vertebral: '',
  resultados_previos: '',
  pronostico: '',
  indicacion_terapeutica: '',
  tiene_hospitalizaciones_previas: false,
  hospitalizaciones_previas: '',
  tiene_antecedentes_quirurgicos: false,
  antecedentes_quirurgicos: '',
  tiene_fracturas: false,
  tiene_antecedentes_traumatismo: false,
  antecedentes_traumatismo: '',
  tiene_otras_enfermedades: false,
  otras_enfermedades: '',
  tiene_tabaquismo: false,
  quienes_tabaquismo: '',
  tiene_alcoholismo: false,
  quienes_alcoholismo: '',
  tiene_drogadiccion: false,
  quienes_drogadiccion: '',
  tiene_diabetes: false,
  quienes_diabetes: '',
  tiene_hipertension: false,
  quienes_hipertension: '',
  tiene_cancer: false,
  quienes_cancer: '',
  tipo_cancer: '',
  tiene_cardiopatias: false,
  quienes_cardiopatias: '',
  tiene_nefropatas: false,
  quienes_nefropatas: '',
  tiene_malformaciones: false,
  quienes_malformaciones: '',
  tipo_malformaciones: '',
  tiene_otro_antedecente: false,
  otro_antedecente: '',
  tiene_transfusiones_previas: false,
  transfusiones_previas: '',
  padecimiento_actual: '',
  superficie_corporal: '',
  presion_arterial: '',
  frecuencia_cardiaca: '',
  frcuencia_respiratoria: '',
  temperatura: '',
  tabaquismo_pasivo: false,
  vivienda_servicios: false,
  desarrollo_psicomotor_normal: false,
  tiene_alimentacion_adecuada: false,
  alimentacion_adecuada: '',
  tiene_otros_antecedentes_no_patologicos: false,
  otros_antecedentes_no_patologicos: '',
  historia_pediatrica_diagnosticos: [],
};

const validationSchema = Yup.object({
  peso: Yup.number().required(RequiredMessage),
  altura: Yup.number().required(RequiredMessage),
  superficie_corporal: Yup.number().required(RequiredMessage),
  presion_arterial: Yup.string().required(RequiredMessage),
  frecuencia_cardiaca: Yup.number().required(RequiredMessage),
  frcuencia_respiratoria: Yup.number().required(RequiredMessage),
  temperatura: Yup.number().required(RequiredMessage),
});

const ClinicPediatricHistoryForm = ({
  open,
  setOpen,
  values,
  closeFunction,
  mode,
  onSuccess,
}: FormProps): JSX.Element => {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    currentPatient,
    cie10,
    getCie10,
    handleLogout,
  } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [patientInformation, setPatientInformation] = useState<Patient>();
  const [cie10Search, setCie10Search] = useState<Cie10[]>([]);
  const [vacunas, setVacunas] = useState<Vacuna[]>([]);
  const [vacunasSeleccionadas, setVacunasSeleccionadas] = useState<HistoriasVacunas[]>(
    [],
  );
  const adInfo = useAds(AdCatalog.detalleHistoriaClinicaPediatrica);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.doctor_id !== user?.id,
    [mode, formValues, user?.id],
  );

  const getVacunas = async () => {
    if (!vacunas.length) {
      try {
        const results = await axios.get<Vacuna[]>(
          `${process.env.REACT_APP_SERVER_URL}/clinicPediatricHistory/vacunas`,
          setHeaders(false, user ? user.token : undefined),
        );
        setVacunas(results.data);
      } catch (e) {
        console.log(e);
        // We do nothing to prevent a bad User Experience
      }
    }
  };

  const vacunasCallback = useCallback(getVacunas, [user, vacunas]);

  useEffect(() => {
    vacunasCallback();
  }, [vacunasCallback]);

  useEffect(() => {
    getCie10();
  }, [getCie10]);

  useEffect(() => {
    setCie10Search(cie10.slice(0, 100));
  }, [cie10]);

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
      }
    } else {
      setPatientInformation(values.patientInformation as Patient);
      setFormValues(values);
      if (values.vacunas_seleccionadas) {
        setVacunasSeleccionadas(values.vacunas_seleccionadas);
      }
    }
  }, [currentPatient, values]);

  const formik = useFormik<ClinicPediatricHistory>({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (form) => {
      try {
        setLoading(true);
        const emailResult = await axios.post<{
          newEntry: ClinicPediatricHistory;
        }>(
          `${process.env.REACT_APP_SERVER_URL}/clinicPediatricHistory/${
            mode === CRUDModes.Create ? 'post' : 'update'
          }`,
          {
            newEntry: {
              ...form,
              bodyMassIndex: form.peso / form.altura ** 2,
              vacunas_seleccionadas: vacunasSeleccionadas,
            },
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          setHeaders(true, user ? user.token : undefined),
        );
        if (emailResult.status === 200) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `¡Historia clínica ${
              mode === CRUDModes.Create ? 'creada' : 'actualizada'
            } exitosamente!`,
          );
          setVacunasSeleccionadas([]);
          setOpenAlert(true);
          setLoading(false);
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar historia clínica pediátrica : ${JSON.stringify(
              e.response.data,
            )} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleDiagnosisInput = (value: string) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    setCie10Search(filteredCie);
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setVacunasSeleccionadas([]);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setVacunasSeleccionadas([]);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='historia clínica pediátrica'
      />

      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              {adInfo ? (
                <Grid item xs={12}>
                  <FlexContainer direction='row' justifyContent='center'>
                    <Ad
                      adInfo={adInfo}
                      type={mobile ? AdTypes.mobileBanner : AdTypes.leaderboard}
                    />
                  </FlexContainer>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Antecedentes prenatales</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Llena la información sobre los antecedentes prenatales del paciente.
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  id='numero_embarazo'
                  label='Procede del embarazo No.'
                  placeholder='Embarazo #'
                  value={formik.values.numero_embarazo || ''}
                  name='numero_embarazo'
                  onChange={formik.handleChange}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  id='numero_abortos'
                  label='Abortos'
                  placeholder='Abortos'
                  value={formik.values.numero_abortos || ''}
                  name='numero_abortos'
                  onChange={formik.handleChange}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  select
                  id='tipo_sangre'
                  label='Tipo de Sangre'
                  value={formik.values.tipo_sangre}
                  name='tipo_sangre'
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value={BloodTypes.Unknown}>
                    No sabe
                  </MenuItem>
                  <MenuItem value={BloodTypes.A1Negative}>
                    {BloodTypes.A1Negative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A1Positive}>
                    {BloodTypes.A1Positive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A2Negative}>
                    {BloodTypes.A2Negative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A2Positive}>
                    {BloodTypes.A2Positive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.ABNegative}>
                    {BloodTypes.ABNegative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.ABPositive}>
                    {BloodTypes.ABPositive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.BNegative}>{BloodTypes.BNegative}</MenuItem>
                  <MenuItem value={BloodTypes.BPositive}>{BloodTypes.BPositive}</MenuItem>
                  <MenuItem value={BloodTypes.ONegative}>{BloodTypes.ONegative}</MenuItem>
                  <MenuItem value={BloodTypes.OPositive}>{BloodTypes.OPositive}</MenuItem>
                  <MenuItem value={BloodTypes.RHNegative}>
                    {BloodTypes.RHNegative}
                  </MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                  id='numero_gesta'
                  label='No. de la gesta'
                  placeholder='No. de la gesta'
                  value={formik.values.numero_gesta || ''}
                  name='numero_gesta'
                  onChange={formik.handleChange}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  select
                  id='tipo_parto'
                  label='Tipo de parto'
                  value={formik.values.tipo_parto}
                  name='tipo_parto'
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value=''>
                    No sabe
                  </MenuItem>
                  <MenuItem value='Cesárea'>Cesárea</MenuItem>
                  <MenuItem value='Parto Natural'>Parto Natural</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='text'
                  id='apgar'
                  label='Apgar'
                  placeholder='Apgar'
                  value={formik.values.apgar}
                  name='apgar'
                  onChange={formik.handleChange}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='text'
                  id='cunero'
                  label='¿Permaneció en cunero?'
                  placeholder='¿Permaneció en cunero?'
                  value={formik.values.cunero}
                  name='cunero'
                  onChange={formik.handleChange}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='text'
                  id='peso_nacimiento'
                  label='Peso al nacer'
                  placeholder='Peso al nacer'
                  value={formik.values.peso_nacimiento}
                  name='peso_nacimiento'
                  onChange={formik.handleChange}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='text'
                  id='talla_nacimiento'
                  label='Talla al nacer'
                  placeholder='Talla al nacer'
                  value={formik.values.talla_nacimiento}
                  name='talla_nacimiento'
                  onChange={formik.handleChange}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Exploración física</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  El índice de masa corporal se calculará automáticamente después de haber
                  llenado el peso y altura del paciente.
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='peso'
                  label='Peso(Kg)'
                  placeholder='Peso(Kg)'
                  value={formik.values.peso || ''}
                  name='peso'
                  onChange={formik.handleChange}
                  error={formik.touched.peso && Boolean(formik.errors.peso)}
                  helperText={formik.touched.peso && formik.errors.peso}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='altura'
                  label='Altura(m)'
                  placeholder='Altura(m)'
                  value={formik.values.altura || ''}
                  name='altura'
                  onChange={formik.handleChange}
                  error={formik.touched.altura && Boolean(formik.errors.altura)}
                  helperText={formik.touched.altura && formik.errors.altura}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              {formik.values.peso && formik.values.altura ? (
                <Grid item xs={12}>
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                  >
                    <PrimaryDescription style={{ margin: '0 0 1rem 0' }}>
                      <b>Índice de masa corporal:</b>
                      {(formik.values.peso / formik.values.altura ** 2).toFixed(2)}
                    </PrimaryDescription>
                  </motion.div>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='superficie_corporal'
                  label='Superficie Corporal(m^2)'
                  placeholder='Superficie Corporal(m^2)'
                  value={formik.values.superficie_corporal || ''}
                  name='superficie_corporal'
                  onChange={formik.handleChange}
                  error={
                    formik.touched.superficie_corporal &&
                    Boolean(formik.errors.superficie_corporal)
                  }
                  helperText={
                    formik.touched.superficie_corporal &&
                    formik.errors.superficie_corporal
                  }
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  id='presion_arterial'
                  value={formik.values.presion_arterial}
                  name='presion_arterial'
                  label='Tensión Arterial(mmHg)'
                  placeholder='Tensión Arterial(mmHg)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.presion_arterial &&
                    Boolean(formik.errors.presion_arterial)
                  }
                  helperText={
                    formik.touched.presion_arterial && formik.errors.presion_arterial
                  }
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='frecuencia_cardiaca'
                  value={formik.values.frecuencia_cardiaca || ''}
                  name='frecuencia_cardiaca'
                  label='Frecuencia Cardiaca(LPM)'
                  placeholder='Frecuencia Cardiaca(LPM)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.frecuencia_cardiaca &&
                    Boolean(formik.errors.frecuencia_cardiaca)
                  }
                  helperText={
                    formik.touched.frecuencia_cardiaca &&
                    formik.errors.frecuencia_cardiaca
                  }
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='frcuencia_respiratoria'
                  value={formik.values.frcuencia_respiratoria || ''}
                  name='frcuencia_respiratoria'
                  label='Frecuencia Respiratoria(RPM)'
                  placeholder='Frecuencia Respiratoria(RPM)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.frcuencia_respiratoria &&
                    Boolean(formik.errors.frcuencia_respiratoria)
                  }
                  helperText={
                    formik.touched.frcuencia_respiratoria &&
                    formik.errors.frcuencia_respiratoria
                  }
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='temperatura'
                  value={formik.values.temperatura || ''}
                  name='temperatura'
                  label='Temperatura(°C)'
                  placeholder='Temperatura(°C)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={formik.touched.temperatura && Boolean(formik.errors.temperatura)}
                  helperText={formik.touched.temperatura && formik.errors.temperatura}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      name='tiene_alergias'
                      id='tiene_alergias'
                      checked={formik.values.tiene_alergias}
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                  }
                  label='Alergias'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_alergias ? (
                  <motion.div
                    initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                    exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                    animate={{
                      opacity: 1,
                      [mobile ? 'height' : 'width']: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='alergicMedicine-div'
                  >
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='alergias_medicamentos'
                      label='Principio activo'
                      placeholder='Principio activo'
                      value={formik.values.alergias_medicamentos}
                      name='alergias_medicamentos'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </motion.div>
                ) : null}
              </Grid>
              {formik.values.tiene_alergias ? (
                <>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='foodAlergies-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='alergias_alimentos'
                        label='Alimentos'
                        placeholder='Alimentos'
                        value={formik.values.alergias_alimentos}
                        name='alergias_alimentos'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otherAlergies-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='alergias_otros'
                        label='¿Otro tipo?'
                        placeholder='Otras alergias'
                        value={formik.values.alergias_otros}
                        name='alergias_otros'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <CMInputLabel mobile>Cráneo y cara</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $spacing={2}
                  id='craneo_cara'
                  name='craneo_cara'
                  value={formik.values.craneo_cara}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Cuello</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $spacing={2}
                  id='cuello'
                  name='cuello'
                  value={formik.values.cuello}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Tórax</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $spacing={2}
                  id='torax'
                  name='torax'
                  value={formik.values.torax}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Abdomen</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $spacing={2}
                  id='abdomen'
                  name='abdomen'
                  value={formik.values.abdomen}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Genitales</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $spacing={2}
                  id='genitales'
                  name='genitales'
                  value={formik.values.genitales}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Extremidades</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $spacing={2}
                  id='extremidades'
                  name='extremidades'
                  value={formik.values.extremidades}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Columna vetebral</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $spacing={2}
                  id='columna_vertebral'
                  name='columna_vertebral'
                  value={formik.values.columna_vertebral}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Antecedentes Personales Patológicos
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Marca las casillas si el paciente ha experimentado alguno de los
                  siguientes antecedentes:
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_hospitalizaciones_previas'
                      id='tiene_hospitalizaciones_previas'
                      checked={formik.values.tiene_hospitalizaciones_previas}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Hospitalizaciones previas'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_hospitalizaciones_previas ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='hospitalizaciones_previas-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='hospitalizaciones_previas'
                        label='¿Cuáles?'
                        placeholder='Hospitalizaciones previas'
                        value={formik.values.hospitalizaciones_previas}
                        name='hospitalizaciones_previas'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_antecedentes_quirurgicos'
                      id='tiene_antecedentes_quirurgicos'
                      checked={formik.values.tiene_antecedentes_quirurgicos}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Antecedentes quirúrgicos'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_antecedentes_quirurgicos ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='quirurgicBackground-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='antecedentes_quirurgicos'
                        label='¿Cuáles?'
                        placeholder='Antecedentes quirúrgicos'
                        value={formik.values.antecedentes_quirurgicos}
                        name='antecedentes_quirurgicos'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_transfusiones_previas'
                      id='tiene_transfusiones_previas'
                      checked={formik.values.tiene_transfusiones_previas}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Transfusiones previas'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_transfusiones_previas ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='previousTransfusions-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='transfusiones_previas'
                        label='Descripción'
                        placeholder='Transfusiones previas'
                        value={formik.values.transfusiones_previas}
                        name='transfusiones_previas'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_antecedentes_traumatismo'
                      id='tiene_antecedentes_traumatismo'
                      checked={formik.values.tiene_antecedentes_traumatismo}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Antecedentes de Traumatismo'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_antecedentes_traumatismo ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='traumas-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='antecedentes_traumatismo'
                        label='¿Cuáles?'
                        placeholder='Antecedentes de Traumatismo'
                        value={formik.values.antecedentes_traumatismo}
                        name='antecedentes_traumatismo'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_fracturas'
                      id='tiene_fracturas'
                      checked={formik.values.tiene_fracturas}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Fracturas'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_otras_enfermedades'
                      id='tiene_otras_enfermedades'
                      checked={formik.values.tiene_otras_enfermedades}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Otras enfermedades'
                />
              </Grid>
              <Grid item xs={12}>
                {formik.values.tiene_otras_enfermedades ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otherDiseases-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='otras_enfermedades'
                        label='Otras enfermedades'
                        placeholder='Otras enfermedades'
                        value={formik.values.otras_enfermedades}
                        name='otras_enfermedades'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Antecedentes No Patológicos</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Marca las casillas si el paciente ha experimentado alguno de los
                  siguientes antecedentes:
                </PrimaryDescription>
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tabaquismo_pasivo'
                      id='tabaquismo_pasivo'
                      checked={formik.values.tabaquismo_pasivo}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Tabaquismo pasivo'
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='vivienda_servicios'
                      id='vivienda_servicios'
                      checked={formik.values.vivienda_servicios}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Vivienda con servicios básicos'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='desarrollo_psicomotor_normal'
                      id='desarrollo_psicomotor_normal'
                      checked={formik.values.desarrollo_psicomotor_normal}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Desarrollo psicomotor normal'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_alimentacion_adecuada'
                      id='tiene_alimentacion_adecuada'
                      checked={formik.values.tiene_alimentacion_adecuada}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Alimentación adecuada'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_alimentacion_adecuada ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='alimentacion_adecuada-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='alimentacion_adecuada'
                        label='Descripción'
                        placeholder='Alimentación'
                        value={formik.values.alimentacion_adecuada}
                        name='alimentacion_adecuada'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_otros_antecedentes_no_patologicos'
                      id='tiene_otros_antecedentes_no_patologicos'
                      checked={formik.values.tiene_otros_antecedentes_no_patologicos}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Otros antecedentes'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_otros_antecedentes_no_patologicos ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otros_antecedentes_no_patologicos-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='otros_antecedentes_no_patologicos'
                        label='Descripción'
                        placeholder='Otros antecedentes'
                        value={formik.values.otros_antecedentes_no_patologicos}
                        name='otros_antecedentes_no_patologicos'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile style={{ marginBottom: '1rem' }}>
                  Vacunas
                </PrimarySubtitle>
                <Grid
                  container
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  wrap='wrap'
                  spacing={2}
                >
                  <>
                    {vacunas.map((vacuna) => {
                      return (
                        <Grid item xs={12} key={`vaccine-${vacuna.nombre}`}>
                          <Grid container direction='row' justifyContent='center'>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              style={{ borderBottom: '1px solid grey' }}
                            >
                              <b>{vacuna.nombre}</b>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={9}
                              style={{ borderBottom: '1px solid grey' }}
                            >
                              <Grid
                                container
                                direction='row'
                                justifyContent='flex-start'
                                alignItems='flex-start'
                                wrap='wrap'
                              >
                                {Object.values(VaccinePeriods)
                                  .filter((val) => typeof val === 'number')
                                  .map((period) => {
                                    return (
                                      <Grid item xs key={`${vacuna.nombre}-${period}`}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disabled={fieldsDisabled}
                                              onBlur={formik.handleBlur}
                                              checked={
                                                !!vacunasSeleccionadas.find(
                                                  (vac) =>
                                                    vac.vacuna_id === vacuna.id &&
                                                    vac.periodicidad === period,
                                                )
                                              }
                                              onChange={(e) => {
                                                const vaccine = vacunasSeleccionadas.find(
                                                  (vac) =>
                                                    vac.vacuna_id === vacuna.id &&
                                                    vac.periodicidad === period,
                                                );
                                                if (vaccine && !e.target.checked) {
                                                  const newSelection = [
                                                    ...vacunasSeleccionadas,
                                                  ];
                                                  newSelection.splice(
                                                    vacunasSeleccionadas.indexOf(vaccine),
                                                    1,
                                                  );
                                                  setVacunasSeleccionadas(newSelection);
                                                } else if (
                                                  e.target.checked &&
                                                  vacuna.id
                                                ) {
                                                  setVacunasSeleccionadas([
                                                    ...vacunasSeleccionadas,
                                                    {
                                                      historia_id: 0,
                                                      vacuna_id: vacuna.id,
                                                      periodicidad:
                                                        period as VaccinePeriods,
                                                    },
                                                  ]);
                                                }
                                              }}
                                            />
                                          }
                                          label={
                                            vaccineDictionary[period as VaccinePeriods]
                                          }
                                        />
                                      </Grid>
                                    );
                                  })}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Padecimiento Actual</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='padecimiento_actual'
                  name='padecimiento_actual'
                  value={formik.values.padecimiento_actual || ''}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Antedentes Heredofamiliares</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Marca las casillas si el paciente o algún familiar directo padece uno de
                  los siguientes padecimientos:
                </PrimaryDescription>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_diabetes'
                      id='tiene_diabetes'
                      checked={formik.values.tiene_diabetes}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Diabetes'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_diabetes ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='diabeticRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='quienes_diabetes'
                        label='¿Quiénes?'
                        placeholder='Familiares con diabetes'
                        value={formik.values.quienes_diabetes}
                        name='quienes_diabetes'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_hipertension'
                      id='tiene_hipertension'
                      checked={formik.values.tiene_hipertension}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Hipertensión'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_hipertension ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='hipertensionRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='quienes_hipertension'
                        label='¿Quiénes?'
                        placeholder='Familiares con hipertensión'
                        value={formik.values.quienes_hipertension}
                        name='quienes_hipertension'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_cancer'
                      id='tiene_cancer'
                      checked={formik.values.tiene_cancer}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Cáncer'
                />
              </Grid>
              {formik.values.tiene_cancer ? (
                <>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='cancerRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='quienes_cancer'
                        label='¿Quiénes?'
                        placeholder='Familiares con cáncer'
                        value={formik.values.quienes_cancer}
                        name='quienes_cancer'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='cancerType-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='tipo_cancer'
                        label='Tipo Cáncer'
                        placeholder='¿Qué tipo de cáncer?'
                        value={formik.values.tipo_cancer}
                        name='tipo_cancer'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_cardiopatias'
                      id='tiene_cardiopatias'
                      checked={formik.values.tiene_cardiopatias}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Cardiopatías'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_cardiopatias ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='heartDiseaseRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='quienes_cardiopatias'
                        label='¿Quiénes?'
                        placeholder='Familiares con cardiopatías'
                        value={formik.values.quienes_cardiopatias}
                        name='quienes_cardiopatias'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_nefropatas'
                      id='tiene_nefropatas'
                      checked={formik.values.tiene_nefropatas}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Nefropatías'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_nefropatas ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='nephropaticalRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='quienes_nefropatas'
                        label='¿Quiénes?'
                        placeholder='Familiares con necropatías'
                        value={formik.values.quienes_nefropatas}
                        name='quienes_nefropatas'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_malformaciones'
                      id='tiene_malformaciones'
                      checked={formik.values.tiene_malformaciones}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Malformaciones'
                />
              </Grid>
              {formik.values.tiene_malformaciones ? (
                <>
                  {' '}
                  <Grid item xs={12} md={6}>
                    {' '}
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='malformationsRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='quienes_malformaciones'
                        label='¿Quiénes?'
                        placeholder='Familiares con malformaciones'
                        value={formik.values.quienes_malformaciones}
                        name='quienes_malformaciones'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='malformationType-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='tipo_malformaciones'
                        label='Tipo malformación'
                        placeholder='Tipo de malformación'
                        value={formik.values.tipo_malformaciones}
                        name='tipo_malformaciones'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_otro_antedecente'
                      id='tiene_otro_antedecente'
                      checked={formik.values.tiene_otro_antedecente}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Otro'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.tiene_otro_antedecente ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otherFamilyDiseases-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='otro_antedecente'
                        label='Otro'
                        placeholder='Otros padecimientos'
                        value={formik.values.otro_antedecente}
                        name='otro_antedecente'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_tabaquismo'
                      id='tiene_tabaquismo'
                      checked={formik.values.tiene_tabaquismo}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Tabaquismo'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <>
                  {formik.values.tiene_tabaquismo ? (
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='cigarettesPerDay-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='text'
                        id='quienes_tabaquismo'
                        label='¿Quienes?'
                        placeholder='¿Quienes?'
                        value={formik.values.quienes_tabaquismo}
                        name='quienes_tabaquismo'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  ) : null}
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_alcoholismo'
                      id='tiene_alcoholismo'
                      checked={formik.values.tiene_alcoholismo}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Alcoholismo'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <>
                  {formik.values.tiene_alcoholismo ? (
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='quienes_alcoholismo-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='text'
                        id='quienes_alcoholismo'
                        label='¿Quienes?'
                        placeholder='¿Quienes?'
                        value={formik.values.quienes_alcoholismo}
                        name='quienes_alcoholismo'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  ) : null}
                </>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='tiene_drogadiccion'
                      id='tiene_drogadiccion'
                      checked={formik.values.tiene_drogadiccion}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Drogradicción'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <>
                  {formik.values.tiene_drogadiccion ? (
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='quienes_drogadiccion-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='text'
                        id='quienes_drogadiccion'
                        label='¿Quienes?'
                        placeholder='¿Quienes?'
                        value={formik.values.quienes_drogadiccion}
                        name='quienes_drogadiccion'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  ) : null}
                </>
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Resultados pasados y diagnósticos
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Llena la información relevante sobre estudios y resultados pasados del
                  paciente, así como el diagnóstico y pronósticos actuales.
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>
                  Resultados previos y actuales de estudios de laboratorio, gabinete y
                  otros
                </CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='resultados_previos'
                  name='resultados_previos'
                  value={formik.values.resultados_previos}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  multiple
                  id='historia_pediatrica_diagnosticos'
                  options={cie10Search}
                  noOptionsText='No hay opciones disponibles'
                  getOptionLabel={(option) =>
                    `${option.cie_clave} ${option.cie_descripcion}`
                  }
                  onInputChange={(_event, value) => handleDiagnosisInput(value)}
                  value={formik.values.historia_pediatrica_diagnosticos}
                  onChange={(_e, value) => {
                    formik.setFieldValue('historia_pediatrica_diagnosticos', value);
                  }}
                  renderInput={(params) => (
                    <CMTextfield
                      $nomargin
                      {...params}
                      label='Diagnóstico(s)'
                      placeholder='Diagnóstico(s)'
                      name='historia_pediatrica_diagnosticos'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CMTextfield
                  $nomargin
                  select
                  id='pronostico'
                  name='pronostico'
                  value={formik.values.pronostico}
                  onChange={formik.handleChange}
                  placeholder='Descripción'
                  label='Pronóstico'
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem disabled value=''>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value='Bueno'>Bueno</MenuItem>
                  <MenuItem value='Regular'>Regular</MenuItem>
                  <MenuItem value='Malo'>Malo</MenuItem>
                  <MenuItem value='Reservado'>Reservado</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Indicación terapéutica</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='indicacion_terapeutica'
                  name='indicacion_terapeutica'
                  value={formik.values.indicacion_terapeutica}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

ClinicPediatricHistoryForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};

export default ClinicPediatricHistoryForm;
