import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import { EvolutionNote } from '../../interfaces/EvolutionNote';
import {
  formatDate,
  getDoctorFullName,
  getNurseFullName,
  getPatientFullName,
} from '../../utils/fns';
import { useMainProvider } from '../../context/MainProvider';
import { UserTypes } from '../../interfaces/User';
import { Nurse } from '../../interfaces/Nurses';

function EvolutionNoteDocument({
  patient,
  doctor,
  values,
}: {
  patient: Patient;
  doctor: Doctor;
  values: EvolutionNote;
}): JSX.Element {
  const { user } = useMainProvider();
  const [name, setName] = useState('');

  useEffect(() => {
    if (user && user.initialData) {
      if (user.typeId === UserTypes.PatientType) {
        setName(getPatientFullName(user.initialData as Patient).toUpperCase());
      }
      if (user.typeId === UserTypes.DoctorType) {
        setName(getDoctorFullName(user.initialData as Doctor).toUpperCase());
      }
      if (user.typeId === UserTypes.NurseType) {
        setName(getNurseFullName(user.initialData as Nurse).toUpperCase());
      }
    }
  }, [user]);

  useEffect(() => {
    moment.locale('es');
  }, [patient]);

  return (
    <table className='table-full-width'>
      <tbody>
        <tr>
          <td>
            <table className='table-full-width'>
              <tbody>
                <tr>
                  <td>
                    <span>
                      <p style={{ textAlign: 'end' }}>
                        <strong>
                          Fecha y Hora de impresión:{' '}
                          {moment().locale('ES').format('DD MMMM YYYY h:mm:ss a')}
                          <br />
                        </strong>
                      </p>

                      <p style={{ textAlign: 'right' }}>
                        <strong>
                          {doctor.med_consultorio?.toUpperCase()}
                          <br />
                          {doctor.med_raz_social?.toUpperCase()},{' '}
                          {doctor.med_no_registro?.toUpperCase()}
                          <br />
                          {doctor.med_domicilio?.toUpperCase()},
                          {doctor.med_colonia_consultorio?.toUpperCase()},{' '}
                          {doctor.med_municipio_consultorio?.toUpperCase()},{' '}
                          {doctor.med_ciudad_consultorio?.toUpperCase()},
                          {doctor.med_cp_consultorio}
                          <br />
                          Tel. {doctor.med_telefono_consultorio}
                        </strong>
                      </p>
                      <br />
                      <p style={{ textAlign: 'center', marginBottom: '50px' }}>
                        <strong>Nota De Evolución.</strong>
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Fecha y Hora de elaboración de la Nota:{' '}
                          {moment(values.fecha)
                            .locale('ES')
                            .format('DD MMMM YYYY h:mm:ss a')}
                        </strong>
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Datos del paciente
                          <br />
                        </strong>
                      </p>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Nombre</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_nombre} {patient.pac_apellidop}{' '}
                            {patient.pac_apellidom}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Fecha de nacimiento</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment(patient.pac_fechanacimiento)
                              .locale('ES')
                              .format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Edad</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment().diff(patient.pac_fechanacimiento, 'years')} años
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Sexo</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_sexo?.cat_sexo_desc}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Curp</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'uppercase' }}>
                            {patient.pac_curp}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Expediente</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_id}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Grupo Sanguíneo</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_tipo_sangre}
                          </span>
                        </div>
                      </div>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Datos de la nota
                          <br />
                        </strong>
                      </p>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Fecha y hora</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment(values.fecha)
                              .locale('ES')
                              .format('DD MMMM YYYY h:mm:ss a')}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Cita del paciente</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.cita_paciente}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Motivo de consulta</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.motivo_consulta}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Exploración física</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.exploracion_fisica}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Peso</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.peso}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Altura</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.talla}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Presión sistólica</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.ta_sistolica}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Presión diastolica</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.ta_diastolica}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> FC</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>{values.fc}</span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> FR</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>{values.fr}</span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Temperatura</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.temp}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Grupo Sanguíneo</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_tipo_sangre}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Resultados de estudios de laboratorio y gabinete
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.laboratorio_gabinete}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Diagóstico(s) presuntivo(s)
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.nota_evolucion_diag_pres?.map((singleDiag) => {
                              return (
                                <p style={{ margin: '0', marginBottom: '15px' }}>
                                  - {singleDiag.cie_clave} {singleDiag.cie_descripcion}
                                </p>
                              );
                            })}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Tratamiento</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.tratamiento}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Pronóstico</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.pronostico}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Paciente cronico-degenerativo
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.cronico_deg ? 'Si' : 'No'}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Cronico-degenerativo</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.cronico_deg_enfermedad}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Requiere Interconsulta</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.requiere_interconsulta ? 'Si' : 'No'}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Interconsulta al especialista es
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.interconsulta_es ? 'Si' : 'No'}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          El Paciente debe ser referido a una Unidad de Urgencias
                          Hospitalaria
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.paciente_referido ? 'Si' : 'No'}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Medico especialista a referir
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.especialista_referir}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Hospital a que se envio</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.hospital_envio}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Especialidad a la que se envio
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.especialidad_envio !== '-1'
                              ? values.especialidad_envio
                              : ''}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Diagnostico(s)</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.nota_evolucion_diag?.map((singleDiag) => {
                              return (
                                <p style={{ margin: '0', marginBottom: '15px' }}>
                                  - {singleDiag.cie_clave} {singleDiag.cie_descripcion}
                                </p>
                              );
                            })}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Tratamiento</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.tratamiento_paciente}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Indicaciones</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.indicaciones}
                          </span>
                        </div>
                      </div>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Datos del doctor
                          <br />
                        </strong>
                      </p>
                    </span>

                    <div className='print-data-container'>
                      <div className='print-data-left'>Nombre</div>

                      <div className='print-data-right'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {doctor.med_nombre} {doctor.med_apellidop}{' '}
                          {doctor.med_apellidom}
                        </span>
                      </div>
                    </div>

                    <div className='print-data-container'>
                      <div className='print-data-left'>Especialidad</div>

                      <div className='print-data-right'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {doctor.med_especialidad?.cat_especialidad_desc}
                        </span>
                      </div>
                    </div>

                    <div className='print-data-container'>
                      <div className='print-data-left'>Cédula</div>

                      <div className='print-data-right'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {doctor.med_cedula_profesional}
                        </span>
                      </div>
                    </div>

                    {doctor.med_especialidad_id !== 37 ? (
                      <>
                        <div className='print-data-container'>
                          <div className='print-data-left'>Cédula especialidad</div>

                          <div className='print-data-right'>
                            <span style={{ textTransform: 'capitalize' }}>
                              {doctor.med_cedula_especialidad}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        Aviso de privacidad
                        <br />
                      </strong>
                    </p>
                    <p style={{ fontSize: '0.8rem' }}>
                      Para ver nuestro Aviso de privacidad favor consultar:
                      http://zivot.com.mx/aviso-privacidad
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default EvolutionNoteDocument;
