import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { BsCheckCircle, BsCircle } from 'react-icons/all';
import Recaptcha from 'react-recaptcha';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useSponsorsProvider } from '../../Context/SponsorsMainContext';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import { saveLocalstorageData } from '../../../../utils/localStorage';
import { initialSponsorAdminForm } from './AdminForm.Interfaces';
import { RequiredMessage } from '../../../../utils/validationMessages';
import { SponsorAccountContextData } from '../../Interfaces/ContextInterfaces';
import CenteredForm from '../../../../index.styled';
import { PageContainer } from '../../../Register/Register.styled';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import {
  CMTextfield,
  CMTextfieldHalfLeft,
  CMTextfieldHalfRight,
} from '../../../../components/Forms/CMTextfield';
import PasswordTextfield from '../../../../components/Forms/PasswordTextfield';
import { mainTheme } from '../../../../utils/theme/theme';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { SponsorAccountsTableEntry } from '../../Interfaces/Sponsors';
import { CRUDModes } from '../../../../interfaces/CRUD';
import { setSponsorHeaders } from '../../../../utils/headerSetter';

interface Props {
  values?: SponsorAccountsTableEntry;
  onSuccess?: () => void;
  mode?: CRUDModes;
}

const SponsorAccountForm = ({ values, mode, onSuccess }: Props): JSX.Element => {
  const {
    user,
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setUser,
    setLoading,
    token,
    sponsor,
    setToken,
  } = useSponsorsProvider();
  const [formValues, setFormValues] = useState<SponsorAccountsTableEntry>(
    initialSponsorAdminForm,
  );
  const [passwordFormatActive, setPasswordFormatActive] = useState<boolean>(false);
  const [passwordLenght, setPasswordLenght] = useState<boolean>(false);
  const [lowerCase, setLowerCase] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [number, setNumber] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<boolean>(false);
  const [samePassword, setSamePassword] = useState<boolean>(false);

  const navigate = useNavigate();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Escriba un email válido')
      .required('El email es un campo obligatorio'),
    password:
      mode === CRUDModes.Create
        ? Yup.string()
            .required('La contraseña es un campo obligatorio')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
              'Debe contener 8 caracteres, una mayuscula, una minuscula, un número y un caracter especial como mínimo',
            )
        : Yup.string().nullable(),
    confirmPasword:
      mode === CRUDModes.Create
        ? Yup.string()
            .required('La contraseña es un campo obligatorio')
            .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
        : Yup.string().nullable(),
    recaptcha: Yup.string().required('Es necesario llenar el captcha'),
    termsConditions:
      mode === CRUDModes.Create
        ? Yup.boolean()
            .required('Los terminos y condiciones deben ser aceptados.')
            .oneOf([true], 'Los terminos y condiciones deben ser aceptados.')
        : Yup.boolean(),
    privacyCondition:
      mode === CRUDModes.Create
        ? Yup.boolean()
            .required('El aviso de privacidad debe ser aceptado.')
            .oneOf([true], 'El aviso de privacidad debe ser aceptado.')
        : Yup.boolean(),
    cellphone: Yup.string()
      .required(RequiredMessage)
      .test('countryCode', 'Ingresa el celular sin LADA, 10 dígitos', (value) => {
        return value ? !value.startsWith('+') : true;
      }),
    name: Yup.string().required(RequiredMessage),
    second_name: Yup.string().nullable(),
    last_name: Yup.string().required(RequiredMessage),
    second_last_name: Yup.string().required(RequiredMessage),
    birthday: Yup.string().required(RequiredMessage),
    labor_department: Yup.string().nullable(),
    labor_area: Yup.string().nullable(),
    labor_position: Yup.string().nullable(),
  });

  const validatePasswordFormat = (
    value: string,
  ): {
    lowerCaseResult: boolean;
    upperCaseResult: boolean;
    numberResult: boolean;
    symbolResult: boolean;
  } => {
    const passwordLenghtResult = value.length >= 8;
    const lowerCaseResult = /.*[a-z].*/.test(value);
    const upperCaseResult = /.*[A-Z].*/.test(value);
    const numberResult = /\d/.test(value);
    const symbolResult = /[*@!#%&()^~{}]+/.test(value);
    setPasswordLenght(passwordLenghtResult);
    setLowerCase(lowerCaseResult);
    setUpperCase(upperCaseResult);
    setNumber(numberResult);
    setSymbol(symbolResult);
    return {
      lowerCaseResult,
      upperCaseResult,
      numberResult,
      symbolResult,
    };
  };

  const validateSamePassword = (
    value: string,
    passwordValue: string,
  ): {
    samePasswordResult: boolean;
  } => {
    const samePasswordResult = value === passwordValue;
    setSamePassword(samePasswordResult);
    return {
      samePasswordResult,
    };
  };

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
    if (!values && mode === CRUDModes.Update && user && sponsor) {
      setFormValues({
        id: user.id,
        is_active: true,
        is_validated: true,
        name: user.name,
        second_name: user.second_name,
        last_name: user.last_name,
        second_last_name: user.second_last_name,
        sponsor_id: sponsor.id,
        sponsor_name: sponsor.name,
        fecha: '',
        account_type: user.typeId,
        email: user.username,
        cellphone: user.cellphone,
        birthday: user.birthday,
        labor_area: user.labor_area,
        labor_department: user.labor_department,
        labor_position: user.labor_position,
      });
    }
  }, [mode, sponsor, user, values]);

  const formik = useFormik<SponsorAccountsTableEntry>({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    /**
     * @desc saves a new user in the database
     * @param registerValues
     */
    onSubmit: async (registerValues: SponsorAccountsTableEntry) => {
      try {
        const payload = { ...registerValues };
        delete payload.confirmPasword;
        delete payload.termsConditions;
        delete payload.recaptcha;
        delete payload.privacyCondition;
        const existingSponsor = mode === CRUDModes.Create && !!sponsor;
        if (existingSponsor && !payload.sponsor_id) {
          payload.sponsor_id = sponsor.id;
        }

        setLoading(true);
        const registerResult = await axios.post<{
          user: SponsorAccountContextData;
          token: string;
        }>(
          `${process.env.REACT_APP_SERVER_URL}/sponsors/${
            mode === CRUDModes.Create ? 'new' : 'update'
          }-${existingSponsor ? 'regular' : 'admin'}-account`,
          payload,
          mode === CRUDModes.Update || existingSponsor
            ? setSponsorHeaders(true, token)
            : undefined,
        );
        if (registerResult.status === 200) {
          formik.resetForm();
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `Usuario ${mode === CRUDModes.Create ? 'creado' : 'actualizado'} con exito`,
          );
          setOpenAlert(true);
          if (mode === CRUDModes.Create && !existingSponsor) {
            const newUser = {
              ...registerResult.data.user,
              token: registerResult.data.token,
            };
            saveLocalstorageData('CODIGO_MEDICO_SPONSOR_INFORMATION', newUser);
            setToken(registerResult.data.token);
            setUser(newUser);
          } else if (!values && mode === CRUDModes.Update && sponsor) {
            const updatedUser = {
              ...registerResult.data.user,
              token: registerResult.data.token,
              sponsor,
            };
            saveLocalstorageData('CODIGO_MEDICO_SPONSOR_INFORMATION', updatedUser);
            setToken(registerResult.data.token);
            setUser(updatedUser);
          } else if (onSuccess) {
            onSuccess();
          }
        }
        setLoading(false);
      } catch (e: any) {
        if (JSON.stringify(e.response.data)) {
          if (e.response.data.code && e.response.data.code === 'ER_DUP_ENTRY') {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(
              `Error al iniciar sesión: Este correo ya se encuentra en uso, utilice un correo distinto`,
            );
            setOpenAlert(true);
          } else {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(
              `Error al iniciar sesión: ${JSON.stringify(e.response.data)} `,
            );
            setOpenAlert(true);
          }
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (user && mode === CRUDModes.Create && !sponsor) {
      navigate('/sponsors/new-sponsor');
    }
  }, [user, navigate, mode, sponsor]);

  return (
    <PageContainer style={{ paddingTop: '1.5rem' }}>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                {mode === CRUDModes.Create && !sponsor && (
                  <>
                    <PrimaryTitle style={{ textAlign: 'center', marginTop: '2rem' }}>
                      Registro al módulo de patrocinadores
                    </PrimaryTitle>
                    <PrimaryDescription
                      mobile={mobile}
                      style={{ marginBottom: '2.5rem' }}
                    >
                      Antes de comenzar primero debes crear una cuenta
                    </PrimaryDescription>
                  </>
                )}
                {mode === CRUDModes.Update && !values && (
                  <>
                    <PrimaryTitle style={{ textAlign: 'center', marginTop: '2rem' }}>
                      Mi cuenta
                    </PrimaryTitle>
                    <PrimaryDescription
                      mobile={mobile}
                      style={{ marginBottom: '2.5rem' }}
                    >
                      Modifica la información de tu cuenta
                    </PrimaryDescription>
                  </>
                )}

                <PrimaryTitleStart>Datos de contacto</PrimaryTitleStart>
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Nombre'
                  value={formik.values.name}
                  name='name'
                  required
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Segundo nombre (opcional)'
                  value={formik.values.second_name}
                  name='second_name'
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.second_name && Boolean(formik.errors.second_name)}
                  helperText={formik.touched.second_name && formik.errors.second_name}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Apellido paterno'
                  value={formik.values.last_name}
                  name='last_name'
                  required
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                  helperText={formik.touched.last_name && formik.errors.last_name}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Apellido materno'
                  value={formik.values.second_last_name}
                  name='second_last_name'
                  onBlur={formik.handleBlur}
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.second_last_name &&
                    Boolean(formik.errors.second_last_name)
                  }
                  helperText={
                    formik.touched.second_last_name && formik.errors.second_last_name
                  }
                  onChange={formik.handleChange}
                />
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    label='Fecha de nacimiento'
                    value={formik.values.birthday}
                    onChange={(value): void => {
                      formik.setFieldValue('birthday', value);
                    }}
                    inputFormat='DD/MM/yyyy'
                    renderInput={(params) => (
                      <CMTextfieldHalfLeft
                        {...params}
                        name='birthday'
                        required
                        onBlur={formik.handleBlur}
                        onChange={(value): void => {
                          formik.setFieldValue('birthday', value);
                        }}
                        error={formik.touched.birthday && Boolean(formik.errors.birthday)}
                        helperText={formik.touched.birthday && formik.errors.birthday}
                      />
                    )}
                  />
                </LocalizationProvider>
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Celular'
                  type='number'
                  value={formik.values.cellphone}
                  name='cellphone'
                  required
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.cellphone && Boolean(formik.errors.cellphone)}
                  helperText={formik.touched.cellphone && formik.errors.cellphone}
                  onChange={formik.handleChange}
                />

                <PrimaryTitleStart style={{ margin: '1rem 0' }}>
                  Datos laborales
                </PrimaryTitleStart>
                <CMTextfield
                  id='outlined-basic'
                  label='Puesto laboral'
                  variant='outlined'
                  onBlur={formik.handleBlur}
                  value={formik.values.labor_position}
                  name='labor_position'
                  error={
                    formik.touched.labor_position && Boolean(formik.errors.labor_position)
                  }
                  helperText={
                    formik.touched.labor_position && formik.errors.labor_position
                  }
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  onBlur={formik.handleBlur}
                  label='Departamento laboral'
                  value={formik.values.labor_department}
                  name='labor_department'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.labor_department &&
                    Boolean(formik.errors.labor_department)
                  }
                  helperText={
                    formik.touched.labor_department && formik.errors.labor_department
                  }
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Área laboral'
                  onBlur={formik.handleBlur}
                  value={formik.values.labor_area}
                  name='labor_area'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.labor_area && Boolean(formik.errors.labor_area)}
                  helperText={formik.touched.labor_area && formik.errors.labor_area}
                  onChange={formik.handleChange}
                />

                <PrimaryTitleStart style={{ margin: '1rem 0' }}>
                  Datos de la cuenta
                </PrimaryTitleStart>
                <CMTextfield
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name='email'
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                />
                {mode === CRUDModes.Create && (
                  <>
                    <PasswordTextfield
                      id='outlined-basic-password'
                      label='Contraseña'
                      variant='outlined'
                      value={formik.values.password}
                      name='password'
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      helperText={formik.touched.password && formik.errors.password}
                      onChange={(e) => {
                        validatePasswordFormat(e.target.value);
                        formik.handleChange(e);
                      }}
                      onFocus={() => setPasswordFormatActive(true)}
                    />
                    <PasswordTextfield
                      id='outlined-basic-password-1'
                      label='Confirmar contraseña'
                      variant='outlined'
                      value={formik.values.confirmPasword}
                      name='confirmPasword'
                      error={
                        formik.touched.confirmPasword &&
                        Boolean(formik.errors.confirmPasword)
                      }
                      helperText={
                        formik.touched.confirmPasword && formik.errors.confirmPasword
                      }
                      onChange={(e) => {
                        validateSamePassword(
                          e.target.value,
                          formik.values.password as string,
                        );
                        formik.handleChange(e);
                      }}
                    />
                    {passwordFormatActive && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        exit={{ opacity: 0, height: 0 }}
                        animate={{
                          opacity: 1,
                          height: 'auto',
                        }}
                        transition={{ duration: 0.3 }}
                        key='bodyMassIndex-div'
                        style={{
                          textAlign: 'start',
                          width: '100%',
                          border: '1px solid rgba(0, 0, 0, 0.23)',
                          borderRadius: '12px',
                          backgroundColor: '#fafafa',
                          padding: '1rem',
                          marginBottom: '1rem',
                        }}
                      >
                        <div>
                          <p style={{ marginTop: '0', marginBottom: '.8rem' }}>
                            {' '}
                            La contraseña debe tener minimo{' '}
                          </p>

                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {passwordLenght ? (
                              <BsCheckCircle
                                style={{
                                  color: 'green',
                                  marginRight: '2%',
                                }}
                              />
                            ) : (
                              <BsCircle
                                style={{
                                  color: 'red',
                                  marginRight: '2%',
                                }}
                              />
                            )}
                            <p
                              style={{
                                color: passwordLenght ? 'green' : 'red',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                              }}
                            >
                              8 caracteres{' '}
                            </p>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {lowerCase ? (
                              <BsCheckCircle
                                style={{
                                  color: 'green',
                                  marginRight: '2%',
                                }}
                              />
                            ) : (
                              <BsCircle
                                style={{
                                  color: 'red',
                                  marginRight: '2%',
                                }}
                              />
                            )}
                            <p
                              style={{
                                color: lowerCase ? 'green' : 'red',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                              }}
                            >
                              Una minuscula{' '}
                            </p>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {upperCase ? (
                              <BsCheckCircle
                                style={{
                                  color: 'green',
                                  marginRight: '2%',
                                }}
                              />
                            ) : (
                              <BsCircle
                                style={{
                                  color: 'red',
                                  marginRight: '2%',
                                }}
                              />
                            )}
                            <p
                              style={{
                                color: upperCase ? 'green' : 'red',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                              }}
                            >
                              Una mayuscula{' '}
                            </p>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {number ? (
                              <BsCheckCircle
                                style={{
                                  color: 'green',
                                  marginRight: '2%',
                                }}
                              />
                            ) : (
                              <BsCircle
                                style={{
                                  color: 'red',
                                  marginRight: '2%',
                                }}
                              />
                            )}
                            <p
                              style={{
                                color: number ? 'green' : 'red',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                              }}
                            >
                              Un número{' '}
                            </p>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {symbol ? (
                              <BsCheckCircle
                                style={{
                                  color: 'green',
                                  marginRight: '2%',
                                }}
                              />
                            ) : (
                              <BsCircle
                                style={{
                                  color: 'red',
                                  marginRight: '2%',
                                }}
                              />
                            )}
                            <p
                              style={{
                                color: symbol ? 'green' : 'red',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                              }}
                            >
                              Un simbolo{' '}
                            </p>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {samePassword ? (
                              <BsCheckCircle
                                style={{
                                  color: 'green',
                                  marginRight: '2%',
                                }}
                              />
                            ) : (
                              <BsCircle
                                style={{
                                  color: 'red',
                                  marginRight: '2%',
                                }}
                              />
                            )}
                            <p
                              style={{
                                color: samePassword ? 'green' : 'red',
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                              }}
                            >
                              Las contraseñas deben coincidir{' '}
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </>
                )}
                <Recaptcha
                  hl='es'
                  sitekey='6Lepqn8eAAAAAEKyrn_qs960q8KQT_WPdGH0XPiS'
                  verifyCallback={(response) => {
                    formik.setFieldValue('recaptcha', response);
                  }}
                />
                {mode === CRUDModes.Create && (
                  <>
                    <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            name='termsConditions'
                            id='termsConditions'
                            checked={formik.values.termsConditions}
                            onChange={formik.handleChange}
                            disabled={false}
                            required
                          />
                        }
                        label={
                          <div>
                            <p>
                              Al registrarse está aceptando los{' '}
                              <a
                                href={`${process.env.REACT_APP_SERVER_URL}/files/Terminos y Condiciones.pdf`}
                                target='_blank'
                                rel='noreferrer'
                              >
                                Terminos y condiciones
                              </a>{' '}
                              de{' '}
                              <strong
                                style={{
                                  color: mainTheme.colors.primaryButton.normalBackground,
                                }}
                              >
                                {' '}
                                Zivot{' '}
                              </strong>
                            </p>
                          </div>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color='primary'
                            name='privacyCondition'
                            id='privacyCondition'
                            checked={formik.values.privacyCondition}
                            onChange={formik.handleChange}
                            disabled={false}
                            required
                          />
                        }
                        label={
                          <div>
                            <p>
                              He leído, acepto y autorizo lo dispuesto en el aviso de
                              privacidad, con respecto a mis datos personales, incluyendo
                              la información médica.
                            </p>
                          </div>
                        }
                      />
                    </Grid>
                  </>
                )}

                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  {mode === CRUDModes.Create ? 'Registrarse' : 'Guardar cambios'}
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

SponsorAccountForm.defaultProps = {
  values: undefined,
  mode: CRUDModes.Create,
  onSuccess: () => {},
};

export default SponsorAccountForm;
