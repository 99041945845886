import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { AnimatePresence } from 'framer-motion';
import { IoMdCloseCircle } from 'react-icons/all';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useReactToPrint } from 'react-to-print';
import { useMainProvider } from '../../context/MainProvider';
import { CRUDModes } from '../../interfaces/CRUD';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { setHeaders } from '../../utils/headerSetter';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import {
  EstudioPost,
  EstudiosTypes,
  ExtendedReceta,
  Medicamento,
  Receta,
  RecetaMedicamento,
  RecetaMedicamentoForm,
  RecetasPost,
} from '../../interfaces/Receta';
import { NoPaddingButton } from '../../components/NoPaddingButton/NoPaddingButton';
import { StandardText } from '../../components/StandardText/StandardText';
import { Patient } from '../../interfaces/Patients';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { canPrintNote } from '../../utils/fns';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';
import RecetaPrintableDocument from './RecetaPrintableDocument';
import useAds from '../../hooks/useAds';
import { AdCatalog, AdTypes } from '../../interfaces/Ads';
import { FlexContainer } from '../../components/FlexContainer/FlexContainer';
import Ad from '../../components/Ad/Ad';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  onSuccess: () => void;
  values?: ExtendedReceta;
  closeFunction?: () => void;
}

const initialValues: Receta = {
  paciente_id: 0,
  doctor_id: 0,
  cuidados_generales: '',
  continuacion_tratamiento: '',
  indicaciones: '',
  fecha_proxima_cita: '',
  comentarios: '',
  medicamentos_fuera_cuadro: '',
  impresion_bloqueada: false,
};

const RecetaForm = ({
  open,
  setOpen,
  mode,
  onSuccess,
  values,
  closeFunction,
}: FormProps): JSX.Element => {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [medicamentos, setMedicamentos] = useState<Medicamento[]>([]);
  const [showExtraMedicineField, setShowExtraMedicineField] = useState(false);
  const [selectedMedicamentos, setSelectedMedicamentos] = useState<
    RecetaMedicamentoForm[]
  >([]);
  const [patientInformation, setPatientInformation] = useState<Patient>();
  const printRef = useRef<HTMLInputElement>(null);
  const adInfo = useAds(AdCatalog.detalleReceta);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.doctor_id !== user?.id,
    [mode, formValues, user?.id],
  );

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
      }
    } else {
      console.log(values);
      setPatientInformation(values.patientInformation as Patient);
      setFormValues(values);
      setSelectedMedicamentos(values.medicamentos_seleccionados || []);
      if (values.medicamentos_fuera_cuadro) {
        console.log('entered');
        setShowExtraMedicineField(true);
      }
    }
  }, [currentPatient, values]);

  const formik = useFormik<Receta>({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: async (form) => {
      try {
        setLoading(true);
        const entry: RecetasPost = {
          receta: {
            ...form,
            fecha_proxima_cita: form.fecha_proxima_cita
              ? moment
                  .utc(form.fecha_proxima_cita)
                  .utcOffset(-300)
                  .toISOString(true)
                  .slice(0, 19)
                  .replace('T', ' ')
              : null,
          },
          medicamentos: selectedMedicamentos
            .filter((med) => med.medicamento !== undefined)
            .map((med): RecetaMedicamento => {
              return {
                id_medicamento: med.medicamento?.id || 0,
                dosis: med.dosis,
                via: med.via,
                marca_recomendada: med.marca_recomendada,
                periodicidad: med.periodicidad,
                numero_dias: med.numero_dias,
                numero_cajas: med.numero_cajas,
              };
            }),
        };
        const creationResult = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/receta${
            mode === CRUDModes.Create ? '' : '/update'
          }`,
          {
            entry,
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          setHeaders(true, user ? user.token : undefined),
        );
        if (creationResult.status === 200) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `¡Receta ${
              mode === CRUDModes.Create ? 'creada' : 'actualizada'
            } exitosamente!`,
          );
          setOpenAlert(true);
          setLoading(false);
          setSelectedMedicamentos([]);
          setShowExtraMedicineField(false);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
        }
      } catch (e) {
        handleLogout(e);
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`${e}`);
        setOpenAlert(true);
      }
    },
  });

  const searchOptionsMedicines = async (searchterm: string) => {
    try {
      const results = await axios.post<Medicamento[]>(
        `${process.env.REACT_APP_SERVER_URL}/receta/search-medicamentos`,
        {
          searchterm,
        },
        setHeaders(true, user ? user.token : undefined),
      );
      setMedicamentos(results.data);
    } catch (e) {
      handleLogout(e);
      setAlertType(AlertTypes.Error);
      setAlertMessage(`${e}`);
      setOpenAlert(true);
    }
  };

  const addMedicamento = () => {
    if (!selectedMedicamentos.find((medicamento) => !medicamento.medicamento))
      setSelectedMedicamentos([
        ...selectedMedicamentos,
        {
          marca_recomendada: '',
          dosis: '',
          via: '',
          periodicidad: '',
          numero_cajas: '',
          numero_dias: 0,
        },
      ]);
  };

  const updateSelectionMedicina = (
    index: number,
    updatedSelection?: Medicamento | null,
  ) => {
    const newArray = [...selectedMedicamentos];
    newArray[index] = {
      ...newArray[index],
      medicamento: updatedSelection || undefined,
      via: updatedSelection ? updatedSelection.via || '' : '',
      marca_recomendada: updatedSelection?.DESCRIPCION
        ? updatedSelection?.DESCRIPCION
        : '',
    };
    setSelectedMedicamentos(newArray);
  };

  const deleteMedicamento = (index: number) => {
    const newEntries = [...selectedMedicamentos];
    newEntries.splice(index, 1);
    setSelectedMedicamentos(newEntries);
  };

  const resetForm = () => {
    formik.resetForm();
    setFormValues(initialValues);
    setSelectedMedicamentos([]);
    setShowExtraMedicineField(false);
    setOpen(false);
    if (closeFunction) closeFunction();
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          resetForm();
        }
      }}
    >
      <DialogCustomTitle onClose={resetForm} mode={mode} title='receta' />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              {adInfo ? (
                <Grid item xs={12}>
                  <FlexContainer direction='row' justifyContent='center'>
                    <Ad
                      adInfo={adInfo}
                      type={mobile ? AdTypes.mobileBanner : AdTypes.leaderboard}
                    />
                  </FlexContainer>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Medicamentos indicados</PrimarySubtitle>
                {!fieldsDisabled ? (
                  <PrimaryDescription mobile style={{ margin: '0' }}>
                    {mobile ? 'Toca el' : 'Haz click en el'} botón de &quot;Añadir
                    medicamento&quot; para capturar el tratamiento de un nuevo
                    medicamento.
                  </PrimaryDescription>
                ) : null}
              </Grid>
              {selectedMedicamentos.length
                ? selectedMedicamentos.map((medicamento, index) => {
                    const key = `divMedicamento#${
                      medicamento.medicamento?.id || '-'
                    }${index}`;
                    const autocompleteKey = `autocompleteMedicamento#${
                      medicamento.medicamento?.id || '-'
                    }${index}`;
                    const inputKey = `inputMedicamento#${
                      medicamento.medicamento?.id || '-'
                    }${index}`;
                    return (
                      <Grid key={key} item xs={12}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                          }}
                        >
                          <div
                            style={{
                              borderRadius: '12px',
                              backgroundColor: '#f4f4f4',
                              padding: '0.5rem',
                              width: 'calc(100% - 2rem)',
                            }}
                          >
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                              spacing={1}
                            >
                              <Grid item xs={12} md={6}>
                                <Autocomplete
                                  disabled={fieldsDisabled}
                                  id={autocompleteKey}
                                  filterOptions={(x) => x}
                                  options={medicamentos}
                                  filterSelectedOptions
                                  noOptionsText='Busca un medicamento por nombre o sustancia'
                                  getOptionLabel={(option) => {
                                    if (option) {
                                      return `${option.sales}`;
                                    }
                                    return '';
                                  }}
                                  renderOption={(props, option?: Medicamento) => {
                                    if (option) {
                                      return (
                                        <li {...props} key={option.id}>
                                          <StandardText>
                                            <b>{option.DESCRIPCION}</b>
                                          </StandardText>
                                          {option.via ? (
                                            <StandardText>{option.via}</StandardText>
                                          ) : null}
                                        </li>
                                      );
                                    }
                                    return null;
                                  }}
                                  onInputChange={(_event, value) =>
                                    searchOptionsMedicines(value)
                                  }
                                  value={medicamento.medicamento}
                                  onChange={(_e, value) => {
                                    updateSelectionMedicina(index, value);
                                  }}
                                  renderInput={(params) => (
                                    <CMTextfield
                                      $nomargin
                                      {...params}
                                      label='Nombre Genérico del Medicamento '
                                      placeholder='Nombre Genérico del Medicamento '
                                      name={inputKey}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='text'
                                  value={medicamento.marca_recomendada}
                                  label='Medicamento de patente recomendado'
                                  placeholder='Medicamento de patente recomendado'
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      marca_recomendada: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='text'
                                  label='Vía'
                                  placeholder='Vía'
                                  value={medicamento.via}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      via: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='text'
                                  label='Dosis'
                                  placeholder='Dosis'
                                  value={medicamento.dosis}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      dosis: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='text'
                                  label='Periodicidad'
                                  placeholder='Periodicidad'
                                  value={medicamento.periodicidad}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      periodicidad: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='number'
                                  inputProps={{
                                    inputMode: 'numeric',
                                  }}
                                  label='Dias de tratamiento'
                                  placeholder='Dias de tratamiento'
                                  value={medicamento.numero_dias}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      numero_dias: e.target.value
                                        ? Number(e.target.value)
                                        : 0,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='string'
                                  label='Presentación / No. Cajas'
                                  placeholder='Presentación / No. Cajas'
                                  value={medicamento.numero_cajas}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      numero_cajas: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {mode === CRUDModes.Create ? (
                            <NoPaddingButton
                              type='button'
                              onClick={() => deleteMedicamento(index)}
                            >
                              <IoMdCloseCircle
                                style={{
                                  fontSize: '1.5rem',
                                  marginLeft: '0.5rem',
                                  cursor: 'pointer',
                                }}
                              />
                            </NoPaddingButton>
                          ) : null}
                        </div>
                      </Grid>
                    );
                  })
                : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <NoPaddingButton
                    type='button'
                    onClick={addMedicamento}
                    style={{ padding: '0.5rem', fontSize: '1rem' }}
                  >
                    + Añadir medicamento
                  </NoPaddingButton>
                </Grid>
              ) : null}
              {showExtraMedicineField ? (
                <Grid item xs={12}>
                  <CMInputLabel mobile>
                    Medicamentos fuera del catálogo médico
                  </CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    $spacing={2}
                    onBlur={formik.handleBlur}
                    id='medicamentos_fuera_cuadro'
                    name='medicamentos_fuera_cuadro'
                    value={formik.values.medicamentos_fuera_cuadro}
                    onChange={formik.handleChange}
                    minRows={2}
                    placeholder='Información de los medicamentos'
                  />
                </Grid>
              ) : (
                <>
                  {!fieldsDisabled ? (
                    <Grid item xs={12}>
                      <NoPaddingButton
                        type='button'
                        onClick={() => setShowExtraMedicineField(true)}
                        style={{
                          padding: '0.5rem',
                          fontSize: '1rem',
                          textAlign: 'start',
                        }}
                      >
                        + Añadir medicamento fuera de cuadro médico
                      </NoPaddingButton>
                    </Grid>
                  ) : null}
                </>
              )}
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      name='impresion_bloqueada'
                      id='impresion_bloqueada'
                      checked={formik.values.impresion_bloqueada}
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                    />
                  }
                  label={
                    <PrimaryDescription style={{ margin: 0, fontWeight: 500 }}>
                      Bloquear la impresión al paciente
                    </PrimaryDescription>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle style={{ margin: '0 0 1rem 0' }} mobile>
                  Cuidados generales
                </PrimarySubtitle>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Cuidados generales</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  $spacing={2}
                  id='cuidados_generales'
                  name='cuidados_generales'
                  onBlur={formik.handleBlur}
                  value={formik.values.cuidados_generales}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>
                  Continuación del tratamiento farmacológico
                </CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  $spacing={2}
                  id='continuacion_tratamiento'
                  name='continuacion_tratamiento'
                  onBlur={formik.handleBlur}
                  value={formik.values.continuacion_tratamiento}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Indicaciones higiénico dietéticas</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  $spacing={2}
                  id='indicaciones'
                  name='indicaciones'
                  onBlur={formik.handleBlur}
                  value={formik.values.indicaciones}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    disabled={fieldsDisabled}
                    label='Fecha de próxima cita'
                    value={formik.values.fecha_proxima_cita}
                    onChange={(value): void => {
                      formik.setFieldValue('fecha_proxima_cita', value);
                    }}
                    inputFormat='DD/MM/yyyy'
                    renderInput={(params) => (
                      <CMTextfield
                        disabled={fieldsDisabled}
                        $nomargin
                        {...params}
                        onBlur={formik.handleBlur}
                        name='fecha_proxima_cita'
                        error={
                          formik.touched.fecha_proxima_cita &&
                          Boolean(formik.errors.fecha_proxima_cita)
                        }
                        helperText={
                          formik.touched.fecha_proxima_cita &&
                          formik.errors.fecha_proxima_cita
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Comentarios adicionales</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  $spacing={2}
                  id='comentarios'
                  name='comentarios'
                  onBlur={formik.handleBlur}
                  value={formik.values.comentarios}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Comentarios'
                />
              </Grid>
              {values &&
              values.doctorInformation &&
              mode !== CRUDModes.Create &&
              values.patientInformation &&
              values.doctor_id &&
              user &&
              canPrintNote(
                values.patientInformation,
                values.doctor_id,
                user,
                undefined,
                values.impresion_bloqueada,
              ) ? (
                <Grid item xs={12}>
                  <div style={{ display: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <RecetaPrintableDocument
                        patient={values.patientInformation}
                        doctor={values.doctorInformation}
                        values={values}
                        selectedMedicamentos={selectedMedicamentos}
                      />
                    </Grid>
                  </div>
                  {!isIOSApp() ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      style={{ width: '100%' }}
                      onClick={() => handlePrint()}
                    >
                      Imprimir
                    </PrimaryButton>
                  ) : (
                    <MobileDownloadMessage />
                  )}
                </Grid>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

RecetaForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};

export default RecetaForm;
