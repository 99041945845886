import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { PageContainer } from '../../../Register/Register.styled';
import illustration from '../../../../assets/img/undraw_medicine_b-1-ol.svg';
import { PrimaryTitle } from '../../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import { useSponsorsProvider } from '../../Context/SponsorsMainContext';

const SponsorsHomePage = (): JSX.Element => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const { sponsor, user } = useSponsorsProvider();
  return (
    <PageContainer>
      <Grid
        container
        direction='row'
        justifyContent='center'
        style={{
          marginTop: isMobile ? '1.5rem' : '5rem',
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <img
          src={sponsor?.logo_url || illustration}
          alt='Imagen de un médico con un paciente'
          style={{ width: '250px', marginBottom: '1rem', objectFit: 'contain' }}
        />
        <PrimaryTitle style={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
          Buen día, {(user?.name || sponsor?.name || 'Patrocinador').toLowerCase()}
        </PrimaryTitle>
        <PrimaryDescription>
          Selecciona una opción del menú para acceder a ella
        </PrimaryDescription>
      </Grid>
    </PageContainer>
  );
};

export default SponsorsHomePage;
