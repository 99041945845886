import { Patient } from './Patients';
import { Doctor } from './Doctors';

export interface Receta {
  id?: number;
  isActive?: boolean;
  fecha_creacion?: string;
  paciente_id: number;
  doctor_id: number;
  cuidados_generales: string;
  continuacion_tratamiento: string;
  indicaciones: string;
  fecha_proxima_cita: string | null;
  comentarios: string;
  medicamentos_seleccionados?: RecetaMedicamento[];
  patientInformation?: Patient;
  medicamentos_fuera_cuadro?: string;
  impresion_bloqueada: boolean;
}

export enum EstudiosTypes {
  Regulares,
  RayosX,
}

export interface EstudioPost {
  id_estudio: string;
  nombre: string;
  tipo: EstudiosTypes;
}

export interface ExtendedReceta extends Receta {
  doctorName: string;
  patientName: string;
  doctorInformation?: Doctor;
}

export interface RecetaMedicamento {
  id?: number;
  fecha_creacion?: string;
  id_medicamento: number;
  marca_recomendada: string;
  dosis: string;
  via: string;
  periodicidad: string;
  numero_cajas: string;
  numero_dias: number;
}

export interface RecetaMedicamentoForm {
  marca_recomendada: string;
  dosis: string;
  via: string;
  periodicidad: string;
  numero_cajas: string;
  numero_dias: number;
  medicamento?: Medicamento;
}

export interface RecetaEstudio {
  id?: number;
  fecha_creacion?: string;
  id_receta: number;
  id_estudio: string;
  tipo_estudio: EstudiosTypes;
}

export interface SimplifiedReceta {
  id?: number;
  doctorName: string;
  fecha_creacion?: string;
  patientName: string;
  moreInformation: 'Consultar';
}

export interface RecetasPost {
  receta: Receta;
  medicamentos: RecetaMedicamento[];
}

export interface Medicamento {
  id: number;
  DESCRIPCION: string | null;
  via: string | null;
  sal: string | null;
  sal1: string | null;
  sal2: string | null;
  sales: string;
}
