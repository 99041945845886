import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import { NotaConsulta } from '../../interfaces/NotaConsulta';
import {
  formatDate,
  getDoctorFullName,
  getNurseFullName,
  getPatientFullName,
} from '../../utils/fns';
import { useMainProvider } from '../../context/MainProvider';
import { UserTypes } from '../../interfaces/User';
import { Nurse } from '../../interfaces/Nurses';

function NotaConsultaDocument({
  patient,
  doctor,
  values,
}: {
  patient: Patient;
  doctor: Doctor;
  values: NotaConsulta;
}): JSX.Element {
  const { user } = useMainProvider();
  const [name, setName] = useState('');

  useEffect(() => {
    if (user && user.initialData) {
      if (user.typeId === UserTypes.PatientType) {
        setName(getPatientFullName(user.initialData as Patient).toUpperCase());
      }
      if (user.typeId === UserTypes.DoctorType) {
        setName(getDoctorFullName(user.initialData as Doctor).toUpperCase());
      }
      if (user.typeId === UserTypes.NurseType) {
        setName(getNurseFullName(user.initialData as Nurse).toUpperCase());
      }
    }
  }, [user]);

  useEffect(() => {
    moment.locale('es');
  }, [patient]);

  return (
    <table className='table-full-width'>
      <tbody>
        <tr>
          <td>
            <table className='table-full-width'>
              <tbody>
                <tr>
                  <td>
                    <span>
                      <p style={{ textAlign: 'end' }}>
                        <strong>
                          Fecha y Hora de impresión:{' '}
                          {moment().locale('ES').format('DD MMMM YYYY h:mm:ss a')}
                          <br />
                        </strong>
                      </p>

                      <p style={{ textAlign: 'right' }}>
                        <strong>
                          {doctor.med_consultorio?.toUpperCase()}
                          <br />
                          {doctor.med_raz_social?.toUpperCase()},{' '}
                          {doctor.med_no_registro?.toUpperCase()}
                          <br />
                          {doctor.med_domicilio?.toUpperCase()},
                          {doctor.med_colonia_consultorio?.toUpperCase()},{' '}
                          {doctor.med_municipio_consultorio?.toUpperCase()},{' '}
                          {doctor.med_ciudad_consultorio?.toUpperCase()},
                          {doctor.med_cp_consultorio}
                          <br />
                          Tel. {doctor.med_telefono_consultorio}
                        </strong>
                      </p>
                      <br />
                      <p style={{ textAlign: 'center', marginBottom: '50px' }}>
                        <strong>Nota De Consulta.</strong>
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Fecha y Hora de elaboración de la Nota:{' '}
                          {moment(values.fecha_creacion)
                            .locale('ES')
                            .format('DD MMMM YYYY h:mm:ss a')}
                        </strong>
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Datos del paciente
                          <br />
                        </strong>
                      </p>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Nombre</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_nombre} {patient.pac_apellidop}{' '}
                            {patient.pac_apellidom}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Fecha de nacimiento</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment(patient.pac_fechanacimiento)
                              .locale('ES')
                              .format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Edad</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment().diff(patient.pac_fechanacimiento, 'years')} años
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Sexo</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_sexo?.cat_sexo_desc}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Curp</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'uppercase' }}>
                            {patient.pac_curp}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Expediente</div>

                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_id}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Grupo Sanguíneo</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {patient.pac_tipo_sangre}
                          </span>
                        </div>
                      </div>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Datos de la nota
                          <br />
                        </strong>
                      </p>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Fecha y hora</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment(values.fecha_creacion)
                              .locale('ES')
                              .format('DD MMMM YYYY h:mm:ss a')}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> ¿Es primera consulta?</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.primera_consulta ? 'Si' : 'No'}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> ¿Consulta programada?</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.consulta_programada ? 'Si' : 'No'}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Subjetivos</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.comentarios_subjetivos}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Peso</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.peso}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Altura</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.altura}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Presión arterial</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.presion_arterial}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Frecuencia cardiaca</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.frecuencia_cardiaca}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Frecuencia respiratoria</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.frecuencia_respiratoria}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Temperatura</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.temperatura}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>Saturación oxígeno(%)</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.saturacion_oxigeno}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Habitus exterior</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.habitus_exterior}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Piel y anexos</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.piel_anexos}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Cabeza y cuello</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.cabeza_cuello}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Tórax</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.torax}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Cardiovascular</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.cardiovascular}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Abdomen</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.abdomen}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Genitales</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.genitales}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Sistema nervioso</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.sistema_nervioso}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Exámenes de laboratorio previos
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.examenes_previos}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'>
                          {' '}
                          Exámenes de laboratorio e imagenología
                        </div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.examenes_laboratorio_imagenologia}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Análisis</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.analisis}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Diagnostico(s)</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.nota_consulta_diagnosticos?.map((singleDiag) => {
                              return (
                                <p style={{ margin: '0', marginBottom: '15px' }}>
                                  - {singleDiag.cie_clave} {singleDiag.cie_descripcion}
                                </p>
                              );
                            })}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Pronóstico</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.pronostico}
                          </span>
                        </div>
                      </div>

                      <div className='print-data-container'>
                        <div className='print-data-left'> Indicación terapéutica</div>
                        <div className='print-data-right'>
                          <span style={{ textTransform: 'capitalize' }}>
                            {values.indicacion_terapeutica}
                          </span>
                        </div>
                      </div>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Datos del medico
                          <br />
                        </strong>
                      </p>
                    </span>

                    <div className='print-data-container'>
                      <div className='print-data-left'>Nombre</div>

                      <div className='print-data-right'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {doctor.med_nombre} {doctor.med_apellidop}{' '}
                          {doctor.med_apellidom}
                        </span>
                      </div>
                    </div>

                    <div className='print-data-container'>
                      <div className='print-data-left'>Especialidad</div>

                      <div className='print-data-right'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {doctor.med_especialidad?.cat_especialidad_desc}
                        </span>
                      </div>
                    </div>

                    <div className='print-data-container'>
                      <div className='print-data-left'>Cédula</div>

                      <div className='print-data-right'>
                        <span style={{ textTransform: 'capitalize' }}>
                          {doctor.med_cedula_profesional}
                        </span>
                      </div>
                    </div>

                    {doctor.med_especialidad_id !== 37 ? (
                      <>
                        <div className='print-data-container'>
                          <div className='print-data-left'>Cédula especialidad</div>

                          <div className='print-data-right'>
                            <span style={{ textTransform: 'capitalize' }}>
                              {doctor.med_cedula_especialidad}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        Aviso de privacidad
                        <br />
                      </strong>
                    </p>
                    <p style={{ fontSize: '0.8rem' }}>
                      Para ver nuestro Aviso de privacidad favor consultar:
                      http://zivot.com.mx/aviso-privacidad
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default NotaConsultaDocument;
