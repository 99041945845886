export interface SponsorPayment {
  id?: number;
  is_active?: boolean;
  fecha?: string;
  amount: number;
  sponsor_id: number;
}

export interface SimplifiedSponsorPayment {
  id?: number;
  is_active?: boolean;
  fecha?: string;
  amount: number;
  moreInformation: 'Consultar';
  delete: 'Desactivar' | 'Activar';
}

export const initialSponsorPayment: SponsorPayment = {
  is_active: true,
  fecha: '',
  amount: 0,
  sponsor_id: 0,
};
