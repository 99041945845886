import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { PrimaryTitle } from '../../components/PrimaryTitle/PrimaryTitle';
import { PageContainer } from '../Register/Register.styled';
import { useMainProvider } from '../../context/MainProvider';
import { UserTypes } from '../../interfaces/User';
import { Patient } from '../../interfaces/Patients';
import { Nurse } from '../../interfaces/Nurses';
import { Doctor } from '../../interfaces/Doctors';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import illustration from '../../assets/img/undraw_medicine_b-1-ol.svg';

const HomePage = (): JSX.Element => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const { user, setIsHMOpen, setQrCode } = useMainProvider();
  const location = useLocation();
  const urlParams = queryString.parse(location.search) as { access: string };
  console.log(urlParams);
  const [accessCode, setAccessCode] = useState<string>(urlParams.access ?? '');

  useEffect(() => {
    if (accessCode) {
      setQrCode(accessCode);
      setAccessCode('');
    }
  }, [accessCode, setQrCode]);

  const name = useMemo(() => {
    if (user && user.initialDataFilled) {
      if (user.typeId === UserTypes.PatientType) {
        return (user.initialData as Patient).pac_nombre;
      }
      if (user.typeId === UserTypes.DoctorType) {
        return (user.initialData as Doctor).med_nombre;
      }
      if (user.typeId === UserTypes.NurseType) {
        return (user.initialData as Nurse).enf_nombre;
      }
    }
    return '';
  }, [user]);

  useEffect(() => {
    setIsHMOpen(true);
  }, [setIsHMOpen]);

  return (
    <PageContainer>
      <Grid
        container
        direction='row'
        justifyContent='center'
        style={{
          marginTop: isMobile ? '1.5rem' : '5rem',
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <img
          src={illustration}
          alt='Imagen de un médico con un paciente'
          style={{ width: '250px', marginBottom: '1rem' }}
        />
        <PrimaryTitle style={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
          Buen día, {name}
        </PrimaryTitle>
        <PrimaryDescription>
          Selecciona una opción del menú para acceder a ella
        </PrimaryDescription>
      </Grid>
    </PageContainer>
  );
};

export default HomePage;
