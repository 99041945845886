import styled from 'styled-components';
import { Tab } from '@mui/material';

const PageContainer = styled.div`
  width: calc(100% - 3rem);
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LoginTab = styled(Tab)`
  text-transform: none !important;
  font-size: 1rem !important;
`;

const LoginFormContainer = styled.div`
  margin-top: 2rem;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export { PageContainer, LoginTab, LoginFormContainer };
