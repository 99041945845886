import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, MenuItem, useMediaQuery, useTheme, Button } from '@mui/material';
import { FaUserCircle, IoIosAddCircle } from 'react-icons/all';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { mainTheme } from '../../utils/theme/theme';
import {
  MaxValidationMessage,
  RequiredMessage,
  SelectValidOption,
} from '../../utils/validationMessages';
import { Patient } from '../../interfaces/Patients';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import {
  saveLocalstorageData,
  getLocalstorageData,
  removeLocalstorageData,
} from '../../utils/localStorage';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import { PageContainer } from '../Register/Register.styled';
import CenteredForm from '../../index.styled';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { postWithToken } from '../../utils/server';
import { User } from '../../interfaces/User';
import { deleteNullValues, getBase64 } from '../../utils/fns';
import EmptyClosure from '../../utils/closures';
import fallbackImage from '../../assets/img/profile-user.png';
import { BloodTypes } from '../../interfaces/BloodTypes';

interface PatientProps {
  existingUser?: User;
  initialDataMode?: boolean;
  externalProfile?: boolean;
}

const initialValues = (user?: User): Patient => {
  return {
    pac_curp: '',
    pac_titulo_id: -1,
    pac_fechanacimiento: moment().subtract(18, 'years').toISOString(true),
    pac_sexo_id: -1,
    pac_nombre: '',
    pac_apellidop: '',
    pac_apellidom: '',
    pac_estadocivil_id: -1,
    pac_ciudad: '',
    pac_estado_id: -1,
    pac_ocupacion_id: -1,
    pac_escolaridad_id: -1,
    pac_nombrepadre: '',
    pac_nombremadre: '',
    pac_direccion: '',
    pac_colonia: '',
    pac_cp: '',
    pac_municipio: '',
    pac_estadonacimiento_id: -1,
    pac_pais: '',
    pac_correo: user?.username ? user?.username : '',
    pac_telefono: '',
    pac_tipo_sangre: BloodTypes.Unknown,
    pac_nombreresponsable: '',
    pac_relacionresponsable: '',
    pac_direccionresponsable: '',
    pac_telefonoresponsable: '',
  };
};
function PatientInitialForm({
  existingUser,
  initialDataMode,
  externalProfile,
}: PatientProps): JSX.Element {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const {
    user: savedUser,
    setLoading,
    setUser,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    getStates,
    states,
    getDoctorSpecialities,
    getTitles,
    titles,
    getSexes,
    sexes,
    getCivilStatuses,
    civilStatuses,
    getOcupations,
    ocupations,
    getScholarDegrees,
    scholarDegrees,
    loading,
    restartTemporayUsers,
    handleLogout,
  } = useMainProvider();

  const [user, setTemporalUser] = useState<User | undefined>(existingUser || savedUser);
  const [values, setValues] = useState<Patient>(
    existingUser?.initialData
      ? (existingUser.initialData as Patient)
      : initialValues(savedUser),
  );

  useEffect(() => {
    if (existingUser) {
      setTemporalUser(existingUser);
    } else {
      setTemporalUser(savedUser);
    }
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUser, existingUser]);

  useEffect(() => {
    if (user && user.initialData) {
      setValues(user.initialData as Patient);
    }
  }, [user]);

  const navigate = useNavigate();
  const [transformedFile, setTransformedFiles] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [fileExtensions, setFileExtension] = useState<string>('');
  const [imagePreview, setImagePreview] = useState('');

  const validationSchema = Yup.object({
    pac_titulo_id: Yup.number()
      .required(RequiredMessage)
      .test('pac_titulo_id', SelectValidOption(1, 'titulo'), (val) => {
        return val !== -1;
      }),
    pac_nombre: Yup.string().required(RequiredMessage),
    pac_apellidop: Yup.string().required(RequiredMessage),
    pac_apellidom: Yup.string().required(RequiredMessage),
    pac_fechanacimiento: Yup.string().required(RequiredMessage),
    pac_sexo_id: Yup.number()
      .required(RequiredMessage)
      .test('pac_sexo_id', SelectValidOption(1, 'sexo'), (val) => {
        return val !== -1;
      }),
    pac_curp: Yup.string().required(RequiredMessage).max(18, MaxValidationMessage(18)),
    pac_estadocivil_id: Yup.number()
      .required(RequiredMessage)
      .test('pac_estadocivil_id', SelectValidOption(1, 'estado civil'), (val) => {
        return val !== -1;
      }),
    pac_ciudad: Yup.string().required(RequiredMessage),
    pac_estado_id: Yup.number()
      .required(RequiredMessage)
      .test('pac_estado_id', SelectValidOption(1, 'estado'), (val) => {
        return val !== -1;
      }),
    pac_ocupacion_id: Yup.number()
      .required(RequiredMessage)
      .test('pac_ocupacion_id', SelectValidOption(0, 'ocupación'), (val) => {
        return val !== -1;
      }),
    pac_direccion: Yup.string().max(50, MaxValidationMessage(50)),
  });

  const formik = useFormik<Patient>({
    initialValues: values,
    validationSchema,
    enableReinitialize: true,
    /**
     * @desc logins the user with the given information
     * @param patientValues
     */
    onSubmit: async (patientValues) => {
      try {
        setLoading(true);
        const savePantientValuesResult = await postWithToken<
          {
            patient: Patient;
            userId?: number;
            file: string | ArrayBuffer | null;
            extension: string;
          },
          Patient
        >(
          `/patient/${initialDataMode ? 'save' : 'update'}-patient`,
          {
            patient: deleteNullValues<Patient>({
              ...patientValues,
              pac_id_usuario_paciente_id: user?.id,
            }),
            userId: user?.id,
            file: transformedFile,
            extension: fileExtensions,
          },
          handleLogout,
        );
        if (savePantientValuesResult.success) {
          const userInformation = getLocalstorageData<User>(
            'CODIGO_MEDICO_USER_INFORMATION',
          );
          if (userInformation.success) {
            const userData = userInformation.data;
            userData.initialDataFilled = true;
            userData.initialData = savePantientValuesResult.data;
            removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
            saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', userData);
            setUser(userData);
            setLoading(false);
            setAlertType(AlertTypes.Success);
            setAlertMessage(`Información guardada con éxito`);
            setOpenAlert(true);
            if (initialDataMode) navigate('/home');
            return;
          }
        }
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al guardar información`);
        setOpenAlert(true);
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar la información del paciente: ${JSON.stringify(
              e.response.data,
            )} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiles(base64);
        setFileExtension(e.target.files[0].type.split('/')[1]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
      }
    } catch (err) {
      handleLogout(e);
      setLoading(false);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    getStates();
    getDoctorSpecialities();
    getTitles();
    getSexes();
    getCivilStatuses();
    getOcupations();
    getScholarDegrees();
  }, [
    getStates,
    getDoctorSpecialities,
    getTitles,
    getSexes,
    getCivilStatuses,
    getOcupations,
    getScholarDegrees,
  ]);

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={6}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                {!initialDataMode ? null : (
                  <>
                    <PrimaryTitle mobile={mobile}>
                      Completa los datos de tu cuenta
                    </PrimaryTitle>
                    <PrimaryDescription mobile={mobile}>
                      Antes de comenzar necesitamos saber un poco más de ti. Añade una
                      imagen para tu foto de perfil.
                    </PrimaryDescription>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ justifyContent: 'center', display: 'flex' }}
                >
                  <>
                    <label htmlFor='upload-photo'>
                      <input
                        style={{ display: 'none' }}
                        id='upload-photo'
                        name='upload-photo'
                        type='file'
                        accept='image/png, image/jpeg'
                        onChange={handleUpload}
                        disabled={externalProfile}
                      />
                      <Button
                        component='span'
                        style={{
                          width: mobile ? '100%' : 'initial',
                          marginBottom: '2rem',
                        }}
                      >
                        {imagePreview || values.pac_imagen ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={imagePreview || values.pac_imagen}
                              onError={(e: SyntheticEvent<HTMLImageElement>) => {
                                const target = e.target as EventTarget & HTMLImageElement;
                                target.src = fallbackImage;
                              }}
                              alt='img'
                              style={{
                                width: '15rem',
                              }}
                            />
                            {!existingUser ? (
                              <IoIosAddCircle
                                style={{
                                  color: 'black',
                                  fontSize: '3rem',
                                  position: 'absolute',
                                  right: '0',
                                  bottom: '0',
                                }}
                              />
                            ) : null}
                          </div>
                        ) : (
                          <div style={{ position: 'relative' }}>
                            <FaUserCircle
                              style={{
                                color: mainTheme.colors.uiElements,
                                fontSize: '10rem',
                              }}
                            />
                            <IoIosAddCircle
                              style={{
                                color: 'black',
                                fontSize: '3rem',
                                position: 'absolute',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </div>
                        )}
                      </Button>
                      <span
                        style={{
                          marginLeft: mobile ? 'initial' : '2%',
                          color: mainTheme.colors.standardText,
                          display: mobile ? 'block' : 'initial',
                        }}
                      />
                    </label>
                  </>
                </Grid>
                <PrimaryTitleStart mobile={mobile}>
                  {!existingUser && !initialDataMode ? 'Mi perfil' : 'Datos del paciente'}
                </PrimaryTitleStart>
                <CMTextfield
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Titulo'
                  value={formik.values.pac_titulo_id}
                  name='pac_titulo_id'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_titulo_id && Boolean(formik.errors.pac_titulo_id)
                  }
                  helperText={formik.touched.pac_titulo_id && formik.errors.pac_titulo_id}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {titles.map((singleTitle) => {
                    return (
                      <MenuItem value={singleTitle.cat_titulo_id}>
                        {singleTitle.cat_titulo_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfield>
                <CMTextfield
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Nombre'
                  value={formik.values.pac_nombre}
                  name='pac_nombre'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_nombre && Boolean(formik.errors.pac_nombre)}
                  helperText={formik.touched.pac_nombre && formik.errors.pac_nombre}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Apellido paterno'
                  value={formik.values.pac_apellidop}
                  name='pac_apellidop'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_apellidop && Boolean(formik.errors.pac_apellidop)
                  }
                  helperText={formik.touched.pac_apellidop && formik.errors.pac_apellidop}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Apellido materno'
                  value={formik.values.pac_apellidom}
                  name='pac_apellidom'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_apellidom && Boolean(formik.errors.pac_apellidom)
                  }
                  helperText={formik.touched.pac_apellidom && formik.errors.pac_apellidom}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    disabled={!!existingUser}
                    label='Fecha de nacimiento'
                    value={formik.values.pac_fechanacimiento}
                    onChange={(value): void => {
                      formik.setFieldValue('pac_fechanacimiento', value);
                    }}
                    inputFormat='DD/MM/yyyy'
                    renderInput={(params) => (
                      <CMTextfield
                        {...params}
                        name='pac_fechanacimiento'
                        required
                        error={
                          formik.touched.pac_fechanacimiento &&
                          Boolean(formik.errors.pac_fechanacimiento)
                        }
                        helperText={
                          formik.touched.pac_fechanacimiento &&
                          formik.errors.pac_fechanacimiento
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
                <CMTextfield
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Sexo'
                  value={formik.values.pac_sexo_id}
                  name='pac_sexo_id'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_sexo_id && Boolean(formik.errors.pac_sexo_id)}
                  helperText={formik.touched.pac_sexo_id && formik.errors.pac_sexo_id}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {sexes.map((singleSex) => {
                    return (
                      <MenuItem value={singleSex.cat_sexo_id}>
                        {singleSex.cat_sexo_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfield>
                <CMTextfield
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='CURP'
                  value={formik.values.pac_curp}
                  name='pac_curp'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_curp && Boolean(formik.errors.pac_curp)}
                  helperText={formik.touched.pac_curp && formik.errors.pac_curp}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                  required
                />
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Estado civil'
                  value={formik.values.pac_estadocivil_id}
                  name='pac_estadocivil_id'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_estadocivil_id &&
                    Boolean(formik.errors.pac_estadocivil_id)
                  }
                  helperText={
                    formik.touched.pac_estadocivil_id && formik.errors.pac_estadocivil_id
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                  required
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {civilStatuses.map((singleCivilStatus) => {
                    return (
                      <MenuItem value={singleCivilStatus.cat_edo_civil_id}>
                        {singleCivilStatus.cat_edo_civil_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfieldHalfLeft>
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Ciudad'
                  value={formik.values.pac_ciudad}
                  name='pac_ciudad'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_ciudad && Boolean(formik.errors.pac_ciudad)}
                  helperText={formik.touched.pac_ciudad && formik.errors.pac_ciudad}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                  required
                />
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Estado'
                  value={formik.values.pac_estado_id}
                  name='pac_estado_id'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_estado_id && Boolean(formik.errors.pac_estado_id)
                  }
                  helperText={formik.touched.pac_estado_id && formik.errors.pac_estado_id}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                  required
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {states.map((singleState) => {
                    return (
                      <MenuItem value={singleState.cat_estado_id}>
                        {singleState.cat_estado_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfieldHalfLeft>
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Ocupación'
                  value={formik.values.pac_ocupacion_id}
                  name='pac_ocupacion_id'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_ocupacion_id &&
                    Boolean(formik.errors.pac_ocupacion_id)
                  }
                  helperText={
                    formik.touched.pac_ocupacion_id && formik.errors.pac_ocupacion_id
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                  required
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {ocupations.map((singleOcupation) => {
                    return (
                      <MenuItem value={singleOcupation.cat_ocup_id}>
                        {singleOcupation.cat_ocup_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfieldHalfRight>
                <CMTextfield
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Escolaridad'
                  value={formik.values.pac_escolaridad_id}
                  name='pac_escolaridad_id'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_escolaridad_id &&
                    Boolean(formik.errors.pac_escolaridad_id)
                  }
                  helperText={
                    formik.touched.pac_escolaridad_id && formik.errors.pac_escolaridad_id
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {scholarDegrees.map((singleScholarDegree) => {
                    return (
                      <MenuItem value={singleScholarDegree.cat_escolaridad_id}>
                        {singleScholarDegree.cat_escolaridad_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfield>
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Dirección'
                  value={formik.values.pac_direccion}
                  name='pac_direccion'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_direccion && Boolean(formik.errors.pac_direccion)
                  }
                  helperText={formik.touched.pac_direccion && formik.errors.pac_direccion}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Colonia'
                  value={formik.values.pac_colonia}
                  name='pac_colonia'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_colonia && Boolean(formik.errors.pac_colonia)}
                  helperText={formik.touched.pac_colonia && formik.errors.pac_colonia}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Código postal'
                  value={formik.values.pac_cp}
                  name='pac_cp'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_cp && Boolean(formik.errors.pac_cp)}
                  helperText={formik.touched.pac_cp && formik.errors.pac_cp}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Municipio'
                  value={formik.values.pac_municipio}
                  name='pac_municipio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_municipio && Boolean(formik.errors.pac_municipio)
                  }
                  helperText={formik.touched.pac_municipio && formik.errors.pac_municipio}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Estado de nacimineto'
                  value={formik.values.pac_estadonacimiento_id}
                  name='pac_estadonacimiento_id'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_estadonacimiento_id &&
                    Boolean(formik.errors.pac_estadonacimiento_id)
                  }
                  helperText={
                    formik.touched.pac_estadonacimiento_id &&
                    formik.errors.pac_estadonacimiento_id
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {states.map((singleState) => {
                    return (
                      <MenuItem value={singleState.cat_estado_id}>
                        {singleState.cat_estado_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfield>
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='País'
                  value={formik.values.pac_pais}
                  name='pac_pais'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_pais && Boolean(formik.errors.pac_pais)}
                  helperText={formik.touched.pac_pais && formik.errors.pac_pais}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Correo'
                  value={formik.values.pac_correo}
                  name='pac_correo'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.pac_correo && Boolean(formik.errors.pac_correo)}
                  helperText={formik.touched.pac_correo && formik.errors.pac_correo}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Teléfono'
                  value={formik.values.pac_telefono}
                  name='pac_telefono'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_telefono && Boolean(formik.errors.pac_telefono)
                  }
                  helperText={formik.touched.pac_telefono && formik.errors.pac_telefono}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  disabled={!!existingUser}
                  select
                  id='outlined-basic'
                  label='Tipo de sangre'
                  value={formik.values.pac_tipo_sangre}
                  name='pac_tipo_sangre'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_tipo_sangre &&
                    Boolean(formik.errors.pac_tipo_sangre)
                  }
                  helperText={
                    formik.touched.pac_tipo_sangre && formik.errors.pac_tipo_sangre
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                  required
                >
                  <MenuItem disabled value={BloodTypes.Unknown}>
                    No sabe
                  </MenuItem>
                  <MenuItem value={BloodTypes.A1Negative}>
                    {BloodTypes.A1Negative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A1Positive}>
                    {BloodTypes.A1Positive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A2Negative}>
                    {BloodTypes.A2Negative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A2Positive}>
                    {BloodTypes.A2Positive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.ABNegative}>
                    {BloodTypes.ABNegative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.ABPositive}>
                    {BloodTypes.ABPositive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.BNegative}>{BloodTypes.BNegative}</MenuItem>
                  <MenuItem value={BloodTypes.BPositive}>{BloodTypes.BPositive}</MenuItem>
                  <MenuItem value={BloodTypes.ONegative}>{BloodTypes.ONegative}</MenuItem>
                  <MenuItem value={BloodTypes.OPositive}>{BloodTypes.OPositive}</MenuItem>
                  <MenuItem value={BloodTypes.RHNegative}>
                    {BloodTypes.RHNegative}
                  </MenuItem>
                </CMTextfield>
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Nombre del padre'
                  value={formik.values.pac_nombrepadre}
                  name='pac_nombrepadre'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_nombrepadre &&
                    Boolean(formik.errors.pac_nombrepadre)
                  }
                  helperText={
                    formik.touched.pac_nombrepadre && formik.errors.pac_nombrepadre
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Nombre de la madre'
                  value={formik.values.pac_nombremadre}
                  name='pac_nombremadre'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_nombremadre &&
                    Boolean(formik.errors.pac_nombremadre)
                  }
                  helperText={
                    formik.touched.pac_nombremadre && formik.errors.pac_nombremadre
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <PrimaryTitleStart mobile={mobile}>
                  Datos del familiar o responsable
                </PrimaryTitleStart>
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Nombre de responsable'
                  value={formik.values.pac_nombreresponsable}
                  name='pac_nombreresponsable'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_nombreresponsable &&
                    Boolean(formik.errors.pac_nombreresponsable)
                  }
                  helperText={
                    formik.touched.pac_nombreresponsable &&
                    formik.errors.pac_nombreresponsable
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Relación de responsable'
                  value={formik.values.pac_relacionresponsable}
                  name='pac_relacionresponsable'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_relacionresponsable &&
                    Boolean(formik.errors.pac_relacionresponsable)
                  }
                  helperText={
                    formik.touched.pac_relacionresponsable &&
                    formik.errors.pac_relacionresponsable
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Dirección de responsable'
                  value={formik.values.pac_direccionresponsable}
                  name='pac_direccionresponsable'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_direccionresponsable &&
                    Boolean(formik.errors.pac_direccionresponsable)
                  }
                  helperText={
                    formik.touched.pac_direccionresponsable &&
                    formik.errors.pac_direccionresponsable
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  disabled={!!existingUser}
                  id='outlined-basic'
                  label='Teléfono de responsable'
                  value={formik.values.pac_telefonoresponsable}
                  name='pac_telefonoresponsable'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.pac_telefonoresponsable &&
                    Boolean(formik.errors.pac_telefonoresponsable)
                  }
                  helperText={
                    formik.touched.pac_telefonoresponsable &&
                    formik.errors.pac_telefonoresponsable
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                {!existingUser ? (
                  <PrimaryButton
                    loading={false}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%', marginTop: '1rem' }}
                  >
                    {initialDataMode ? 'Enviar información' : 'Actualizar información'}
                  </PrimaryButton>
                ) : null}
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

PatientInitialForm.defaultProps = {
  existingUser: undefined,
  initialDataMode: false,
  externalProfile: false,
};

export default PatientInitialForm;
