import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { mainTheme } from '../../utils/theme/theme';

export const RouterLink = styled(Link)`
  width: 100%;
  color: ${mainTheme.colors.links};
  text-align: start;
  margin: 0 0 1rem 0;
  font-size: 0.9rem;
  font-family: ${mainTheme.fonts.text.family};
  font-weight: ${mainTheme.fonts.text.weight};
  text-decoration: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
