/* eslint-disable no-nested-ternary */
import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { CRUDModes } from '../../interfaces/CRUD';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';

interface ImagePreviewProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  imageURL: string;
}

const ImagePreview = ({ open, setOpen, imageURL }: ImagePreviewProps): JSX.Element => {
  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        setOpen(false);
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          setOpen(false);
        }}
        mode={CRUDModes.Update}
        title='imágen'
      />

      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          <div>
            <img
              src={imageURL}
              alt='Imágen no disponible por el momento, intentelo más tarde'
              style={{ width: '100%' }}
            />
          </div>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

export default ImagePreview;
