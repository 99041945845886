import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Autocomplete } from '@mui/material';
import { useSponsorsProvider } from '../../../Context/SponsorsMainContext';
import { SponsorsTableEntry } from '../../../Interfaces/Sponsors';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../../../../interfaces/GenericTable';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { postWithToken } from '../../../../../utils/server';
import { AlertTypes } from '../../../../../interfaces/MainContextInitialValues';
import { setSponsorHeaders } from '../../../../../utils/headerSetter';
import EmptyClosure from '../../../../../utils/closures';
import GenericTablePage from '../../../../GenericTablePage/GenericTablePage';
import {
  initialSponsorPayment,
  SimplifiedSponsorPayment,
  SponsorPayment,
} from '../SponsorPayments.Interfaces';
import { CMTextfield } from '../../../../../components/Forms/CMTextfield';
import { SponsorAccountType } from '../../../Interfaces/ContextInterfaces';
import SponsorsPaymentPopup from '../CRUD/SponsorPaymentPopup';
import { FlexContainer } from '../../../../../components/FlexContainer/FlexContainer';

const SponsorPaymentsTable = (): JSX.Element => {
  const {
    setLoading,
    setOpenAlert,
    setAlertType,
    setAlertMessage,
    token,
    handleLogoutError,
    sponsor,
    user,
  } = useSponsorsProvider();
  const [entries, setEntries] = useState<SponsorPayment[]>();
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);
  const [selectedSponsor, setSelectedSponsor] = useState<number>();
  const [sponsorList, setSponsorList] = useState<{ id: number; name: string }[]>();

  const handleDeactivate = useCallback(
    async (entry: SponsorPayment) => {
      try {
        setLoading(true);
        const result = await postWithToken<SponsorPayment, null>(
          `/sponsors/update-payment`,
          { ...entry, is_active: !entry.is_active },
          handleLogoutError,
          true,
        );
        if (!result.success) {
          throw new Error(result.error);
        }
        setAlertType(AlertTypes.Success);
        setAlertMessage(`Pago ${entry.is_active ? 'desactivado' : 'activado'} con éxito`);
        setOpenAlert(true);
        setLoading(false);
        setEntries(undefined);
      } catch (e: any) {
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al guardar información: ${e?.message || 'Desconocido'}`);
        setOpenAlert(true);
        setLoading(false);
      }
    },
    [handleLogoutError, setAlertMessage, setAlertType, setLoading, setOpenAlert],
  );

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await axios.get<SponsorPayment[]>(
        `${process.env.REACT_APP_SERVER_URL}/sponsors/payments/${
          selectedSponsor ?? sponsor?.id
        }`,
        setSponsorHeaders(false, token),
      );
      if (fetchResult.status === 200) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha',
            displayName: 'Fecha de creación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'is_active',
            displayName: 'Activo',
            type: GenericColumnTypes.Boolean,
            sortable: false,
          },
          {
            name: 'amount',
            displayName: 'Monto',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: 'Consultar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
          {
            name: 'delete',
            displayName: 'Desactivar/Activar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              handleDeactivate(orderedRows[index]);
            },
          },
        ]);
        const simplifiedData: SimplifiedSponsorPayment[] = orderedRows.map((entry) => {
          return {
            id: entry.id,
            fecha: entry.fecha,
            is_active: entry.is_active,
            amount: entry.amount,
            moreInformation: 'Consultar',
            delete: entry.is_active ? 'Desactivar' : 'Activar',
          };
        });
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      setEntries([]);
      handleLogoutError(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener lista de pagos: ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const fetchCallback = useCallback(fetchEntries, [
    handleDeactivate,
    handleLogoutError,
    selectedSponsor,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    sponsor?.id,
    token,
  ]);

  useEffect(() => {
    if (
      !entries &&
      ((user?.typeId === SponsorAccountType.superAdmin && selectedSponsor) ||
        (user?.typeId !== SponsorAccountType.superAdmin && sponsor?.id))
    ) {
      fetchCallback().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [fetchCallback, entries, user?.typeId, selectedSponsor, sponsor?.id]);

  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const response = await axios.get<SponsorsTableEntry[]>(
          `${process.env.REACT_APP_SERVER_URL}/sponsors`,
          setSponsorHeaders(false, token),
        );
        setSponsorList(
          response.data.map((sp) => ({
            id: sp.id,
            name: sp.name,
          })),
        );
      } catch (e) {
        handleLogoutError(e);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al obtener lista de patrocinadores: ${e}`);
        setOpenAlert(true);
      }
    };

    if (!sponsorList && user?.typeId === SponsorAccountType.superAdmin) {
      fetchSponsors().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [
    handleLogoutError,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    sponsorList,
    token,
    user?.typeId,
  ]);

  useEffect(() => {
    setEntries(undefined);
  }, [selectedSponsor]);

  return (
    <>
      <SponsorsPaymentPopup
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={
          selectedRow !== undefined && selectedRow !== -1
            ? (entries || [])[selectedRow]
            : {
                ...initialSponsorPayment,
                sponsor_id: selectedSponsor ?? 0,
              }
        }
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />
      {user?.typeId === 1 && sponsorList ? (
        <div style={{ width: 'calc(100% - 6rem)' }}>
          <FlexContainer style={{ padding: '3rem 3rem 0 3rem' }}>
            <Autocomplete
              style={{ width: '100%' }}
              openOnFocus
              id='select-sponsor'
              options={sponsorList}
              noOptionsText='No hay opciones disponibles'
              getOptionLabel={(option) => `${option.name}`}
              onChange={(_e, value) => {
                setSelectedSponsor(value?.id);
              }}
              renderInput={(params) => (
                <CMTextfield
                  {...params}
                  label='Selecciona un patrocinador'
                  placeholder='Patrocinador'
                  style={{ marginBottom: '0' }}
                />
              )}
            />
          </FlexContainer>
        </div>
      ) : null}
      <GenericTablePage
        title='Lista de pagos'
        rows={rows}
        columns={columns}
        canCreate
        canDelete={false}
        canEdit
        onCreate={() => {
          setOpen(true);
          setSelectedRow(-1);
          setMode(CRUDModes.Create);
        }}
      />
    </>
  );
};

export default SponsorPaymentsTable;
