import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useMainProvider } from '../../context/MainProvider';
import GenericTablePage from '../GenericTablePage/GenericTablePage';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import { setHeaders } from '../../utils/headerSetter';
import { CRUDModes } from '../../interfaces/CRUD';
import { UserTypes } from '../../interfaces/User';
import {
  ExtendedNotaConsulta,
  SimplifiedNotaConsulta,
} from '../../interfaces/NotaConsulta';
import NotaConsultaForm from './NotaConsultaForm';
import { getPatientFullName } from '../../utils/fns';
import { Patient } from '../../interfaces/Patients';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import { ZivotDocumentTypes } from '../../interfaces/DoctorProfile';
import { previewUserID } from '../../utils/server';

const NotaConsultaPage = (): JSX.Element => {
  const {
    setLoading,
    user,
    setOpenAlert,
    setAlertType,
    setAlertMessage,
    currentPatient,
    restartTemporayUsers,
    setLoginPopupOpen,
    handleLogout,
  } = useMainProvider();
  const [entries, setEntries] = useState<ExtendedNotaConsulta[]>([]);
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await axios.post<ExtendedNotaConsulta[]>(
        `${process.env.REACT_APP_SERVER_URL}/notaConsulta`,
        { patientId: currentPatient ? currentPatient.id : undefined },
        setHeaders(true, user ? user.token : undefined),
      );
      if (fetchResult.status === 200) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID Nota',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha_creacion',
            displayName: 'Fecha de creación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'doctorName',
            displayName: 'Médico que Elaboró',
            type: GenericColumnTypes.ShowProfile,
            sortable: true,
            documentType: ZivotDocumentTypes.consultationNote,
          },
          {
            name: 'doctorSpeciality',
            displayName: 'Especialidad',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'patientName',
            displayName: 'Nombre Paciente',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'primera_consulta',
            displayName: '¿Primera Consulta?',
            type: GenericColumnTypes.Boolean,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: isIOSApp() ? 'Consultar' : 'Consultar y/o Imprimir',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
        ]);
        const simplifiedData: SimplifiedNotaConsulta[] = orderedRows.map((nota) => {
          return {
            id: nota.id,
            creationDate: nota.fecha_creacion,
            doctorName: `${nota.doctorInformation?.med_nombre} ${nota.doctorInformation?.med_apellidop} ${nota.doctorInformation?.med_apellidom}`,
            doctorSpeciality:
              nota.doctorInformation?.med_especialidad?.cat_especialidad_desc,
            patientName: `${nota.patientInformation?.pac_nombre} ${nota.patientInformation?.pac_apellidop} ${nota.patientInformation?.pac_apellidom}`,
            primera_consulta: nota.primera_consulta,
            moreInformation: isIOSApp() ? 'Consultar' : 'Ver',
          };
        });
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener notas de consulta: ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
        if (
          e.response.data ===
          'El tiempo de acceso al Expediente Clínico del Paciente terminó, si requiere continuar, favor de escanear otro código QR de su Paciente. Gracias'
        ) {
          restartTemporayUsers();
        }
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const fetchCallback = useCallback(fetchEntries, [
    currentPatient,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    user,
    restartTemporayUsers,
    handleLogout,
  ]);

  useEffect(() => {
    fetchCallback().then(EmptyClosure).catch(EmptyClosure);
  }, [fetchCallback]);

  return (
    <>
      <NotaConsultaForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={selectedRow !== -1 ? entries[selectedRow] : undefined}
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      <GenericTablePage
        title={`Notas de consulta${
          currentPatient
            ? ` de ${getPatientFullName(currentPatient.initialData as Patient)}`
            : ''
        }`}
        rows={rows}
        columns={columns}
        canCreate={user && currentPatient ? user.typeId === UserTypes.DoctorType : false}
        canDelete={false}
        canEdit
        onCreate={() => {
          if (user && user.id === previewUserID) {
            setLoginPopupOpen(true);
          } else {
            setOpen(true);
            setSelectedRow(-1);
            setMode(CRUDModes.Create);
          }
        }}
      />
    </>
  );
};

export default NotaConsultaPage;
