import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import * as Yup from 'yup';
import { IoMdCloseCircle } from 'react-icons/all';
import { useMainProvider } from '../../context/MainProvider';
import { CRUDModes } from '../../interfaces/CRUD';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { setHeaders } from '../../utils/headerSetter';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import { Medicamento } from '../../interfaces/Receta';
import { NoPaddingButton } from '../../components/NoPaddingButton/NoPaddingButton';
import { StandardText } from '../../components/StandardText/StandardText';
import { Patient } from '../../interfaces/Patients';
import {
  NotaEnfermeria,
  NotaEnfermeriaMedicamento,
  NotaEnfermeriaMedicamentoForm,
  NotaEnfermeriaPost,
} from '../../interfaces/NotaEnfermeria';
import { Cie10 } from '../../interfaces/Cie10';
import NotaEnfermeriaDocument from './NotaEnfermeriaDocument';
import { Nurse } from '../../interfaces/Nurses';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { RequiredMessage } from '../../utils/validationMessages';
import { canPrintNote } from '../../utils/fns';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: NotaEnfermeria;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: NotaEnfermeria = {
  paciente_id: 0,
  doctor_id: 0,
  peso: '',
  altura: '',
  presion_arterial: '',
  frecuencia_cardiaca: '',
  frecuencia_respiratoria: '',
  temperatura: '',
  oxigenacion: '',
  comentarios_subjetivos: '',
  comentarios_objetivos: '',
  analisis: '',
  pronostico: '',
  plan_trabajo: '',
  nota_enfermeria_alergias: '',
  alergias_alimentos: '',
  alergias_otros: '',
  dieta: '',
  soluciones: '',
  estudios_indicados: '',
  otras_indicaciones: '',
  imc: 0,
  medicamentos_fuera_cuadro: '',
  nota_enfermeria_diagnosticos: [],
};

const NotaEnfermeriaForm = ({
  open,
  setOpen,
  mode,
  onSuccess,
  values,
  closeFunction,
}: FormProps): JSX.Element => {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    currentPatient,
    cie10,
    getCie10,
    handleLogout,
  } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [medicamentos, setMedicamentos] = useState<Medicamento[]>([]);
  const [showExtraMedicineField, setShowExtraMedicineField] = useState(false);
  const [sales, setSales] = useState<Medicamento[]>([]);
  const [selectedMedicamentos, setSelectedMedicamentos] = useState<
    NotaEnfermeriaMedicamentoForm[]
  >([]);
  const [medicineSearch, setMedicineSearch] = useState('');
  const [cie10Search, setCie10Search] = useState<Cie10[]>([]);
  const [salSearch, setSalSearch] = useState('');
  const [nurseInformation, setNurseInformation] = useState<Nurse | null>(null);
  const [patientInformation, setPatientInformation] = useState<Patient | null>(null);
  const printRef = useRef<HTMLInputElement>(null);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.doctor_id !== user?.id,
    [mode, formValues, user?.id],
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  useEffect(() => {
    getCie10();
  }, [getCie10]);

  useEffect(() => {
    setCie10Search(cie10.slice(0, 100));
  }, [cie10]);

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
      }
    } else {
      setPatientInformation(values.patientInformation as Patient);
      setNurseInformation(values.nurseInformation as Nurse);
      setFormValues(values);
      setSelectedMedicamentos(values.medicamentos_seleccionados || []);
      if (values.medicamentos_fuera_cuadro) {
        setShowExtraMedicineField(true);
      }
    }
  }, [currentPatient, values]);

  const validationSchema = Yup.object({
    peso: Yup.string().required(RequiredMessage),
    altura: Yup.string().required(RequiredMessage),
    presion_arterial: Yup.string().required(RequiredMessage),
    frecuencia_cardiaca: Yup.string().required(RequiredMessage),
    frecuencia_respiratoria: Yup.string().required(RequiredMessage),
    temperatura: Yup.string().required(RequiredMessage),
    oxigenacion: Yup.string().required(RequiredMessage),
  });

  const formik = useFormik<NotaEnfermeria>({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (form) => {
      try {
        setLoading(true);
        const entry: NotaEnfermeriaPost = {
          nota: {
            ...form,
            imc: Number((Number(form.peso) / Number(form.altura) ** 2).toFixed(2)),
          },
          medicamentos: selectedMedicamentos
            .filter((med) => med.medicamento !== undefined)
            .map((med): NotaEnfermeriaMedicamento => {
              return {
                marca_recomendada: med.marca_recomendada,
                id_medicamento: med.medicamento?.id || 0,
                dosis: med.dosis,
                via: med.via,
                periodicidad: med.periodicidad,
                numero_dias: med.numero_dias,
                numero_cajas: med.numero_cajas,
              };
            }),
        };
        const creationResult = await axios.post<{
          entry: NotaEnfermeriaPost;
        }>(
          `${process.env.REACT_APP_SERVER_URL}/notaEnfermeria/${
            mode === CRUDModes.Create ? 'post' : 'update'
          }`,
          {
            entry,
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          setHeaders(true, user ? user.token : undefined),
        );
        if (creationResult.status === 200) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `¡Nota de enfermería ${
              mode === CRUDModes.Create ? 'creada' : 'actualizada'
            } exitosamente!`,
          );
          setOpenAlert(true);
          setLoading(false);
          formik.resetForm();
          setFormValues(initialValues);
          setMedicineSearch('');
          setSelectedMedicamentos([]);
          setCie10Search([]);
          setSales([]);
          setShowExtraMedicineField(false);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar nota de enfermeria: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const searchOptionsMedicines = async (searchterm: string) => {
    try {
      const results = await axios.post<Medicamento[]>(
        `${process.env.REACT_APP_SERVER_URL}/receta/search-medicamentos`,
        {
          searchterm,
        },
        setHeaders(true, user ? user.token : undefined),
      );
      setMedicamentos(results.data);
    } catch (e) {
      handleLogout(e);
      setAlertType(AlertTypes.Error);
      setAlertMessage(`Error al buscar medicamentos`);
      setOpenAlert(true);
    }
  };
  const addMedicamento = () => {
    setMedicineSearch('');
    if (!selectedMedicamentos.find((medicamento) => !medicamento.medicamento))
      setSelectedMedicamentos([
        ...selectedMedicamentos,
        {
          marca_recomendada: '',
          dosis: '',
          via: '',
          periodicidad: '',
          numero_cajas: '',
          numero_dias: 0,
        },
      ]);
  };

  const updateSelectionMedicina = (
    index: number,
    updatedSelection?: Medicamento | null,
  ) => {
    const newArray = [...selectedMedicamentos];
    newArray[index] = {
      ...newArray[index],
      medicamento: updatedSelection || undefined,
      via: updatedSelection ? updatedSelection.via || '' : '',
      marca_recomendada: updatedSelection?.DESCRIPCION
        ? updatedSelection?.DESCRIPCION
        : '',
    };
    setSelectedMedicamentos(newArray);
  };

  const deleteMedicamento = (index: number) => {
    const newEntries = [...selectedMedicamentos];
    newEntries.splice(index, 1);
    setSelectedMedicamentos(newEntries);
  };

  const handleDiagnosisInput = (value: string) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    setCie10Search(filteredCie);
  };

  const searchOptionsSales = async (searchterm: string) => {
    try {
      const results = await axios.post<Medicamento[]>(
        `${process.env.REACT_APP_SERVER_URL}/receta/search-sales`,
        {
          searchterm,
        },
        setHeaders(true, user ? user.token : undefined),
      );
      setSales(results.data);
    } catch (e) {
      handleLogout(e);
      setAlertType(AlertTypes.Error);
      setAlertMessage(`Error al buscar información`);
      setOpenAlert(true);
    }
  };

  const searchSalesCallback = useCallback(searchOptionsSales, [
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    handleLogout,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchSalesCallback(salSearch);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchSalesCallback, salSearch]);

  const handleReset = () => {
    formik.resetForm();
    setFormValues(initialValues);
    setMedicineSearch('');
    setSelectedMedicamentos([]);
    setCie10Search([]);
    setSales([]);
    setShowExtraMedicineField(false);
    setOpen(false);
    if (closeFunction) closeFunction();
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleReset();
        }
      }}
    >
      <DialogCustomTitle onClose={handleReset} mode={mode} title='nota de enfermería' />{' '}
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Datos del paciente</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0' }}>
                  Llena la información general del paciente. El IMC se calculará en
                  automático al ingresar el peso y altura.
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12}>
                <StandardText>
                  <b>Nombre:</b>{' '}
                  {patientInformation ? (
                    <>
                      {patientInformation?.pac_nombre} {patientInformation?.pac_apellidop}{' '}
                      {patientInformation?.pac_apellidom}
                    </>
                  ) : (
                    ''
                  )}
                </StandardText>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='peso'
                  label='Peso(Kg)'
                  placeholder='Peso(Kg)'
                  value={formik.values.peso}
                  name='peso'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={formik.touched.peso && Boolean(formik.errors.peso)}
                  helperText={formik.touched.peso && formik.errors.peso}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='altura'
                  label='Altura(m)'
                  placeholder='Altura(m)'
                  value={formik.values.altura}
                  name='altura'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={formik.touched.altura && Boolean(formik.errors.altura)}
                  helperText={formik.touched.altura && formik.errors.altura}
                />
              </Grid>
              {formik.values.peso && formik.values.altura ? (
                <Grid item xs={12}>
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                  >
                    <PrimaryDescription style={{ margin: '0 0 1rem 0' }}>
                      <b>Índice de masa corporal:</b>
                      {(
                        Number(formik.values.peso) /
                        Number(formik.values.altura) ** 2
                      ).toFixed(2)}
                    </PrimaryDescription>
                  </motion.div>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='text'
                  id='presion_arterial'
                  value={formik.values.presion_arterial}
                  name='presion_arterial'
                  label='Presión Arterial(mmHg)'
                  placeholder='Presión Arterial(mmHg)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.presion_arterial &&
                    Boolean(formik.errors.presion_arterial)
                  }
                  helperText={
                    formik.touched.presion_arterial && formik.errors.presion_arterial
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='frecuencia_cardiaca'
                  value={formik.values.frecuencia_cardiaca}
                  name='frecuencia_cardiaca'
                  label='Frecuencia Cardiaca(LPM)'
                  placeholder='Frecuencia Cardiaca(LPM)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.frecuencia_cardiaca &&
                    Boolean(formik.errors.frecuencia_cardiaca)
                  }
                  helperText={
                    formik.touched.frecuencia_cardiaca &&
                    formik.errors.frecuencia_cardiaca
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  onBlur={formik.handleBlur}
                  id='frecuencia_respiratoria'
                  value={formik.values.frecuencia_respiratoria}
                  name='frecuencia_respiratoria'
                  label='Frecuencia Respiratoria(RPM)'
                  placeholder='Frecuencia Respiratoria(RPM)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  error={
                    formik.touched.frecuencia_respiratoria &&
                    Boolean(formik.errors.frecuencia_respiratoria)
                  }
                  helperText={
                    formik.touched.frecuencia_respiratoria &&
                    formik.errors.frecuencia_respiratoria
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  onBlur={formik.handleBlur}
                  id='temperatura'
                  value={formik.values.temperatura}
                  name='temperatura'
                  label='Temperatura(°C)'
                  placeholder='Temperatura(°C)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  error={formik.touched.temperatura && Boolean(formik.errors.temperatura)}
                  helperText={formik.touched.temperatura && formik.errors.temperatura}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='oxigenacion'
                  value={formik.values.oxigenacion}
                  name='oxigenacion'
                  label='Oxigenación(%)'
                  placeholder='Oxigenación(%)'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={fieldsDisabled}
                  error={formik.touched.oxigenacion && Boolean(formik.errors.oxigenacion)}
                  helperText={formik.touched.oxigenacion && formik.errors.oxigenacion}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Subjetivos</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='comentarios_subjetivos'
                  name='comentarios_subjetivos'
                  value={formik.values.comentarios_subjetivos}
                  onChange={formik.handleChange}
                  minRows={2}
                  onBlur={formik.handleBlur}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Objetivos</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='comentarios_objetivos'
                  name='comentarios_objetivos'
                  value={formik.values.comentarios_objetivos}
                  onChange={formik.handleChange}
                  minRows={2}
                  onBlur={formik.handleBlur}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Análisis</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='analisis'
                  name='analisis'
                  onBlur={formik.handleBlur}
                  value={formik.values.analisis}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CMTextfield
                  $nomargin
                  select
                  id='pronostico'
                  name='pronostico'
                  onBlur={formik.handleBlur}
                  value={formik.values.pronostico}
                  onChange={formik.handleChange}
                  placeholder='Descripción'
                  label='Pronóstico'
                  disabled={fieldsDisabled}
                >
                  <MenuItem disabled value=''>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value='Bueno'>Bueno</MenuItem>
                  <MenuItem value='Regular'>Regular</MenuItem>
                  <MenuItem value='Malo'>Malo</MenuItem>
                  <MenuItem value='Reservado'>Reservado</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    disabled={fieldsDisabled}
                    openOnFocus
                    multiple
                    id='nota_enfermeria_diagnosticos'
                    options={cie10Search}
                    noOptionsText='No hay opciones disponibles'
                    getOptionLabel={(option) =>
                      `${option.cie_clave} ${option.cie_descripcion}`
                    }
                    onInputChange={(_event, value) => handleDiagnosisInput(value)}
                    value={formik.values.nota_enfermeria_diagnosticos}
                    onChange={(_e, value) => {
                      formik.setFieldValue('nota_enfermeria_diagnosticos', value);
                    }}
                    renderInput={(params) => (
                      <CMTextfield
                        $nomargin
                        {...params}
                        onBlur={formik.handleBlur}
                        label='Diagnóstico(s) presuntivo(s)'
                        placeholder='Diagnóstico(s) presuntivo(s)'
                        name='nota_enfermeria_diagnosticos'
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Plan de trabajo</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='plan_trabajo'
                  name='plan_trabajo'
                  onBlur={formik.handleBlur}
                  value={formik.values.plan_trabajo}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  label='Alergias a medicamentos'
                  placeholder='Descripción'
                  id='nota_enfermeria_alergias'
                  onBlur={formik.handleBlur}
                  name='nota_enfermeria_alergias'
                  value={formik.values.nota_enfermeria_alergias}
                  onChange={formik.handleChange}
                  type='text'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  label='Alergias alimentos'
                  placeholder='Descripción'
                  id='alergias_alimentos'
                  onBlur={formik.handleBlur}
                  name='alergias_alimentos'
                  value={formik.values.alergias_alimentos}
                  onChange={formik.handleChange}
                  type='text'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  label='Otras alergias'
                  placeholder='Descripción'
                  id='alergias_otros'
                  name='alergias_otros'
                  onBlur={formik.handleBlur}
                  value={formik.values.alergias_otros}
                  onChange={formik.handleChange}
                  type='text'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <PrimarySubtitle mobile>Indicaciones del Médico Tratante</PrimarySubtitle>
              <Grid item xs={12} md={12}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  $nomargin
                  select
                  id='dieta'
                  name='dieta'
                  onBlur={formik.handleBlur}
                  value={formik.values.dieta}
                  onChange={formik.handleChange}
                  label='Dieta'
                  placeholder='Descripción'
                >
                  <MenuItem disabled value=''>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value='Blanda'>Blanda</MenuItem>
                  <MenuItem value='Especial'>Especial</MenuItem>
                  <MenuItem value='Hipoproteica'>Hipoprotéica</MenuItem>
                  <MenuItem value='Hiposodica'>Hiposódica</MenuItem>
                  <MenuItem value='Liquida'>Líquida</MenuItem>
                  <MenuItem value='Liquidos frios'>Líquidos frios</MenuItem>
                  <MenuItem value='Normal'>Normal</MenuItem>
                  <MenuItem value='Diabetico'>Diabético</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Soluciones</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  $spacing={2}
                  id='soluciones'
                  name='soluciones'
                  onBlur={formik.handleBlur}
                  value={formik.values.soluciones}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Nombre de la Solución, Cantidad, Periodicidad, Velocidad de Infusión'
                />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Medicamentos indicados</PrimarySubtitle>
                {!fieldsDisabled ? (
                  <PrimaryDescription mobile style={{ margin: '0' }}>
                    {mobile ? 'Toca el' : 'Haz click en el'} botón de &quot;Añadir
                    medicamento&quot; para capturar el tratamiento de un nuevo
                    medicamento.
                  </PrimaryDescription>
                ) : null}
              </Grid>
              {selectedMedicamentos.length
                ? selectedMedicamentos.map((medicamento, index) => {
                    const key = `divMedicamento#${
                      medicamento.medicamento?.id || '-'
                    }${index}`;
                    const autocompleteKey = `autocompleteMedicamento#${
                      medicamento.medicamento?.id || '-'
                    }${index}`;
                    const inputKey = `inputMedicamento#${
                      medicamento.medicamento?.id || '-'
                    }${index}`;
                    return (
                      <Grid key={key} item xs={12}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                          }}
                        >
                          <div
                            style={{
                              borderRadius: '12px',
                              backgroundColor: '#f4f4f4',
                              padding: '0.5rem',
                              width: 'calc(100% - 2rem)',
                            }}
                          >
                            <Grid
                              container
                              direction='row'
                              justifyContent='flex-start'
                              alignItems='center'
                              spacing={1}
                            >
                              <Grid item xs={12} md={6}>
                                <Autocomplete
                                  disabled={fieldsDisabled}
                                  id={autocompleteKey}
                                  filterOptions={(x) => x}
                                  options={medicamentos}
                                  filterSelectedOptions
                                  noOptionsText='Busca un medicamento por nombre o sustancia'
                                  getOptionLabel={(option) => {
                                    if (option) {
                                      return `${option.sales}`;
                                    }
                                    return '';
                                  }}
                                  renderOption={(props, option?: Medicamento) => {
                                    if (option) {
                                      return (
                                        <li {...props} key={option.id}>
                                          <StandardText>
                                            <b>{option.DESCRIPCION}</b>
                                          </StandardText>
                                          {option.via ? (
                                            <StandardText>{option.via}</StandardText>
                                          ) : null}
                                        </li>
                                      );
                                    }
                                    return null;
                                  }}
                                  onInputChange={(_event, value) =>
                                    searchOptionsMedicines(value)
                                  }
                                  value={medicamento.medicamento}
                                  onChange={(_e, value) => {
                                    updateSelectionMedicina(index, value);
                                  }}
                                  renderInput={(params) => (
                                    <CMTextfield
                                      $nomargin
                                      {...params}
                                      label='Medicamento genérico'
                                      placeholder='Medicamento genérico'
                                      name={inputKey}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='text'
                                  value={medicamento.marca_recomendada}
                                  label='Nombre patente recomendado'
                                  placeholder='Nombre patente recomendado'
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      marca_recomendada: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  disabled={fieldsDisabled}
                                  $nomargin
                                  type='text'
                                  label='Vía'
                                  placeholder='Vía'
                                  value={medicamento.via}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      via: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  $nomargin
                                  disabled={fieldsDisabled}
                                  type='text'
                                  label='Dosis'
                                  placeholder='Dosis'
                                  value={medicamento.dosis}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      dosis: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CMTextfield
                                  $nomargin
                                  type='text'
                                  disabled={fieldsDisabled}
                                  label='Periodicidad'
                                  placeholder='Periodicidad'
                                  value={medicamento.periodicidad}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      periodicidad: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <CMTextfield
                                  $nomargin
                                  type='number'
                                  inputProps={{
                                    inputMode: 'numeric',
                                  }}
                                  disabled={fieldsDisabled}
                                  label='Dias de tratamiento'
                                  placeholder='Dias de tratamiento'
                                  value={medicamento.numero_dias}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      numero_dias: e.target.value
                                        ? Number(e.target.value)
                                        : 0,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <CMTextfield
                                  $nomargin
                                  disabled={fieldsDisabled}
                                  type='string'
                                  label='Presentación / No. Cajas'
                                  placeholder='Presentación / No. Cajas'
                                  value={medicamento.numero_cajas}
                                  onChange={(e) => {
                                    const newArray = [...selectedMedicamentos];
                                    newArray[index] = {
                                      ...newArray[index],
                                      numero_cajas: e.target.value,
                                    };
                                    setSelectedMedicamentos(newArray);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {!fieldsDisabled ? (
                            <NoPaddingButton
                              type='button'
                              disabled={fieldsDisabled}
                              onClick={() => deleteMedicamento(index)}
                            >
                              <IoMdCloseCircle
                                style={{
                                  fontSize: '1.5rem',
                                  marginLeft: '0.5rem',
                                  cursor: 'pointer',
                                }}
                              />
                            </NoPaddingButton>
                          ) : null}
                        </div>
                      </Grid>
                    );
                  })
                : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <NoPaddingButton
                    disabled={fieldsDisabled}
                    type='button'
                    onClick={addMedicamento}
                    style={{ padding: '0.5rem' }}
                  >
                    + Añadir medicamento
                  </NoPaddingButton>
                </Grid>
              ) : null}
              {showExtraMedicineField ? (
                <Grid item xs={12}>
                  <CMInputLabel mobile>Medicamentos fuera del cuadro médico</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    $spacing={2}
                    id='medicamentos_fuera_cuadro'
                    name='medicamentos_fuera_cuadro'
                    value={formik.values.medicamentos_fuera_cuadro}
                    onChange={formik.handleChange}
                    minRows={2}
                    placeholder='Información de los medicamentos'
                  />
                </Grid>
              ) : (
                <>
                  {!fieldsDisabled ? (
                    <Grid item xs={12}>
                      <NoPaddingButton
                        type='button'
                        onClick={() => setShowExtraMedicineField(true)}
                        style={{ padding: '0.5rem' }}
                      >
                        + Añadir medicamento fuera del cuadro médico
                      </NoPaddingButton>
                    </Grid>
                  ) : null}
                </>
              )}
              <Grid item xs={12}>
                <CMInputLabel mobile>Estudios indicados</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='estudios_indicados'
                  name='estudios_indicados'
                  value={formik.values.estudios_indicados}
                  onChange={formik.handleChange}
                  minRows={2}
                  onBlur={formik.handleBlur}
                  disabled={fieldsDisabled}
                  placeholder='Estudios'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Otras indicaciones</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='otras_indicaciones'
                  name='otras_indicaciones'
                  value={formik.values.otras_indicaciones}
                  onChange={formik.handleChange}
                  minRows={2}
                  onBlur={formik.handleBlur}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              {values &&
              values.nurseInformation &&
              mode !== CRUDModes.Create &&
              values.patientInformation &&
              values.doctor_id &&
              user &&
              canPrintNote(values.patientInformation, values.doctor_id, user, true) ? (
                <Grid item xs={12}>
                  <div style={{ display: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <NotaEnfermeriaDocument
                        patient={values.patientInformation}
                        nurse={values.nurseInformation}
                        values={values as NotaEnfermeria}
                      />
                    </Grid>
                  </div>

                  {!isIOSApp() ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      style={{ width: '100%' }}
                      onClick={() => handlePrint()}
                    >
                      Imprimir
                    </PrimaryButton>
                  ) : (
                    <MobileDownloadMessage />
                  )}
                </Grid>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

NotaEnfermeriaForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};

export default NotaEnfermeriaForm;
