import styled from 'styled-components';
import { StandardText } from '../StandardText/StandardText';
import { mainTheme } from '../../utils/theme/theme';
import { MobileProps } from '../../interfaces/GeneralProps';

export const PrimaryTitle = styled(StandardText)<MobileProps>`
  font-weight: ${mainTheme.fonts.title.weight};
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-family: ${mainTheme.fonts.title.family};
  color: ${mainTheme.colors.primaryTitle};
  text-align: ${(props) => (props.mobile ? 'start' : 'center')};
`;

export const PrimaryTitleStart = styled(StandardText)<MobileProps>`
  font-weight: ${mainTheme.fonts.title.weight};
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: ${mainTheme.fonts.title.family};
  color: ${mainTheme.colors.primaryTitle};
  text-align: start;
`;
