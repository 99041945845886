import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useMainProvider } from '../../context/MainProvider';
import { PageContainer } from '../Register/Register.styled';
import CenteredForm from '../../index.styled';
import { TemporaryUser, User } from '../../interfaces/User';
import { saveLocalstorageData } from '../../utils/localStorage';
import { RegisterInitialValues } from '../../interfaces/Register';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { PrimaryTitle } from '../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import {
  EmailMessage,
  MinValidationMessage,
  RequiredMessage,
} from '../../utils/validationMessages';
import { RouterLink } from '../../components/RouterLink/RouterLink';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import PasswordTextfield from '../../components/Forms/PasswordTextfield';
import { IOSHandlerFunctions, postWebkitMessage } from '../../interfaces/WebkitWindow';
import EmptyClosure from '../../utils/closures';
import { AdCatalog, AdTypes } from '../../interfaces/Ads';
import useAds from '../../hooks/useAds';

const LoginPage = (): JSX.Element => {
  const {
    user,
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setUser,
    setLoading,
    setCurrentPatient,
    setIsHMOpen,
  } = useMainProvider();
  const navigate = useNavigate();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { userData } = useParams();
  const validationSchema = Yup.object({
    username: Yup.string().email(EmailMessage).required(RequiredMessage),
    password: Yup.string().min(8, MinValidationMessage(8)).required(RequiredMessage),
  });
  const location = useLocation();
  const urlParams = queryString.parse(location.search) as { access: string };
  const [accessCode] = useState<string>(urlParams.access ?? '');

  const publicLogin = async () => {
    try {
      setLoading(true);
      const loginResult = await axios.post<{
        user: User;
        token: string;
        temporaryUser: TemporaryUser;
      }>(`${process.env.REACT_APP_SERVER_URL}/login-public`, {
        accessCode,
      });
      if (loginResult.status === 200) {
        const loginUser = {
          ...loginResult.data.user,
          token: loginResult.data.token,
        };
        console.log(loginUser);
        postWebkitMessage({
          function: IOSHandlerFunctions.SavedLoggedUser,
          loggedUser: loginUser as User,
        });
        saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', loginUser);
        setUser(loginUser);
        setCurrentPatient(loginResult.data.temporaryUser);
        setIsHMOpen(true);
      }
      setLoading(false);
    } catch (e: any) {
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al iniciar sesión: ${JSON.stringify(e.response.data)} `);
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const callbackPublicLogin = useCallback(publicLogin, [
    accessCode,
    setAlertMessage,
    setAlertType,
    setCurrentPatient,
    setIsHMOpen,
    setLoading,
    setOpenAlert,
    setUser,
  ]);

  useEffect(() => {
    if (accessCode) {
      console.log(accessCode);
      callbackPublicLogin().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [accessCode, callbackPublicLogin]);

  const onSubmit = async (registerValues: RegisterInitialValues) => {
    try {
      setLoading(true);
      const loginResult = await axios.post<{ user: User; token: string }>(
        `${process.env.REACT_APP_SERVER_URL}/login`,
        {
          username: registerValues.username,
          password: registerValues.password,
        },
      );
      if (loginResult.status === 200) {
        const loginUser = {
          ...loginResult.data.user,
          token: loginResult.data.token,
        };
        console.log(loginUser);
        postWebkitMessage({
          function: IOSHandlerFunctions.SavedLoggedUser,
          loggedUser: loginUser as User,
        });
        saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', loginUser);
        setUser(loginUser);
      }
      setLoading(false);
    } catch (e: any) {
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al iniciar sesión: ${JSON.stringify(e.response.data)} `);
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const submitCallback = useCallback(onSubmit, [
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    setUser,
  ]);

  useEffect(() => {
    if (userData) {
      console.log(userData);
      const decoded = Buffer.from(userData, 'base64').toString();
      const userToSave = JSON.parse(decoded) as User;
      saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', userToSave);
      setUser(userToSave);
    }
  }, [setUser, userData]);

  const formik = useFormik<RegisterInitialValues>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    /**
     * @desc logins the user with the given information
     * @param registerValues
     */
    onSubmit,
  });

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user, navigate]);

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5} lg={3}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <PrimaryTitle mobile={mobile}>Bienvenido de vuelta</PrimaryTitle>
                <PrimaryDescription mobile={mobile}>
                  Inicia sesión con tu correo y contraseña
                </PrimaryDescription>
                <CMTextfield
                  capitalized
                  id='outlined-basic'
                  label='Email'
                  value={formik.values.username}
                  name='username'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                  onChange={formik.handleChange}
                />
                <PasswordTextfield
                  id='outlined-basic'
                  label='Contraseña'
                  variant='outlined'
                  value={formik.values.password}
                  name='password'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  onChange={formik.handleChange}
                />
                <RouterLink to='/forgot'>¿Olvidaste tu contraseña?</RouterLink>
                <RouterLink to='/register'>¿Nuevo en Zivot? Crea tu cuenta</RouterLink>
                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                  }}
                >
                  Inciar sesión
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default LoginPage;
