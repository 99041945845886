import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SponsorAccountContextData,
  SponsorContextData,
  SponsorsContext,
} from '../Interfaces/ContextInterfaces';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';
import EmptyClosure from '../../../utils/closures';
import { getLocalstorageData, removeLocalstorageData } from '../../../utils/localStorage';

const initialState: SponsorsContext = {
  openAlert: false,
  alertMessage: '',
  alertType: AlertTypes.Success,
  loading: false,
  sponsor: undefined,
  user: undefined,
  setSponsor: EmptyClosure,
  setUser: EmptyClosure,
  setLoading: EmptyClosure,
  setAlertMessage: EmptyClosure,
  setAlertType: EmptyClosure,
  setOpenAlert: EmptyClosure,
  token: undefined,
  handleLogout: EmptyClosure,
  initialLoad: false,
  menuOpen: false,
  setMenuOpen: EmptyClosure,
  handleLogoutError: EmptyClosure,
  setToken: EmptyClosure,
  loadInitialData: EmptyClosure,
  currentBudget: 0,
  setCurrentBudget: EmptyClosure,
};

const SponsorMainContext = createContext<SponsorsContext>(initialState);

const useSponsorsProvider = (): SponsorsContext => {
  const context = useContext(SponsorMainContext);
  if (!context) {
    throw new Error('Context is not initialized');
  }
  return context;
};

const SponsorsMainProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [sponsor, setSponsor] = useState<SponsorContextData | undefined>(undefined);
  const [user, setUser] = useState<SponsorAccountContextData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.Success);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [currentBudget, setCurrentBudget] = useState<number>(0);

  const navigate = useNavigate();

  const loadInitialData = useCallback(() => {
    // Check local storage for user information and st the user state if needed
    const localStorageUser = getLocalstorageData<SponsorAccountContextData>(
      'CODIGO_MEDICO_SPONSOR_INFORMATION',
    );
    if (localStorageUser.success) {
      if (localStorageUser.data && localStorageUser.data.token) {
        setUser({ ...localStorageUser.data, sponsor: undefined, token: undefined });
        if (localStorageUser.data.sponsor) {
          setSponsor(localStorageUser.data.sponsor);
        }
        setToken(localStorageUser.data.token);
      }
    }
    setInitialLoad(true);
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleLogout = useCallback(() => {
    setSponsor(undefined);
    setUser(undefined);
    setToken(undefined);
    removeLocalstorageData('CODIGO_MEDICO_SPONSOR_INFORMATION');
    setMenuOpen(false);
    setCurrentBudget(0);
    navigate('/sponsors/login');
  }, [navigate]);

  const handleLogoutError = useCallback(
    (e: any) => {
      const error = e as { response?: { data?: any } };
      if (
        error?.response?.data &&
        JSON.stringify(error.response.data) &&
        JSON.stringify(error.response.data).includes('Error in authentication')
      ) {
        handleLogout();
      }
    },
    [handleLogout],
  );

  return (
    <SponsorMainContext.Provider
      value={{
        initialLoad,
        openAlert,
        alertMessage,
        alertType,
        loading,
        sponsor,
        user,
        setSponsor,
        setUser,
        setLoading,
        setAlertMessage,
        setAlertType,
        setOpenAlert,
        token,
        handleLogout,
        menuOpen,
        setMenuOpen,
        handleLogoutError,
        setToken,
        loadInitialData,
        currentBudget,
        setCurrentBudget,
      }}
    >
      {children}
    </SponsorMainContext.Provider>
  );
};

export default SponsorsMainProvider;
export { useSponsorsProvider };
