import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Autocomplete } from '@mui/material';
import { useSponsorsProvider } from '../../../Context/SponsorsMainContext';
import {
  SimplifiedSponsorAccountsTableEntry,
  SponsorAccountsTableEntry,
  SponsorsTableEntry,
} from '../../../Interfaces/Sponsors';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../../../../interfaces/GenericTable';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { setSponsorHeaders } from '../../../../../utils/headerSetter';
import { AlertTypes } from '../../../../../interfaces/MainContextInitialValues';
import EmptyClosure from '../../../../../utils/closures';
import GenericTablePage from '../../../../GenericTablePage/GenericTablePage';
import SponsorAccountsPopupForm from '../Form/SponsorAccountsPopupForm';
import { postWithToken } from '../../../../../utils/server';
import { SponsorAccountType } from '../../../Interfaces/ContextInterfaces';
import { initialSponsorAdminForm } from '../../SponsorAccountForm/AdminForm.Interfaces';
import { FlexContainer } from '../../../../../components/FlexContainer/FlexContainer';
import { CMTextfield } from '../../../../../components/Forms/CMTextfield';

const SponsorAccountsTable = (): JSX.Element => {
  const {
    setLoading,
    setOpenAlert,
    setAlertType,
    setAlertMessage,
    token,
    handleLogoutError,
    sponsor,
    user,
  } = useSponsorsProvider();
  const [entries, setEntries] = useState<SponsorAccountsTableEntry[]>();
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);
  const [selectedSponsor, setSelectedSponsor] = useState<number>();
  const [sponsorList, setSponsorList] = useState<{ id: number; name: string }[]>();

  const handleDeactivate = useCallback(
    async (entry: SponsorAccountsTableEntry) => {
      try {
        setLoading(true);
        const result = await postWithToken<SponsorAccountsTableEntry, null>(
          `/sponsors/update-admin-account`,
          { ...entry, is_active: !entry.is_active },
          handleLogoutError,
          true,
        );
        if (!result.success) {
          throw new Error(result.error);
        }
        setAlertType(AlertTypes.Success);
        setAlertMessage(
          `Cuenta ${entry.is_active ? 'desactivada' : 'activada'} con éxito`,
        );
        setOpenAlert(true);
        setLoading(false);
        setEntries(undefined);
      } catch (e: any) {
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al guardar información: ${e?.message || 'Desconocido'}`);
        setOpenAlert(true);
        setLoading(false);
      }
    },
    [handleLogoutError, setAlertMessage, setAlertType, setLoading, setOpenAlert],
  );

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await axios.get<SponsorAccountsTableEntry[]>(
        `${process.env.REACT_APP_SERVER_URL}/sponsors/sponsor-accounts/${
          selectedSponsor ?? sponsor?.id
        }`,
        setSponsorHeaders(false, token),
      );
      const orderedRows = fetchResult.data.sort((a, b) => {
        if (a.id && b.id) {
          return a.id > b.id ? -1 : 1;
        }
        return 1;
      });
      setEntries(orderedRows);
      const cols: GenericTableColumn[] = [
        {
          name: 'id',
          displayName: 'ID',
          type: GenericColumnTypes.Numeric,
          sortable: true,
        },
        {
          name: 'creationDate',
          displayName: 'Fecha de creación',
          type: GenericColumnTypes.Date,
          sortable: true,
        },
        {
          name: 'is_active',
          displayName: 'Activo',
          type: GenericColumnTypes.Boolean,
          sortable: false,
        },
        {
          name: 'is_validated',
          displayName: 'Validado',
          type: GenericColumnTypes.Boolean,
          sortable: false,
        },
        {
          name: 'name',
          displayName: 'Nombre',
          type: GenericColumnTypes.Text,
          sortable: true,
        },
        {
          name: 'last_name',
          displayName: 'Apellido paterno',
          type: GenericColumnTypes.Text,
          sortable: true,
        },
        {
          name: 'moreInformation',
          displayName: 'Consultar',
          type: GenericColumnTypes.Action,
          sortable: false,
          onActionButtonClick: (index) => {
            setSelectedRow(index);
            setMode(CRUDModes.Update);
            setOpen(true);
          },
        },
      ];
      if (user?.typeId !== SponsorAccountType.regular) {
        cols.push({
          name: 'delete',
          displayName: 'Desactivar/Activar',
          type: GenericColumnTypes.Action,
          sortable: false,
          onActionButtonClick: (index) => {
            handleDeactivate(orderedRows[index]);
          },
        });
      }
      setColumns(cols);
      const simplifiedData: SimplifiedSponsorAccountsTableEntry[] = orderedRows.map(
        (entry) => {
          const accRow: SimplifiedSponsorAccountsTableEntry = {
            id: entry.id,
            fecha: entry.fecha,
            is_active: entry.is_active,
            is_validated: entry.is_validated,
            name: entry.name,
            last_name: entry.last_name,
            moreInformation: 'Consultar',
          };
          if (user?.typeId !== SponsorAccountType.regular) {
            accRow.delete = entry.is_active ? 'Desactivar' : 'Activar';
          }
          return accRow;
        },
      );
      setRows(simplifiedData);
      setLoading(false);
    } catch (e: any) {
      setEntries([]);
      handleLogoutError(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener lista de cuentas de patrocinadores: ${JSON.stringify(
            e.response.data,
          )} `,
        );
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const fetchCallback = useCallback(fetchEntries, [
    setLoading,
    selectedSponsor,
    sponsor?.id,
    token,
    user?.typeId,
    handleDeactivate,
    handleLogoutError,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
  ]);

  useEffect(() => {
    if (
      !entries &&
      ((user?.typeId === SponsorAccountType.superAdmin && selectedSponsor) ||
        (user?.typeId !== SponsorAccountType.superAdmin && sponsor?.id))
    ) {
      fetchCallback().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [fetchCallback, entries, user?.typeId, selectedSponsor, sponsor?.id]);

  useEffect(() => {
    const fetchSponsors = async () => {
      try {
        const response = await axios.get<SponsorsTableEntry[]>(
          `${process.env.REACT_APP_SERVER_URL}/sponsors`,
          setSponsorHeaders(false, token),
        );
        setSponsorList(
          response.data.map((sp) => ({
            id: sp.id,
            name: sp.name,
          })),
        );
      } catch (e) {
        handleLogoutError(e);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al obtener lista de patrocinadores: ${e}`);
        setOpenAlert(true);
      }
    };

    if (!sponsorList && user?.typeId === SponsorAccountType.superAdmin) {
      fetchSponsors().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [
    handleLogoutError,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    sponsorList,
    token,
    user?.typeId,
  ]);

  useEffect(() => {
    setEntries(undefined);
  }, [selectedSponsor]);

  return (
    <>
      <SponsorAccountsPopupForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={
          selectedRow !== undefined && selectedRow !== -1
            ? (entries || [])[selectedRow]
            : { ...initialSponsorAdminForm, sponsor_id: selectedSponsor ?? 0 }
        }
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      {user?.typeId === 1 && sponsorList ? (
        <div style={{ width: 'calc(100% - 6rem)' }}>
          <FlexContainer style={{ padding: '3rem 3rem 0 3rem' }}>
            <Autocomplete
              style={{ width: '100%' }}
              openOnFocus
              id='select-sponsor'
              options={sponsorList}
              noOptionsText='No hay opciones disponibles'
              getOptionLabel={(option) => `${option.name}`}
              onChange={(_e, value) => {
                setSelectedSponsor(value?.id);
              }}
              renderInput={(params) => (
                <CMTextfield
                  {...params}
                  label='Selecciona un patrocinador'
                  placeholder='Patrocinador'
                  style={{ marginBottom: '0' }}
                />
              )}
            />
          </FlexContainer>
        </div>
      ) : null}

      <GenericTablePage
        title={`Lista de accesos de ${sponsor?.name}`}
        rows={rows}
        columns={columns}
        canCreate
        canDelete={false}
        canEdit
        onCreate={() => {
          setOpen(true);
          setSelectedRow(-1);
          setMode(CRUDModes.Create);
        }}
      />
    </>
  );
};

export default SponsorAccountsTable;
