/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { useMainProvider } from '../../context/MainProvider';
import GenericTablePage from '../GenericTablePage/GenericTablePage';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import { CRUDModes } from '../../interfaces/CRUD';
import { UserTypes } from '../../interfaces/User';
import { gettWithToken, postWithToken } from '../../utils/server';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import {
  PendingValidationUser,
  SimplidiedUserValidation,
} from '../../interfaces/UserValidation';
import InitialForm from '../InitialForm/InitialForm';

const UserValidationPage = (): JSX.Element => {
  const {
    setLoading,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    handleLogout,
    currentPatient,
    setCurrentPatient,
  } = useMainProvider();
  const [entries, setEntries] = useState<PendingValidationUser[]>();
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);

  useEffect(() => {
    console.log(selectedRow);
    console.log(currentPatient);
    console.log(entries);
    if (selectedRow === -1 && currentPatient) {
      setCurrentPatient(undefined);
    } else if (selectedRow !== -1 && !currentPatient && entries) {
      setCurrentPatient({
        ...entries[selectedRow],
        expira_en: '',
        token: '',
        initialDataFilled: true,
        initialData: entries[selectedRow].data,
      });
    }

    /*
    return () => {
      setCurrentPatient(undefined);
    };
     */
  }, [currentPatient, entries, selectedRow, setCurrentPatient]);

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await gettWithToken<PendingValidationUser[]>(
        `/admin/validations`,
        handleLogout,
      );
      if (fetchResult.success) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'username',
            displayName: 'Correo usuario',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'typeId',
            displayName: 'Tipo de usuario',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: 'Consultar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
          {
            name: 'approve',
            displayName: 'Validar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: async (index) => {
              try {
                const entry = orderedRows[index];
                if (!entry.id) throw new Error('No se pudo obtener el id del usuario');
                const result = await postWithToken<{ userId: number }, null>(
                  `/admin/validate`,
                  { userId: entry.id },
                  handleLogout,
                );
                if (result.success) {
                  setAlertType(AlertTypes.Success);
                  setAlertMessage('Usuario validado con éxito');
                  setOpenAlert(true);
                  setEntries(undefined);
                }
              } catch (e: any) {
                handleLogout(e);
                if (JSON.stringify(e.response.data)) {
                  setAlertType(AlertTypes.Error);
                  setAlertMessage(
                    `Error al validar usuario : ${JSON.stringify(e.response.data)} `,
                  );
                  setOpenAlert(true);
                } else {
                  setAlertType(AlertTypes.Error);
                  setAlertMessage(`Error en el servidor: ${e}`);
                  setOpenAlert(true);
                }
              }
            },
          },
          {
            name: 'deny',
            displayName: 'Denegar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: async (index) => {
              try {
                const entry = orderedRows[index];
                if (!entry.id) throw new Error('No se pudo obtener el id del usuario');
                const result = await postWithToken<{ userId: number }, null>(
                  `/admin/reject`,
                  { userId: entry.id },
                  handleLogout,
                );
                if (result.success) {
                  setAlertType(AlertTypes.Success);
                  setAlertMessage('Usuario rechazado con éxito');
                  setOpenAlert(true);
                  setEntries(undefined);
                }
              } catch (e: any) {
                handleLogout(e);
                if (JSON.stringify(e.response.data)) {
                  setAlertType(AlertTypes.Error);
                  setAlertMessage(
                    `Error al rechazar usuario : ${JSON.stringify(e.response.data)} `,
                  );
                  setOpenAlert(true);
                } else {
                  setAlertType(AlertTypes.Error);
                  setAlertMessage(`Error en el servidor: ${e}`);
                  setOpenAlert(true);
                }
              }
            },
          },
        ]);
        const simplifiedData: SimplidiedUserValidation[] = orderedRows.map((usr) => {
          return {
            id: usr.id,
            username: usr.username,
            typeId: usr.typeId === UserTypes.DoctorType ? 'Doctor' : 'Enfermera',
            moreInformation: 'Consultar',
            approve: 'Validar',
            deny: 'Denegar',
          };
        });
        setRows(simplifiedData);
      } else {
        setEntries([]);
      }
      setLoading(false);
    } catch (e: any) {
      setEntries([]);
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener usuarios a validar : ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  // Avoid infinite loop
  const fetchCallback = useCallback(fetchEntries, [
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
  ]);

  useEffect(() => {
    if (!entries) {
      fetchCallback().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [fetchCallback, entries]);

  return (
    <>
      <Dialog
        onClose={() => {
          setOpen(false);
          setSelectedRow(-1);
        }}
        maxWidth='xl'
        open={open && currentPatient !== undefined}
      >
        <InitialForm myProfile />
      </Dialog>

      <GenericTablePage
        title='Validación de usuarios'
        rows={rows}
        columns={columns}
        canDelete={false}
        canEdit
        canCreate={false}
        onCreate={() => {
          setOpen(true);
          setSelectedRow(-1);
          setMode(CRUDModes.Create);
        }}
      />
    </>
  );
};

export default UserValidationPage;
