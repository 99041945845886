import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainProvider } from '../../context/MainProvider';
import { CRUDModes } from '../../interfaces/CRUD';
import DialogCustomTitle from '../DialogCustomTitle/DialogCustomTitle';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { removeLocalstorageData } from '../../utils/localStorage';

const LoginPopup = (): JSX.Element => {
  const {
    loginPopupOpen,
    setLoginPopupOpen,
    setUser,
    setCurrentPatient,
    setTemporaryUsers,
    setQrCode,
  } = useMainProvider();
  const navigate = useNavigate();
  return (
    <Dialog maxWidth='sm' open={loginPopupOpen} onClose={() => setLoginPopupOpen(false)}>
      <DialogCustomTitle
        onClose={() => setLoginPopupOpen(false)}
        mode={CRUDModes.Delete}
        title='Crea tu cuenta para obtener todas las funciones'
        description='Actualmente estás viendo el expediente del paciente de forma temporal, para obtener todas las funciones de la plataforma, crea tu cuenta.'
      />
      <DialogContent>
        <PrimaryButton
          loading={false}
          onClick={() => {
            setUser(undefined);
            setCurrentPatient(undefined);
            setTemporaryUsers([]);
            setQrCode('');
            removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
            navigate({
              pathname: `/register`,
            });
          }}
        >
          Crea tu cuenta
        </PrimaryButton>
      </DialogContent>
    </Dialog>
  );
};

export default LoginPopup;
