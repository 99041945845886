import { Dispatch, SetStateAction } from 'react';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';

export enum SponsorAccountType {
  superAdmin = 1,
  admin,
  regular,
}

export interface SponsorContextData {
  id: number;
  name: string;
  address: string;
  business_name: string;
  sanitary_license: string;
  fiscal_statement_url: string;
  logo_url: string;
  website_url: string | null;
  description: string;
}
export interface SponsorAccountContextData {
  id: number;
  username: string;
  name: string;
  second_name: string | null;
  last_name: string;
  second_last_name: string;
  typeId: SponsorAccountType;
  cellphone: string;
  birthday: string;
  labor_department: string;
  labor_area: string;
  labor_position: string;
  sponsor?: SponsorContextData;
  token?: string;
}

export interface SponsorsContext {
  loadInitialData: () => void;
  sponsor?: SponsorContextData;
  user?: SponsorAccountContextData;
  token?: string;
  setToken: (token: string | undefined) => void;
  openAlert: boolean;
  alertMessage: string;
  alertType: AlertTypes;
  setUser: (user: SponsorAccountContextData | undefined) => void;
  setSponsor: (sponsor: SponsorContextData | undefined) => void;
  setOpenAlert: (openAlertMessage: boolean) => void;
  setAlertMessage: (alertMessage: string) => void;
  setAlertType: (setAlertType: AlertTypes) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  handleLogoutError: (e: any) => void;
  handleLogout: () => void;
  initialLoad: boolean;
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  currentBudget: number;
  setCurrentBudget: Dispatch<SetStateAction<number>>;
}
