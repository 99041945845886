import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryTitle } from '../../components/PrimaryTitle/PrimaryTitle';
import { PageContainer } from '../Register/Register.styled';
import { useMainProvider } from '../../context/MainProvider';
import { User, UserTypes } from '../../interfaces/User';
import { Patient } from '../../interfaces/Patients';
import { Nurse } from '../../interfaces/Nurses';
import { Doctor } from '../../interfaces/Doctors';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import illustration from '../../assets/img/Researching-amico.svg';
import EmptyClosure from '../../utils/closures';
import { gettWithToken } from '../../utils/server';
import {
  getLocalstorageData,
  removeLocalstorageData,
  saveLocalstorageData,
} from '../../utils/localStorage';

const HomePage = (): JSX.Element => {
  const muiTheme = useTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const { user, setIsHMOpen, handleLogout, setUser } = useMainProvider();
  const navigate = useNavigate();

  const name = useMemo(() => {
    if (user && user.initialDataFilled) {
      if (user.typeId === UserTypes.PatientType) {
        return (user.initialData as Patient).pac_nombre;
      }
      if (user.typeId === UserTypes.DoctorType) {
        return (user.initialData as Doctor).med_nombre;
      }
      if (user.typeId === UserTypes.NurseType) {
        return (user.initialData as Nurse).enf_nombre;
      }
    }
    return '';
  }, [user]);

  useEffect(() => {
    setIsHMOpen(false);
  }, [setIsHMOpen]);

  useEffect(() => {
    const fetchValidate = async () => {
      const res = await gettWithToken<boolean>('/is-validated', handleLogout);
      if (res.success && res.data) {
        const userInformation = getLocalstorageData<User>(
          'CODIGO_MEDICO_USER_INFORMATION',
        );
        if (userInformation.success) {
          const userData = userInformation.data;
          userData.validated = true;
          removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
          saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', userData);
          setUser(userData);
          navigate('/home');
        }
      }
    };
    fetchValidate().then(EmptyClosure).catch(EmptyClosure);
  }, [handleLogout, navigate, setUser]);

  return (
    <PageContainer>
      <Grid
        container
        direction='row'
        justifyContent='center'
        style={{
          marginTop: isMobile ? '1.5rem' : '5rem',
          backgroundColor: 'white',
          borderRadius: '12px',
        }}
      >
        <img
          src={illustration}
          alt='Imagen de un médico investigando en su escritorio'
          style={{ width: '250px', marginBottom: '1rem' }}
        />
        <PrimaryTitle style={{ fontSize: isMobile ? '1.5rem' : '2rem' }}>
          Buen día, {name}
        </PrimaryTitle>
        <PrimaryDescription>
          Su cuenta se encuentra en proceso de validación de datos. Recibirá un correo en
          cuanto se le otorgue acceso a la plataforma.
        </PrimaryDescription>
      </Grid>
    </PageContainer>
  );
};

export default HomePage;
