import styled from 'styled-components';
import { Step } from '@mui/material';
import { mainTheme } from '../../utils/theme/theme';

export const ZivotStep = styled(Step)`
  .Mui-active {
    color: ${() => mainTheme.colors.uiElements} !important;
  }

  .Mui-completed {
    color: ${() => mainTheme.colors.uiElements} !important;
  }
`;
