import React, { useCallback, useEffect, useState } from 'react';
import { useMainProvider } from '../../context/MainProvider';
import GenericTablePage from '../GenericTablePage/GenericTablePage';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import { CRUDModes } from '../../interfaces/CRUD';
import { UserTypes } from '../../interfaces/User';
import { gettWithToken, previewUserID } from '../../utils/server';
import { SimplifiedSurgicalNote, SurgicalNote } from '../../interfaces/SurgicalNote';
import SurgicalNotesForm from './SurgicalNotesForm';
import { getPatientFullName } from '../../utils/fns';
import { Patient } from '../../interfaces/Patients';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import { ZivotDocumentTypes } from '../../interfaces/DoctorProfile';

const SurgicalNotePage = (): JSX.Element => {
  const {
    setLoading,
    user,
    currentPatient,
    setOpenAlert,
    setAlertType,
    setAlertMessage,
    restartTemporayUsers,
    setLoginPopupOpen,
    handleLogout,
  } = useMainProvider();
  const [entries, setEntries] = useState<SurgicalNote[]>([]);
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await gettWithToken<SurgicalNote[]>(
        `/notes/get-surgical-notes-doctor?patientId=${
          currentPatient ? currentPatient.id : undefined
        }`,
        handleLogout,
      );
      if (fetchResult.success) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID Nota',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha',
            displayName: 'Fecha de creación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'doctorName',
            displayName: 'Médico que Elaboró',
            type: GenericColumnTypes.ShowProfile,
            sortable: true,
            documentType: ZivotDocumentTypes.postOperationNote,
          },
          {
            name: 'doctorSpeciality',
            displayName: 'Especialidad',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'patientName',
            displayName: 'Nombre Paciente',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'cirugia_programada',
            displayName: 'Cirugía programada',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'cirugia_efectuada',
            displayName: 'Cirugía efectuada',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: isIOSApp() ? 'Consultar' : 'Consultar y/o Imprimir',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
        ]);
        const simplifiedData: SimplifiedSurgicalNote[] = orderedRows.map(
          (surgicalNote) => {
            return {
              id: surgicalNote.id,
              fecha: surgicalNote.fecha,
              doctorName: `${surgicalNote.medico?.med_nombre} ${surgicalNote.medico?.med_apellidop} ${surgicalNote.medico?.med_apellidom}`,
              doctorSpeciality:
                surgicalNote.medico?.med_especialidad?.cat_especialidad_desc,
              patientName: `${surgicalNote.paciente?.pac_nombre} ${surgicalNote.paciente?.pac_apellidop} ${surgicalNote.paciente?.pac_apellidom}`,
              cirugia_programada: surgicalNote.cirugia_programada,
              cirugia_efectuada: surgicalNote.cirugia_efectuada,
              moreInformation: isIOSApp() ? 'Consultar' : 'Ver',
            };
          },
        );
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener notas quírurjicas : ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
        if (
          e.response.data ===
          'El tiempo de acceso al Expediente Clínico del Paciente terminó, si requiere continuar, favor de escanear otro código QR de su Paciente. Gracias'
        ) {
          restartTemporayUsers();
        }
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  // Avoid infinite loop
  const fetchCallback = useCallback(fetchEntries, [
    currentPatient,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    restartTemporayUsers,
    handleLogout,
  ]);

  useEffect(() => {
    fetchCallback().then(EmptyClosure).catch(EmptyClosure);
  }, [fetchCallback]);

  return (
    <>
      <SurgicalNotesForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={selectedRow !== -1 ? entries[selectedRow] : undefined}
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      <GenericTablePage
        title={`Notas postoperatorias${
          currentPatient
            ? ` de ${getPatientFullName(currentPatient.initialData as Patient)}`
            : ''
        }`}
        rows={rows}
        columns={columns}
        canDelete={false}
        canEdit
        canCreate={user && currentPatient ? user.typeId === UserTypes.DoctorType : false}
        onCreate={() => {
          if (user && user.id === previewUserID) {
            setLoginPopupOpen(true);
          } else {
            setOpen(true);
            setSelectedRow(-1);
            setMode(CRUDModes.Create);
          }
        }}
      />
    </>
  );
};

export default SurgicalNotePage;
