export enum BloodTypes {
  A1Positive = 'A1+',
  A1Negative = 'A1-',
  A2Positive = 'A2+',
  A2Negative = 'A2-',
  BPositive = 'B+',
  BNegative = 'B-',
  ABPositive = 'AB+',
  ABNegative = 'AB-',
  OPositive = 'O+',
  ONegative = 'O-',
  RHNegative = 'RH-',
  Unknown = 'No sabe',
}
