import React, { useState } from 'react';
import { Grid, StepLabel, Stepper } from '@mui/material';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import {
  MaxValidationMessage,
  RequiredMessage,
  SelectValidOption,
} from '../../../../utils/validationMessages';
import { Patient } from '../../../../interfaces/Patients';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import {
  saveLocalstorageData,
  getLocalstorageData,
  removeLocalstorageData,
} from '../../../../utils/localStorage';
import { PageContainer } from '../../../Register/Register.styled';
import CenteredForm from '../../../../index.styled';
import { useMainProvider } from '../../../../context/MainProvider';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import { postWithToken } from '../../../../utils/server';
import { User } from '../../../../interfaces/User';
import { deleteNullValues, getBase64 } from '../../../../utils/fns';
import { BloodTypes } from '../../../../interfaces/BloodTypes';
import PatientStepOne from './PatientStepOne';
import PatientStepTwo from './PatientStepTwo';
import { initialStepList } from '../../../../interfaces/initialFormInterfaces';
import { ZivotStep } from '../../../../components/Stepper/Stepper.Styled';

const initialValues = (user?: User): Patient => {
  return {
    pac_curp: '',
    pac_titulo_id: -1,
    pac_fechanacimiento: moment().subtract(18, 'years').toISOString(true),
    pac_sexo_id: -1,
    pac_nombre: '',
    pac_apellidop: '',
    pac_apellidom: '',
    pac_estadocivil_id: -1,
    pac_ciudad: '',
    pac_estado_id: -1,
    pac_ocupacion_id: -1,
    pac_escolaridad_id: -1,
    pac_nombrepadre: '',
    pac_nombremadre: '',
    pac_direccion: '',
    pac_colonia: '',
    pac_cp: '',
    pac_municipio: '',
    pac_estadonacimiento_id: -1,
    pac_pais: '',
    pac_correo: user?.username ? user?.username : '',
    pac_telefono: '',
    pac_tipo_sangre: BloodTypes.Unknown,
    pac_nombreresponsable: '',
    pac_relacionresponsable: '',
    pac_direccionresponsable: '',
    pac_telefonoresponsable: '',
  };
};
function PatientInitialFormV2(): JSX.Element {
  const {
    user,
    setLoading,
    setUser,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    handleLogout,
  } = useMainProvider();

  const [values, setValues] = useState<Patient>(initialValues(user));

  const navigate = useNavigate();
  const [transformedFile, setTransformedFiles] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [fileExtensions, setFileExtension] = useState<string>('');
  const [imagePreview, setImagePreview] = useState('');
  const [currentStep, setCurrentStep] = useState(1);

  const validationSchema = () =>
    Yup.object({
      pac_titulo_id: Yup.number()
        .required(RequiredMessage)
        .test('pac_titulo_id', SelectValidOption(1, 'titulo'), (val) => {
          return val !== -1;
        }),
      pac_nombre: Yup.string().required(RequiredMessage),
      pac_apellidop: Yup.string().required(RequiredMessage),
      pac_apellidom: Yup.string().required(RequiredMessage),
      pac_fechanacimiento: Yup.string().required(RequiredMessage),
      pac_sexo_id: Yup.number()
        .required(RequiredMessage)
        .test('pac_sexo_id', SelectValidOption(1, 'sexo'), (val) => {
          return val !== -1;
        }),
      pac_curp: Yup.string().required(RequiredMessage).max(18, MaxValidationMessage(18)),
      pac_estadocivil_id: Yup.number()
        .required(RequiredMessage)
        .test('pac_estadocivil_id', SelectValidOption(1, 'estado civil'), (val) => {
          return val !== -1;
        }),
      pac_ciudad: Yup.string().required(RequiredMessage),
      pac_estado_id: Yup.number()
        .required(RequiredMessage)
        .test('pac_estado_id', SelectValidOption(1, 'estado'), (val) => {
          return val !== -1;
        }),
      pac_ocupacion_id: Yup.number()
        .required(RequiredMessage)
        .test('pac_ocupacion_id', SelectValidOption(0, 'ocupación'), (val) => {
          return val !== -1;
        }),
      pac_direccion: Yup.string().max(50, MaxValidationMessage(50)),
      pac_cp:
        currentStep === 2 ? Yup.string().max(5, MaxValidationMessage(5)) : Yup.string(),
    });

  const formik = useFormik<Patient>({
    initialValues: values,
    validationSchema,
    enableReinitialize: true,
    /**
     * @desc logins the user with the given information
     * @param patientValues
     */
    onSubmit: async (patientValues) => {
      try {
        if (currentStep === 1) {
          setCurrentStep(2);
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return;
        }
        setLoading(true);
        const savePantientValuesResult = await postWithToken<
          {
            patient: Patient;
            userId?: number;
            file: string | ArrayBuffer | null;
            extension: string;
          },
          Patient
        >(
          `/patient/save-patient`,
          {
            patient: deleteNullValues<Patient>({
              ...patientValues,
              pac_id_usuario_paciente_id: user?.id,
            }),
            userId: user?.id,
            file: transformedFile,
            extension: fileExtensions,
          },
          handleLogout,
        );
        if (savePantientValuesResult.success) {
          const userInformation = getLocalstorageData<User>(
            'CODIGO_MEDICO_USER_INFORMATION',
          );
          if (userInformation.success) {
            const userData = userInformation.data;
            userData.initialDataFilled = true;
            userData.initialData = savePantientValuesResult.data;
            removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
            saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', userData);
            setUser(userData);
            setLoading(false);
            setAlertType(AlertTypes.Success);
            setAlertMessage(`Información guardada con éxito`);
            setOpenAlert(true);
            navigate('/home');
            return;
          }
        }
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al guardar información`);
        setOpenAlert(true);
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar la información del paciente: ${JSON.stringify(
              e.response.data,
            )} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiles(base64);
        setFileExtension(e.target.files[0].type.split('/')[1]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
      }
    } catch (err) {
      handleLogout(err);
      setLoading(false);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={6}>
          <Stepper
            style={{ marginBottom: '2rem' }}
            activeStep={currentStep}
            alternativeLabel
          >
            {initialStepList.map((label) => (
              <ZivotStep key={label}>
                <StepLabel>{label}</StepLabel>
              </ZivotStep>
            ))}
          </Stepper>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                {currentStep === 1 ? (
                  <PatientStepOne
                    formik={formik}
                    handleUpload={handleUpload}
                    imagePreview={imagePreview}
                  />
                ) : null}
                {currentStep === 2 ? <PatientStepTwo formik={formik} /> : null}
                <PrimaryButton
                  loading={false}
                  type='button'
                  onClick={formik.handleSubmit}
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  {currentStep === 1 ? 'Siguiente' : 'Guardar perfil'}
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

PatientInitialFormV2.defaultProps = {
  existingUser: undefined,
  initialDataMode: false,
  externalProfile: false,
};

export default PatientInitialFormV2;
