import { Autocomplete, Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useSponsorsProvider } from '../../../Context/SponsorsMainContext';
import { RequiredMessage } from '../../../../../utils/validationMessages';
import { AlertTypes } from '../../../../../interfaces/MainContextInitialValues';
import { postWithToken } from '../../../../../utils/server';
import { SponsorAccountType } from '../../../Interfaces/ContextInterfaces';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { PageContainer } from '../../../../Register/Register.styled';
import CenteredForm from '../../../../../index.styled';
import {
  CMTextfield,
  CMTextfieldHalfLeft,
  CMTextfieldHalfRight,
} from '../../../../../components/Forms/CMTextfield';
import { DisclaimerText } from '../../../../../components/DisclaimerText/DisclaimerText';
import PrimaryButton from '../../../../../components/PrimaryButton/PrimaryButton';
import {
  initialSponsorCampaign,
  SponsorCampaign,
  SponsorCampaignFormLocation,
  SponsorCampaignFormSegment,
} from '../SponsorCampaigns.interfaces';
import { getBase64 } from '../../../../../utils/fns';
import EmptyClosure from '../../../../../utils/closures';
import { setSponsorHeaders } from '../../../../../utils/headerSetter';
import { SponsorSegment } from '../../SponsorSegments/SponsorSegments.Interfaces';
import { StandardText } from '../../../../../components/StandardText/StandardText';
import { PrimaryTitleStart } from '../../../../../components/PrimaryTitle/PrimaryTitle';
import { StyledButton } from '../../../../../components/PrimaryButton/PrimaryButton.Styled';
import { mainTheme } from '../../../../../utils/theme/theme';
import { FlexContainer } from '../../../../../components/FlexContainer/FlexContainer';
import SponsorsLocationSelectionPopup from './SponsorLocationSelectionPopup';
import { formatMoney } from '../../../../../utils/money';

interface Props {
  values?: SponsorCampaign;
  onSuccess?: () => void;
  mode?: CRUDModes;
}

const SponsorCampaignForm = ({ values, onSuccess, mode }: Props): JSX.Element => {
  const {
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setLoading,
    handleLogoutError,
    sponsor,
    user,
    currentBudget,
    token,
  } = useSponsorsProvider();
  const [formValues, setFormValues] = useState<SponsorCampaign>(initialSponsorCampaign);
  const [segments, setSegments] = useState<SponsorCampaignFormSegment[]>();
  const [locations, setLocations] = useState<SponsorCampaignFormLocation[]>();
  const [error, setError] = useState<string>('');
  const [selectedSegments, setSelectedSegments] = useState<SponsorCampaignFormSegment[]>(
    [],
  );
  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);

  // desktop image variables
  const [transformedMedia, setTransformedMedia] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [mediaTitle, setMediaTitle] = useState<string>('');
  const [mediaExtension, setMediaExtension] = useState<string>('');

  // mobile image variables
  const [transformedMobile, setTransformedMobile] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [mobileTitle, setMobileTitle] = useState<string>('');
  const [mobileExtension, setMobileExtension] = useState<string>('');

  const adjustedCurrentBudget = useMemo(() => {
    if (values) {
      return currentBudget + values.budget;
    }
    return currentBudget;
  }, [currentBudget, values]);

  const handleMediaUpload = async (e: any, isMobile = false) => {
    try {
      const maximumSize = 9 * 1024 * 1024; // In MegaBytes
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size > maximumSize) {
          setAlertType(AlertTypes.Error);
          setAlertMessage('El peso del archivo es mayor al permitido');
          setOpenAlert(true);
          return;
        }
        const base64 = await getBase64(e.target.files[0]);
        if (isMobile) {
          setTransformedMobile(base64);
          setMobileTitle(e.target.files[0].name);
          setMobileExtension(e.target.files[0].type.split('/')[1]);
        } else {
          setTransformedMedia(base64);
          setMediaTitle(e.target.files[0].name);
          setMediaExtension(e.target.files[0].type.split('/')[1]);
        }
      }
    } catch (err) {
      handleLogoutError(err);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };
  const validationSchema = Yup.object({
    budget: Yup.number()
      .required(RequiredMessage)
      .min(0, 'El monto debe ser mayor a 0')
      .max(
        adjustedCurrentBudget,
        `El monto no puede ser mayor al balance actual de ${adjustedCurrentBudget}`,
      ),
    name: Yup.string().required(RequiredMessage).max(255, 'Máximo 255 caracteres'),
    fecha_inicio: Yup.string().nullable(),
    fecha_fin: Yup.string().nullable(),
    media_url: Yup.string().nullable(),
    mobile_url: Yup.string().nullable(),
    location_id: Yup.number().required(RequiredMessage),
    type_id: Yup.number().required(RequiredMessage),
    redirect_url: Yup.string().nullable().url('Debe ser una URL válida'),
  });

  useEffect(() => {
    if (values) {
      setFormValues(values);
      const segmentIds = values.campaign_segments?.map(
        (segment) => segment.segment_id as number,
      );
      setSelectedSegments(
        (segments ?? []).filter((segment) => segmentIds?.includes(segment.id as number)),
      );
    }
  }, [segments, values]);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    validate: () => {
      setError('');
    },
    onSubmit: async (form) => {
      try {
        const locationObject = locations?.find(
          (loc) => loc.id === formik.values.location_id,
        );
        if (!locationObject) {
          setError('Selecciona una ubicación válida');
          return;
        }
        if (!transformedMedia && !form.media_url) {
          setError('Por favor sube los archivos requeridos');
          return;
        }
        if (locationObject.media_type === 1 && !transformedMobile && !form.mobile_url) {
          setError('Por favor sube los archivos requeridos');
          return;
        }
        if (!selectedSegments.length) {
          setError('Elige al menos un segmento de público objetivo');
          return;
        }
        setLoading(true);
        const payload = { ...form };
        payload.campaign_segments = selectedSegments.map((seg) => ({
          segment_id: seg.id,
        }));
        if (user?.typeId !== SponsorAccountType.superAdmin) {
          payload.sponsor_id = sponsor?.id ?? 0;
        }
        const result = await postWithToken<SponsorCampaign, null>(
          `/sponsors/${mode === CRUDModes.Create ? '' : 'update-'}campaigns`,
          {
            ...payload,
            mediaFile: transformedMedia,
            mediaExtension,
            mobileFile: transformedMobile,
            mobileExtension,
          },
          handleLogoutError,
          true,
        );
        if (result.success) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          if (onSuccess) {
            onSuccess();
          }
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
        setLoading(false);
      } catch (e: any) {
        handleLogoutError(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al ${
              mode === CRUDModes.Update ? 'editar' : 'guardar'
            } campaña: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (!formik.values.type_id && selectedSegments.length) {
      formik.setFieldValue('type_id', selectedSegments[0].type_id);
    }
  }, [formik, selectedSegments]);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axios.get<SponsorSegment[]>(
          `${process.env.REACT_APP_SERVER_URL}/sponsors/segments`,
          setSponsorHeaders(false, token),
        );
        setSegments(
          response.data.map((segment) => ({
            id: segment.id as number,
            name: segment.name,
            comments: segment.comments,
            type_id: segment.type_id,
          })),
        );
      } catch (e) {
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al obtener lista de segmentos: ${e}`);
        setOpenAlert(true);
      }
    };
    if (!segments) {
      fetchSegments().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [segments, setAlertMessage, setAlertType, setOpenAlert, token]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get<SponsorCampaignFormLocation[]>(
          `${process.env.REACT_APP_SERVER_URL}/sponsors/locations`,
          setSponsorHeaders(false, token),
        );
        setLocations(response.data);
      } catch (e) {
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al obtener lista de ubicaciones: ${e}`);
        setOpenAlert(true);
      }
    };
    if (!locations) {
      fetchLocations().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [locations, setAlertMessage, setAlertType, setOpenAlert, token]);

  const selectedLocation = useMemo(() => {
    return locations?.find((loc) => loc.id === formik.values.location_id);
  }, [formik.values.location_id, locations]);
  return (
    <PageContainer style={{ paddingTop: '1.5rem' }}>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <SponsorsLocationSelectionPopup
                  locations={locations ?? []}
                  onSuccess={(locationId) => {
                    formik.setFieldValue('location_id', locationId);
                    formik.setFieldValue('media_url', '');
                    formik.setFieldValue('mobile_url', '');
                    setTransformedMedia(null);
                    setMediaTitle('');
                    setMediaExtension('');
                    setTransformedMobile(null);
                    setMobileTitle('');
                    setMobileExtension('');
                    setLocationModalOpen(false);
                  }}
                  open={locationModalOpen}
                  setOpen={setLocationModalOpen}
                />
                <StandardText
                  style={{
                    textAlign: 'center',
                    marginBottom: '1rem',
                    fontSize: '1.2rem',
                  }}
                >
                  <b>Presupuesto disponible</b>:{' '}
                  <span style={{ color: 'green' }}>
                    {formatMoney(adjustedCurrentBudget)}
                  </span>
                </StandardText>
                <StandardText style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
                  Si desea abonar más dinero a su cuenta, por favor contáctenos a través
                  de nuestro correo electrónico:{' '}
                  <a
                    style={{ color: mainTheme.colors.primaryButton.normalBackground }}
                    href='mailto:contacto@zivot.com.mx'
                  >
                    contacto@zivot.com.mx
                  </a>
                </StandardText>
                <CMTextfield
                  id='outlined-basic'
                  label='Nombre de la campaña'
                  placeholder='Define un alias para la campaña'
                  value={formik.values.name}
                  name='name'
                  type='text'
                  required
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  disabled={values?.is_active && mode === CRUDModes.Update}
                  id='outlined-basic'
                  label='Presupuesto a asignar'
                  value={formik.values.budget}
                  name='budget'
                  type='number'
                  required
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.budget && Boolean(formik.errors.budget)}
                  helperText={formik.touched.budget && formik.errors.budget}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Tipo de usuarios'
                  value={formik.values.type_id}
                  select
                  name='type_id'
                  type='number'
                  required
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.type_id && Boolean(formik.errors.type_id)}
                  helperText={formik.touched.type_id && formik.errors.type_id}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue('campaign_segments', []);
                  }}
                >
                  <MenuItem value={-1}>Selecciona un tipo de usuario</MenuItem>
                  <MenuItem value={1}>Doctores</MenuItem>
                  <MenuItem value={2}>Pacientes</MenuItem>
                  <MenuItem value={3}>Enfermeras</MenuItem>
                </CMTextfieldHalfRight>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    label='Fecha de inicio'
                    value={formik.values.fecha_inicio}
                    onChange={(value): void => {
                      formik.setFieldValue('fecha_inicio', value);
                    }}
                    inputFormat='DD/MM/yyyy'
                    renderInput={(params) => (
                      <CMTextfieldHalfLeft
                        {...params}
                        name='fecha_inicio'
                        error={
                          formik.touched.fecha_inicio &&
                          Boolean(formik.errors.fecha_inicio)
                        }
                        helperText={
                          formik.touched.fecha_inicio && formik.errors.fecha_inicio
                        }
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    label='Fecha de fin'
                    value={formik.values.fecha_fin}
                    onChange={(value): void => {
                      formik.setFieldValue('fecha_fin', value);
                    }}
                    inputFormat='DD/MM/yyyy'
                    renderInput={(params) => (
                      <CMTextfieldHalfRight
                        {...params}
                        name='fecha_fin'
                        error={
                          formik.touched.fecha_fin && Boolean(formik.errors.fecha_fin)
                        }
                        onBlur={formik.handleBlur}
                        helperText={formik.touched.fecha_fin && formik.errors.fecha_fin}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Autocomplete
                  style={{ width: '100%' }}
                  multiple
                  id='select-segments'
                  options={(segments ?? []).filter(
                    (opt) => formik.values.type_id === opt.type_id,
                  )}
                  filterSelectedOptions
                  noOptionsText='Elige un tipo de usuarios para ver los segmentos disponibles'
                  getOptionLabel={(option) => `${option.name}: ${option.comments}`}
                  value={selectedSegments}
                  onChange={(_e, value) => {
                    setSelectedSegments(value);
                  }}
                  renderOption={(props, option?: SponsorCampaignFormSegment) => {
                    if (option) {
                      return (
                        <li {...props} key={option.id}>
                          <StandardText>
                            <b>{option.name}</b>
                          </StandardText>
                          {option.comments ? (
                            <StandardText>{option.comments.toLowerCase()}</StandardText>
                          ) : null}
                        </li>
                      );
                    }
                    return null;
                  }}
                  renderInput={(params) => (
                    <CMTextfield
                      $nomargin
                      {...params}
                      label='Selecciona segmentos de público objetivo'
                      placeholder='Segmentos'
                      name='campaign_segments'
                    />
                  )}
                />
                <FlexContainer
                  direction='column'
                  justifyContent='flex-start'
                  alignContent='center'
                  alignItems='center'
                >
                  <>
                    <PrimaryTitleStart
                      style={{
                        fontSize: '1rem',
                        margin: '2rem 0 0.5rem 0',
                        textAlign: 'center',
                      }}
                    >
                      Ubicación de la campaña:
                    </PrimaryTitleStart>
                    <StandardText
                      style={{
                        color: mainTheme.colors.standardText,
                        textAlign: 'center',
                        marginBottom: '1rem',
                      }}
                    >
                      {selectedLocation?.name ?? 'Ubicación no seleccionada'}
                    </StandardText>
                    <StyledButton
                      component='span'
                      style={{
                        width: mobile ? '100%' : 'initial',
                        marginBottom: '3rem',
                      }}
                      onClick={() => setLocationModalOpen(true)}
                    >
                      {formik.values.location_id
                        ? 'Cambiar ubicación'
                        : 'Seleccionar ubicación'}
                    </StyledButton>
                  </>
                  {formik.values.location_id ? (
                    <>
                      {selectedLocation?.media_type === 1 ? (
                        <>
                          <PrimaryTitleStart
                            style={{
                              fontSize: '1rem',
                              margin: '0 0 0.5rem 0',
                              textAlign: 'center',
                            }}
                          >
                            Subir archivos para campaña
                          </PrimaryTitleStart>
                          <label
                            htmlFor='upload-media'
                            style={{ textAlign: 'center', marginBottom: '1rem' }}
                          >
                            <input
                              style={{ display: 'none' }}
                              id='upload-media'
                              name='upload-media'
                              type='file'
                              accept='image/png, image/jpeg, image/jpg'
                              onChange={handleMediaUpload}
                            />
                            <StyledButton
                              component='span'
                              style={{
                                width: mobile ? '100%' : 'initial',
                                marginBottom: '0',
                              }}
                            >
                              {!(transformedMedia ?? formik.values.media_url)
                                ? 'Seleccionar'
                                : 'Cambiar'}{' '}
                              archivo desktop
                            </StyledButton>
                            <StandardText
                              style={{
                                margin: '1rem, 0, 2rem, 0',
                              }}
                            >
                              {mediaTitle ||
                                formik.values.media_url ||
                                selectedLocation.desktopSize}
                            </StandardText>
                          </label>
                          <label
                            htmlFor='upload-mobile'
                            style={{ textAlign: 'center', marginBottom: '1rem' }}
                          >
                            <input
                              style={{ display: 'none' }}
                              id='upload-mobile'
                              name='upload-mobile'
                              type='file'
                              accept='image/png, image/jpeg, image/jpg'
                              onChange={(e) => handleMediaUpload(e, true)}
                            />
                            <StyledButton
                              component='span'
                              style={{
                                width: mobile ? '100%' : 'initial',
                                marginBottom: '0',
                              }}
                            >
                              {!(transformedMobile ?? formik.values.mobile_url)
                                ? 'Seleccionar'
                                : 'Cambiar'}{' '}
                              archivo móvil
                            </StyledButton>
                            <StandardText
                              style={{
                                margin: '1rem, 0, 2rem, 0',
                              }}
                            >
                              {mobileTitle ||
                                formik.values.mobile_url ||
                                selectedLocation.mobileSize}
                            </StandardText>
                          </label>
                        </>
                      ) : null}
                      {selectedLocation?.media_type === 2 ? (
                        <CMTextfield
                          id='outlined-basic'
                          label='URL del video'
                          onBlur={formik.handleBlur}
                          value={formik.values.media_url}
                          name='media_url'
                          style={{ marginBottom: '1rem' }}
                          error={
                            formik.touched.media_url && Boolean(formik.errors.media_url)
                          }
                          helperText={
                            formik.errors.media_url
                              ? formik.touched.media_url && formik.errors.media_url
                              : 'Debe ser un video de Youtube'
                          }
                          onChange={formik.handleChange}
                        />
                      ) : null}
                      <CMTextfield
                        id='outlined-basic'
                        label='URL al hacer click (opcional)'
                        onBlur={formik.handleBlur}
                        value={formik.values.redirect_url}
                        name='redirect_url'
                        style={{ marginBottom: '1rem' }}
                        error={
                          formik.touched.redirect_url &&
                          Boolean(formik.errors.redirect_url)
                        }
                        helperText={
                          formik.errors.redirect_url
                            ? formik.touched.redirect_url && formik.errors.redirect_url
                            : 'Si el usuario hace click lo llevaremos al url que se especifique'
                        }
                        onChange={formik.handleChange}
                      />
                    </>
                  ) : null}
                </FlexContainer>
                {error ? (
                  <DisclaimerText
                    style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}
                  >
                    {error}
                  </DisclaimerText>
                ) : null}
                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                  onClick={() => {
                    console.log(formik.errors);
                  }}
                >
                  {mode === CRUDModes.Create ? 'Crear' : 'Guardar'} campaña
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

SponsorCampaignForm.defaultProps = {
  values: undefined,
  mode: CRUDModes.Create,
  onSuccess: () => {},
};

export default SponsorCampaignForm;
