import { SponsorAccountsTableEntry } from '../../Interfaces/Sponsors';
import { SponsorAccountType } from '../../Interfaces/ContextInterfaces';

export interface SponsorAdminForm {
  email: string;
  password: string;
  confirmPasword?: string;
  recaptcha?: string;
  termsConditions?: boolean;
  privacyCondition?: boolean;
  cellphone: string;
  name: string;
  second_name: string | null;
  last_name: string;
  second_last_name: string;
  birthday: string;
  labor_department: string;
  labor_area: string;
  labor_position: string;
}

export const initialSponsorAdminForm: SponsorAccountsTableEntry = {
  id: 0,
  email: '',
  password: '',
  confirmPasword: '',
  recaptcha: '',
  termsConditions: false,
  privacyCondition: false,
  cellphone: '',
  name: '',
  second_name: '',
  last_name: '',
  second_last_name: '',
  birthday: '',
  labor_department: '',
  labor_area: '',
  labor_position: '',
  is_active: true,
  is_validated: true,
  fecha: '',
  sponsor_name: '',
  account_type: SponsorAccountType.admin,
};
