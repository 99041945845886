import { Button, ButtonProps } from '@mui/material';
import styled from 'styled-components';
import { mainTheme } from '../../utils/theme/theme';

interface PrimaryButtonProps extends ButtonProps {
  $loading?: boolean;
}

const StyledPrimaryButton = styled(Button)<PrimaryButtonProps>`
  font-family: ${mainTheme.fonts.text.family};
  font-weight: ${mainTheme.fonts.title.weight};
  background-color: ${mainTheme.colors.primaryButton.normalBackground};
  padding: 0.7rem 1.8rem;
  color: ${mainTheme.colors.primaryButton.normalText};
  text-transform: none;
  font-size: 0.9rem;
  border-radius: 10px;
  &:hover {
    background-color: ${mainTheme.colors.primaryButton.selectedBackground};
    color: ${mainTheme.colors.primaryButton.selectedText};
  }
  &:disabled {
    background-color: ${(props) => {
      if (props.$loading) {
        return mainTheme.colors.primaryButton.normalBackground;
      }
      return mainTheme.colors.primaryButton.disabledBackground;
    }};
  }
`;

export const StyledButton = styled(Button)<{ component: string }>`
  font-family: ${mainTheme.fonts.text.family};
  font-weight: ${mainTheme.fonts.title.weight};
  background-color: ${mainTheme.colors.primaryButton.normalBackground};
  padding: 0.7rem 1.8rem;
  color: ${mainTheme.colors.primaryButton.normalText};
  text-transform: none;
  font-size: 0.9rem;
  border-radius: 10px;
  &:hover {
    background-color: ${mainTheme.colors.primaryButton.selectedBackground};
    color: ${mainTheme.colors.primaryButton.selectedText};
  }
`;

export default StyledPrimaryButton;
