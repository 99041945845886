import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import moment from 'moment';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import * as Yup from 'yup';
import { useReactToPrint } from 'react-to-print';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { EvolutionNote } from '../../interfaces/EvolutionNote';
import { Cie10 } from '../../interfaces/Cie10';
import { postWithToken } from '../../utils/server';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import EvolutionNoteDocument from './EvolutionNoteDocument';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { RequiredMessage } from '../../utils/validationMessages';
import { canPrintNote } from '../../utils/fns';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';
import EmptyClosure from '../../utils/closures';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: EvolutionNote;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: EvolutionNote = {
  cita_paciente: '',
  motivo_consulta: '',
  peso: '',
  talla: '',
  ta_sistolica: '',
  ta_diastolica: '',
  fc: '',
  fr: '',
  temp: '',
  oxigenacion: '',
  laboratorio_gabinete: '',
  tratamiento: '',
  pronostico: '',
  fecha_nueva_cita: moment(),
  cronico_deg: false,
  cronico_deg_enfermedad: '',
  requiere_interconsulta: false,
  interconsulta_es: '',
  paciente_referido: false,
  indicaciones: '',
  tratamiento_paciente: '',
  hospital_envio: '',
  especialista_referir: '',
  especialidad_envio: '-1',
  nota_evolucion_diag_pres: [],
  nota_evolucion_diag: [],
};

function EvolutionNotesForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
}: FormProps): JSX.Element {
  const {
    loading,
    cie10,
    getCie10,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    specialities,
    getDoctorSpecialities,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const [formValues, setFormValues] = useState(initialValues);
  const [diagPresAut, setDiagPresAut] = useState<Cie10[]>([]);
  const [diagAut, setDiagAut] = useState<Cie10[]>([]);
  const [doctorInformation, setDoctorInformation] = useState<Doctor | null>(null);
  const [patientInformation, setPatientInformation] = useState<Patient | null>(null);
  const printRef = useRef<HTMLInputElement>(null);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.id_medico !== user?.id,
    [mode, formValues, user?.id],
  );

  const getDoctorInformation = useCallback(async () => {
    try {
      const doctorResult = await postWithToken<
        {
          doctorId?: number;
        },
        Doctor
      >(
        '/doctor/get-doctor',
        {
          doctorId: user?.id,
        },
        handleLogout,
      );
      if (doctorResult.success) {
        setDoctorInformation(doctorResult.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [handleLogout, user?.id]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  useEffect(() => {
    setDiagAut(cie10.slice(0, 100));
    setDiagPresAut(cie10.slice(0, 100));
  }, [cie10]);

  useEffect(() => {
    getCie10();
    getDoctorSpecialities();
  }, [getCie10, getDoctorSpecialities]);

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
        getDoctorInformation().then(EmptyClosure).catch(EmptyClosure);
      }
    } else {
      setPatientInformation(values.paciente as Patient);
      setDoctorInformation(values.medico as Doctor);
      setFormValues(values);
    }
  }, [
    cie10,
    currentPatient,
    getCie10,
    getDoctorInformation,
    getDoctorSpecialities,
    values,
  ]);

  const validationSchema = Yup.object({
    peso: Yup.string().required(RequiredMessage),
    talla: Yup.string().required(RequiredMessage),
    ta_sistolica: Yup.string().required(RequiredMessage),
    ta_diastolica: Yup.string().required(RequiredMessage),
    fc: Yup.string().required(RequiredMessage),
    fr: Yup.string().required(RequiredMessage),
    temp: Yup.string().required(RequiredMessage),
    oxigenacion: Yup.string().required(RequiredMessage),
  });

  const formik = useFormik<EvolutionNote>({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (evolutionNoteValues) => {
      try {
        setLoading(true);
        // Create post object and add missing attributes
        const postInformation = { ...evolutionNoteValues };
        if (mode === CRUDModes.Create) {
          postInformation.fecha = moment
            .utc()
            .utcOffset(-300)
            .toISOString(true)
            .slice(0, 19)
            .replace('T', ' ');
          postInformation.id_paciente = currentPatient ? currentPatient.id : undefined;
          postInformation.id_medico = user?.id;
        }
        postInformation.imc = (
          Number(evolutionNoteValues.peso) /
          Number(evolutionNoteValues.talla) ** 2
        ).toFixed(2);

        const evolutionNoteResult = await postWithToken<
          {
            evolutionNote: EvolutionNote;
            doctorId?: number;
            patientId?: number;
          },
          EvolutionNote
        >(
          `/notes/${mode === CRUDModes.Create ? 'save' : 'update'}-evolution-note`,
          {
            evolutionNote: postInformation,
            doctorId: user?.id,
            patientId: currentPatient ? currentPatient.id : undefined,
          },
          handleLogout,
        );
        if (evolutionNoteResult.success) {
          setLoading(false);
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar nota de evolución : ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleDiagnosisInput = (value: string, type: number) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    if (type === 0) {
      setDiagPresAut(filteredCie);
    } else {
      setDiagAut(filteredCie);
    }
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='nota de evolución'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Agregar nueva nota de evolución</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Recuerda que el peso es en Kilogramos y la talla en Metros
                </PrimaryDescription>

                <Grid item xs={12} md={12}>
                  <CMTextfield
                    disabled={fieldsDisabled}
                    select
                    id='cita_paciente'
                    label='Cita del paciente'
                    placeholder='Cita del paciente'
                    value={formik.values.cita_paciente}
                    name='cita_paciente'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value='-1' disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value='Electiva'>Electiva</MenuItem>
                    <MenuItem value='Urgencia relativa'>Urgencia relativa</MenuItem>
                    <MenuItem value='Urgencia real'>Urgenica real</MenuItem>
                    <MenuItem value='Check up'>Check up</MenuItem>
                  </CMTextfield>
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Motivo de consulta</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='motivo_consulta'
                    minRows={2}
                    placeholder='Motivo de consulta'
                    value={formik.values.motivo_consulta}
                    name='motivo_consulta'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Exploracion física</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='indicaciones'
                    minRows={2}
                    placeholder='Exploracion física'
                    value={formik.values.exploracion_fisica}
                    name='exploracion_fisica'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    id='peso'
                    label='Peso(Kg)'
                    placeholder='Peso(Kg)'
                    value={formik.values.peso}
                    name='peso'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.peso && Boolean(formik.errors.peso)}
                    helperText={formik.touched.peso && formik.errors.peso}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    id='talla'
                    label='Altura(m)'
                    placeholder='Altura(m)'
                    value={formik.values.talla}
                    name='talla'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.talla && Boolean(formik.errors.talla)}
                    helperText={formik.touched.talla && formik.errors.talla}
                  />
                </Grid>
                {formik.values.peso && formik.values.talla ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                  >
                    <Grid item xs={12}>
                      <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                        <b>Índice de masa corporal:</b>
                        {(
                          Number(formik.values.peso) /
                          Number(formik.values.talla) ** 2
                        ).toFixed(2)}
                      </PrimaryDescription>
                    </Grid>
                  </motion.div>
                ) : null}
                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    id='ta_sistolica'
                    label='Presión sistólica (mmHH)'
                    placeholder='Presión sistólica (mmHH)'
                    value={formik.values.ta_sistolica}
                    name='ta_sistolica'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.ta_sistolica && Boolean(formik.errors.ta_sistolica)
                    }
                    helperText={formik.touched.ta_sistolica && formik.errors.ta_sistolica}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                    id='ta_diastolica'
                    label='Presión diastolica (mmHH)'
                    placeholder='Presión diastolica (mmHH)'
                    value={formik.values.ta_diastolica}
                    name='ta_diastolica'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.ta_diastolica && Boolean(formik.errors.ta_diastolica)
                    }
                    helperText={
                      formik.touched.ta_diastolica && formik.errors.ta_diastolica
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    id='fc'
                    label='FC (LPM)'
                    placeholder='FC (LPM)'
                    value={formik.values.fc}
                    name='fc'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fc && Boolean(formik.errors.fc)}
                    helperText={formik.touched.fc && formik.errors.fc}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    id='fr'
                    label='FR (RPM)'
                    placeholder='FR (RPM)'
                    value={formik.values.fr}
                    name='fr'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fr && Boolean(formik.errors.fr)}
                    helperText={formik.touched.fr && formik.errors.fr}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    id='temp'
                    label='Temperatura °C'
                    placeholder='Temperatura °C'
                    value={formik.values.temp}
                    name='temp'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.temp && Boolean(formik.errors.temp)}
                    helperText={formik.touched.temp && formik.errors.temp}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    id='oxigenacion'
                    label='Saturación de oxígeno (%)'
                    placeholder='Saturación de oxígeno (%)'
                    value={formik.values.oxigenacion}
                    name='oxigenacion'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.oxigenacion && Boolean(formik.errors.oxigenacion)
                    }
                    helperText={formik.touched.oxigenacion && formik.errors.oxigenacion}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>
                    Resultados de estudios de laboratorio y gabinete
                  </CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='laboratorio_gabinete'
                    minRows={2}
                    placeholder='Resultados de estudios de laboratorio y gabinete'
                    value={formik.values.laboratorio_gabinete}
                    name='laboratorio_gabinete'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    disabled={fieldsDisabled}
                    openOnFocus
                    multiple
                    id='nota_evolucion_diag_pres'
                    options={diagPresAut}
                    noOptionsText='No hay opciones disponibles'
                    getOptionLabel={(option) =>
                      `${option.cie_clave} ${option.cie_descripcion}`
                    }
                    onInputChange={(_event, value) => handleDiagnosisInput(value, 0)}
                    value={formik.values.nota_evolucion_diag_pres}
                    onChange={(_e, value) => {
                      formik.setFieldValue('nota_evolucion_diag_pres', value);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <CMTextfield
                        {...params}
                        label='Diagnóstico(s) presuntivo(s)'
                        placeholder='Diagnóstico(s) presuntivo(s)'
                        name='nota_evolucion_diag_pres'
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Tratamiento</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='tratamiento'
                    minRows={2}
                    placeholder='Tratamiento'
                    value={formik.values.tratamiento}
                    name='tratamiento'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfield
                    disabled={fieldsDisabled}
                    select
                    id='pronostico'
                    label='Pronóstico'
                    placeholder='Pronóstico'
                    value={formik.values.pronostico}
                    name='pronostico'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value='-1' disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value='Bueno'>Bueno</MenuItem>
                    <MenuItem value='Regular'>Regular</MenuItem>
                    <MenuItem value='Malo'>Malo</MenuItem>
                    <MenuItem value='Reservado'>Reservado</MenuItem>
                  </CMTextfield>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                      disabled={fieldsDisabled}
                      label='Fecha de nueva cita'
                      value={formik.values.fecha_nueva_cita}
                      onChange={(value): void => {
                        formik.setFieldValue('fecha_nueva_cita', value);
                      }}
                      inputFormat='DD/MM/yyyy'
                      renderInput={(params) => (
                        <CMTextfield
                          onBlur={formik.handleBlur}
                          {...params}
                          name='fecha_nueva_cita'
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsDisabled}
                        color='primary'
                        name='cronico_deg'
                        id='cronico_deg'
                        checked={formik.values.cronico_deg}
                        onChange={formik.handleChange}
                      />
                    }
                    label='Paciente cronico-degenerativo'
                    style={{ marginBottom: '1rem' }}
                  />
                </Grid>
                {formik.values.cronico_deg ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyCronicDeg-div'
                  >
                    <Grid item xs={12} md={12}>
                      <CMTextfield
                        disabled={fieldsDisabled}
                        select
                        id='cronico_deg_enfermedad'
                        label='Cronico-degenerativo'
                        placeholder='Cronico-degenerativo'
                        value={formik.values.cronico_deg_enfermedad}
                        name='cronico_deg_enfermedad'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value='-1' disabled>
                          Seleccione una opción
                        </MenuItem>
                        <MenuItem value='Diabetes Mellitus'>Diabetes Mellitus</MenuItem>
                        <MenuItem value='Hipertension Arterial Sistemica'>
                          Hipertension Arterial Sistemica
                        </MenuItem>
                        <MenuItem value='Cirrosis Hepatica'>Cirrosis Hepatica</MenuItem>
                        <MenuItem value='Insuficiencia Renal Cronica'>
                          Insuficiencia Renal Cronica
                        </MenuItem>
                        <MenuItem value='Cardiopatia Isquemica'>
                          Cardiopatia Isquemica
                        </MenuItem>
                        <MenuItem value='Cardiopatia de otra Etiologia'>
                          Cardiopatia de otra Etiologia
                        </MenuItem>
                        <MenuItem value='Neoplasias'>Neoplasias</MenuItem>
                        <MenuItem value='Enfermedades Pulmonares Cronicas'>
                          Enfermedades Pulmonares Cronicas
                        </MenuItem>
                        <MenuItem value='Enfermedades Neurologicas Degenerativas'>
                          Enfermedades Neurologicas Degenerativas
                        </MenuItem>
                        <MenuItem value='Enfermedades Discapacitantes Tercera Edad'>
                          Enfermedades Discapacitantes Tercera Edad
                        </MenuItem>
                      </CMTextfield>
                    </Grid>
                  </motion.div>
                ) : null}
                <PrimarySubtitle mobile style={{ marginBottom: '1rem' }}>
                  Interconsulta
                </PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={fieldsDisabled}
                        color='primary'
                        name='requiere_interconsulta'
                        id='requiere_interconsulta'
                        checked={formik.values.requiere_interconsulta}
                        onChange={formik.handleChange}
                      />
                    }
                    label='Requiere Interconsulta'
                    style={{ marginBottom: '1rem' }}
                  />
                </Grid>
                {formik.values.requiere_interconsulta ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyInterConsult-div'
                  >
                    <Grid item xs={12} md={12}>
                      <CMTextfield
                        disabled={fieldsDisabled}
                        select
                        id='interconsulta_es'
                        label='Interconsulta al especialista es'
                        placeholder='Interconsulta al especialista es'
                        value={formik.values.interconsulta_es}
                        name='interconsulta_es'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value='-1' disabled>
                          Seleccione una opción
                        </MenuItem>
                        <MenuItem value='Electiva'>Electiva</MenuItem>
                        <MenuItem value='Urgencia relativa'>Urgencia relativa</MenuItem>
                        <MenuItem value='Urgencia real'>Urgenica real</MenuItem>
                        <MenuItem value='Check up'>Check up</MenuItem>
                      </CMTextfield>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={fieldsDisabled}
                            color='primary'
                            name='paciente_referido'
                            id='paciente_referido'
                            checked={formik.values.paciente_referido}
                            onChange={formik.handleChange}
                          />
                        }
                        label='El Paciente debe ser referido a una Unidad de Urgencias Hospitalaria'
                        style={{ marginBottom: '1rem' }}
                      />
                    </Grid>
                    {formik.values.paciente_referido && (
                      <CMTextfield
                        disabled={fieldsDisabled}
                        id='hospital_envio'
                        label='Hospital al que se envio'
                        placeholder='Hospital al que se envio'
                        value={formik.values.hospital_envio}
                        name='hospital_envio'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                    {!formik.values.paciente_referido && (
                      <CMTextfield
                        disabled={fieldsDisabled}
                        id='especialista_referir'
                        label='Médico especialista a referir'
                        placeholder='Médico especialista a referir'
                        value={formik.values.especialista_referir}
                        name='especialista_referir'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                    <CMTextfield
                      select
                      disabled={fieldsDisabled}
                      id='outlined-basic'
                      label='Especialidad a la que se envio'
                      value={formik.values.especialidad_envio}
                      name='especialidad_envio'
                      style={{ marginBottom: '1rem' }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value='-1'>Seleccione una opción</MenuItem>
                      {specialities.map((singleSpeciality) => {
                        return (
                          <MenuItem value={singleSpeciality.cat_especialidad_id}>
                            {singleSpeciality.cat_especialidad_desc}
                          </MenuItem>
                        );
                      })}
                    </CMTextfield>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        disabled={fieldsDisabled}
                        openOnFocus
                        multiple
                        id='nota_evolucion_diag'
                        options={diagAut}
                        noOptionsText='No hay opciones disponibles'
                        getOptionLabel={(option) =>
                          `${option.cie_clave} ${option.cie_descripcion}`
                        }
                        onInputChange={(_event, value) => handleDiagnosisInput(value, 1)}
                        value={formik.values.nota_evolucion_diag}
                        onChange={(_e, value) => {
                          formik.setFieldValue('nota_evolucion_diag', value);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <CMTextfield
                            {...params}
                            label='Diagnóstico(s)'
                            placeholder='Diagnóstico(s)'
                            name='nota_evolucion_diag'
                          />
                        )}
                      />
                    </Grid>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      id='tratamiento_paciente'
                      label='Tratamiento'
                      placeholder='Tratamiento'
                      value={formik.values.tratamiento_paciente}
                      name='tratamiento_paciente'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </motion.div>
                ) : null}
                <PrimarySubtitle mobile style={{ marginBottom: '1rem' }}>
                  Indicaciones
                </PrimarySubtitle>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Indicaciones</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='indicaciones'
                    minRows={2}
                    placeholder='Indicaciones'
                    value={formik.values.indicaciones}
                    name='indicaciones'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
              </Grid>
              {values &&
              values.medico &&
              mode !== CRUDModes.Create &&
              values.paciente &&
              values.id_medico &&
              user &&
              canPrintNote(values.paciente, values.id_medico, user) ? (
                <Grid item xs={12}>
                  <div style={{ display: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <EvolutionNoteDocument
                        patient={values.paciente}
                        doctor={values.medico}
                        values={values as EvolutionNote}
                      />
                    </Grid>
                  </div>

                  {!isIOSApp() ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      style={{ width: '100%' }}
                      onClick={() => handlePrint()}
                    >
                      Imprimir
                    </PrimaryButton>
                  ) : (
                    <MobileDownloadMessage />
                  )}
                </Grid>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

EvolutionNotesForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default EvolutionNotesForm;
