import React, { useCallback, useEffect, useState } from 'react';
import { useMainProvider } from '../../context/MainProvider';
import GenericTablePage from '../GenericTablePage/GenericTablePage';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import { CRUDModes } from '../../interfaces/CRUD';
import { UserTypes } from '../../interfaces/User';
import { gettWithToken, previewUserID } from '../../utils/server';
import { SimpleCards, SimplifiedSimpleCards } from '../../interfaces/SimpleCards';
import SimpleCardsForm from './SimpleCardsForm';
import 'moment/locale/es';
import { getPatientFullName } from '../../utils/fns';
import { Patient } from '../../interfaces/Patients';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { isIOSApp } from '../../interfaces/WebkitWindow';

const SimpleCardsPage = (): JSX.Element => {
  const {
    setLoading,
    user,
    currentPatient,
    setOpenAlert,
    setAlertType,
    setAlertMessage,
    restartTemporayUsers,
    setLoginPopupOpen,
    handleLogout,
  } = useMainProvider();
  const [entries, setEntries] = useState<SimpleCards[]>([]);
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await gettWithToken<SimpleCards[]>(
        `/cards/get-simple-cards-doctor?patientId=${
          currentPatient ? currentPatient.id : undefined
        }`,
        handleLogout,
      );
      if (fetchResult.success) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha',
            displayName: 'Fecha de creación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: isIOSApp() ? 'Consultar' : 'Ver',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
        ]);
        const simplifiedData: SimplifiedSimpleCards[] = orderedRows.map((simpleCard) => {
          return {
            id: simpleCard.id,
            fecha: simpleCard.fecha,
            moreInformation: isIOSApp() ? 'Consultar' : 'Ver',
          };
        });
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener cartas de consentimiento simples : ${JSON.stringify(
            e.response.data,
          )} `,
        );
        setOpenAlert(true);
        if (
          e.response.data ===
          'El tiempo de acceso al Expediente Clínico del Paciente terminó, si requiere continuar, favor de escanear otro código QR de su Paciente. Gracias'
        ) {
          restartTemporayUsers();
        }
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const fetchCallback = useCallback(fetchEntries, [
    currentPatient,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    restartTemporayUsers,
    handleLogout,
  ]);

  useEffect(() => {
    fetchCallback().then(EmptyClosure).catch(EmptyClosure);
  }, [fetchCallback]);

  return (
    <>
      <SimpleCardsForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={selectedRow !== -1 ? entries[selectedRow] : undefined}
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      <GenericTablePage
        title={`Cartas de consentimiento simple${
          currentPatient
            ? ` de ${getPatientFullName(currentPatient.initialData as Patient)}`
            : ''
        }`}
        rows={rows}
        columns={columns}
        canDelete={false}
        canEdit
        canCreate={user && currentPatient ? user.typeId === UserTypes.DoctorType : false}
        onCreate={() => {
          if (user && user.id === previewUserID) {
            setLoginPopupOpen(true);
          } else {
            setOpen(true);
            setSelectedRow(-1);
            setMode(CRUDModes.Create);
          }
        }}
      />
    </>
  );
};

export default SimpleCardsPage;
