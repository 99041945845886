import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import * as Yup from 'yup';
import { useMainProvider } from '../../../context/MainProvider';
import { CRUDModes } from '../../../interfaces/CRUD';
import {
  BirthMethods,
  ClinicHistory,
  NutritionQualities,
  NutritionQuantities,
} from '../../../interfaces/ClinicHistories';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../../components/Forms/CMTextfield';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../../index.styled';
import { PrimarySubtitle } from '../../../components/PrimarySubtitle/PrimarySubtitle';
import { setHeaders } from '../../../utils/headerSetter';
import { CMTextArea } from '../../../components/Forms/CMTextArea';
import { CMInputLabel } from '../../../components/Forms/InputLabel';
import { StandardText } from '../../../components/StandardText/StandardText';
import DialogCustomTitle from '../../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../../components/PatientData/PatientData';
import { Patient } from '../../../interfaces/Patients';
import { BloodTypes } from '../../../interfaces/BloodTypes';
import { Cie10 } from '../../../interfaces/Cie10';
import { RequiredMessage } from '../../../utils/validationMessages';
import Ad from '../../../components/Ad/Ad';
import useAds from '../../../hooks/useAds';
import { AdCatalog, AdTypes } from '../../../interfaces/Ads';
import { FlexContainer } from '../../../components/FlexContainer/FlexContainer';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  onSuccess: () => void;
  values?: ClinicHistory;
  closeFunction?: () => void;
}

const initialValues: ClinicHistory = {
  isActive: true,
  patientId: 0,
  doctorId: 0,
  hasDiabetes: false,
  diabeticRelatives: '',
  hasHipertension: false,
  hipertensionRelatives: '',
  hasCancer: false,
  cancerRelatives: '',
  cancerType: '',
  hasHeartDisease: false,
  heartDiseaseRelatives: '',
  hasNephropathy: false,
  nephropaticalRelatives: '',
  hasMalformations: false,
  malformationsRelatives: '',
  malformationType: '',
  otherFamilyDiseases: '',
  isSmoker: false,
  cigarettesPerDay: 0,
  yearsSmoking: 0,
  isExSmoker: false,
  isAlcoholic: false,
  drinksPerWeek: 0,
  yearsDrinking: 0,
  isExAlcoholic: false,
  hasGoodNutrition: false,
  nutritionQuality: NutritionQualities.Unknown,
  nutritionQuantity: NutritionQuantities.Unknown,
  bloodType: BloodTypes.Unknown,
  hasBasicServices: false,
  isDrugAddict: false,
  isExDrugAddict: false,
  drugAddictionType: '',
  yearsDrugAddict: 0,
  otherSocioPersonalBackground: '',
  hasInfancyDiseases: false,
  infancyDiseases: '',
  infancyDiseaseAfterEffects: '',
  hasPreviousHospitalizations: false,
  previousHospitalizations: '',
  hasQuirurgicBackground: false,
  quirurgicBackground: '',
  hasPreviousTransfusions: false,
  previousTransfusions: '',
  hasBoneFractures: false,
  hasTraumas: false,
  traumas: '',
  hasOtherDiseases: false,
  otherDiseases: '',
  hasAlergies: false,
  alergicMedicine: '',
  otherAlergies: '',
  hasSTDiseases: false,
  sTDiseases: '',
  respiratoryDescription: '',
  cardiovascularDescription: '',
  peripheralVascularDescription: '',
  digestiveDescription: '',
  endocrineDescription: '',
  muscularDescription: '',
  genitalDescription: '',
  lymphaticDescription: '',
  skinDescription: '',
  neurologicalDescription: '',
  weightKg: 0,
  height: 0,
  bodyMassIndex: 0,
  hasOtherFamilyDiseases: false,
  hasOtherSocioPersonalBackground: false,
  foodAlergies: '',
  menarca: '',
  ciclos_regulares: false,
  ciclo_cantidad: '',
  ciclo_periodicidad: '',
  ultima_menstruacion: '',
  polimenorrea: false,
  hipermenorrea: false,
  disiminorrea: false,
  ivsa: '',
  numero_parejas: 0,
  gesta: '',
  para: '',
  abortos: '',
  cesareas: '',
  ultima_pap: '',
  resultado_ultima_pap: '',
  metodo_planificacion_familiar: '',
  padecimiento_actual: '',
  peso_ideal: '',
  peso_habitual: '',
  temperatura: '',
  frecuencia_cardiaca: '',
  frecuencia_respiratoria: '',
  presion_arterial: '',
  habitus_exterior: '',
  cabeza: '',
  cuello: '',
  torax: '',
  abdomen: '',
  extremidades: '',
  genitales: '',
  ginecologico: '',
  resultados_estudios: '',
  pronostico: '',
  indicacion_terapeutica: '',
  historia_clinica_diagnosticos: [],
};

const validationSchema = Yup.object({
  weightKg: Yup.number().required(RequiredMessage),
  height: Yup.number().required(RequiredMessage),
  peso_ideal: Yup.number().required(RequiredMessage),
  peso_habitual: Yup.number().required(RequiredMessage),
  presion_arterial: Yup.string().required(RequiredMessage),
  frecuencia_cardiaca: Yup.number().required(RequiredMessage),
  frecuencia_respiratoria: Yup.number().required(RequiredMessage),
  temperatura: Yup.number().required(RequiredMessage),
});

const ClinicHistoryForm = ({
  open,
  setOpen,
  mode,
  onSuccess,
  values,
  closeFunction,
}: FormProps): JSX.Element => {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    currentPatient,
    cie10,
    getCie10,
    handleLogout,
  } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [patientInformation, setPatientInformation] = useState<Patient>();
  const [cie10Search, setCie10Search] = useState<Cie10[]>([]);
  const adInfo = useAds(AdCatalog.detalleHistoriaClinica);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.doctorId !== user?.id,
    [mode, formValues, user?.id],
  );

  useEffect(() => {
    getCie10();
  }, [getCie10]);

  useEffect(() => {
    setCie10Search(cie10.slice(0, 100));
  }, [cie10]);

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
      }
    } else {
      setPatientInformation(values.patientInformation as Patient);
      setFormValues(values);
    }
  }, [currentPatient, values]);

  const formik = useFormik<ClinicHistory>({
    initialValues: formValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (form) => {
      try {
        setLoading(true);
        const emailResult = await axios.post<{
          json: ClinicHistory;
        }>(
          `${process.env.REACT_APP_SERVER_URL}/clinicHistory/${
            mode === CRUDModes.Create ? 'post' : 'update'
          }`,
          {
            json: {
              ...form,
              bodyMassIndex: form.weightKg / form.height ** 2,
            },
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          setHeaders(true, user ? user.token : undefined),
        );
        if (emailResult.status === 200) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `¡Historia clínica ${
              mode === CRUDModes.Create ? 'creada' : 'actualizada'
            } exitosamente!`,
          );
          setOpenAlert(true);
          setLoading(false);
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar historia clínica: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleDiagnosisInput = (value: string) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    setCie10Search(filteredCie);
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='historia clínica'
      />

      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              {adInfo ? (
                <Grid item xs={12}>
                  <FlexContainer direction='row' justifyContent='center'>
                    <Ad
                      adInfo={adInfo}
                      type={mobile ? AdTypes.mobileBanner : AdTypes.leaderboard}
                    />
                  </FlexContainer>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Exploración física</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  El índice de masa corporal se calculará automáticamente después de
                  ingresar el peso actual y altura del paciente.
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='weightKg'
                  label='Peso actual(Kg)'
                  placeholder='Peso actual(Kg)'
                  value={formik.values.weightKg || ''}
                  name='weightKg'
                  onChange={formik.handleChange}
                  error={formik.touched.weightKg && Boolean(formik.errors.weightKg)}
                  helperText={formik.touched.weightKg && formik.errors.weightKg}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='height'
                  label='Altura(m)'
                  placeholder='Altura(m)'
                  value={formik.values.height || ''}
                  name='height'
                  onChange={formik.handleChange}
                  error={formik.touched.height && Boolean(formik.errors.height)}
                  helperText={formik.touched.height && formik.errors.height}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              {formik.values.weightKg && formik.values.height ? (
                <Grid item xs={12}>
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                  >
                    <PrimaryDescription style={{ margin: '0 0 1rem 0' }}>
                      <b>Índice de masa corporal:</b>
                      {(formik.values.weightKg / formik.values.height ** 2).toFixed(2)}
                    </PrimaryDescription>
                  </motion.div>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='peso_ideal'
                  label='Peso ideal(Kg)'
                  placeholder='Peso ideal(Kg)'
                  value={formik.values.peso_ideal || ''}
                  name='peso_ideal'
                  onChange={formik.handleChange}
                  error={formik.touched.peso_ideal && Boolean(formik.errors.peso_ideal)}
                  helperText={formik.touched.peso_ideal && formik.errors.peso_ideal}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='peso_habitual'
                  label='Peso habitual(Kg)'
                  placeholder='Peso habitual(Kg)'
                  value={formik.values.peso_habitual || ''}
                  name='peso_habitual'
                  onChange={formik.handleChange}
                  error={
                    formik.touched.peso_habitual && Boolean(formik.errors.peso_habitual)
                  }
                  helperText={formik.touched.peso_habitual && formik.errors.peso_habitual}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  id='presion_arterial'
                  value={formik.values.presion_arterial}
                  name='presion_arterial'
                  label='Tensión Arterial(mmHg)'
                  placeholder='Tensión Arterial(mmHg)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.presion_arterial &&
                    Boolean(formik.errors.presion_arterial)
                  }
                  helperText={
                    formik.touched.presion_arterial && formik.errors.presion_arterial
                  }
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='frecuencia_cardiaca'
                  value={formik.values.frecuencia_cardiaca || ''}
                  name='frecuencia_cardiaca'
                  label='Frecuencia Cardiaca(LPM)'
                  placeholder='Frecuencia Cardiaca(LPM)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.frecuencia_cardiaca &&
                    Boolean(formik.errors.frecuencia_cardiaca)
                  }
                  helperText={
                    formik.touched.frecuencia_cardiaca &&
                    formik.errors.frecuencia_cardiaca
                  }
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='frecuencia_respiratoria'
                  value={formik.values.frecuencia_respiratoria || ''}
                  name='frecuencia_respiratoria'
                  label='Frecuencia Respiratoria(RPM)'
                  placeholder='Frecuencia Respiratoria(RPM)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.frecuencia_respiratoria &&
                    Boolean(formik.errors.frecuencia_respiratoria)
                  }
                  helperText={
                    formik.touched.frecuencia_respiratoria &&
                    formik.errors.frecuencia_respiratoria
                  }
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='temperatura'
                  value={formik.values.temperatura || ''}
                  name='temperatura'
                  label='Temperatura(°C)'
                  placeholder='Temperatura(°C)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={formik.touched.temperatura && Boolean(formik.errors.temperatura)}
                  helperText={formik.touched.temperatura && formik.errors.temperatura}
                  onKeyPress={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Habitus exterior</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='habitus_exterior'
                  name='habitus_exterior'
                  value={formik.values.habitus_exterior}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Cabeza</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='cabeza'
                  name='cabeza'
                  value={formik.values.cabeza}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Cuello</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='cuello'
                  name='cuello'
                  value={formik.values.cuello}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Tórax</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='torax'
                  name='torax'
                  value={formik.values.torax}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Abdomen</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='abdomen'
                  name='abdomen'
                  value={formik.values.abdomen}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Extremidades</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='extremidades'
                  name='extremidades'
                  value={formik.values.extremidades}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Genitales</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='genitales'
                  name='genitales'
                  value={formik.values.genitales}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              {patientInformation && patientInformation.pac_sexo_id === 2 ? (
                <Grid item xs={12}>
                  <CMInputLabel mobile>Ginecología</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                    id='ginecologico'
                    name='ginecologico'
                    value={formik.values.ginecologico}
                    onChange={formik.handleChange}
                    minRows={2}
                    placeholder='Descripción'
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Antedentes Heredofamiliares</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Marca las casillas si el paciente o algún familiar directo padece uno de
                  los siguientes padecimientos:
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasDiabetes'
                      id='hasDiabetes'
                      checked={formik.values.hasDiabetes}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Diabetes'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasDiabetes ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='diabeticRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='diabeticRelatives'
                        label='¿Quiénes?'
                        placeholder='Familiares con diabetes'
                        value={formik.values.diabeticRelatives}
                        name='diabeticRelatives'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasHipertension'
                      id='hasHipertension'
                      checked={formik.values.hasHipertension}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Hipertensión'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasHipertension ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='hipertensionRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='hipertensionRelatives'
                        label='¿Quiénes?'
                        placeholder='Familiares con hipertensión'
                        value={formik.values.hipertensionRelatives}
                        name='hipertensionRelatives'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasCancer'
                      id='hasCancer'
                      checked={formik.values.hasCancer}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Cáncer'
                />
              </Grid>
              {formik.values.hasCancer ? (
                <>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='cancerRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='cancerRelatives'
                        label='¿Quiénes?'
                        placeholder='Familiares con cáncer'
                        value={formik.values.cancerRelatives}
                        name='cancerRelatives'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='cancerType-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='cancerType'
                        label='Tipo Cáncer'
                        placeholder='¿Qué tipo de cáncer?'
                        value={formik.values.cancerType}
                        name='cancerType'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasHeartDisease'
                      id='hasHeartDisease'
                      checked={formik.values.hasHeartDisease}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Cardiopatías'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasHeartDisease ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='heartDiseaseRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='heartDiseaseRelatives'
                        label='¿Quiénes?'
                        placeholder='Familiares con cardiopatías'
                        value={formik.values.heartDiseaseRelatives}
                        name='heartDiseaseRelatives'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasNephropathy'
                      id='hasNephropathy'
                      checked={formik.values.hasNephropathy}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Nefropatías'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasNephropathy ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='nephropaticalRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='nephropaticalRelatives'
                        label='¿Quiénes?'
                        placeholder='Familiares con necropatías'
                        value={formik.values.nephropaticalRelatives}
                        name='nephropaticalRelatives'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasMalformations'
                      id='hasMalformations'
                      checked={formik.values.hasMalformations}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Malformaciones'
                />
              </Grid>
              {formik.values.hasMalformations ? (
                <>
                  {' '}
                  <Grid item xs={12} md={6}>
                    {' '}
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='malformationsRelatives-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='malformationsRelatives'
                        label='¿Quiénes?'
                        placeholder='Familiares con malformaciones'
                        value={formik.values.malformationsRelatives}
                        name='malformationsRelatives'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='malformationType-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='malformationType'
                        label='Tipo malformación'
                        placeholder='Tipo de malformación'
                        value={formik.values.malformationType}
                        name='malformationType'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasOtherFamilyDiseases'
                      id='hasOtherFamilyDiseases'
                      checked={formik.values.hasOtherFamilyDiseases}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Otro'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasOtherFamilyDiseases ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otherFamilyDiseases-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='otherFamilyDiseases'
                        label='Otros padecimientos'
                        placeholder='Otros padecimientos'
                        value={formik.values.otherFamilyDiseases}
                        name='otherFamilyDiseases'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile style={{ marginTop: '1rem' }}>
                  Antecedentes Sociopersonales
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 2rem 0' }}>
                  Responde a las preguntas y marca las casillas sobre los antecedentes
                  sociopersonales del paciente
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  select
                  id='bloodType'
                  label='Tipo de Sangre'
                  value={formik.values.bloodType}
                  name='bloodType'
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value={BloodTypes.Unknown}>
                    No sabe
                  </MenuItem>
                  <MenuItem value={BloodTypes.A1Negative}>
                    {BloodTypes.A1Negative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A1Positive}>
                    {BloodTypes.A1Positive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A2Negative}>
                    {BloodTypes.A2Negative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.A2Positive}>
                    {BloodTypes.A2Positive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.ABNegative}>
                    {BloodTypes.ABNegative}
                  </MenuItem>
                  <MenuItem value={BloodTypes.ABPositive}>
                    {BloodTypes.ABPositive}
                  </MenuItem>
                  <MenuItem value={BloodTypes.BNegative}>{BloodTypes.BNegative}</MenuItem>
                  <MenuItem value={BloodTypes.BPositive}>{BloodTypes.BPositive}</MenuItem>
                  <MenuItem value={BloodTypes.ONegative}>{BloodTypes.ONegative}</MenuItem>
                  <MenuItem value={BloodTypes.OPositive}>{BloodTypes.OPositive}</MenuItem>
                  <MenuItem value={BloodTypes.RHNegative}>
                    {BloodTypes.RHNegative}
                  </MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  select
                  id='nutritionQuality'
                  label='Calidad de alimentación'
                  value={formik.values.nutritionQuality}
                  name='nutritionQuality'
                  style={{ marginBottom: '0' }}
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value={NutritionQualities.Unknown}>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value={NutritionQualities.Bad}>Mala</MenuItem>
                  <MenuItem value={NutritionQualities.Regular}>Regular</MenuItem>
                  <MenuItem value={NutritionQualities.Good}>Buena</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  $nomargin
                  select
                  id='nutritionQuantity'
                  label='Cantidad de alimentación'
                  value={formik.values.nutritionQuantity}
                  name='nutritionQuantity'
                  style={{ marginBottom: '0' }}
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value={NutritionQuantities.Unknown}>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value={NutritionQuantities.Bad}>Mala</MenuItem>
                  <MenuItem value={NutritionQuantities.Regular}>Regular</MenuItem>
                  <MenuItem value={NutritionQuantities.Good}>Buena</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasGoodNutrition'
                      id='hasGoodNutrition'
                      checked={formik.values.hasGoodNutrition}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Tiene buena nutrición'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasBasicServices'
                      id='hasBasicServices'
                      checked={formik.values.hasBasicServices}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Vivienda con servicios básicos'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='isSmoker'
                      id='isSmoker'
                      checked={formik.values.isSmoker}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Tabaquismo'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='isExSmoker'
                      id='isExSmoker'
                      checked={formik.values.isExSmoker}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Ex fumador / tabaquismo pasivo'
                />
              </Grid>
              {formik.values.isSmoker ? (
                <>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='cigarettesPerDay-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='number'
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        id='cigarettesPerDay'
                        label='Cigarros diarios'
                        placeholder='Cigarros al día'
                        value={formik.values.cigarettesPerDay || ''}
                        name='cigarettesPerDay'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {' '}
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='yearsSmoking-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='number'
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        id='yearsSmoking'
                        label='Años fumando'
                        placeholder='Años fumando'
                        value={formik.values.yearsSmoking || ''}
                        name='yearsSmoking'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='isAlcoholic'
                      id='isAlcoholic'
                      checked={formik.values.isAlcoholic}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Alcoholismo'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='isExAlcoholic'
                      id='isExAlcoholic'
                      checked={formik.values.isExAlcoholic}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Ex alhocolico / pasivo'
                />
              </Grid>
              {formik.values.isAlcoholic ? (
                <>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='drinksPerWeek-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='number'
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        id='drinksPerWeek'
                        label='Copas diarias'
                        placeholder='Copas al día'
                        value={formik.values.drinksPerWeek || ''}
                        name='drinksPerWeek'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {' '}
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='yearsDrinking-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='number'
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        id='yearsDrinking'
                        label='Años tomando'
                        placeholder='Años tomando'
                        value={formik.values.yearsDrinking || ''}
                        name='yearsDrinking'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='isDrugAddict'
                      id='isDrugAddict'
                      checked={formik.values.isDrugAddict}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Drogadicción'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='isExDrugAddict'
                      id='isExDrugAddict'
                      checked={formik.values.isExDrugAddict}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Ex drogadicto'
                />
              </Grid>
              {formik.values.isDrugAddict ? (
                <>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='drugAddictionType-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='drugAddictionType'
                        label='Tipo adicción'
                        placeholder='Tipo de adicción'
                        value={formik.values.drugAddictionType}
                        name='drugAddictionType'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='yearsDrugAddict-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        type='number'
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        id='yearsDrugAddict'
                        label='Años con droga'
                        placeholder='Años con droga'
                        value={formik.values.yearsDrugAddict || ''}
                        name='yearsDrugAddict'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}

              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasOtherSocioPersonalBackground'
                      id='hasOtherSocioPersonalBackground'
                      checked={formik.values.hasOtherSocioPersonalBackground}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Otro'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasOtherSocioPersonalBackground ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otherSocioPersonalBackground-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='otherSocioPersonalBackground'
                        label='Otro'
                        placeholder='Otros sociopersonales'
                        value={formik.values.otherSocioPersonalBackground}
                        name='otherSocioPersonalBackground'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Antecedentes Personales Patológicos
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Marca las casillas si el paciente ha experimentado alguno de los
                  siguientes antecedentes:
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasInfancyDiseases'
                      id='hasInfancyDiseases'
                      checked={formik.values.hasInfancyDiseases}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Enfermedades de la infancia'
                />
              </Grid>
              {formik.values.hasInfancyDiseases ? (
                <>
                  {' '}
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='infancyDiseases-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='infancyDiseases'
                        label='¿Cuáles?'
                        placeholder='Enfermedades de la infancia'
                        value={formik.values.infancyDiseases}
                        name='infancyDiseases'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='infancyDiseaseAfterEffects-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='infancyDiseaseAfterEffects'
                        label='Secuelas'
                        placeholder='Secuelas'
                        value={formik.values.infancyDiseaseAfterEffects}
                        name='infancyDiseaseAfterEffects'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasPreviousHospitalizations'
                      id='hasPreviousHospitalizations'
                      checked={formik.values.hasPreviousHospitalizations}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Hospitalizaciones previas'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasPreviousHospitalizations ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='previousHospitalizations-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='previousHospitalizations'
                        label='¿Cuáles?'
                        placeholder='Hospitalizaciones previas'
                        value={formik.values.previousHospitalizations}
                        name='previousHospitalizations'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasQuirurgicBackground'
                      id='hasQuirurgicBackground'
                      checked={formik.values.hasQuirurgicBackground}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Antecedentes quirúrgicos'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasQuirurgicBackground ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='quirurgicBackground-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='quirurgicBackground'
                        label='¿Cuáles?'
                        placeholder='Antecedentes quirúrgicos'
                        value={formik.values.quirurgicBackground}
                        name='quirurgicBackground'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasPreviousTransfusions'
                      id='hasPreviousTransfusions'
                      checked={formik.values.hasPreviousTransfusions}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Transfusiones previas'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasPreviousTransfusions ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='previousTransfusions-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='previousTransfusions'
                        label='Descripción'
                        placeholder='Transfusiones previas'
                        value={formik.values.previousTransfusions}
                        name='previousTransfusions'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasTraumas'
                      id='hasTraumas'
                      checked={formik.values.hasTraumas}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Antecedentes de Traumatismo'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasTraumas ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='traumas-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='traumas'
                        label='¿Cuáles?'
                        placeholder='Antecedentes de Traumatismo'
                        value={formik.values.traumas}
                        name='traumas'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasSTDiseases'
                      id='hasSTDiseases'
                      checked={formik.values.hasSTDiseases}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Enfermedades transmisibles'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasSTDiseases ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='sTDiseases-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='sTDiseases'
                        label='¿Cuáles?'
                        placeholder='Enfermedades transmisibles'
                        value={formik.values.sTDiseases}
                        name='sTDiseases'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasAlergies'
                      id='hasAlergies'
                      checked={formik.values.hasAlergies}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Alergias'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {formik.values.hasAlergies ? (
                  <motion.div
                    initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                    exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                    animate={{
                      opacity: 1,
                      [mobile ? 'height' : 'width']: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='alergicMedicine-div'
                  >
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='alergicMedicine'
                      label='Principio activo'
                      placeholder='Principio activo'
                      value={formik.values.alergicMedicine}
                      name='alergicMedicine'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </motion.div>
                ) : null}
              </Grid>
              {formik.values.hasAlergies ? (
                <>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='foodAlergies-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='foodAlergies'
                        label='Alimentos'
                        placeholder='Alimentos'
                        value={formik.values.foodAlergies}
                        name='foodAlergies'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otherAlergies-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='otherAlergies'
                        label='¿Otro tipo?'
                        placeholder='Alergias diferentes a medicamentos'
                        value={formik.values.otherAlergies}
                        name='otherAlergies'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasBoneFractures'
                      id='hasBoneFractures'
                      checked={formik.values.hasBoneFractures}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Fracturas'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      color='primary'
                      name='hasOtherDiseases'
                      id='hasOtherDiseases'
                      checked={formik.values.hasOtherDiseases}
                      onChange={formik.handleChange}
                    />
                  }
                  label='Otras enfermedades'
                />
              </Grid>
              <Grid item xs={12}>
                {formik.values.hasOtherDiseases ? (
                  <>
                    <motion.div
                      initial={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      exit={{ opacity: 0, [mobile ? 'height' : 'width']: 0 }}
                      animate={{
                        opacity: 1,
                        [mobile ? 'height' : 'width']: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='otherDiseases-div'
                    >
                      <CMTextfield
                        disabled={fieldsDisabled}
                        onBlur={formik.handleBlur}
                        $nomargin
                        id='otherDiseases'
                        label='Otras enfermedades'
                        placeholder='Otras enfermedades'
                        value={formik.values.otherDiseases}
                        name='otherDiseases'
                        onChange={formik.handleChange}
                        onKeyPress={(keyEvent) => {
                          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                      />
                    </motion.div>
                  </>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Padecimiento actual</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='padecimiento_actual'
                  name='padecimiento_actual'
                  value={formik.values.padecimiento_actual}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              {patientInformation && patientInformation.pac_sexo_id === 2 ? (
                <>
                  <Grid item xs={12}>
                    <PrimarySubtitle mobile>
                      Antecedentes Gineco-obstétricos
                    </PrimarySubtitle>
                    <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                      En el caso de las pacientes del sexo femenino, llena sus
                      antecedentes gineco-obstétricos
                    </PrimaryDescription>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={fieldsDisabled}
                          onBlur={formik.handleBlur}
                          color='primary'
                          name='ciclos_regulares'
                          id='ciclos_regulares'
                          checked={formik.values.ciclos_regulares}
                          onChange={formik.handleChange}
                        />
                      }
                      label='¿Tiene ciclos regulares?'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='menarca'
                      label='Menarca'
                      placeholder='Menarca'
                      value={formik.values.menarca}
                      name='menarca'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PrimaryDescription mobile style={{ margin: '0' }}>
                      Ritmo del ciclo
                    </PrimaryDescription>
                  </Grid>
                  <Grid item xs={5}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='ciclo_cantidad'
                      label='Cantidad'
                      placeholder='#'
                      value={formik.values.ciclo_cantidad}
                      name='ciclo_cantidad'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <StandardText style={{ textAlign: 'center', fontSize: '1.5rem' }}>
                      x
                    </StandardText>
                  </Grid>
                  <Grid item xs={5}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='ciclo_periodicidad'
                      label='Periodicidad'
                      placeholder='días'
                      value={formik.values.ciclo_periodicidad}
                      name='ciclo_periodicidad'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        disabled={fieldsDisabled}
                        label='Última menstruación'
                        value={formik.values.ultima_menstruacion}
                        onChange={(value): void => {
                          formik.setFieldValue('ultima_menstruacion', value);
                        }}
                        inputFormat='DD/MM/yyyy'
                        renderInput={(params) => (
                          <CMTextfield
                            $nomargin
                            {...params}
                            onBlur={formik.handleBlur}
                            name='ultima_menstruacion'
                            error={
                              formik.touched.ultima_menstruacion &&
                              Boolean(formik.errors.ultima_menstruacion)
                            }
                            helperText={
                              formik.touched.ultima_menstruacion &&
                              formik.errors.ultima_menstruacion
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={fieldsDisabled}
                          onBlur={formik.handleBlur}
                          color='primary'
                          name='polimenorrea'
                          id='polimenorrea'
                          checked={formik.values.polimenorrea}
                          onChange={formik.handleChange}
                        />
                      }
                      label='Polimenorrea'
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={fieldsDisabled}
                          onBlur={formik.handleBlur}
                          color='primary'
                          name='hipermenorrea'
                          id='hipermenorrea'
                          checked={formik.values.hipermenorrea}
                          onChange={formik.handleChange}
                        />
                      }
                      label='Hipermenorrea'
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={fieldsDisabled}
                          onBlur={formik.handleBlur}
                          color='primary'
                          name='disiminorrea'
                          id='disiminorrea'
                          checked={formik.values.disiminorrea}
                          onChange={formik.handleChange}
                        />
                      }
                      label='Dismenorrea (incapacitante)'
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='ivsa'
                      label='IVSA'
                      placeholder='IVSA'
                      value={formik.values.ivsa}
                      name='ivsa'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='numero_parejas'
                      label='Número Parejas'
                      placeholder='# parejas'
                      value={formik.values.numero_parejas}
                      name='numero_parejas'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                      type='number'
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='gesta'
                      label='Gesta'
                      placeholder='Gesta'
                      value={formik.values.gesta}
                      name='gesta'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='para'
                      label='Para'
                      placeholder='Para'
                      value={formik.values.para}
                      name='para'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='abortos'
                      label='¿Abortos?'
                      placeholder='¿Abortos?'
                      value={formik.values.abortos}
                      name='abortos'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='cesareas'
                      label='¿Cesáreas?'
                      placeholder='¿Cesáreas?'
                      value={formik.values.cesareas}
                      name='cesareas'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                      <DesktopDatePicker
                        disabled={fieldsDisabled}
                        label='Última Citopatología (PAP)'
                        value={formik.values.ultima_pap}
                        onChange={(value): void => {
                          formik.setFieldValue('ultima_pap', value);
                        }}
                        inputFormat='DD/MM/yyyy'
                        renderInput={(params) => (
                          <CMTextfield
                            $nomargin
                            {...params}
                            name='ultima_pap'
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.ultima_pap &&
                              Boolean(formik.errors.ultima_pap)
                            }
                            helperText={
                              formik.touched.ultima_pap && formik.errors.ultima_pap
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      id='resultado_ultima_pap'
                      label='Resultado última PAP'
                      placeholder='Resultado última PAP'
                      value={formik.values.resultado_ultima_pap}
                      name='resultado_ultima_pap'
                      onChange={formik.handleChange}
                      onKeyPress={(keyEvent) => {
                        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                          keyEvent.preventDefault();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CMTextfield
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                      $nomargin
                      select
                      id='metodo_planificacion_familiar'
                      label='Método de planificación familiar'
                      value={formik.values.metodo_planificacion_familiar}
                      name='metodo_planificacion_familiar'
                      onChange={formik.handleChange}
                    >
                      <MenuItem disabled value={BirthMethods.Unknown}>
                        Selecciona una opción
                      </MenuItem>
                      {Object.values(BirthMethods)
                        .filter((method) => method !== '')
                        .map((method) => {
                          return (
                            <MenuItem key={method} value={method}>
                              {method}
                            </MenuItem>
                          );
                        })}
                    </CMTextfield>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Entrevista por Aparatos y Sistemas
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  En caso de aplicar, llena la evaluación sobre los diferentes aparatos y
                  sistemas del paciente
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Respiratorio</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='respiratoryDescription'
                  name='respiratoryDescription'
                  value={formik.values.respiratoryDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Sistema cardiovascular</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='cardiovascularDescription'
                  name='cardiovascularDescription'
                  value={formik.values.cardiovascularDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Vascular periférico</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='peripheralVascularDescription'
                  name='peripheralVascularDescription'
                  value={formik.values.peripheralVascularDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Digestivo</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='digestiveDescription'
                  name='digestiveDescription'
                  value={formik.values.digestiveDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Endócrino</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='endocrineDescription'
                  name='endocrineDescription'
                  value={formik.values.endocrineDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Muscular y esquelético</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='muscularDescription'
                  name='muscularDescription'
                  value={formik.values.muscularDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Genital y urinario</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='genitalDescription'
                  name='genitalDescription'
                  value={formik.values.genitalDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>

              <Grid item xs={12}>
                <CMInputLabel mobile>Hematopoyético y linfático</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='lymphaticDescription'
                  name='lymphaticDescription'
                  value={formik.values.lymphaticDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>

              <Grid item xs={12}>
                <CMInputLabel mobile>Piel y anexos</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='skinDescription'
                  name='skinDescription'
                  value={formik.values.skinDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Neurológico y psiquiátrico</CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='neurologicalDescription'
                  name='neurologicalDescription'
                  value={formik.values.neurologicalDescription}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Resultados pasados y diagnósticos
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Llena la información relevante sobre estudios y resultados pasados del
                  paciente, así como el diagnóstico y pronósticos actuales.
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>
                  Resultados previos y actuales de estudios de laboratorio, gabinete y
                  otros
                </CMInputLabel>
                <CMTextArea
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  id='resultados_estudios'
                  name='resultados_estudios'
                  value={formik.values.resultados_estudios}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  multiple
                  id='historia_clinica_diagnosticos'
                  options={cie10Search}
                  noOptionsText='No hay opciones disponibles'
                  getOptionLabel={(option) =>
                    `${option.cie_clave} ${option.cie_descripcion}`
                  }
                  onInputChange={(_event, value) => handleDiagnosisInput(value)}
                  value={formik.values.historia_clinica_diagnosticos}
                  onChange={(_e, value) => {
                    formik.setFieldValue('historia_clinica_diagnosticos', value);
                  }}
                  renderInput={(params) => (
                    <CMTextfield
                      $nomargin
                      {...params}
                      label='Diagnóstico(s)'
                      placeholder='Diagnóstico(s)'
                      name='historia_clinica_diagnosticos'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CMTextfield
                  $nomargin
                  select
                  id='pronostico'
                  name='pronostico'
                  value={formik.values.pronostico}
                  onChange={formik.handleChange}
                  placeholder='Descripción'
                  label='Pronóstico'
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem disabled value=''>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value='Bueno'>Bueno</MenuItem>
                  <MenuItem value='Regular'>Regular</MenuItem>
                  <MenuItem value='Malo'>Malo</MenuItem>
                  <MenuItem value='Reservado'>Reservado</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Indicación terapéutica</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='indicacion_terapeutica'
                  name='indicacion_terapeutica'
                  value={formik.values.indicacion_terapeutica}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

ClinicHistoryForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};

export default ClinicHistoryForm;
