import {
  Grid,
  Tabs,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { BsCheckCircle, BsCircle } from 'react-icons/all';
import Recaptcha from 'react-recaptcha';
import { LoginTab, PageContainer } from './Register.styled';
import CenteredForm from '../../index.styled';
import { User } from '../../interfaces/User';
import { useMainProvider } from '../../context/MainProvider';
import { saveLocalstorageData } from '../../utils/localStorage';
import { PrimaryTitle } from '../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { RegisterInitialValues } from '../../interfaces/Register';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import PasswordTextfield from '../../components/Forms/PasswordTextfield';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import { mainTheme } from '../../utils/theme/theme';

export default function RegisterPage(): JSX.Element {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [userType, setCurrentUserType] = useState<string>('medicos');

  const [passwordFormatActive, setPasswordFormatActive] = useState<boolean>(false);
  const [passwordLenght, setPasswordLenght] = useState<boolean>(false);
  const [lowerCase, setLowerCase] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [number, setNumber] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<boolean>(false);
  const [samePassword, setSamePassword] = useState<boolean>(false);
  const [termsConditions, setTermsConditions] = useState<boolean>(false);

  const { user, setOpenAlert, setAlertMessage, setAlertType, setUser, setLoading } =
    useMainProvider();
  const navigate = useNavigate();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const validationSchema = Yup.object({
    username: Yup.string()
      .email('Escriba un email válido')
      .required('El email es un campo obligatorio'),
    password: Yup.string()
      .required('La contraseña es un campo obligatorio')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Debe contener 8 caracteres, una mayuscula, una minuscula, un número y un caracter especial como mínimo',
      ),
    confirmPasword: Yup.string()
      .required('La contraseña es un campo obligatorio')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir'),
    recaptcha: Yup.string().required('Es necesario llenar el captcha'),
    termsConditions: Yup.boolean()
      .required('Los terminos y condiciones deben ser aceptados.')
      .oneOf([true], 'Los terminos y condiciones deben ser aceptados.'),
    privacyCondition: Yup.boolean()
      .required('El aviso de privacidad debe ser aceptado.')
      .oneOf([true], 'El aviso de privacidad debe ser aceptado.'),
  });

  /**
   *
   * @desc checks for the string format and sets the state for all the needed formats
   * @param { string } the current password value
   */
  const validatePasswordFormat = (
    value: string,
  ): {
    lowerCaseResult: boolean;
    upperCaseResult: boolean;
    numberResult: boolean;
    symbolResult: boolean;
  } => {
    const passwordLenghtResult = value.length >= 8;
    const lowerCaseResult = /.*[a-z].*/.test(value);
    const upperCaseResult = /.*[A-Z].*/.test(value);
    const numberResult = /\d/.test(value);
    const symbolResult = /[*@!#%&()^~{}]+/.test(value);
    setPasswordLenght(passwordLenghtResult);
    setLowerCase(lowerCaseResult);
    setUpperCase(upperCaseResult);
    setNumber(numberResult);
    setSymbol(symbolResult);
    return {
      lowerCaseResult,
      upperCaseResult,
      numberResult,
      symbolResult,
    };
  };

  const validateSamePassword = (
    value: string,
    passwordValue: string,
  ): {
    samePasswordResult: boolean;
  } => {
    const samePasswordResult = value === passwordValue;
    setSamePassword(samePasswordResult);
    return {
      samePasswordResult,
    };
  };

  const formik = useFormik<RegisterInitialValues>({
    initialValues: {
      username: '',
      password: '',
      confirmPasword: '',
      recaptcha: '',
      termsConditions: false,
      privacyCondition: false,
    },
    validationSchema,
    /**
     * @desc saves a new user in the database
     * @param registerValues
     */
    onSubmit: async (registerValues) => {
      try {
        if (registerValues.password === registerValues.confirmPasword) {
          setLoading(true);
          const registerResult = await axios.post<{ user: User; token: string }>(
            `${process.env.REACT_APP_SERVER_URL}/register-user`,
            {
              user: {
                username: registerValues.username,
                password: registerValues.password,
                isActive: 1,
                typeId: currentTab + 1,
              },
            },
          );
          if (registerResult.status === 200) {
            formik.resetForm();
            setAlertType(AlertTypes.Success);
            setAlertMessage('Usuario creado con exito');
            setOpenAlert(true);
            const newUser = {
              ...registerResult.data.user,
              token: registerResult.data.token,
            };
            saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', newUser);
            setUser(newUser);
          }
          setLoading(false);
        } else {
          formik.touched.password = true;
          formik.errors.confirmPasword = 'Las contraseñas no coinciden';
          formik.touched.confirmPasword = true;
          formik.errors.confirmPasword = 'La contraseñas no coinciden';
        }
      } catch (e: any) {
        if (JSON.stringify(e.response.data)) {
          if (e.response.data.code && e.response.data.code === 'ER_DUP_ENTRY') {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(
              `Error al iniciar sesión: Este correo ya se encuentra en uso, utilice un correo distinto`,
            );
            setOpenAlert(true);
          } else {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(
              `Error al iniciar sesión: ${JSON.stringify(e.response.data)} `,
            );
            setOpenAlert(true);
          }
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  useEffect(() => {
    if (isIOSApp() && !formik.values.recaptcha) {
      formik.setFieldValue('recaptcha', '123');
    }
  }, [formik]);

  const handleTabChange = (tabNumber: number) => {
    setCurrentTab(tabNumber);
    if (tabNumber === 0) setCurrentUserType('medicos');
    if (tabNumber === 1) setCurrentUserType('pacientes');
    if (tabNumber === 2) setCurrentUserType('enfermeros(as)');
  };

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user, navigate]);

  return (
    <PageContainer style={{ paddingTop: '1.5rem' }}>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5} lg={3}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <Tabs
                  value={currentTab}
                  onChange={(_e, value: number) => handleTabChange(value)}
                  aria-label='basic tabs example'
                >
                  <LoginTab label='Medicos' />
                  <LoginTab label='Paciente' />
                  <LoginTab label='Enfermera' />
                </Tabs>

                <PrimaryTitle style={{ textAlign: 'center', marginTop: '2rem' }}>
                  Registro para {userType}
                </PrimaryTitle>
                <PrimaryDescription mobile={mobile} style={{ marginBottom: '2.5rem' }}>
                  Antes de comenzar primero debes crear una cuenta
                </PrimaryDescription>

                <CMTextfield
                  capitalized
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  value={formik.values.username}
                  name='username'
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                  onChange={formik.handleChange}
                />
                <PasswordTextfield
                  id='outlined-basic-password'
                  label='Contraseña'
                  variant='outlined'
                  value={formik.values.password}
                  name='password'
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  onChange={(e) => {
                    validatePasswordFormat(e.target.value);
                    formik.handleChange(e);
                  }}
                  onFocus={() => setPasswordFormatActive(true)}
                />
                <PasswordTextfield
                  id='outlined-basic-password-1'
                  label='Confirmar contraseña'
                  variant='outlined'
                  value={formik.values.confirmPasword}
                  name='confirmPasword'
                  error={
                    formik.touched.confirmPasword && Boolean(formik.errors.confirmPasword)
                  }
                  helperText={
                    formik.touched.confirmPasword && formik.errors.confirmPasword
                  }
                  onChange={(e) => {
                    validateSamePassword(e.target.value, formik.values.password);
                    formik.handleChange(e);
                  }}
                />
                {passwordFormatActive && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                    style={{
                      textAlign: 'start',
                      width: '100%',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '12px',
                      backgroundColor: '#fafafa',
                      padding: '1rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <div>
                      <p style={{ marginTop: '0', marginBottom: '.8rem' }}>
                        {' '}
                        La contraseña debe tener minimo{' '}
                      </p>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {passwordLenght ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: passwordLenght ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          8 caracteres{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {lowerCase ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: lowerCase ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Una minuscula{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {upperCase ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: upperCase ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Una mayuscula{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {number ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: number ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Un número{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {symbol ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: symbol ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Un simbolo{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {samePassword ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: samePassword ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Las contraseñas deben coincidir{' '}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                )}
                {!isIOSApp() ? (
                  <Recaptcha
                    hl='es'
                    sitekey='6Lepqn8eAAAAAEKyrn_qs960q8KQT_WPdGH0XPiS'
                    verifyCallback={(response) => {
                      formik.setFieldValue('recaptcha', response);
                    }}
                  />
                ) : null}
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        name='termsConditions'
                        id='termsConditions'
                        checked={formik.values.termsConditions}
                        onChange={formik.handleChange}
                        disabled={false}
                        required
                      />
                    }
                    label={
                      <div>
                        <p>
                          Al registrarse está aceptando los{' '}
                          <a
                            href={`${process.env.REACT_APP_SERVER_URL}/files/Terminos y Condiciones.pdf`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            Terminos y condiciones
                          </a>{' '}
                          de{' '}
                          <strong
                            style={{
                              color: mainTheme.colors.primaryButton.normalBackground,
                            }}
                          >
                            {' '}
                            Zivot{' '}
                          </strong>
                        </p>
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        name='privacyCondition'
                        id='privacyCondition'
                        checked={formik.values.privacyCondition}
                        onChange={formik.handleChange}
                        disabled={false}
                        required
                      />
                    }
                    label={
                      <div>
                        <p>
                          He leído, acepto y autorizo lo dispuesto en el aviso de
                          privacidad, con respecto a mis datos personales, incluyendo la
                          información médica.
                        </p>
                      </div>
                    }
                  />
                </Grid>

                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  Registrarse
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
