/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import { useReactToPrint } from 'react-to-print';
import { useFormik } from 'formik';
import moment from 'moment';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { Patient } from '../../interfaces/Patients';
import { postWithToken, gettWithToken } from '../../utils/server';
import SpecialCardsDocument from './SpecialCardsDocument';
import { Doctor } from '../../interfaces/Doctors';
import { SpecialCards } from '../../interfaces/SpecialCards';
import CenteredForm from '../../index.styled';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import { RequiredMessage, SelectValidOption } from '../../utils/validationMessages';
import { SpecialCardContent } from '../../interfaces/SpecialCardContent';
import { SpecialCardsType } from '../../interfaces/SpecialCardsTypes';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import { canPrintNote } from '../../utils/fns';
import PatientData from '../../components/PatientData/PatientData';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';
import EmptyClosure from '../../utils/closures';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: SpecialCards;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: SpecialCards = {
  servicio: '',
  habitacion: '',
  medico_tratante: '',
  expediente: '',
  diagnostico: '',
  lugar: '',
  institucion: '',
  iden_paciente: '',
  resp: '',
  iden_resp: '',
  par_resp: '',
  rep_legal: '',
  iden_rep_legal: '',
  par_rep_legal: '',
  testigo1: '',
  iden_testigo1: '',
  testigo2: '',
  iden_testigo2: '',
  riesgos: '',
  alternativas: '',
  denegacion: false,
  id_tipo: -1,
};

function SpecialCardsForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    user,
    getSpecialCardTypes,
    specialCardTypes,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const [formValues, setFormValues] = useState(initialValues);
  const [doctorInformation, setDoctorInformation] = useState<Doctor | null>(null);
  const [patientInformation, setPatientInformation] = useState<Patient | null>(null);
  const [specialCardContent, setSpecialCardContent] = useState<SpecialCardContent[]>([]);
  const printRef = useRef<HTMLInputElement>(null);
  const [typeAut, setTypeAut] = useState<SpecialCardsType[]>([]);
  const [selectedCardType, setSelectedCardType] = useState<SpecialCardsType | null>(null);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.id_medico !== user?.id,
    [mode, formValues, user?.id],
  );

  const validationSchema = Yup.object({
    id_tipo: Yup.number()
      .required(RequiredMessage)
      .test('id_tipo', SelectValidOption(1, 'tipo de carta'), (val) => {
        return val !== -1;
      }),
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  const formik = useFormik<SpecialCards>({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (specialCardValues) => {
      try {
        setLoading(true);
        const specialCardResult = await postWithToken<
          {
            specialCard: SpecialCards;
            doctorId?: number;
            patientId?: number;
          },
          SpecialCards
        >(
          `/cards/${mode === CRUDModes.Create ? 'save' : 'update'}-special-card`,
          {
            specialCard: {
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
              ...specialCardValues,
              id_paciente:
                currentPatient && mode === CRUDModes.Create
                  ? currentPatient.id
                  : undefined,
              id_medico: user?.id,
            },
            doctorId: user?.id,
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          handleLogout,
        );
        if (specialCardResult.success) {
          setLoading(false);
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          setFormValues(initialValues);
          setSelectedCardType(null);
          setSpecialCardContent([]);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e) {
        handleLogout(e);
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`${e}`);
        setOpenAlert(true);
      }
    },
  });

  const handleTypeInput = (value: string) => {
    const filteredTypes = specialCardTypes
      .filter((singleTyp) => {
        return `${singleTyp.titulo}`.toLowerCase().includes(value.toLowerCase());
      })
      .slice(0, 100);
    setTypeAut(filteredTypes);
  };

  const getDoctorInformation = useCallback(async () => {
    try {
      const doctorResult = await postWithToken<
        {
          doctorId?: number;
        },
        Doctor
      >(
        '/doctor/get-doctor',
        {
          doctorId: user?.id,
        },
        handleLogout,
      );
      if (doctorResult.success) {
        setDoctorInformation(doctorResult.data);
      }
    } catch (e) {
      handleLogout(e);
      console.log(e);
    }
  }, [handleLogout, user?.id]);

  const getSpecialCardContent = useCallback(
    async (cardId: number) => {
      try {
        setLoading(true);
        const specialCardContentResult = await gettWithToken<SpecialCardContent[]>(
          `/cards/get-special-card-content?cardId=${cardId}`,
          handleLogout,
        );
        if (specialCardContentResult.success) {
          setLoading(false);
          setSpecialCardContent(specialCardContentResult.data);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al obtener informacion`);
          setOpenAlert(true);
        }
      } catch (e) {
        handleLogout(e);
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`${e}`);
        setOpenAlert(true);
      }
    },
    [handleLogout, setAlertMessage, setAlertType, setLoading, setOpenAlert],
  );

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
        getDoctorInformation().then(EmptyClosure).catch(EmptyClosure);
      }
    } else if (specialCardTypes.length) {
      setPatientInformation(values.paciente as Patient);
      setDoctorInformation(values.medico as Doctor);
      setFormValues(values);
      if (values.id_tipo) {
        for (let i = 0; i < specialCardTypes.length; i += 1) {
          if (values.id_tipo === specialCardTypes[i].id) {
            setSelectedCardType(specialCardTypes[i]);
            getSpecialCardContent(specialCardTypes[i].id)
              .then(EmptyClosure)
              .catch(EmptyClosure);
            break;
          }
        }
      }
    }
  }, [
    currentPatient,
    getDoctorInformation,
    getSpecialCardContent,
    specialCardTypes,
    values,
  ]);

  useEffect(() => {
    getSpecialCardTypes();
  }, [getSpecialCardTypes]);

  useEffect(() => {
    if (specialCardTypes) {
      setTypeAut(specialCardTypes.slice(0, 100));
    }
  }, [specialCardTypes]);

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setSelectedCardType(null);
          setSpecialCardContent([]);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setSelectedCardType(null);
          setSpecialCardContent([]);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='carta de consentimiento especifica'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Carta de consentimiento especifica
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Revise que la información del paciente y doctor sean correctas
                </PrimaryDescription>
              </Grid>

              {doctorInformation && patientInformation ? (
                <Grid item xs={12}>
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      openOnFocus
                      id='id_tipo'
                      filterOptions={(x) => x}
                      options={typeAut}
                      noOptionsText='No hay opciones disponibles'
                      getOptionLabel={(option) => `${option.titulo}`}
                      onInputChange={(_event, value) => handleTypeInput(value)}
                      onChange={(_e, value) => {
                        formik.setFieldValue('id_tipo', value?.id);
                        setSelectedCardType(value);
                        if (value && value.id) {
                          getSpecialCardContent(value.id)
                            .then(EmptyClosure)
                            .catch(EmptyClosure);
                        }
                      }}
                      value={selectedCardType}
                      renderInput={(params) => (
                        <CMTextfield
                          {...params}
                          onBlur={formik.handleBlur}
                          label='Tipo de carta'
                          placeholder='Tipo de carta'
                          name='id_tipo'
                        />
                      )}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  {doctorInformation && patientInformation && values?.id_tipo !== -1 ? (
                    <Grid
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <SpecialCardsDocument
                        patientIdentification={formik.values.iden_paciente}
                        rep={formik.values.resp}
                        repIdentification={formik.values.iden_resp}
                        par_rep={formik.values.par_resp}
                        repLegal={formik.values.rep_legal}
                        repLegalIdentification={formik.values.iden_rep_legal}
                        par_rep_legal={formik.values.par_rep_legal}
                        testigo1={formik.values.testigo1}
                        testigo1Identificacion={formik.values.iden_testigo1}
                        testigo2={formik.values.testigo2}
                        testigo2Identificacion={formik.values.iden_testigo2}
                        cardTitle={selectedCardType?.titulo as string}
                        patient={patientInformation}
                        doctor={doctorInformation}
                        date={values?.fecha}
                        valuesSetted={!!values}
                        specialCardContent={specialCardContent}
                        riesgos={formik.values.riesgos}
                        alternativas={formik.values.alternativas}
                        denegacion={formik.values.denegacion}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='resp'
                      label='Familiar responsable'
                      placeholder='Familiar responsable'
                      value={formik.values.resp}
                      name='resp'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                    <CMTextfieldHalfRight
                      id='iden_resp'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_resp}
                      name='iden_resp'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfield
                      id='par_resp'
                      onBlur={formik.handleBlur}
                      label='Parentesco del familiar responsable'
                      placeholder='Parentesco del familiar responsable'
                      value={formik.values.par_resp}
                      name='par_resp'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='rep_legal'
                      label='Representante legal'
                      placeholder='Representante legal'
                      value={formik.values.rep_legal}
                      name='rep_legal'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                    <CMTextfieldHalfRight
                      id='iden_rep_legal'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_rep_legal}
                      name='iden_rep_legal'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfield
                      id='par_rep_legal'
                      label='Parentesco del representante legal'
                      placeholder='Parentesco del representante legal'
                      value={formik.values.par_rep_legal}
                      name='par_rep_legal'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMInputLabel mobile>Riesgos personalizados</CMInputLabel>
                    <CMTextArea
                      id='riesgos'
                      minRows={2}
                      placeholder='Riesgos personalizados'
                      value={formik.values.riesgos}
                      name='riesgos'
                      onChange={formik.handleChange}
                      style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <CMInputLabel mobile>Otras alternativas</CMInputLabel>
                    <CMTextArea
                      id='alternativas'
                      minRows={2}
                      placeholder='Otras aternativas'
                      value={formik.values.alternativas}
                      name='alternativas'
                      onChange={formik.handleChange}
                      style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='testigo1'
                      label='Testigo 1'
                      placeholder='Testigo 1'
                      value={formik.values.testigo1}
                      name='testigo1'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                    <CMTextfieldHalfRight
                      id='iden_testigo1'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_testigo1}
                      name='iden_testigo1'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfieldHalfLeft
                      id='testigo2'
                      label='Testigo 2'
                      placeholder='Testigo 2'
                      value={formik.values.testigo2}
                      name='testigo2'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                    <CMTextfieldHalfRight
                      id='iden_testigo2'
                      label='Identificado con'
                      placeholder='Identificado con'
                      value={formik.values.iden_testigo2}
                      name='iden_testigo2'
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <CMTextfield
                      id='medico_tratante'
                      label='Médico tratante'
                      placeholder='Médico tratante'
                      value={`${doctorInformation.med_nombre} ${doctorInformation.med_apellidop} ${doctorInformation.med_apellidom}`}
                      name='medico_tratante'
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color='primary'
                          name='denegacion'
                          id='denegacion'
                          checked={formik.values.denegacion}
                          onChange={formik.handleChange}
                          disabled={fieldsDisabled}
                        />
                      }
                      label='Denegación'
                      style={{ marginBottom: '1rem' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <>
                      {values &&
                      values.medico &&
                      mode !== CRUDModes.Create &&
                      values.paciente &&
                      values.id_medico &&
                      user &&
                      canPrintNote(values.paciente, values.id_medico, user) &&
                      values.id_tipo !== -1 ? (
                        !isIOSApp() ? (
                          <PrimaryButton
                            loading={loading}
                            type='button'
                            style={{ width: '100%', marginBottom: '1rem' }}
                            onClick={() => handlePrint()}
                          >
                            Imprimir
                          </PrimaryButton>
                        ) : (
                          <MobileDownloadMessage />
                        )
                      ) : null}
                    </>
                  </Grid>
                  {!fieldsDisabled ? (
                    <Grid item xs={12}>
                      <PrimaryButton
                        loading={loading}
                        type='button'
                        onClick={formik.handleSubmit}
                        style={{ width: '100%' }}
                      >
                        {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                      </PrimaryButton>
                      {mode === CRUDModes.Update ? (
                        <PrimaryButton
                          loading={loading}
                          type='button'
                          onClick={async () => {
                            await formik.setFieldValue('isActive', false);
                            formik.handleSubmit();
                          }}
                          style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                        >
                          Eliminar
                        </PrimaryButton>
                      ) : null}
                    </Grid>
                  ) : null}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                    No hay información del paciente o doctor disponible
                  </PrimaryDescription>
                </Grid>
              )}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

SpecialCardsForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default SpecialCardsForm;
