import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useSponsorsProvider } from '../../../Context/SponsorsMainContext';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../../../../interfaces/GenericTable';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { postWithToken } from '../../../../../utils/server';
import { AlertTypes } from '../../../../../interfaces/MainContextInitialValues';
import { setSponsorHeaders } from '../../../../../utils/headerSetter';
import EmptyClosure from '../../../../../utils/closures';
import GenericTablePage from '../../../../GenericTablePage/GenericTablePage';
import { SimplifiedSponsorSegment, SponsorSegment } from '../SponsorSegments.Interfaces';
import SponsorsSegmentsPopupForm from '../Form/SponsorSegmentsPopup';

const SponsorSegmentsTable = (): JSX.Element => {
  const {
    setLoading,
    setOpenAlert,
    setAlertType,
    setAlertMessage,
    token,
    handleLogoutError,
  } = useSponsorsProvider();
  const [entries, setEntries] = useState<SponsorSegment[]>();
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<number>();
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);

  const handleDeactivate = useCallback(
    async (entry: SponsorSegment) => {
      try {
        setLoading(true);
        const result = await postWithToken<SponsorSegment, null>(
          `/sponsors/update-segments`,
          { ...entry, is_active: !entry.is_active },
          handleLogoutError,
          true,
        );
        if (!result.success) {
          throw new Error(result.error);
        }
        setAlertType(AlertTypes.Success);
        setAlertMessage(
          `Segmento ${entry.is_active ? 'desactivado' : 'activado'} con éxito`,
        );
        setOpenAlert(true);
        setLoading(false);
        setEntries(undefined);
      } catch (e: any) {
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al guardar información: ${e?.message || 'Desconocido'}`);
        setOpenAlert(true);
        setLoading(false);
      }
    },
    [handleLogoutError, setAlertMessage, setAlertType, setLoading, setOpenAlert],
  );

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await axios.get<SponsorSegment[]>(
        `${process.env.REACT_APP_SERVER_URL}/sponsors/segments`,
        setSponsorHeaders(false, token),
      );
      if (fetchResult.status === 200) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha_modificacion',
            displayName: 'Fecha de modificación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'is_active',
            displayName: 'Activo',
            type: GenericColumnTypes.Boolean,
            sortable: false,
          },
          {
            name: 'name',
            displayName: 'Nombre',
            type: GenericColumnTypes.Text,
            sortable: true,
          },
          {
            name: 'type_id',
            displayName: 'ID usuario objetivo',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: 'Consultar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
          {
            name: 'delete',
            displayName: 'Desactivar/Activar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              handleDeactivate(orderedRows[index]);
            },
          },
        ]);
        const simplifiedData: SimplifiedSponsorSegment[] = orderedRows.map((entry) => {
          return {
            id: entry.id,
            fecha_modificacion: entry.fecha_modificacion,
            is_active: entry.is_active,
            name: entry.name,
            type_id: entry.type_id,
            moreInformation: 'Consultar',
            delete: entry.is_active ? 'Desactivar' : 'Activar',
          };
        });
        console.log(simplifiedData);
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      setEntries([]);
      handleLogoutError(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener lista de segmentos: ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const fetchCallback = useCallback(fetchEntries, [
    handleDeactivate,
    handleLogoutError,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    token,
  ]);

  useEffect(() => {
    if (!entries) {
      fetchCallback().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [fetchCallback, entries]);

  return (
    <>
      <SponsorsSegmentsPopupForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={
          selectedRow !== undefined && selectedRow !== -1
            ? (entries || [])[selectedRow]
            : undefined
        }
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      <GenericTablePage
        title='Lista de segmentos de público'
        rows={rows}
        columns={columns}
        canCreate
        canDelete={false}
        canEdit
        onCreate={() => {
          setOpen(true);
          setSelectedRow(-1);
          setMode(CRUDModes.Create);
        }}
      />
    </>
  );
};

export default SponsorSegmentsTable;
