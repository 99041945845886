import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import { getDoctorFullName, getNurseFullName, getPatientFullName } from '../../utils/fns';
import { useMainProvider } from '../../context/MainProvider';
import { UserTypes } from '../../interfaces/User';
import { Nurse } from '../../interfaces/Nurses';
import { ExtendedReceta, RecetaMedicamentoForm } from '../../interfaces/Receta';
import PatientData from '../../components/PatientData/PatientData';

function RecetaPrintableDocument({
  patient,
  doctor,
  values,
  selectedMedicamentos,
}: {
  patient: Patient;
  doctor: Doctor;
  values: ExtendedReceta;
  selectedMedicamentos: RecetaMedicamentoForm[];
}): JSX.Element {
  const { user } = useMainProvider();
  const [name, setName] = useState('');

  useEffect(() => {
    if (user && user.initialData) {
      if (user.typeId === UserTypes.PatientType) {
        setName(getPatientFullName(user.initialData as Patient).toUpperCase());
      }
      if (user.typeId === UserTypes.DoctorType) {
        setName(getDoctorFullName(user.initialData as Doctor).toUpperCase());
      }
      if (user.typeId === UserTypes.NurseType) {
        setName(getNurseFullName(user.initialData as Nurse).toUpperCase());
      }
    }
  }, [user, user?.initialData]);

  useEffect(() => {
    moment.locale('es');
  }, [patient]);

  return (
    <table className='table-full-width'>
      <tbody>
        <tr>
          <td>
            <table className='table-full-width'>
              <tbody>
                <tr>
                  <td>
                    <span>
                      <p style={{ textAlign: 'end', fontSize: '10px' }}>
                        <strong>
                          Fecha y Hora de impresión:{' '}
                          {moment().locale('ES').format('DD MMMM YYYY h:mm:ss a')}
                          <br />
                        </strong>
                      </p>

                      <p style={{ textAlign: 'right', fontSize: '10px' }}>
                        <strong>
                          {doctor.med_consultorio?.toUpperCase()}
                          <br />
                          {doctor.med_raz_social?.toUpperCase()},{' '}
                          {doctor.med_no_registro?.toUpperCase()}
                          <br />
                          {doctor.med_domicilio?.toUpperCase()},
                          {doctor.med_colonia_consultorio?.toUpperCase()},{' '}
                          {doctor.med_municipio_consultorio?.toUpperCase()},{' '}
                          {doctor.med_ciudad_consultorio?.toUpperCase()},
                          {doctor.med_cp_consultorio}
                          <br />
                          Tel. {doctor.med_telefono_consultorio}
                        </strong>
                      </p>
                      <br />
                      <p
                        style={{
                          textAlign: 'center',
                          marginBottom: '16px',
                          fontSize: '12px',
                        }}
                      >
                        <strong>Receta</strong>
                      </p>

                      <p style={{ textAlign: 'start', fontSize: '10px' }}>
                        <strong>
                          Fecha y Hora de elaboración de la Nota:{' '}
                          {moment(values.fecha_creacion)
                            .locale('ES')
                            .format('DD MMMM YYYY h:mm:ss a')}
                        </strong>
                      </p>

                      <p style={{ textAlign: 'start', fontSize: '10px' }}>
                        <PatientData patientInformation={patient} forceDesktop />
                      </p>

                      <div
                        className='receta-data-container'
                        style={{ marginBottom: '1rem' }}
                      >
                        <table className='table-full-width'>
                          <tr>
                            <th align='left' className='th'>
                              Medicamento
                            </th>
                            <th align='left' className='th'>
                              Dosis
                            </th>
                            <th align='left' className='th'>
                              Vía
                            </th>
                            <th align='left' className='th'>
                              Periodicidad
                            </th>
                            <th align='left' className='th'>
                              No. Cajas / presentación
                            </th>
                            <th align='left' className='th'>
                              Duración tratamiento (días)
                            </th>
                          </tr>
                          {selectedMedicamentos.map((medicamento) => (
                            <tr key={medicamento.medicamento?.id}>
                              <td align='left' className='th'>
                                {medicamento.medicamento?.DESCRIPCION}
                              </td>
                              <td align='left' className='th'>
                                {medicamento.dosis}
                              </td>
                              <td align='left' className='th'>
                                {medicamento.via}
                              </td>
                              <td align='left' className='th'>
                                {medicamento.periodicidad}
                              </td>
                              <td align='left' className='th'>
                                {medicamento.numero_cajas}
                              </td>
                              <td align='left' className='th'>
                                {medicamento.numero_dias}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </span>

                    {values.medicamentos_fuera_cuadro ? (
                      <>
                        <p className='general-p'>
                          <b>Medicamentos fuera del cuadro médico</b>
                        </p>
                        <p className='general-p' style={{ marginBottom: '1rem' }}>
                          {values.medicamentos_fuera_cuadro}
                        </p>
                      </>
                    ) : null}

                    {values.cuidados_generales ? (
                      <>
                        <p className='general-p'>
                          <b>Cuidados Generales</b>
                        </p>
                        <p className='general-p' style={{ marginBottom: '1rem' }}>
                          {values.cuidados_generales}
                        </p>
                      </>
                    ) : null}

                    {values.continuacion_tratamiento ? (
                      <>
                        <p className='general-p'>
                          <b>Continuación del tratamiento farmacológico</b>
                        </p>
                        <p className='general-p' style={{ marginBottom: '1rem' }}>
                          {values.continuacion_tratamiento}
                        </p>
                      </>
                    ) : null}

                    {values.indicaciones ? (
                      <>
                        <p className='general-p'>
                          <b>Indicaciones higiénico dietéticas</b>
                        </p>
                        <p className='general-p' style={{ marginBottom: '1rem' }}>
                          {values.indicaciones}
                        </p>
                      </>
                    ) : null}

                    {values.fecha_proxima_cita ? (
                      <>
                        <p className='general-p'>
                          <b>Fecha de próxima cita</b>
                        </p>
                        <p className='general-p' style={{ marginBottom: '1rem' }}>
                          {moment(values.fecha_proxima_cita)
                            .locale('ES')
                            .format('DD MMMM YYYY h:mm:ss a')}
                        </p>
                      </>
                    ) : null}

                    {values.comentarios ? (
                      <>
                        <p className='general-p'>
                          <b>Comentarios adicionales</b>
                        </p>
                        <p className='general-p' style={{ marginBottom: '1rem' }}>
                          {values.comentarios}
                        </p>
                      </>
                    ) : null}

                    <p className='general-p' style={{ marginTop: '1rem' }}>
                      <span style={{ textTransform: 'capitalize' }}>
                        {doctor.med_nombre} {doctor.med_apellidop} {doctor.med_apellidom}
                      </span>
                    </p>
                    <p className='general-p'>
                      <span style={{ textTransform: 'capitalize' }}>
                        Nombre y firma del médico que elaboró la nota:
                        ____________________________________________
                      </span>
                    </p>
                    <p className='general-p'>
                      <span style={{ textTransform: 'capitalize' }}>
                        Especialidad: {doctor.med_especialidad?.cat_especialidad_desc}
                      </span>
                    </p>
                    <p className='general-p'>
                      <span style={{ textTransform: 'capitalize' }}>
                        Cédula: {doctor.med_cedula_profesional}{' '}
                        {doctor.med_institucion_expedio_cedula}
                      </span>
                    </p>

                    {doctor.med_especialidad_id !== 37 ? (
                      <>
                        <p className='general-p'>
                          <span style={{ textTransform: 'capitalize' }}>
                            Cédula especialidad: {doctor.med_cedula_especialidad}{' '}
                            {doctor.med_institucion_expidio_cedula_esp}
                          </span>
                        </p>
                      </>
                    ) : null}

                    <p className='general-p' style={{ textTransform: 'none' }}>
                      <strong>
                        Aviso de privacidad
                        <br />
                      </strong>
                    </p>
                    <p className='general-p' style={{ textTransform: 'none' }}>
                      Para ver nuestro Aviso de privacidad favor consultar:
                      http://zivot.com.mx/aviso-privacidad
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default RecetaPrintableDocument;
