import React, { useEffect } from 'react';
import axios from 'axios';
import { AdInformation } from '../interfaces/Ads';
import { setHeaders } from '../utils/headerSetter';
import { useMainProvider } from '../context/MainProvider';
import EmptyClosure from '../utils/closures';

const useAds = (locationId?: number): AdInformation | null | undefined => {
  const [adInfo, setAdInfo] = React.useState<AdInformation | null>();
  const { user } = useMainProvider();

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get<AdInformation | null>(
          `${process.env.REACT_APP_SERVER_URL}/get-available-ad/${locationId}`,
          setHeaders(false, user ? user.token : undefined),
        );
        setAdInfo(response.data);
      } catch (e) {
        setAdInfo(null);
        console.log(e);
      }
    };

    if (adInfo === undefined && locationId && user) {
      fetchAds().then(EmptyClosure).catch(EmptyClosure);
    }
  }, [adInfo, locationId, user]);

  return adInfo;
};

export default useAds;
