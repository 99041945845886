import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import { Nurse } from '../../interfaces/Nurses';
import { useMainProvider } from '../../context/MainProvider';
import { UserTypes } from '../../interfaces/User';
import { SpecialCardContent } from '../../interfaces/SpecialCardContent';
import { getDoctorFullName, getNurseFullName, getPatientFullName } from '../../utils/fns';

function SpecialCardsDocument({
  patientIdentification,
  rep,
  repIdentification,
  par_rep,
  repLegal,
  repLegalIdentification,
  par_rep_legal,
  testigo1,
  testigo1Identificacion,
  testigo2,
  testigo2Identificacion,
  cardTitle,
  patient,
  doctor,
  date,
  valuesSetted,
  specialCardContent,
  riesgos,
  alternativas,
  denegacion,
}: {
  patientIdentification: string;
  rep: string;
  repIdentification: string;
  par_rep_legal: string;
  repLegal: string;
  repLegalIdentification: string;
  par_rep: string;
  testigo1: string;
  testigo1Identificacion: string;
  testigo2: string;
  testigo2Identificacion: string;
  cardTitle: string;
  patient: Patient;
  doctor: Doctor;
  date?: string;
  valuesSetted: boolean;
  specialCardContent: SpecialCardContent[];
  riesgos: string;
  alternativas: string;
  denegacion: boolean;
}): JSX.Element {
  const { user } = useMainProvider();
  const [name, setName] = useState('');
  const [formattedContent, setFormattedContent] = useState<SpecialCardContent[]>([]);

  useEffect(() => {
    moment.locale('es');
    if (user && user.initialData) {
      if (user.typeId === UserTypes.PatientType) {
        setName(getPatientFullName(user.initialData as Patient).toUpperCase());
      }
      if (user.typeId === UserTypes.DoctorType) {
        setName(getDoctorFullName(user.initialData as Doctor).toUpperCase());
      }
      if (user.typeId === UserTypes.NurseType) {
        setName(getNurseFullName(user.initialData as Nurse).toUpperCase());
      }
    }
  }, [user]);

  useEffect(() => {
    console.log(specialCardContent);
    const formattedItems = [];
    for (let i = 0; i < specialCardContent.length; i += 1) {
      if (
        specialCardContent[i] &&
        specialCardContent[i].tipo === 'SUB' &&
        specialCardContent[i + 1] &&
        specialCardContent[i + 1].tipo === 'TEX'
      ) {
        formattedItems.push(specialCardContent[i]);
      } else if (specialCardContent[i] && specialCardContent[i].tipo === 'TEX') {
        formattedItems.push(specialCardContent[i]);
      }
    }
    setFormattedContent(formattedItems);
  }, [specialCardContent]);

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <tbody>
        <tr>
          <td>
            <table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <p style={{ textAlign: 'end' }}>
                      <strong>
                        Fecha y Hora de impresión:{' '}
                        {moment().locale('ES').format('DD MMMM YYYY h:mm:ss a')}
                        <br />
                      </strong>
                    </p>

                    <p style={{ textAlign: 'right' }}>
                      <strong>
                        {doctor.med_consultorio?.toUpperCase()}
                        <br />
                        {doctor.med_raz_social?.toUpperCase()},{' '}
                        {doctor.med_no_registro?.toUpperCase()}
                        <br />
                        {doctor.med_domicilio?.toUpperCase()},
                        {doctor.med_colonia_consultorio?.toUpperCase()},{' '}
                        {doctor.med_municipio_consultorio?.toUpperCase()},{' '}
                        {doctor.med_ciudad_consultorio?.toUpperCase()},
                        {doctor.med_cp_consultorio}
                        <br />
                        Tel. {doctor.med_telefono_consultorio}
                      </strong>
                    </p>
                    <br />

                    <p style={{ textAlign: 'center' }}>
                      <strong>
                        {' '}
                        CARTA DE CONSENTIMIENTO INFORMADO ESPECIFICO DE PROCEDIMIENTO
                        MÉDICO Y/O QUIRÚRGICO
                      </strong>
                      <br />
                      <br />
                    </p>

                    <p style={{ textAlign: 'center' }}>
                      <strong>{cardTitle}</strong>
                      <br />
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Nombre: </strong> {patient.pac_nombre?.toUpperCase()}{' '}
                      {patient.pac_apellidop?.toUpperCase()}{' '}
                      {patient.pac_apellidom?.toUpperCase()}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Sexo: </strong> {patient.pac_sexo?.cat_sexo_desc}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Edad: </strong>{' '}
                      {parseInt(
                        moment()
                          .diff(patient.pac_fechanacimiento, 'years', true)
                          .toString(),
                        10,
                      )}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Fecha de nacimiento: </strong>{' '}
                      {moment(patient.pac_fechanacimiento)
                        .locale('ES')
                        .format('DD/MM/YYYY')}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Medico tratante: </strong> Dr.{' '}
                      {doctor.med_apellidop?.toUpperCase()}{' '}
                      {doctor.med_apellidom?.toUpperCase()}{' '}
                      {doctor.med_nombre?.toUpperCase()}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Fecha y Hora de Elaboración: </strong>{' '}
                      {valuesSetted ? (
                        <>
                          {moment(date?.toString())
                            .locale('ES')
                            .format('DD MMMM YYYY h:mm:ss a')}
                        </>
                      ) : (
                        <>{moment().locale('ES').format('DD MMMM YYYY h:mm:ss a')}</>
                      )}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Lugar: </strong> {doctor.med_domicilio?.toUpperCase()}, Col.{' '}
                      {doctor.med_colonia_consultorio?.toUpperCase()},{' '}
                      {doctor.med_ciudad_consultorio?.toUpperCase()},{' '}
                      {doctor.med_estado_consultorio?.cat_estado_desc}. C.P.{' '}
                      {doctor.med_cp_consultorio?.toUpperCase()}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Razón social: </strong>{' '}
                      {doctor.med_raz_social?.toUpperCase()}
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Licencia sanitaria: </strong>{' '}
                      {doctor.med_no_registro?.toUpperCase()}
                      <br />
                      <br />
                      <br />
                    </p>
                    <span>
                      {formattedContent.map((singleContent) => {
                        if (singleContent.tipo === 'SUB') {
                          return (
                            <p style={{ textAlign: 'start' }} key={singleContent.id}>
                              <strong>{singleContent.elemento}</strong>
                            </p>
                          );
                        }

                        return (
                          <p style={{ textAlign: 'start' }} key={singleContent.id}>
                            {singleContent.elemento}
                            <br />
                            <br />
                            <br />
                          </p>
                        );
                      })}
                    </span>
                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        RIESGOS PERSONALIZADOS
                        <br />
                      </strong>
                    </p>
                    <p style={{ textAlign: 'start' }}>
                      {riesgos}
                      <br />
                      <br />
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        OTRAS ALTERNATIVAS
                        <br />
                      </strong>
                    </p>
                    <p style={{ textAlign: 'start' }}>
                      {alternativas}
                      <br />
                      <br />
                      <br />
                    </p>

                    {!denegacion ? (
                      <>
                        <p style={{ textAlign: 'start' }}>
                          <strong>
                            CONSENTIMIENTO
                            <br />
                          </strong>
                        </p>
                        <p style={{ textAlign: 'start' }}>
                          Yo: por medio de la presente acepto y autorizo al personal de
                          este hospital para que sea realizada {cardTitle}. Me han
                          facilitado esta hoja informativa, donde me explican el
                          significado del procedimiento y los riegos inherentes al mismo,
                          Así como, para la atención de contingencias y/o urgencias, ya
                          que he tenido la oportunidad de formular las preguntas
                          referentes al procedimiento, el cual me permitirá conservar la
                          vida. Declaro entonces estar debidamente informado/a con
                          fundamento en la Norma Oficial Mexicana NOM - 004 - SSA3 - 2012
                          del Expediente Clínico.
                          <br />
                          <br />
                          <br />
                        </p>
                      </>
                    ) : (
                      <>
                        <p style={{ textAlign: 'start' }}>
                          <strong>
                            DENEGACIÓN O REVOCACIÓN
                            <br />
                          </strong>
                        </p>
                        <p style={{ textAlign: 'start' }}>
                          Yo: después de ser informado de la naturaleza y riesgos del
                          procedimiento propuesto, manifiesto de forma libre y consciente
                          mi denegación/revocación (tachése lo que no proceda) para su
                          realización, haciéndome responsable de las consecuencias que
                          puedan derivarse de esta decisión.
                          <br />
                          <br />
                          <br />
                        </p>
                      </>
                    )}

                    <p style={{ textAlign: 'start' }}>
                      <strong>Nombre y firma del paciente: </strong>
                      <br />
                      <br />
                      <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                        {patient.pac_nombre?.toUpperCase()}{' '}
                        {patient.pac_apellidop?.toUpperCase()}{' '}
                        {patient.pac_apellidom?.toUpperCase()}
                        {'  '}___________________________________________________
                      </p>
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>Nombre y firma del médico tratante: </strong>
                      <br />
                      <br />
                      <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                        {doctor.med_nombre?.toUpperCase()}{' '}
                        {doctor.med_apellidop?.toUpperCase()}{' '}
                        {doctor.med_apellidom?.toUpperCase()}
                        {'  '}___________________________________________________
                      </p>
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        Nombre y firma del familiar responsable, parentesco, se identifica
                        con:
                      </strong>
                      <br />
                      <br />
                      <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                        {rep?.toUpperCase()}, {repIdentification?.toUpperCase()},{' '}
                        {par_rep?.toUpperCase()}
                        {'  '}___________________________________________________
                      </p>
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        Nombre y firma del representante legal, parentesco, se identifica
                        con:{' '}
                      </strong>{' '}
                      <br />
                      <br />
                      <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                        {repLegal?.toUpperCase()}, {repLegalIdentification?.toUpperCase()}
                        , {par_rep_legal?.toUpperCase()}
                        {'  '}___________________________________________________
                      </p>
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        Nombre y firma del testigo 1 que se identifica con:{' '}
                      </strong>{' '}
                      <br />
                      <br />
                      <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                        {testigo1?.toUpperCase()}, {testigo1Identificacion?.toUpperCase()}
                        {'  '}___________________________________________________
                      </p>
                      <br />
                    </p>

                    <p style={{ textAlign: 'start' }}>
                      <strong>
                        Nombre y firma del testigo 2 que se identifica con:{' '}
                      </strong>{' '}
                      <br />
                      <br />
                      <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                        {testigo2?.toUpperCase()}, {testigo2Identificacion?.toUpperCase()}
                        {'  '}___________________________________________________
                      </p>
                      <br />
                    </p>

                    <div>
                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          AVISO DE PRIVACIDAD
                          <br />
                        </strong>
                      </p>
                      <p style={{ fontSize: '0.8rem' }}>
                        Para ver nuestro Aviso de privacidad favor consultar:
                        http://zivot.com.mx/aviso-privacidad
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

SpecialCardsDocument.defaultProps = {
  date: moment(),
};

export default SpecialCardsDocument;
