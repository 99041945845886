import React, { useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import { Dialog, DialogContent, IconButton, InputAdornment } from '@mui/material';
import { FaSearch } from 'react-icons/all';
import { QrReader } from 'react-qr-reader';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import DialogCustomTitle from '../DialogCustomTitle/DialogCustomTitle';
import { QRModes } from '../../interfaces/QRCodes';
import { StandardText } from '../StandardText/StandardText';
import { CMTextfield } from '../Forms/CMTextfield';

const QRPopup = (): JSX.Element => {
  const { qrCode, setQrModeOpen, qrModeOpen, setQrCode } = useMainProvider();
  const [qrReaderVisible, setQRReaderVisible] = useState(true);
  const [letterCode, handleLetterCode] = useState('');

  const popupTitle = useMemo(() => {
    if (qrModeOpen) {
      switch (qrModeOpen) {
        case QRModes.createDoctor:
          return 'Invita a tus pacientes';
        case QRModes.createPatient:
          return 'Tu QR Personal';
        case QRModes.scanPatient:
        default:
          return 'Escanea un código QR de Paciente temporal';
      }
    }
    return '';
  }, [qrModeOpen]);

  const popupDescription = useMemo(() => {
    if (qrModeOpen) {
      switch (qrModeOpen) {
        case QRModes.createDoctor:
          return 'Enseña este código QR a tus pacientes para que creen su cuenta en la plataforma';
        case QRModes.createPatient:
          return 'Enseña este código QR a tu Médico y/o Enfermera(o) para que pueda acceder temporalmente a tu expediente clínico por una hora';
        case QRModes.scanPatient:
        default:
          return 'El acceso al Expediente Clínico de tu Paciente es temporal de una hora a partir de escanear el código QR';
      }
    }
    return '';
  }, [qrModeOpen]);

  const handleResult = (result: { getText: () => string }) => {
    if (result) {
      const url = result.getText();
      const code = url.split('access=');
      if (code.length > 1) {
        setQrCode(code[1]);
        setQRReaderVisible(false);
        setTimeout(() => {
          setQRReaderVisible(true);
          setQrCode('');
        }, 500);
      }
    }
  };

  const handleCodeResult = () => {
    setQrCode(letterCode);
    handleLetterCode('');
  };
  return (
    <Dialog
      maxWidth='xl'
      open={!!qrModeOpen}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          setQrModeOpen(undefined);
        }
        handleLetterCode('');
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          setQrModeOpen(undefined);
          handleLetterCode('');
        }}
        mode={CRUDModes.Delete}
        title={popupTitle}
        description={popupDescription}
      />
      <DialogContent>
        {qrModeOpen === QRModes.createPatient ? (
          <>
            <StandardText style={{ marginBottom: '1rem' }}>
              <b>Código de acceso:</b> {qrCode.toUpperCase()}
            </StandardText>
            <QRCode
              value={`${window.location.origin}/home?access=${qrCode.toUpperCase()}`}
              level='L'
            />
          </>
        ) : null}
        {qrModeOpen === QRModes.createDoctor ? (
          <>
            <QRCode value={`${window.location.origin}`} level='L' />
          </>
        ) : null}
        {qrModeOpen === QRModes.scanPatient ? (
          <>
            <CMTextfield
              $nomargin
              label='Ingresa el código manualmente'
              capitalized
              placeholder='código'
              style={{ marginTop: '0.5rem' }}
              value={letterCode}
              onChange={(e) => handleLetterCode(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleCodeResult}>
                      <FaSearch />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <QrReader
                constraints={{ facingMode: 'environment' }}
                scanDelay={100}
                containerStyle={{ width: '100%', maxWidth: '1200px' }}
                className='qr-reader-container'
                onResult={(result) => {
                  if (result && qrReaderVisible) {
                    handleResult(result);
                  }
                }}
              />
            </div>
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default QRPopup;
