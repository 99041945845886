/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMainProvider } from '../../context/MainProvider';
import GenericTablePage from '../GenericTablePage/GenericTablePage';
import {
  GenericColumnTypes,
  GenericTableColumn,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import { CRUDModes } from '../../interfaces/CRUD';
import { UserTypes } from '../../interfaces/User';
import { gettWithToken, previewUserID } from '../../utils/server';
import DocumentsForm from './DocumentsForm';
import { getPatientFullName } from '../../utils/fns';
import { Patient } from '../../interfaces/Patients';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import {
  IOSHandlerFunctions,
  isIOSApp,
  isAndroid,
  postWebkitMessage,
} from '../../interfaces/WebkitWindow';
import ImagePreview from '../ImagePreview/ImagePreview';
import { DocumentEntity, SimplifiedDocumentEntity } from '../../interfaces/Documents';
import ImageViewerPopup from '../../components/ImageViewerPopup/ImageViewerPopup';

const DocumentsPage = (): JSX.Element => {
  const {
    setLoading,
    user,
    currentPatient,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    restartTemporayUsers,
    setLoginPopupOpen,
    handleLogout,
  } = useMainProvider();
  const [entries, setEntries] = useState<DocumentEntity[]>([]);
  const [rows, setRows] = useState<GenericTableRow[]>([]);
  const [columns, setColumns] = useState<GenericTableColumn[]>([]);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [imageURL, setImageURL] = useState('');
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [mode, setMode] = useState<CRUDModes>(CRUDModes.Create);
  const [selectedFile, setSelectedFile] = useState('');

  const fetchEntries = async () => {
    try {
      setLoading(true);
      const fetchResult = await gettWithToken<DocumentEntity[]>(
        `/documents/get-document-doctor?patientId=${
          currentPatient ? currentPatient.id : undefined
        }`,
        handleLogout,
      );
      if (fetchResult.success) {
        const orderedRows = fetchResult.data.sort((a, b) => {
          if (a.id && b.id) {
            return a.id > b.id ? -1 : 1;
          }
          return 1;
        });
        setEntries(orderedRows);
        setColumns([
          {
            name: 'id',
            displayName: 'ID',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'fecha',
            displayName: 'Fecha de creación',
            type: GenericColumnTypes.Date,
            sortable: true,
          },
          {
            name: 'titulo',
            displayName: 'titulo',
            type: GenericColumnTypes.Numeric,
            sortable: true,
          },
          {
            name: 'moreInformation',
            displayName: 'Consultar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              setSelectedRow(index);
              setMode(CRUDModes.Update);
              setOpen(true);
            },
          },
          {
            name: 'preview',
            displayName: 'Ver',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              const currentInformation = fetchResult.data[index];
              if (currentInformation && currentInformation.url) {
                setSelectedFile(currentInformation.url);
              }
            },
          },
          {
            name: 'download',
            displayName: 'Descargar',
            type: GenericColumnTypes.Action,
            sortable: false,
            onActionButtonClick: (index) => {
              const currentInformation = fetchResult.data[index];
              if (currentInformation && currentInformation.url) {
                if (isIOSApp() && currentInformation.url) {
                  const splitURL = currentInformation.url.split('/');
                  const fileName = splitURL[splitURL.length - 1];
                  postWebkitMessage({
                    function: IOSHandlerFunctions.DownloadData,
                    download: {
                      url: currentInformation.url,
                      fileName,
                    },
                  });
                }

                const currentUrlString = currentInformation.url as string;

                if (!isAndroid()) {
                  // Change to https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click when security implemented
                  const link = document.createElement('a');
                  link.href = currentUrlString;
                  link.setAttribute('target', '_blank');
                  link.setAttribute('download', currentInformation.url as string);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }

                if (isAndroid() && currentUrlString.includes('.pdf')) {
                  // Change to https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click when security implemented
                  const link = document.createElement('a');
                  link.href = currentUrlString;
                  link.setAttribute('target', '_blank');
                  link.setAttribute('download', currentInformation.url as string);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }

                if (isAndroid() && !currentUrlString.includes('.pdf')) {
                  setImageURL(currentUrlString);
                  setOpenPreview(true);
                }
              }
            },
          },
        ]);
        const simplifiedData: SimplifiedDocumentEntity[] = orderedRows.map(
          (laboratory) => {
            return {
              id: laboratory.id,
              fecha: laboratory.fecha,
              titulo: laboratory.titulo,
              moreInformation: 'Consultar',
              preview: 'Ver',
              download: 'Descargar',
            };
          },
        );
        setRows(simplifiedData);
      }
      setLoading(false);
    } catch (e: any) {
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(
          `Error al obtener documentos: ${JSON.stringify(e.response.data)} `,
        );
        setOpenAlert(true);
        if (
          e.response.data ===
          'El tiempo de acceso al Expediente Clínico del Paciente terminó, si requiere continuar, favor de escanear otro código QR de su Paciente. Gracias'
        ) {
          restartTemporayUsers();
        }
      } else {
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  // Avoid infinite loop
  const fetchCallback = useCallback(fetchEntries, [
    currentPatient,
    setAlertMessage,
    setAlertType,
    setLoading,
    setOpenAlert,
    restartTemporayUsers,
  ]);

  useEffect(() => {
    fetchCallback().then(EmptyClosure).catch(EmptyClosure);
  }, [fetchCallback]);

  return (
    <>
      <DocumentsForm
        setOpen={setOpen}
        mode={mode}
        open={open}
        onSuccess={fetchCallback}
        values={selectedRow !== -1 ? entries[selectedRow] : undefined}
        closeFunction={() => {
          setSelectedRow(-1);
        }}
      />

      <ImagePreview open={openPreview} setOpen={setOpenPreview} imageURL={imageURL} />
      <ImageViewerPopup
        link={selectedFile}
        open={!!selectedFile}
        onClose={() => setSelectedFile('')}
      />

      <GenericTablePage
        title={`Documentos${
          currentPatient
            ? ` de ${getPatientFullName(currentPatient.initialData as Patient)}`
            : ''
        }`}
        rows={rows}
        columns={columns}
        canDelete={false}
        canEdit
        canCreate={
          (user && currentPatient ? user.typeId === UserTypes.DoctorType : false) ||
          (user && user.typeId === UserTypes.PatientType)
        }
        onCreate={() => {
          if (user && user.id === previewUserID) {
            setLoginPopupOpen(true);
          } else {
            setOpen(true);
            setSelectedRow(-1);
            setMode(CRUDModes.Create);
          }
        }}
      />
    </>
  );
};

export default DocumentsPage;
