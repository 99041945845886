import { UserTypes } from '../../../../interfaces/User';

export interface SponsorCampaign {
  id?: number;
  is_active?: boolean;
  name: string;
  fecha_inicio: string | null;
  fecha_fin: string | null;
  budget: number;
  sponsor_id: number;

  location_id: number;

  type_id?: UserTypes; // only needed in the form to select segment
  campaign_segments: SponsorCampaignSegment[];

  // when image or video is already uploaded
  media_url: string;
  mobile_url?: string; // mobile image url
  // when uploading new photos
  mediaFile?: string | ArrayBuffer | null;
  mediaExtension?: string;
  mobileFile?: string | ArrayBuffer | null;
  mobileExtension?: string;

  // for clicking on a campaign
  redirect_url?: string;

  // analytics added by the endpoint after creation
  views?: number;
  clicks?: number;
  budgetSpent?: number;
}

export interface SponsorCampaignSegment {
  id?: number;
  campaign_id?: number;
  segment_id: number;
  is_active?: boolean;
}

export interface SimplifiedSponsorCampaign {
  id?: number;
  name: string;
  fecha_inicio: string | null;
  fecha_fin: string | null;
  is_active?: boolean;
  budget: number;
  moreInformation: 'Consultar/Editar';
  delete: 'Desactivar' | 'Activar';
}

export const initialSponsorCampaign: SponsorCampaign = {
  is_active: true,
  name: '',
  fecha_inicio: '',
  fecha_fin: '',
  budget: 0,
  sponsor_id: 0,
  location_id: 0,
  campaign_segments: [],
  media_url: '',
  type_id: -1,
  redirect_url: '',
};

export interface SponsorCampaignFormSegment {
  type_id: number;
  id: number;
  name: string;
  comments: string;
}

export interface SponsorCampaignFormLocation {
  id: number;
  name: string;
  view_cost: number;
  media_type: number;
  showcase_image_url: string;
  comments: string;
  desktopSize: string;
  mobileSize: string;
}
