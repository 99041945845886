import React from 'react';
import axios from 'axios';
import { AdInformation, AdTypes, dimensionsByAdType } from '../../interfaces/Ads';
import { NoPaddingButton } from '../NoPaddingButton/NoPaddingButton';
import { setHeaders } from '../../utils/headerSetter';
import { useMainProvider } from '../../context/MainProvider';

interface AdProps {
  adInfo: AdInformation;
  type: AdTypes;
}

const Ad = ({ adInfo, type }: AdProps): JSX.Element => {
  const { user } = useMainProvider();

  const openUrl = async () => {
    window.open(adInfo.redirectUrl, '_blank', 'noopener,noreferrer');
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/register-ad-click`,
        { adViewId: adInfo.adViewId },
        setHeaders(true, user ? user.token : undefined),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const renderAd = (): JSX.Element => {
    switch (type) {
      case AdTypes.leaderboard:
      case AdTypes.largeRectangle:
      case AdTypes.mediumRectangle:
      case AdTypes.mobileBanner:
      case AdTypes.wideSkyscraper:
      default: {
        const dimensions = dimensionsByAdType[type];
        return (
          <NoPaddingButton type='button' onClick={openUrl}>
            <img
              src={type === AdTypes.mobileBanner ? adInfo.mobileUrl ?? '' : adInfo.url}
              alt='Publicidad'
              style={{
                width: '100%',
                maxWidth: dimensions.width,
                height: dimensions.height,
                cursor: 'pointer',
                objectFit: 'contain',
                borderRadius: '8px',
              }}
            />
          </NoPaddingButton>
        );
      }
    }
    return <></>;
  };

  return renderAd();
};

export default Ad;
