import React from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import UsersRouter from '../../router/UsersRouter';
import SponsorsRouter from '../../pages/SponsorDashboard/Router/SponsorsRouter';

function App(): JSX.Element {
  const theme = useTheme();
  const themeWithLocale = React.useMemo(() => createTheme(theme, esES), [theme]);

  return (
    <ThemeProvider theme={themeWithLocale}>
      <BrowserRouter>
        <Routes>
          <Route path='/sponsors/*' element={<SponsorsRouter />} />
          <Route path='*' element={<UsersRouter />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
