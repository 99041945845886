import { ZivotDocumentTypes } from './DoctorProfile';
import { AdCatalog } from './Ads';

export enum GenericColumnTypes {
  Numeric,
  Currency,
  Text,
  Date,
  Custom,
  Action,
  Boolean,
  ShowProfile,
}

export type GenericTableRow = {
  [key: string]: any;
};

export interface GenericTableProps {
  title: string;
  columns: GenericTableColumn[];
  rows: GenericTableRow[];
  canDelete?: boolean;
  onDelete?: (rowIndexes: number[]) => void;
  canEdit?: boolean;
  onEdit?: () => void;
  canCreate?: boolean;
  onCreate?: () => void;
  onRowClick?: (row: GenericTableRow) => void;
  locationId?: AdCatalog;
}

export interface GenericTableColumn {
  name: string;
  displayName: string;
  type: GenericColumnTypes;
  sortable: boolean;
  onActionButtonClick?: (rowIndex: number) => void; // Only applicable to Action type buttons
  documentType?: ZivotDocumentTypes; // This is used to pass it to the DoctorProfile popup
}
