import React, { PropsWithChildren, CSSProperties, HTMLAttributes } from 'react';
import { GridDirection, GridWrap } from '@mui/material';

interface FlexContainerProps {
  direction?: GridDirection;
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  wrap?: GridWrap;
  alignItems?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  alignContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  style?: CSSProperties;
}

export const FlexContainer = ({
  children,
  alignContent,
  alignItems,
  justifyContent,
  style,
  wrap,
  direction,
  className,
}: PropsWithChildren<FlexContainerProps> &
  HTMLAttributes<HTMLDivElement>): JSX.Element => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      style={{
        width: '100%',
        ...style,
        display: 'flex',
        flexDirection: direction,
        justifyContent,
        alignItems,
        alignContent,
        flexWrap: wrap,
      }}
    >
      {children}
    </div>
  );
};

FlexContainer.defaultProps = {
  direction: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  wrap: 'wrap',
  style: {},
};
