import { Nurse } from './Nurses';
import { Patient } from './Patients';
import { Doctor } from './Doctors';

export enum UserTypes {
  DoctorType = 1,
  PatientType = 2,
  NurseType = 3,
  AdminType = 4,
}

export interface User {
  id?: number;
  username: string;
  type?: UserTypes;
  typeId: number;
  password?: string;
  token: string;
  initialDataFilled?: boolean;
  validated?: boolean;
  initialData?: Nurse | Patient | Doctor;
}

export interface TemporaryUser extends User {
  expira_en: string;
}

export interface UserLocalStorageInformation {
  user: User;
  token: string;
}
