import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, MenuItem, useMediaQuery, useTheme, Button } from '@mui/material';
import { FaUserCircle, IoIosAddCircle } from 'react-icons/all';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { mainTheme } from '../../utils/theme/theme';
import {
  MaxValidationMessage,
  RequiredMessage,
  SelectValidOption,
} from '../../utils/validationMessages';
import { Doctor } from '../../interfaces/Doctors';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import {
  saveLocalstorageData,
  getLocalstorageData,
  removeLocalstorageData,
} from '../../utils/localStorage';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import { PageContainer } from '../Register/Register.styled';
import CenteredForm from '../../index.styled';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { postWithToken } from '../../utils/server';
import { User } from '../../interfaces/User';
import { deleteNullValues, getBase64 } from '../../utils/fns';
import EmptyClosure from '../../utils/closures';
import fallbackImage from '../../assets/img/profile-user.png';

interface DoctorProps {
  existingUser?: User;
  initialDataMode?: boolean;
}

const initialValues = (user?: User): Doctor => {
  return {
    med_apellidop: '',
    med_apellidom: '',
    med_nombre: '',
    med_cedula_profesional: '',
    med_institucion_expedio_cedula: '',
    med_correo: user?.username ? user?.username : '',
    med_especialidad_id: -1,
    med_cedula_especialidad: '',
    med_institucion_expidio_cedula_esp: '',
    med_consultorio: '',
    med_no_registro: '',
    med_domicilio: '',
    med_colonia_consultorio: '',
    med_cp_consultorio: '',
    med_municipio_consultorio: '',
    med_ciudad_consultorio: '',
    med_estado_consultorio_id: -1,
    med_telefono_consultorio: '',
    med_raz_social: '',
  };
};

function DoctorInitialForm({ existingUser, initialDataMode }: DoctorProps): JSX.Element {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const {
    user: savedUser,
    setLoading,
    setUser,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    getStates,
    states,
    specialities,
    getDoctorSpecialities,
    handleLogout,
  } = useMainProvider();

  const [user, setTemporalUser] = useState<User | undefined>(existingUser || savedUser);
  const [values, setValues] = useState<Doctor>(
    existingUser?.initialData
      ? (existingUser.initialData as Doctor)
      : initialValues(savedUser),
  );

  useEffect(() => {
    if (existingUser) {
      setTemporalUser(existingUser);
    } else {
      setTemporalUser(savedUser);
    }
  }, [savedUser, existingUser]);

  useEffect(() => {
    if (user && user.initialData) {
      setValues(user.initialData as Doctor);
    }
  }, [user]);

  const navigate = useNavigate();
  const [transformedFile, setTransformedFiles] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [fileExtensions, setFileExtension] = useState<string>('');
  const [imagePreview, setImagePreview] = useState('');

  const validationSchema = Yup.object({
    med_nombre: Yup.string().required(RequiredMessage),
    med_apellidop: Yup.string().required(RequiredMessage),
    med_apellidom: Yup.string().required(RequiredMessage),
    med_correo: Yup.string().required(RequiredMessage),
    med_especialidad_id: Yup.number()
      .required(RequiredMessage)
      .test('med_estado_consultorio_id', SelectValidOption(0, 'especialidad'), (val) => {
        return val !== -1;
      }),
    med_cedula_profesional: Yup.string().required(RequiredMessage),
    med_institucion_expedio_cedula: Yup.string().required(RequiredMessage),
    med_cedula_especialidad: Yup.string().when(
      'med_especialidad_id',
      (med_especialidad_id: number) => {
        if (med_especialidad_id !== 37 && med_especialidad_id !== -1) {
          return Yup.string().required(RequiredMessage);
        }
        return Yup.string().nullable();
      },
    ),
    med_institucion_expidio_cedula_esp: Yup.string().when(
      'med_especialidad_id',
      (med_especialidad_id: number) => {
        if (med_especialidad_id !== 37 && med_especialidad_id !== -1) {
          return Yup.string().required(RequiredMessage);
        }
        return Yup.string().nullable();
      },
    ),
    med_consultorio: Yup.string().required(RequiredMessage),
    med_no_registro: Yup.string().required(RequiredMessage),
    med_domicilio: Yup.string().required(RequiredMessage),
    med_colonia_consultorio: Yup.string().required(RequiredMessage),
    med_cp_consultorio: Yup.string()
      .required(RequiredMessage)
      .max(5, MaxValidationMessage(5)),
    med_municipio_consultorio: Yup.string().required(RequiredMessage),
    med_ciudad_consultorio: Yup.string().required(RequiredMessage),
    med_estado_consultorio_id: Yup.number()
      .required(RequiredMessage)
      .test(
        'med_estado_consultorio_id',
        SelectValidOption(1, 'estado del consultorio'),
        (val) => {
          return val !== -1;
        },
      ),
    med_telefono_consultorio: Yup.string().required(RequiredMessage),
    med_raz_social: Yup.string().required(RequiredMessage),
  });

  const formik = useFormik<Doctor>({
    initialValues: values,
    validationSchema,
    enableReinitialize: true,
    /**
     * @desc logins the user with the given information
     * @param doctorValues
     */
    onSubmit: async (doctorValues) => {
      try {
        console.log('entered');
        setLoading(true);
        const saveDoctorValuesResult = await postWithToken<
          {
            doctor: Doctor;
            userId?: number;
            file: string | ArrayBuffer | null;
            extension: string;
          },
          Doctor
        >(
          `/doctor/${initialDataMode ? 'save' : 'update'}-doctor`,
          {
            doctor: deleteNullValues<Doctor>({
              ...doctorValues,
              med_id_usuario_medico_id: user?.id,
            }),
            userId: user?.id,
            file: transformedFile,
            extension: fileExtensions,
          },
          handleLogout,
        );
        if (saveDoctorValuesResult.success) {
          const userInformation = getLocalstorageData<User>(
            'CODIGO_MEDICO_USER_INFORMATION',
          );
          if (userInformation.success) {
            const userData = userInformation.data;
            userData.initialDataFilled = true;
            userData.initialData = saveDoctorValuesResult.data;
            removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
            saveLocalstorageData('CODIGO_MEDICO_USER_INFORMATION', userData);
            setUser(userData);
            setLoading(false);
            setAlertType(AlertTypes.Success);
            setAlertMessage(`Información guardada con éxito`);
            setOpenAlert(true);
            if (initialDataMode) navigate('/home');
            return;
          }
        }
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al guardar información`);
        setOpenAlert(true);
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar la información del paciente: ${JSON.stringify(
              e.response.data,
            )} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files && e.target.files.length > 0) {
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiles(base64);
        setFileExtension(e.target.files[0].type.split('/')[1]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
      }
    } catch (err) {
      handleLogout(err);
      setLoading(false);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    getStates();
    getDoctorSpecialities();
  }, [getStates, getDoctorSpecialities]);

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={6}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                {!initialDataMode ? null : (
                  <>
                    <PrimaryTitle mobile={mobile}>Completa tu perfil</PrimaryTitle>
                    <PrimaryDescription mobile={mobile}>
                      Antes de comenzar necesitamos saber un poco más de ti
                    </PrimaryDescription>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ justifyContent: 'center', display: 'flex' }}
                >
                  <>
                    <label htmlFor='upload-photo'>
                      <input
                        style={{ display: 'none' }}
                        id='upload-photo'
                        name='upload-photo'
                        type='file'
                        accept='image/png, image/jpeg'
                        onChange={handleUpload}
                      />
                      <Button
                        component='span'
                        style={{
                          width: mobile ? '100%' : 'initial',
                          marginBottom: '2rem',
                        }}
                      >
                        {imagePreview || values.med_imagen ? (
                          <div style={{ position: 'relative' }}>
                            <img
                              src={imagePreview || values.med_imagen}
                              onError={(e: SyntheticEvent<HTMLImageElement>) => {
                                const target = e.target as EventTarget & HTMLImageElement;
                                target.src = fallbackImage;
                              }}
                              alt='img'
                              style={{
                                width: '15rem',
                              }}
                            />
                            <IoIosAddCircle
                              style={{
                                color: 'black',
                                fontSize: '3rem',
                                position: 'absolute',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </div>
                        ) : (
                          <div style={{ position: 'relative' }}>
                            <FaUserCircle
                              style={{
                                color: mainTheme.colors.uiElements,
                                fontSize: '10rem',
                              }}
                            />
                            <IoIosAddCircle
                              style={{
                                color: 'black',
                                fontSize: '3rem',
                                position: 'absolute',
                                right: '0',
                                bottom: '0',
                              }}
                            />
                          </div>
                        )}
                      </Button>
                      <span
                        style={{
                          marginLeft: mobile ? 'initial' : '2%',
                          color: mainTheme.colors.standardText,
                          display: mobile ? 'block' : 'initial',
                        }}
                      />
                    </label>
                  </>
                </Grid>
                <PrimaryTitleStart>Datos del médico</PrimaryTitleStart>
                <CMTextfield
                  id='outlined-basic'
                  label='Nombre'
                  value={formik.values.med_nombre}
                  name='med_nombre'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.med_nombre && Boolean(formik.errors.med_nombre)}
                  helperText={formik.touched.med_nombre && formik.errors.med_nombre}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Apellido paterno'
                  value={formik.values.med_apellidop}
                  name='med_apellidop'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_apellidop && Boolean(formik.errors.med_apellidop)
                  }
                  helperText={formik.touched.med_apellidop && formik.errors.med_apellidop}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Apellido materno'
                  value={formik.values.med_apellidom}
                  name='med_apellidom'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_apellidom && Boolean(formik.errors.med_apellidom)
                  }
                  helperText={formik.touched.med_apellidom && formik.errors.med_apellidom}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Cédula profesional'
                  value={formik.values.med_cedula_profesional}
                  name='med_cedula_profesional'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_cedula_profesional &&
                    Boolean(formik.errors.med_cedula_profesional)
                  }
                  helperText={
                    formik.touched.med_cedula_profesional &&
                    formik.errors.med_cedula_profesional
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Institución de cédula profesional'
                  value={formik.values.med_institucion_expedio_cedula}
                  name='med_institucion_expedio_cedula'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_institucion_expedio_cedula &&
                    Boolean(formik.errors.med_institucion_expedio_cedula)
                  }
                  helperText={
                    formik.touched.med_institucion_expedio_cedula &&
                    formik.errors.med_institucion_expedio_cedula
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Correo'
                  value={formik.values.med_correo}
                  name='med_correo'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.med_correo && Boolean(formik.errors.med_correo)}
                  helperText={formik.touched.med_correo && formik.errors.med_correo}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  select
                  id='outlined-basic'
                  label='Especialidad'
                  value={formik.values.med_especialidad_id}
                  name='med_especialidad_id'
                  style={{ marginBottom: '1rem' }}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {specialities.map((singleSpeciality) => {
                    return (
                      <MenuItem value={singleSpeciality.cat_especialidad_id}>
                        {singleSpeciality.cat_especialidad_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfield>
                {formik.values.med_especialidad_id !== 37 &&
                  formik.values.med_especialidad_id !== -1 && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      exit={{ opacity: 0, height: 0 }}
                      animate={{
                        opacity: 1,
                        height: 'auto',
                      }}
                      transition={{ duration: 0.3 }}
                      key='bodyMassIndex-div'
                      style={{
                        width: '100%',
                      }}
                    >
                      <CMTextfieldHalfLeft
                        id='outlined-basic'
                        label='Cédula de especialidad'
                        value={formik.values.med_cedula_especialidad}
                        name='med_cedula_especialidad'
                        style={{ marginBottom: '1rem' }}
                        error={
                          formik.touched.med_cedula_especialidad &&
                          Boolean(formik.errors.med_cedula_especialidad)
                        }
                        helperText={
                          formik.touched.med_cedula_especialidad &&
                          formik.errors.med_cedula_especialidad
                        }
                        onChange={existingUser ? EmptyClosure : formik.handleChange}
                        required={
                          formik.values.med_especialidad_id !== 37 &&
                          formik.values.med_especialidad_id !== -1
                        }
                      />
                      <CMTextfieldHalfRight
                        id='outlined-basic'
                        label='Institución de cédula de especialidad'
                        value={formik.values.med_institucion_expidio_cedula_esp}
                        name='med_institucion_expidio_cedula_esp'
                        style={{ marginBottom: '1rem' }}
                        error={
                          formik.touched.med_institucion_expidio_cedula_esp &&
                          Boolean(formik.errors.med_institucion_expidio_cedula_esp)
                        }
                        helperText={
                          formik.touched.med_institucion_expidio_cedula_esp &&
                          formik.errors.med_institucion_expidio_cedula_esp
                        }
                        onChange={existingUser ? EmptyClosure : formik.handleChange}
                        required={
                          formik.values.med_especialidad_id !== 37 &&
                          formik.values.med_especialidad_id !== -1
                        }
                      />
                    </motion.div>
                  )}
                <PrimaryTitleStart>Datos del consultorio</PrimaryTitleStart>
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Consultorio'
                  value={formik.values.med_consultorio}
                  name='med_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_consultorio &&
                    Boolean(formik.errors.med_consultorio)
                  }
                  helperText={
                    formik.touched.med_consultorio && formik.errors.med_consultorio
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Licencia Sanitaria'
                  value={formik.values.med_no_registro}
                  name='med_no_registro'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_no_registro &&
                    Boolean(formik.errors.med_no_registro)
                  }
                  helperText={
                    formik.touched.med_no_registro && formik.errors.med_no_registro
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Calle con número interior y exterio'
                  value={formik.values.med_domicilio}
                  name='med_domicilio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_domicilio && Boolean(formik.errors.med_domicilio)
                  }
                  helperText={formik.touched.med_domicilio && formik.errors.med_domicilio}
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Colonia'
                  value={formik.values.med_colonia_consultorio}
                  name='med_colonia_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_colonia_consultorio &&
                    Boolean(formik.errors.med_colonia_consultorio)
                  }
                  helperText={
                    formik.touched.med_colonia_consultorio &&
                    formik.errors.med_colonia_consultorio
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Código postal'
                  value={formik.values.med_cp_consultorio}
                  name='med_cp_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_cp_consultorio &&
                    Boolean(formik.errors.med_cp_consultorio)
                  }
                  helperText={
                    formik.touched.med_cp_consultorio && formik.errors.med_cp_consultorio
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='Ciudad'
                  value={formik.values.med_ciudad_consultorio}
                  name='med_ciudad_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_ciudad_consultorio &&
                    Boolean(formik.errors.med_ciudad_consultorio)
                  }
                  helperText={
                    formik.touched.med_ciudad_consultorio &&
                    formik.errors.med_ciudad_consultorio
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Municipio'
                  value={formik.values.med_municipio_consultorio}
                  name='med_municipio_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_municipio_consultorio &&
                    Boolean(formik.errors.med_municipio_consultorio)
                  }
                  helperText={
                    formik.touched.med_municipio_consultorio &&
                    formik.errors.med_municipio_consultorio
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  select
                  id='outlined-basic'
                  label='Estado'
                  value={formik.values.med_estado_consultorio_id}
                  name='med_estado_consultorio_id'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_estado_consultorio_id &&
                    Boolean(formik.errors.med_estado_consultorio_id)
                  }
                  helperText={
                    formik.touched.med_estado_consultorio_id &&
                    formik.errors.med_estado_consultorio_id
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                >
                  <MenuItem value='-1'>Seleccione una opción</MenuItem>
                  {states.map((singleState) => {
                    return (
                      <MenuItem value={singleState.cat_estado_id}>
                        {singleState.cat_estado_desc}
                      </MenuItem>
                    );
                  })}
                </CMTextfield>
                <CMTextfield
                  id='outlined-basic'
                  label='Teléfono'
                  value={formik.values.med_telefono_consultorio}
                  name='med_telefono_consultorio'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_telefono_consultorio &&
                    Boolean(formik.errors.med_telefono_consultorio)
                  }
                  helperText={
                    formik.touched.med_telefono_consultorio &&
                    formik.errors.med_telefono_consultorio
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Razón social'
                  value={formik.values.med_raz_social}
                  name='med_raz_social'
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.med_raz_social && Boolean(formik.errors.med_raz_social)
                  }
                  helperText={
                    formik.touched.med_raz_social && formik.errors.med_raz_social
                  }
                  onChange={existingUser ? EmptyClosure : formik.handleChange}
                />
                {!existingUser ? (
                  <PrimaryButton
                    loading={false}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%', marginTop: '1rem' }}
                  >
                    {initialDataMode ? 'Enviar información' : 'Actualizar información'}
                  </PrimaryButton>
                ) : null}
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

DoctorInitialForm.defaultProps = {
  existingUser: undefined,
  initialDataMode: false,
};

export default DoctorInitialForm;
