export enum ZivotDocumentTypes {
  clinicHistory = 1,
  clinicPediatricHistory = 2,
  recipe,
  consultationNote,
  evolutionNote,
  indicationNote,
  preOperationNote,
  postOperationNote,
  nurseNote,
  indicatedStudies,
}

export interface InfoForDoctorProfile {
  documentId: number;
  documentType: ZivotDocumentTypes;
}

export interface DoctorProfileResponse {
  completeName: string;
  cedula: string;
  cedulaInstitution: string;
  email: string;
  speciality: string;
  specialityCedula: string;
  phone: string;
  image: string;
}
