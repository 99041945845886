import { getLocalstorageData } from './localStorage';
import { User } from '../interfaces/User';

export interface CMHeaders {
  headers: { 'Content-type'?: string; 'access-token'?: string };
}

export const setHeaders = (post = true, token?: string): CMHeaders => {
  const config: CMHeaders = {
    headers: {},
  };
  if (post) {
    config.headers['Content-type'] = 'application/json';
  }
  let validatedToken: string | null = token || null;
  // Fallback when there is no user object
  if (!validatedToken) {
    const localStorageToken = getLocalstorageData<User>('CODIGO_MEDICO_USER_INFORMATION');
    if (
      localStorageToken.success &&
      localStorageToken.data &&
      localStorageToken.data.token
    ) {
      validatedToken = localStorageToken.data.token;
    }
  }
  if (validatedToken) {
    config.headers['access-token'] = validatedToken;
  }
  return config;
};

export const setSponsorHeaders = (post = true, token?: string): CMHeaders => {
  const config: CMHeaders = {
    headers: {},
  };
  if (post) {
    config.headers['Content-type'] = 'application/json';
  }
  let validatedToken: string | null = token || null;
  // Fallback when there is no user object
  if (!validatedToken) {
    const localStorageToken = getLocalstorageData<User>(
      'CODIGO_MEDICO_SPONSOR_INFORMATION',
    );
    if (
      localStorageToken.success &&
      localStorageToken.data &&
      localStorageToken.data.token
    ) {
      validatedToken = localStorageToken.data.token;
    }
  }
  if (validatedToken) {
    config.headers['access-token'] = validatedToken;
  }
  return config;
};
