import styled from 'styled-components';
import { mainTheme } from '../../utils/theme/theme';

export const NoPaddingButton = styled.button`
  font-family: ${mainTheme.fonts.text.family};
  padding: 0;
  background-color: transparent;
  border: none;
  margin: 0;
  color: ${mainTheme.colors.primaryButton.normalBackground};
  cursor: pointer;
`;
