import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useSponsorsProvider } from '../../Context/SponsorsMainContext';
import { RequiredMessage } from '../../../../utils/validationMessages';
import { initialSponsorForm, SponsorFormPayload } from './SponsorForm.interfaces';
import { PageContainer } from '../../../Register/Register.styled';
import CenteredForm from '../../../../index.styled';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import {
  CMTextfield,
  CMTextfieldHalfLeft,
  CMTextfieldHalfRight,
} from '../../../../components/Forms/CMTextfield';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import { getBase64 } from '../../../../utils/fns';
import { StyledButton } from '../../../../components/PrimaryButton/PrimaryButton.Styled';
import { mainTheme } from '../../../../utils/theme/theme';
import { FlexContainer } from '../../../../components/FlexContainer/FlexContainer';
import { DisclaimerText } from '../../../../components/DisclaimerText/DisclaimerText';
import { postWithToken } from '../../../../utils/server';
import { SponsorContextData } from '../../Interfaces/ContextInterfaces';
import { CRUDModes } from '../../../../interfaces/CRUD';
import { SponsorsTableEntry } from '../../Interfaces/Sponsors';

interface Props {
  values?: SponsorsTableEntry;
  onSuccess?: () => void;
  mode?: CRUDModes;
}

const SponsorForm = ({ values, onSuccess, mode }: Props): JSX.Element => {
  const {
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setLoading,
    handleLogoutError,
    setSponsor,
    sponsor,
  } = useSponsorsProvider();

  const navigate = useNavigate();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState<SponsorsTableEntry>(initialSponsorForm);

  // Logo variables
  const [transformedLogo, setTransformedLogo] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [logoTitle, setLogoTitle] = useState<string>('');
  const [logoExtension, setLogoExtension] = useState<string>('');

  // Fiscal statement variables
  const [transformedFiscalStatement, setTransformedFiscalStatement] = useState<
    string | ArrayBuffer | null
  >(null);
  const [fiscalStatementTitle, setFiscalStatementTitle] = useState<string>('');
  const [fiscalStatementExtension, setFiscalStatementExtension] = useState<string>('');

  const [error, setError] = useState<string>('');

  const validationSchema = Yup.object({
    name: Yup.string().required(RequiredMessage),
    address: Yup.string().required(RequiredMessage),
    business_name: Yup.string().required(RequiredMessage),
    sanitary_license: Yup.string().required(RequiredMessage),
    description: Yup.string().required(RequiredMessage),
    website_url: Yup.string().nullable(),
  });

  const handleLogoUpload = async (e: any) => {
    try {
      const maximumSize = 9 * 1024 * 1024; // In MegaBytes
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size > maximumSize) {
          setAlertType(AlertTypes.Error);
          setAlertMessage('El peso del archivo es mayor al permitido');
          setOpenAlert(true);
          return;
        }
        const base64 = await getBase64(e.target.files[0]);
        setTransformedLogo(base64);
        setLogoTitle(e.target.files[0].name);
        setLogoExtension(e.target.files[0].type.split('/')[1]);
      }
    } catch (err) {
      handleLogoutError(err);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  const handleFiscalUpload = async (e: any) => {
    try {
      const maximumSize = 9 * 1024 * 1024; // In MegaBytes
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size > maximumSize) {
          setAlertType(AlertTypes.Error);
          setAlertMessage('El peso del archivo es mayor al permitido');
          setOpenAlert(true);
          return;
        }
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiscalStatement(base64);
        setFiscalStatementTitle(e.target.files[0].name);
        setFiscalStatementExtension(e.target.files[0].type.split('/')[1]);
      }
    } catch (err) {
      handleLogoutError(err);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
    if (mode === CRUDModes.Update && !values && sponsor) {
      setFormValues(sponsor as SponsorsTableEntry);
    }
  }, [mode, sponsor, values]);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    validate: () => {
      setError('');
    },
    onSubmit: async (form) => {
      if (
        (!transformedLogo && !form.logo_url) ||
        (!transformedFiscalStatement && !form.fiscal_statement_url)
      ) {
        setError('Por favor sube los archivos requeridos');
        return;
      }
      setError('');
      try {
        setLoading(true);
        const result = await postWithToken<SponsorFormPayload, SponsorContextData>(
          `/sponsors/${mode === CRUDModes.Create ? 'new' : 'update'}-sponsor`,
          {
            ...form,
            logoFile: transformedLogo,
            logoExtension,
            fiscalStatementFile: transformedFiscalStatement,
            fiscalStatementExtension,
          },
          handleLogoutError,
          true,
        );
        if (result.success) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          if (mode === CRUDModes.Create) {
            setSponsor(result.data);
            navigate('/sponsors/home');
          } else if (!values && mode === CRUDModes.Update) {
            setSponsor(result.data);
          } else if (onSuccess) {
            onSuccess();
          }
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
        setLoading(false);
      } catch (e: any) {
        handleLogoutError(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al ${
              mode === CRUDModes.Update ? 'editar' : 'guardar'
            } patrocinador: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
        setLoading(false);
      }
    },
  });
  return (
    <PageContainer style={{ paddingTop: '1.5rem' }}>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                {mode === CRUDModes.Create ? (
                  <>
                    <PrimaryTitle style={{ textAlign: 'center', marginTop: '2rem' }}>
                      Completa tu perfil de patrocinador
                    </PrimaryTitle>
                    <PrimaryDescription
                      mobile={mobile}
                      style={{ marginBottom: '2.5rem' }}
                    >
                      Llena la información de la empresa patrocinadora
                    </PrimaryDescription>
                  </>
                ) : null}
                {mode === CRUDModes.Update && !values ? (
                  <>
                    <PrimaryTitle style={{ textAlign: 'center', marginTop: '2rem' }}>
                      Datos de la empresa
                    </PrimaryTitle>
                    <PrimaryDescription
                      mobile={mobile}
                      style={{ marginBottom: '2.5rem' }}
                    >
                      Edita la información de la empresa patrocinadora
                    </PrimaryDescription>
                  </>
                ) : null}

                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Nombre de la empresa'
                  value={formik.values.name}
                  name='name'
                  required
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  required
                  label='Razón social'
                  value={formik.values.business_name}
                  name='business_name'
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.business_name && Boolean(formik.errors.business_name)
                  }
                  helperText={formik.touched.business_name && formik.errors.business_name}
                  onChange={formik.handleChange}
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Dirección'
                  value={formik.values.address}
                  name='address'
                  required
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Licencia sanitaria'
                  value={formik.values.sanitary_license}
                  name='sanitary_license'
                  onBlur={formik.handleBlur}
                  required
                  style={{ marginBottom: '1rem' }}
                  error={
                    formik.touched.sanitary_license &&
                    Boolean(formik.errors.sanitary_license)
                  }
                  helperText={
                    formik.touched.sanitary_license && formik.errors.sanitary_license
                  }
                  onChange={formik.handleChange}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  label='URL Página web'
                  value={formik.values.website_url}
                  name='website_url'
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.website_url && Boolean(formik.errors.website_url)}
                  helperText={formik.touched.website_url && formik.errors.website_url}
                  onChange={formik.handleChange}
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Descripción de la empresa'
                  value={formik.values.description}
                  name='description'
                  required
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  onChange={formik.handleChange}
                />

                <FlexContainer
                  direction='column'
                  justifyContent='flex-start'
                  alignContent='center'
                  alignItems='center'
                >
                  <>
                    <PrimaryTitleStart
                      style={{ fontSize: '1rem', margin: '1rem 0', textAlign: 'center' }}
                    >
                      Logo de la empresa
                    </PrimaryTitleStart>
                    <label htmlFor='upload-logo' style={{ textAlign: 'center' }}>
                      <input
                        style={{ display: 'none' }}
                        id='upload-logo'
                        name='upload-logo'
                        type='file'
                        accept='image/png, image/jpeg, image/jpg'
                        onChange={handleLogoUpload}
                      />
                      <StyledButton
                        component='span'
                        style={{
                          width: mobile ? '100%' : 'initial',
                          marginBottom: '0',
                        }}
                      >
                        {logoTitle || formik.values.logo_url
                          ? 'Cambiar logo'
                          : 'Seleccionar logo'}
                      </StyledButton>
                      <p
                        style={{
                          color: mainTheme.colors.standardText,
                        }}
                      >
                        {logoTitle || formik.values.logo_url}
                      </p>
                    </label>
                  </>
                  <>
                    <PrimaryTitleStart
                      style={{
                        fontSize: '1rem',
                        margin: '2rem 0 1rem 0',
                        textAlign: 'center',
                      }}
                    >
                      Constancia de situación fiscal
                    </PrimaryTitleStart>
                    <label htmlFor='upload-fiscal' style={{ textAlign: 'center' }}>
                      <input
                        style={{ display: 'none' }}
                        id='upload-fiscal'
                        name='upload-fiscal'
                        type='file'
                        accept='image/png, image/jpeg, image/jpg, application/pdf, .pdf'
                        onChange={handleFiscalUpload}
                      />
                      <StyledButton
                        component='span'
                        style={{
                          width: mobile ? '100%' : 'initial',
                          marginBottom: '0',
                        }}
                      >
                        {fiscalStatementTitle || formik.values.fiscal_statement_url
                          ? 'Cambiar'
                          : 'Seleccionar'}{' '}
                        archivo
                      </StyledButton>
                      <p
                        style={{
                          color: mainTheme.colors.standardText,
                        }}
                      >
                        {fiscalStatementTitle || formik.values.fiscal_statement_url}
                      </p>
                    </label>
                  </>
                </FlexContainer>
                {error ? (
                  <DisclaimerText
                    style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}
                  >
                    {error}
                  </DisclaimerText>
                ) : null}
                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  Guardar datos
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

SponsorForm.defaultProps = {
  values: undefined,
  mode: CRUDModes.Create,
  onSuccess: () => {},
};

export default SponsorForm;
