import { UserTypes } from '../../../../interfaces/User';

export interface SponsorSegment {
  id?: number;
  fecha?: string;
  fecha_modificacion?: string;
  is_active: boolean;
  name: string;
  sql_query: string;
  comments: string;
  type_id: UserTypes;
}

export interface SimplifiedSponsorSegment {
  id?: number;
  fecha_modificacion?: string;
  is_active: boolean;
  name: string;
  type_id: UserTypes;
  moreInformation: 'Consultar';
}

export const initialSponsorSegment: SponsorSegment = {
  fecha: '',
  fecha_modificacion: '',
  is_active: true,
  name: '',
  sql_query: '',
  comments: '',
  type_id: -1,
};
