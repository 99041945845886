import { TextareaAutosize } from '@mui/material';
import styled from 'styled-components';
import { mainTheme } from '../../utils/theme/theme';

export interface CMTextAreaProps {
  $spacing?: number;
  capitalized?: boolean;
}

export const CMTextArea = styled(TextareaAutosize)<CMTextAreaProps>`
  width: calc(100% - ${(props) => (props.$spacing ? 0.5 * props.$spacing : 0.5)}rem);
  border-radius: 10px;
  font-family: ${mainTheme.fonts.text.family};
  font-weight: ${mainTheme.fonts.text.weight};
  font-size: 1rem;
  padding: 0.5rem;
  text-transform: ${(props) => (!props.capitalized ? 'uppercase' : 'initial')};
`;
