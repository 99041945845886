import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import { Nurse } from '../../interfaces/Nurses';
import { useMainProvider } from '../../context/MainProvider';
import { UserTypes } from '../../interfaces/User';
import { getDoctorFullName, getNurseFullName, getPatientFullName } from '../../utils/fns';

function SimpleCardsDocument({
  diagnostico,
  patient,
  doctor,
  date,
  rep,
  repIdentification,
  par_rep,
  repLegal,
  repLegalIdentification,
  par_rep_legal,
  testigo1,
  testigo1Identificacion,
  testigo2,
  testigo2Identificacion,
  denegacion,
}: {
  diagnostico?: string;
  patient: Patient;
  doctor: Doctor;
  date: Moment;
  rep?: string;
  repIdentification?: string;
  par_rep_legal?: string;
  repLegal?: string;
  repLegalIdentification?: string;
  par_rep?: string;
  testigo1?: string;
  testigo1Identificacion?: string;
  testigo2?: string;
  testigo2Identificacion?: string;
  denegacion: boolean;
}): JSX.Element {
  const { user } = useMainProvider();
  const [name, setName] = useState('');

  useEffect(() => {
    if (user && user.initialData) {
      if (user.typeId === UserTypes.PatientType) {
        setName(getPatientFullName(user.initialData as Patient).toUpperCase());
      }
      if (user.typeId === UserTypes.DoctorType) {
        setName(getDoctorFullName(user.initialData as Doctor).toUpperCase());
      }
      if (user.typeId === UserTypes.NurseType) {
        setName(getNurseFullName(user.initialData as Nurse).toUpperCase());
      }
    }
  }, [user]);

  useEffect(() => {
    moment.locale('es');
  }, [patient]);

  return (
    <table style={{ tableLayout: 'fixed', width: '100%' }}>
      <tbody>
        <tr>
          <td>
            <table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>
                <tr>
                  <td>
                    <span>
                      <p style={{ textAlign: 'end' }}>
                        <strong>
                          Fecha y Hora de impresión:{' '}
                          {moment().locale('ES').format('DD MMMM YYYY h:mm:ss a')}
                          <br />
                        </strong>
                      </p>

                      <p style={{ textAlign: 'right' }}>
                        <strong>
                          {doctor.med_consultorio?.toUpperCase()}
                          <br />
                          {doctor.med_raz_social?.toUpperCase()},{' '}
                          {doctor.med_no_registro?.toUpperCase()}
                          <br />
                          {doctor.med_domicilio?.toUpperCase()},
                          {doctor.med_colonia_consultorio?.toUpperCase()},{' '}
                          {doctor.med_municipio_consultorio?.toUpperCase()},{' '}
                          {doctor.med_ciudad_consultorio?.toUpperCase()},
                          {doctor.med_cp_consultorio}
                          <br />
                          Tel. {doctor.med_telefono_consultorio}
                        </strong>
                      </p>

                      <p style={{ textAlign: 'center' }}>
                        <strong>Carta De Atencion Medica.</strong>
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        {doctor.med_ciudad_consultorio?.toUpperCase()},{' '}
                        {doctor.med_estado_consultorio?.cat_estado_desc}. A:{' '}
                        <strong style={{ textTransform: 'capitalize' }}>
                          {moment(date?.toString())
                            .locale('ES')
                            .format('DD MMMM YYYY h:mm:ss a')}{' '}
                        </strong>
                      </p>
                      <br />
                      <p>
                        <strong>
                          Yo: {patient.pac_apellidop?.toUpperCase()}{' '}
                          {patient.pac_apellidom?.toUpperCase()}{' '}
                          {patient.pac_nombre?.toUpperCase()} de{' '}
                          {moment().diff(patient.pac_fechanacimiento, 'years')} años de
                          edad
                          <br />
                          Fecha de nacimiento:{' '}
                          {moment(patient.pac_fechanacimiento)
                            .locale('ES')
                            .format('DD/MM/YYYY')}
                          <br />
                          Sexo: {patient.pac_sexo?.cat_sexo_desc}
                          <br /> Con domicilio en : {patient.pac_direccion?.toUpperCase()}
                          <br />
                          Familiar Responsable:{' '}
                          {patient.pac_nombreresponsable?.toUpperCase()}
                        </strong>
                      </p>
                      <br />
                      <p>
                        Declaro:
                        <br />
                        Que el{' '}
                        <strong>
                          Dr. {doctor.med_apellidop?.toUpperCase()}{' '}
                          {doctor.med_apellidom?.toUpperCase()}{' '}
                          {doctor.med_nombre?.toUpperCase()}
                        </strong>
                        , me ha explicado los siguientes diagnósticos presuntivos:
                        <strong />.
                      </p>
                      <br />
                      <p>{diagnostico?.toUpperCase()}</p>
                      <br />
                      <p>
                        Así mismo, se me ha informado en lenguaje claro y sencillo, que
                        todo acto médico, diagnóstico o terapéutico, sea o no quirúrgico,
                        lleva implícito un riesgo de complicaciones menores o mayores e
                        incluso de mortalidad, que pueden derivarse del estado previo del
                        paciente y/o de efectos adversos impredecibles de los medicamentos
                        que se administran, las cuales pueden requerir tratamientos
                        complementarios. En particular manifiesto que me ha informado al
                        respecto de los beneficios esperados y de los riesgos correlativos
                        a mi (su) caso, por lo que, conocedor del contenido y alcance
                        legal de este documento, bajo protesta de decir verdad de que he
                        sido satisfactoriamente informado, y autorizo al personal para que
                        lleve a cabo los procedimientos médico-quirúrgico, de diagnóstico
                        o terapéuticos necesarios de acuerdo a mis (sus) condiciones de
                        salud, así como la aplicación de las medidas que se requieran por
                        alguna situación no sospechada de contingencias y urgencias
                        derivadas del acto autorizado, atendiendo al principio de libertad
                        prescriptiva.
                        <br />
                        <br />
                        De igual manera, he sido enterado de que en cualquier momento y
                        sin necesidad de explicación alguna, puedo renunciar por escrito a
                        seguir recibiendo la atención médica en cuestión.
                        <br />
                        <br />
                        Atendiendo al principio de confidencialidad, designo a
                        <strong>
                          {' '}
                          {patient.pac_nombreresponsable?.toUpperCase()}{' '}
                        </strong>{' '}
                        para que solo él (ella) reciba información sobre mi estado de
                        salud, diagnóstico, tratamiento y/o pronóstico.
                      </p>

                      {!denegacion ? (
                        <>
                          <p style={{ textAlign: 'start' }}>
                            <strong>
                              CONSENTIMIENTO
                              <br />
                            </strong>
                          </p>
                          <p>
                            Por lo anterior firmo al margen y al calce para constancia y
                            efectos legales a que haya lugar.
                          </p>
                          <br />
                        </>
                      ) : (
                        <>
                          <p style={{ textAlign: 'start' }}>
                            <strong>
                              DENEGACIÓN O REVOCACIÓN
                              <br />
                            </strong>
                          </p>
                          <p style={{ textAlign: 'start' }}>
                            A pesar de las propuestas de manejo arriba referidas y del a
                            información recibida sobre su importancia para mi salud, niego
                            la autorización para recibir dicha atención y libero o de toda
                            responsabilidad sobre esta decisión al Dr.{' '}
                            <strong>
                              {doctor.med_apellidop?.toUpperCase()}{' '}
                              {doctor.med_apellidom?.toUpperCase()}{' '}
                              {doctor.med_nombre?.toUpperCase()}{' '}
                            </strong>
                            <br />
                          </p>
                        </>
                      )}

                      <p style={{ textAlign: 'start' }}>
                        <strong>Nombre y firma del paciente: </strong>
                        <br />
                        <br />
                        <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                          {patient.pac_nombre?.toUpperCase()}{' '}
                          {patient.pac_apellidop?.toUpperCase()}{' '}
                          {patient.pac_apellidom?.toUpperCase()}
                          {'  '}___________________________________________________
                        </p>
                        <br />
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>Nombre y firma del médico tratante: </strong>
                        <br />
                        <br />
                        <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                          {doctor.med_nombre?.toUpperCase()}{' '}
                          {doctor.med_apellidop?.toUpperCase()}{' '}
                          {doctor.med_apellidom?.toUpperCase()}
                          {'  '}___________________________________________________
                        </p>
                        <br />
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Nombre y firma del familiar responsable, parentesco, se
                          identifica con:
                        </strong>
                        <br />
                        <br />
                        <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                          {rep?.toUpperCase()}, {repIdentification?.toUpperCase()},{' '}
                          {par_rep?.toUpperCase()}
                          {'  '}___________________________________________________
                        </p>
                        <br />
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Nombre y firma del representante legal, parentesco, se
                          identifica con:{' '}
                        </strong>{' '}
                        <br />
                        <br />
                        <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                          {repLegal?.toUpperCase()},{' '}
                          {repLegalIdentification?.toUpperCase()},{' '}
                          {par_rep_legal?.toUpperCase()}
                          {'  '}___________________________________________________
                        </p>
                        <br />
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Nombre y firma del testigo 1 que se identifica con:{' '}
                        </strong>{' '}
                        <br />
                        <br />
                        <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                          {testigo1?.toUpperCase()},{' '}
                          {testigo1Identificacion?.toUpperCase()}
                          {'  '}___________________________________________________
                        </p>
                        <br />
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          Nombre y firma del testigo 2 que se identifica con:{' '}
                        </strong>{' '}
                        <br />
                        <br />
                        <p style={{ textAlign: 'start', wordBreak: 'break-all' }}>
                          {testigo2?.toUpperCase()},{' '}
                          {testigo2Identificacion?.toUpperCase()}
                          {'  '}___________________________________________________
                        </p>
                        <br />
                      </p>

                      <p style={{ textAlign: 'start' }}>
                        {doctor.med_ciudad_consultorio?.toUpperCase()},{' '}
                        {doctor.med_estado_consultorio?.cat_estado_desc?.toUpperCase()}.
                        A:{' '}
                        <strong style={{ textTransform: 'capitalize' }}>
                          {moment(date).locale('ES').format('DD MMMM YYYY h:mm:ss a')}{' '}
                        </strong>
                      </p>
                    </span>
                    <br />
                    <div>
                      <p style={{ textAlign: 'start' }}>
                        <strong>
                          AVISO DE PRIVACIDAD
                          <br />
                        </strong>
                      </p>
                      <p style={{ fontSize: '0.8rem' }}>
                        Para ver nuestro Aviso de privacidad favor consultar:
                        http://zivot.com.mx/aviso-privacidad
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

SimpleCardsDocument.defaultProps = {
  diagnostico: '',
  rep: '',
  repIdentification: '',
  par_rep_legal: '',
  repLegal: '',
  repLegalIdentification: '',
  par_rep: '',
  testigo1: '',
  testigo1Identificacion: '',
  testigo2: '',
  testigo2Identificacion: '',
};
export default SimpleCardsDocument;
