/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Dialog, DialogContent, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { UserTypes } from '../../interfaces/User';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import { getBase64 } from '../../utils/fns';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { postWithToken } from '../../utils/server';
import { Laboratory } from '../../interfaces/Laboratory';
import { StyledButton } from '../../components/PrimaryButton/PrimaryButton.Styled';
import { mainTheme } from '../../utils/theme/theme';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import { DisclaimerText } from '../../components/DisclaimerText/DisclaimerText';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: Laboratory;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: Laboratory = {
  titulo: '',
  descripcion: '',
  hemoglobina: '',
  hematocrito: '',
  heucocitos: '',
  neutrofilos: '',
  bandas: '',
  linfocitos: '',
  plaquetas: '',
  glucosa: '',
  creatinina: '',
  bun: '',
  sodio: '',
  potacio: '',
  cloro: '',
  calcio: '',
  magnesio: '',
  fosforo: '',
  fosfatasa: '',
  dhl: '',
  ast: '',
  orina: '',
  antigeno: '',
  alt: '',
  bili: '',
  bd: '',
  albumina: '',
  globulina: '',
  ggt: '',
  tp: '',
  tpt: '',
  inr: '',
  cpk: '',
  cpkmb: '',
  amilasa: '',
  lipasa: '',
  ags: '',
  vhc: '',
  vih: '',
  protO: '',
  depCre: '',
  otro: '',
  uroc: '',
  antiP: '',
  alfa: '',
  bodo: '',
  desi: '',
};

function LaboratoryForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [transformedFile, setTransformedFiles] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [error, setError] = useState<string>('');
  const [fileTitle, setFileTitle] = useState<string>('');
  const [fileExtensions, setFileExtension] = useState<string>('');
  const fieldsDisabled = useMemo(() => {
    if (user?.typeId === UserTypes.PatientType) {
      return mode === CRUDModes.Update && !!formValues.id_medico;
    }
    return mode === CRUDModes.Update && user?.id !== formValues.id_medico;
  }, [user, mode, formValues.id_medico]);

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  const handleUpload = async (e: any) => {
    try {
      const maximumSize = 10 * 1024 * 1024; // In MegaBytes
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size > maximumSize) {
          setAlertType(AlertTypes.Error);
          setAlertMessage('El peso del archivo es mayor al permitido');
          setOpenAlert(true);
          return;
        }
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiles(base64);
        setFileTitle(e.target.files[0].name);
        setFileExtension(e.target.files[0].type.split('/')[1]);
      }
    } catch (err) {
      handleLogout(err);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  const formik = useFormik<Laboratory>({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: async (laboratoryValues) => {
      try {
        if ((!transformedFile || !fileExtensions) && !laboratoryValues.url) {
          setError('Por favor sube los archivos requeridos');
          return;
        }
        setError('');
        setLoading(true);
        const laboratoryResult = await postWithToken<
          {
            laboratory: Laboratory;
            patientId?: number;
            file?: string | ArrayBuffer | null;
            extension?: string;
          },
          Laboratory
        >(
          `/laboratory/${mode === CRUDModes.Create ? 'save' : 'update'}-laboratory`,
          {
            laboratory: {
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
              ...laboratoryValues,
            },
            patientId:
              user?.typeId === UserTypes.PatientType
                ? user?.id
                : currentPatient?.id ?? undefined,
            file: transformedFile,
            extension: fileExtensions,
          },
          handleLogout,
        );
        if (laboratoryResult.success) {
          setLoading(false);
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar resultados de estudio de laboratorio: ${JSON.stringify(
              e.response.data,
            )} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='resultado de estudio de
        laboratorio'
      />

      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Agregar nuevo resultado de estudio de laboratorio:
                </PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Recuerda llenar la información correctamente
                </PrimaryDescription>

                <Grid item xs={12} md={12}>
                  <CMTextfield
                    id='titulo'
                    label='Título'
                    placeholder='Título'
                    value={formik.values.titulo}
                    name='titulo'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Descripción</CMInputLabel>
                  <CMTextArea
                    id='descripcion'
                    name='descripcion'
                    value={formik.values.descripcion}
                    onChange={formik.handleChange}
                    minRows={2}
                    placeholder='Descripción'
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='hemoglobina'
                    label='Hemoglobina'
                    placeholder='Hemoglobina'
                    value={formik.values.hemoglobina}
                    name='hemoglobina'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='hematocrito'
                    label='Hematocrito'
                    placeholder='Hematocrito'
                    value={formik.values.hematocrito}
                    name='hematocrito'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='heucocitos'
                    label='Leucocitos'
                    placeholder='Leucocitos'
                    value={formik.values.heucocitos}
                    name='heucocitos'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='neutrofilos'
                    label='Neutrofilos'
                    placeholder='Neutrofilos'
                    value={formik.values.neutrofilos}
                    name='neutrofilos'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='bandas'
                    label='Bandas'
                    placeholder='Bandas'
                    value={formik.values.bandas}
                    name='bandas'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='linfocitos'
                    label='Linfocitos'
                    placeholder='Linfocitos'
                    value={formik.values.linfocitos}
                    name='linfocitos'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='plaquetas'
                    label='Plaquetas'
                    placeholder='Plaquetas'
                    value={formik.values.plaquetas}
                    name='plaquetas'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='glucosa'
                    label='Glucosa'
                    placeholder='Glucosa'
                    value={formik.values.glucosa}
                    name='glucosa'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='creatinina'
                    label='Creatinina'
                    placeholder='Creatinina'
                    value={formik.values.creatinina}
                    name='creatinina'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='bun'
                    label='BUN'
                    placeholder='BUN'
                    value={formik.values.bun}
                    name='bun'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='sodio'
                    label='Sodio'
                    placeholder='Sodio'
                    value={formik.values.sodio}
                    name='sodio'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='potacio'
                    label='Potacio'
                    placeholder='Potacio'
                    value={formik.values.potacio}
                    name='potacio'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='cloro'
                    label='Cloro'
                    placeholder='Cloro'
                    value={formik.values.cloro}
                    name='cloro'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='calcio'
                    label='Calcio'
                    placeholder='Calcio'
                    value={formik.values.calcio}
                    name='calcio'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='magnesio'
                    label='Magnesio'
                    placeholder='Magnesio'
                    value={formik.values.magnesio}
                    name='magnesio'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='fosforo'
                    label='Fosforo'
                    placeholder='Fosforo'
                    value={formik.values.fosforo}
                    name='fosforo'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='fosfatasa'
                    label='Fosfatasa Al.'
                    placeholder='Fosfatasa Al.'
                    value={formik.values.fosfatasa}
                    name='fosfatasa'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='dhl'
                    label='DHL'
                    placeholder='DHL'
                    value={formik.values.dhl}
                    name='dhl'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='ast'
                    label='AST'
                    placeholder='AST'
                    value={formik.values.ast}
                    name='ast'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='orina'
                    label='Exámen general de orina'
                    placeholder='Exámen general de orina'
                    value={formik.values.orina}
                    name='orina'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='antigeno'
                    label='Antígeno propático esp. total'
                    placeholder='Antígeno propático esp. total'
                    value={formik.values.antigeno}
                    name='antigeno'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='alt'
                    label='ALT'
                    placeholder='ALT'
                    value={formik.values.alt}
                    name='alt'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='bili'
                    label='Bilirrubinas'
                    placeholder='Bilirrubinas'
                    value={formik.values.bili}
                    name='bili'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='bd'
                    label='BD/BI'
                    placeholder='BD/BI'
                    value={formik.values.bd}
                    name='bd'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='albumina'
                    label='Albumina'
                    placeholder='Albumina:'
                    value={formik.values.albumina}
                    name='albumina'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='globulina'
                    label='Globulina'
                    placeholder='Globulina:'
                    value={formik.values.globulina}
                    name='globulina'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='ggt'
                    label='GGT'
                    placeholder='GGT'
                    value={formik.values.ggt}
                    name='ggt'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='tp'
                    label='TP'
                    placeholder='TP'
                    value={formik.values.tp}
                    name='tp'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='tpt'
                    label='TPT'
                    placeholder='TPT'
                    value={formik.values.tpt}
                    name='tpt'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='inr'
                    label='INR'
                    placeholder='INR'
                    value={formik.values.inr}
                    name='inr'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='cpk'
                    label='CPK'
                    placeholder='CPK'
                    value={formik.values.cpk}
                    name='cpk'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='cpkmb'
                    label='CPKMB'
                    placeholder='CPKMB'
                    value={formik.values.cpkmb}
                    name='cpkmb'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='amilasa'
                    label='Amilasa'
                    placeholder='Amilasa'
                    value={formik.values.amilasa}
                    name='amilasa'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='lipasa'
                    label='Lipasa'
                    placeholder='Lipasa'
                    value={formik.values.lipasa}
                    name='lipasa'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='ags'
                    label='AGS HB'
                    placeholder='AGS HB'
                    value={formik.values.ags}
                    name='ags'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='vhc'
                    label='VHC'
                    placeholder='VHC'
                    value={formik.values.vhc}
                    name='vhc'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='vih'
                    label='VIH'
                    placeholder='VIH'
                    value={formik.values.vih}
                    name='vih'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='protO'
                    label='Proteínas en orina'
                    placeholder='Proteínas en orina'
                    value={formik.values.protO}
                    name='protO'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='depCre'
                    label='Depuración creatinina'
                    placeholder='Depuración creatinina'
                    value={formik.values.depCre}
                    name='depCre'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='otro'
                    label='Otros'
                    placeholder='Otros'
                    value={formik.values.otro}
                    name='otro'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='uroc'
                    label='Urocultivos'
                    placeholder='Urocultivos'
                    value={formik.values.uroc}
                    name='uroc'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='antiP'
                    label='Antígeno prostático específico libre'
                    placeholder='Antígeno prostático específico libre'
                    value={formik.values.antiP}
                    name='antiP'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='alfa'
                    label='Alfafetoproteína'
                    placeholder='Alfafetoproteína'
                    value={formik.values.alfa}
                    name='alfa'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='bodo'
                    label='Gonadotropina corionica (Fracción beta)'
                    placeholder='Gonadotropina corionica (Fracción beta)'
                    value={formik.values.bodo}
                    name='bodo'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} md={12} style={{ display: 'flex', flexDirection: 'row' }}>
                  <CMTextfield
                    id='desi'
                    label='Deshidrogenasa láctica'
                    placeholder='Deshidrogenasa láctica'
                    value={formik.values.desi}
                    name='desi'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ marginBottom: '0.5rem', marginTop: '3rem' }}
              >
                <>
                  <label htmlFor='upload-photo'>
                    <input
                      style={{ display: 'none' }}
                      id='upload-photo'
                      name='upload-photo'
                      type='file'
                      accept='image/png, image/jpeg, */dicom,.dcm, image/dcm, */dcm, .dicom, application/pdf, .pdf'
                      onChange={handleUpload}
                      disabled={fieldsDisabled}
                      onBlur={formik.handleBlur}
                    />
                    {!fieldsDisabled ? (
                      <>
                        <StyledButton
                          component='span'
                          style={{
                            width: mobile ? '100%' : 'initial',
                            marginBottom: mobile ? '1rem' : 'initial',
                          }}
                        >
                          Seleccionar archivo
                        </StyledButton>
                        <span
                          style={{
                            marginLeft: mobile ? 'initial' : '2%',
                            color: mainTheme.colors.standardText,
                            display: mobile ? 'block' : 'initial',
                          }}
                        >
                          {fileTitle || formik.values.url}
                        </span>
                      </>
                    ) : null}
                  </label>
                </>
              </Grid>
              {!fieldsDisabled ? (
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <span
                    style={{
                      color: mainTheme.colors.standardText,
                      display: mobile ? 'block' : 'initial',
                    }}
                  >
                    El peso máximo del archivo es 10 MB. los formatos permitidos son .png,
                    .jpg, jpeg, dcm, pdf
                  </span>
                </Grid>
              ) : null}
              {error ? (
                <DisclaimerText
                  style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}
                >
                  {error}
                </DisclaimerText>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

LaboratoryForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default LaboratoryForm;
