import React, { useEffect, useRef, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { BsCheckCircle, BsCircle } from 'react-icons/all';
import { ResetPasswordForm } from '../../../../interfaces/ForgotPassword';
import { AlertTypes } from '../../../../interfaces/MainContextInitialValues';
import { PageContainer } from '../../../Register/Register.styled';
import CenteredForm from '../../../../index.styled';
import { PrimaryTitle } from '../../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import PasswordTextfield from '../../../../components/Forms/PasswordTextfield';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { useSponsorsProvider } from '../../Context/SponsorsMainContext';

const SponsorResetPasswordPage = (): JSX.Element => {
  const { setOpenAlert, setAlertMessage, setAlertType } = useSponsorsProvider();
  const [buttonLoading, setButtonLoading] = useState(false);
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const navigator = useRef(useNavigate());
  const { accountId, token } = useParams();
  const validationSchema = Yup.object({
    password: Yup.string()
      .required('La contraseña es un campo obligatorio')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Debe contener 8 caracteres, una mayuscula, una minuscula, un número y un caracter especial como mínimo',
      ),
    confirmPassword: Yup.string()
      .required('La contraseña es un campo obligatorio')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        'Debe contener 8 caracteres, una mayuscula, una minuscula, un número y un caracter especial como mínimo',
      ),
  });

  const [passwordFormatActive, setPasswordFormatActive] = useState<boolean>(false);
  const [passwordLenght, setPasswordLenght] = useState<boolean>(false);
  const [lowerCase, setLowerCase] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [number, setNumber] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<boolean>(false);
  const [samePassword, setSamePassword] = useState<boolean>(false);

  useEffect(() => {
    if (!accountId || !token) {
      navigator.current('/sponsors/login');
    }
  }, [accountId, token]);

  const formik = useFormik<ResetPasswordForm>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    /**
     * @desc logins the user with the given information
     * @param form
     */
    onSubmit: async (form) => {
      try {
        if (form.password === form.confirmPassword) {
          setButtonLoading(true);
          const emailResult = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/sponsors/reset`,
            { newPassword: form.password, accountId, token },
          );
          if (emailResult.status === 200) {
            setAlertType(AlertTypes.Success);
            setAlertMessage(`¡Contraseña reestablecida exitosamente!`);
            setOpenAlert(true);
            setButtonLoading(false);
            navigator.current('/sponsors/login');
          } else {
            setButtonLoading(false);
          }
        } else {
          formik.touched.password = true;
          formik.errors.password = 'Las contraseñas no coinciden';
          formik.touched.confirmPassword = true;
          formik.errors.confirmPassword = 'La contraseñas no coinciden';
        }
      } catch (e: any) {
        if (JSON.stringify(e.response.data)) {
          setButtonLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al restablecer contraseña: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setButtonLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  /**
   *
   * @desc checks for the string format and sets the state for all the needed formats
   * @param { string } the current password value
   */
  const validatePasswordFormat = (
    value: string,
  ): {
    lowerCaseResult: boolean;
    upperCaseResult: boolean;
    numberResult: boolean;
    symbolResult: boolean;
  } => {
    const passwordLenghtResult = value.length >= 8;
    const lowerCaseResult = /.*[a-z].*/.test(value);
    const upperCaseResult = /.*[A-Z].*/.test(value);
    const numberResult = /\d/.test(value);
    const symbolResult = /[*@!#%&()^~{}]+/.test(value);
    setPasswordLenght(passwordLenghtResult);
    setLowerCase(lowerCaseResult);
    setUpperCase(upperCaseResult);
    setNumber(numberResult);
    setSymbol(symbolResult);
    return {
      lowerCaseResult,
      upperCaseResult,
      numberResult,
      symbolResult,
    };
  };

  const validateSamePassword = (
    value: string,
    passwordValue: string,
  ): {
    samePasswordResult: boolean;
  } => {
    const samePasswordResult = value === passwordValue;
    setSamePassword(samePasswordResult);
    return {
      samePasswordResult,
    };
  };

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5} lg={3}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              key='reset'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <PrimaryTitle mobile={mobile}>Reestablecer contraseña</PrimaryTitle>
                <PrimaryDescription mobile={mobile}>
                  Escribe una nueva contraseña para tu cuenta. Recuerda que debe tener
                  mínimo 8 caracteres.
                </PrimaryDescription>
                <PasswordTextfield
                  id='outlined-basic'
                  label='Contraseña'
                  value={formik.values.password}
                  name='password'
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  onChange={(e) => {
                    validatePasswordFormat(e.target.value);
                    formik.handleChange(e);
                  }}
                  onFocus={() => setPasswordFormatActive(true)}
                />
                <PasswordTextfield
                  id='outlined-basic'
                  label='Confirmar'
                  value={formik.values.confirmPassword}
                  name='confirmPassword'
                  style={{ marginBottom: '1rem' }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword && formik.errors.confirmPassword
                  }
                  onChange={(e) => {
                    validateSamePassword(e.target.value, formik.values.password);
                    formik.handleChange(e);
                  }}
                />
                {passwordFormatActive && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                    style={{
                      textAlign: 'start',
                      width: '100%',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '12px',
                      backgroundColor: '#fafafa',
                      padding: '1rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <div>
                      <p style={{ marginTop: '0', marginBottom: '.8rem' }}>
                        {' '}
                        La contraseña debe tener minimo{' '}
                      </p>

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {passwordLenght ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: passwordLenght ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          8 caracteres{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {lowerCase ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: lowerCase ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Una minuscula{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {upperCase ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: upperCase ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Una mayuscula{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {number ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: number ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Un número{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {symbol ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: symbol ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Un simbolo{' '}
                        </p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {samePassword ? (
                          <BsCheckCircle
                            style={{
                              color: 'green',
                              marginRight: '2%',
                            }}
                          />
                        ) : (
                          <BsCircle
                            style={{
                              color: 'red',
                              marginRight: '2%',
                            }}
                          />
                        )}
                        <p
                          style={{
                            color: samePassword ? 'green' : 'red',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                          }}
                        >
                          Las contraseñas deben coincidir{' '}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                )}
                <PrimaryButton
                  loading={buttonLoading}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  Cambiar contraseña
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default SponsorResetPasswordPage;
