export enum VaccinePeriods {
  Newborn,
  TwoMonths,
  FourMonths,
  SixMonths,
  NineMonths,
  TwelveMonths,
  FifteenMonths,
  EighteenMonths,
  TwoYears,
  FourYears,
  SixYears,
}

export const vaccineDictionary: { [key in VaccinePeriods]: string } = {
  [VaccinePeriods.Newborn]: 'Recién nacido',
  [VaccinePeriods.TwoMonths]: '2 meses',
  [VaccinePeriods.FourMonths]: '4 meses',
  [VaccinePeriods.SixMonths]: '6 meses',
  [VaccinePeriods.NineMonths]: '8 meses',
  [VaccinePeriods.TwelveMonths]: '12 meses',
  [VaccinePeriods.FifteenMonths]: '15 meses',
  [VaccinePeriods.EighteenMonths]: '18 meses',
  [VaccinePeriods.TwoYears]: '2 años',
  [VaccinePeriods.FourYears]: '4 años',
  [VaccinePeriods.SixYears]: '6 años',
};
