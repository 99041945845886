import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { CivilStatuses } from '../interfaces/CivilStatuses';
import { Specialities } from '../interfaces/DoctorSpecialities';
import {
  AlertTypes,
  MainContextInitialValues,
} from '../interfaces/MainContextInitialValues';
import { NursesSpecialities } from '../interfaces/NurseSpecialities';
import { Ocupations } from '../interfaces/Ocupations';
import { ScholarDegree } from '../interfaces/ScholarDegree';
import { Sexes } from '../interfaces/Sexes';
import { States } from '../interfaces/States';
import { Titles } from '../interfaces/Titles';
import { TemporaryUser, User } from '../interfaces/User';
import { Cie10 } from '../interfaces/Cie10';
import { SpecialCardsType } from '../interfaces/SpecialCardsTypes';
import EmptyClosure from '../utils/closures';
import { getLocalstorageData, removeLocalstorageData } from '../utils/localStorage';
import { gettWithToken } from '../utils/server';
import { InfoViewerRow } from '../interfaces/InfoViewer';
import { sortObjectArray } from '../utils/fns';
import { QRModes } from '../interfaces/QRCodes';
import { InfoForDoctorProfile } from '../interfaces/DoctorProfile';
import { IOSHandlerFunctions, postWebkitMessage } from '../interfaces/WebkitWindow';

const initialContextValue: MainContextInitialValues = {
  openAlert: false,
  alertMessage: '',
  alertType: AlertTypes.Success,
  loading: false,
  initialLoad: false,
  setUser: EmptyClosure,
  setOpenAlert: EmptyClosure,
  setAlertMessage: EmptyClosure,
  setAlertType: EmptyClosure,
  setLoading: EmptyClosure,
  setInitialLoad: EmptyClosure,
  loadInitialData: EmptyClosure,
  nurseSpecialities: [],
  getNurseSpecialities: EmptyClosure,
  getStates: EmptyClosure,
  states: [],
  getDoctorSpecialities: EmptyClosure,
  specialities: [],
  getTitles: EmptyClosure,
  titles: [],
  getSexes: EmptyClosure,
  sexes: [],
  getCivilStatuses: EmptyClosure,
  civilStatuses: [],
  getOcupations: EmptyClosure,
  ocupations: [],
  getScholarDegrees: EmptyClosure,
  scholarDegrees: [],
  getCie10: EmptyClosure,
  cie10: [],
  isHMOpen: false,
  setIsHMOpen: EmptyClosure,
  specialCardTypes: [],
  getSpecialCardTypes: EmptyClosure,
  setCurrentPatient: EmptyClosure,
  setTemporaryUsers: EmptyClosure,
  temporaryUsers: [],
  qrModeOpen: undefined,
  setQrModeOpen: EmptyClosure,
  qrCode: '',
  setQrCode: EmptyClosure,
  infoViewerInfo: {},
  setInfoViewerInfo: EmptyClosure,
  infoViewerOpen: false,
  setInfoViewerOpen: EmptyClosure,
  restartTemporayUsers: EmptyClosure,
  infoForDoctorProfile: undefined,
  setInfoForDoctorProfile: EmptyClosure,
  loginPopupOpen: false,
  setLoginPopupOpen: EmptyClosure,
  handleLogout: EmptyClosure,
};

const MainContext: React.Context<MainContextInitialValues> =
  createContext(initialContextValue);

function useMainProvider(): MainContextInitialValues {
  const context = useContext(MainContext);
  if (!context) {
    throw new Error('Context is not initialized');
  }
  return context;
}

function MainProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [user, setUser] = useState<User>();
  const [currentPatient, setCurrentPatient] = useState<TemporaryUser>();
  const [temporaryUsers, setTemporaryUsers] = useState<TemporaryUser[]>([]);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertType, setAlertType] = useState<AlertTypes>(AlertTypes.Success);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [nurseSpecialities, setNurseSpecialities] = useState<NursesSpecialities[]>([]);
  const [states, setStates] = useState<States[]>([]);
  const [specialities, setSpecialities] = useState<Specialities[]>([]);
  const [titles, setTitles] = useState<Titles[]>([]);
  const [sexes, setSexes] = useState<Sexes[]>([]);
  const [civilStatuses, setCivilStatuses] = useState<CivilStatuses[]>([]);
  const [ocupations, setOcupations] = useState<Ocupations[]>([]);
  const [scholarDegrees, setScholarDegrees] = useState<ScholarDegree[]>([]);
  const [cie10, setCie10] = useState<Cie10[]>([]);
  const [isHMOpen, setIsHMOpen] = useState(false);
  const [specialCardTypes, setSpecialCardTypes] = useState<SpecialCardsType[]>([]);
  const [qrModeOpen, setQrModeOpen] = useState<QRModes>();
  const [qrCode, setQrCode] = useState('');
  const [infoViewerInfo, setInfoViewerInfo] = useState<InfoViewerRow>({});
  const [infoViewerOpen, setInfoViewerOpen] = useState(false);
  const [infoForDoctorProfile, setInfoForDoctorProfile] =
    useState<InfoForDoctorProfile>();
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const navigate = useNavigate();

  const loadInitialData = useCallback(() => {
    // Check local storage for user information and st the user state if needed
    const localStorageUser = getLocalstorageData<User>('CODIGO_MEDICO_USER_INFORMATION');
    if (localStorageUser.success) {
      if (localStorageUser.data && localStorageUser.data.token) {
        setUser(localStorageUser.data);
      }
    }
    setInitialLoad(true);
  }, []);

  const handleLogout = useCallback(
    (e: any) => {
      const error = e as { response?: { data?: any } };
      if (
        error?.response?.data &&
        JSON.stringify(error.response.data) &&
        JSON.stringify(error.response.data).includes('Error in authentication')
      ) {
        postWebkitMessage({
          function: IOSHandlerFunctions.Logout,
        });
        setUser(undefined);
        setCurrentPatient(undefined);
        setTemporaryUsers([]);
        setQrCode('');
        removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
        navigate('/login');
      }
    },
    [navigate],
  );

  const getNurseSpecialities = useCallback(async () => {
    const getNurseSpecialitiesResult = await gettWithToken<NursesSpecialities[]>(
      '/nurse/get-nurse-specialities',
      handleLogout,
    );
    if (getNurseSpecialitiesResult.success) {
      setNurseSpecialities(
        sortObjectArray<NursesSpecialities>(
          getNurseSpecialitiesResult.data,
          'cat_especialidad_desc',
        ),
      );
    }
  }, [handleLogout]);

  const getStates = useCallback(async () => {
    const getStatesResult = await gettWithToken<States[]>('/get-states', handleLogout);
    if (getStatesResult.success) {
      setStates(getStatesResult.data);
    }
  }, [handleLogout]);

  const getDoctorSpecialities = useCallback(async () => {
    const getSpecialitiesResult = await gettWithToken<Specialities[]>(
      '/doctor/get-specialities',
      handleLogout,
    );
    if (getSpecialitiesResult.success) {
      setSpecialities(
        sortObjectArray<Specialities>(
          getSpecialitiesResult.data,
          'cat_especialidad_desc',
        ),
      );
    }
  }, [handleLogout]);

  const getTitles = useCallback(async () => {
    const getTitlesResult = await gettWithToken<Titles[]>('/get-titles', handleLogout);
    if (getTitlesResult.success) {
      setTitles(getTitlesResult.data);
    }
  }, [handleLogout]);

  const getSexes = useCallback(async () => {
    const getSexesResult = await gettWithToken<Sexes[]>('/get-sexes', handleLogout);
    if (getSexesResult.success) {
      setSexes(getSexesResult.data);
    }
  }, [handleLogout]);

  const getCivilStatuses = useCallback(async () => {
    const getCivilStatusesResult = await gettWithToken<CivilStatuses[]>(
      '/get-civil-statuses',
      handleLogout,
    );
    if (getCivilStatusesResult.success) {
      setCivilStatuses(getCivilStatusesResult.data);
    }
  }, [handleLogout]);

  const getOcupations = useCallback(async () => {
    const getOcupationsResult = await gettWithToken<Ocupations[]>(
      '/get-ocupations',
      handleLogout,
    );
    if (getOcupationsResult.success) {
      setOcupations(
        sortObjectArray<Ocupations>(getOcupationsResult.data, 'cat_ocup_desc'),
      );
    }
  }, [handleLogout]);

  const getScholarDegrees = useCallback(async () => {
    const getScholarDegreesResult = await gettWithToken<ScholarDegree[]>(
      '/get-scholar-degrees',
      handleLogout,
    );
    if (getScholarDegreesResult.success) {
      setScholarDegrees(getScholarDegreesResult.data);
    }
  }, [handleLogout]);

  const getCie10 = useCallback(async () => {
    if (cie10.length === 0) {
      const getCie10Result = await gettWithToken<Cie10[]>('/get-cie10', handleLogout);
      if (getCie10Result.success) {
        setCie10(getCie10Result.data);
      }
    }
  }, [cie10, handleLogout]);

  const getSpecialCardTypes = useCallback(async () => {
    if (specialCardTypes.length === 0) {
      const specialCardsTypeResult = await gettWithToken<SpecialCardsType[]>(
        '/cards/get-special-cards-types',
        handleLogout,
      );

      if (specialCardsTypeResult.success) {
        setSpecialCardTypes(
          sortObjectArray<SpecialCardsType>(specialCardsTypeResult.data, 'titulo'),
        );
      }
    }
  }, [specialCardTypes.length, handleLogout]);

  const restartTemporayUsers = useCallback(async () => {
    try {
      const userFetch = await gettWithToken<TemporaryUser[]>(
        '/temporaryAccess/getAvailablePatients',
        handleLogout,
      );
      if (userFetch.success) {
        setTemporaryUsers(userFetch.data);
        setCurrentPatient(undefined);
      }
    } catch (e) {
      handleLogout(e);
      // No c hace nada
    }
  }, [handleLogout]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  return (
    <MainContext.Provider
      value={{
        user,
        setUser,
        openAlert,
        setOpenAlert,
        alertMessage,
        setAlertMessage,
        alertType,
        setAlertType,
        loading,
        setLoading,
        initialLoad,
        setInitialLoad,
        loadInitialData,
        getNurseSpecialities,
        nurseSpecialities,
        getStates,
        states,
        getDoctorSpecialities,
        specialities,
        getTitles,
        titles,
        getSexes,
        sexes,
        getCivilStatuses,
        civilStatuses,
        getOcupations,
        ocupations,
        getScholarDegrees,
        scholarDegrees,
        getCie10,
        cie10,
        isHMOpen,
        setIsHMOpen,
        currentPatient,
        setCurrentPatient,
        temporaryUsers,
        setTemporaryUsers,
        qrModeOpen,
        setQrModeOpen,
        qrCode,
        setQrCode,
        getSpecialCardTypes,
        specialCardTypes,
        infoViewerInfo,
        setInfoViewerInfo,
        infoViewerOpen,
        setInfoViewerOpen,
        restartTemporayUsers,
        infoForDoctorProfile,
        setInfoForDoctorProfile,
        loginPopupOpen,
        setLoginPopupOpen,
        handleLogout,
      }}
    >
      {children}
    </MainContext.Provider>
  );
}

export default MainProvider;
export { useMainProvider };
