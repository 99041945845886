import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { visuallyHidden } from '@mui/utils';
import { mainTheme } from '../../utils/theme/theme';
import { PageContainer } from '../Register/Register.styled';
import {
  GenericColumnTypes,
  GenericTableProps,
  GenericTableRow,
} from '../../interfaces/GenericTable';
import EmptyClosure from '../../utils/closures';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { StandardText } from '../../components/StandardText/StandardText';
import { NoPaddingButton } from '../../components/NoPaddingButton/NoPaddingButton';
import { useMainProvider } from '../../context/MainProvider';
import Searchbar, { SearchbarForm } from '../../components/Searchbar/Searchbar';
import { FlexContainer } from '../../components/FlexContainer/FlexContainer';
import useAds from '../../hooks/useAds';
import Ad from '../../components/Ad/Ad';
import { AdTypes } from '../../interfaces/Ads';

function GenericTablePage({
  title,
  columns,
  canEdit,
  onEdit,
  canDelete,
  onDelete,
  onCreate,
  canCreate,
  rows,
  onRowClick,
  locationId,
}: GenericTableProps): JSX.Element {
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState(columns.length > 0 ? columns[0].name : '');
  const [rowsSelected, setRowsSelected] = useState<number[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const { setInfoForDoctorProfile } = useMainProvider();
  const [displayedRows, setDisplayedRows] = useState<GenericTableRow[]>([]);
  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const adInfo = useAds(locationId);

  useEffect(() => {
    setDisplayedRows(rows);
    setPage(0);
  }, [rows]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - displayedRows.length) : 0;

  useEffect(() => {
    // Change backgroundColor
    document.body.style.backgroundColor = mainTheme.colors.loggedInBackground;
    return () => {
      document.body.style.backgroundColor = 'white';
    };
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((element, index) => index);
      setRowsSelected(newSelecteds);
      return;
    }
    setRowsSelected([]);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a: GenericTableRow, b: GenericTableRow, ordBy: string) {
    if (b[ordBy] < a[ordBy]) {
      return -1;
    }
    if (b[ordBy] > a[ordBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderType: 'asc' | 'desc', ordBy: string) {
    return orderType === 'desc'
      ? (a: GenericTableRow, b: GenericTableRow) => descendingComparator(a, b, ordBy)
      : (a: GenericTableRow, b: GenericTableRow) => -descendingComparator(a, b, ordBy);
  }

  const isSelected = (index: number) => rowsSelected.indexOf(index) !== -1;

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    const selectedIndex = rowsSelected.indexOf(index);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(rowsSelected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(rowsSelected.slice(1));
    } else if (selectedIndex === rowsSelected.length - 1) {
      newSelected = newSelected.concat(rowsSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        rowsSelected.slice(0, selectedIndex),
        rowsSelected.slice(selectedIndex + 1),
      );
    }

    setRowsSelected(newSelected);
  };

  const handleSearch = ({ columnName, value }: SearchbarForm) => {
    if (value === '') {
      setDisplayedRows(rows);
      setPage(0);
      return;
    }
    const newResults = rows.filter((row) => {
      if (row[columnName] === undefined) {
        return false;
      }
      return row[columnName].toString().toLowerCase().includes(value.toLowerCase());
    });
    setDisplayedRows(newResults);
    setPage(0);
  };

  return (
    <PageContainer>
      <FlexContainer direction='row' justifyContent='space-between'>
        <div style={{ width: adInfo && !mobile ? 'calc(100% - 1rem - 160px)' : '100%' }}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              key='genericTable'
            >
              <Grid
                container
                direction={mobile ? 'row-reverse' : 'row'}
                justifyContent='flex-start'
                alignItems='flex-end'
                style={{ padding: '0 0 0.5rem 0' }}
              >
                <Grid item xs={12} md={8} style={{ marginBottom: '0.5rem' }}>
                  {adInfo && mobile ? (
                    <FlexContainer
                      direction='row'
                      justifyContent='center'
                      style={{ marginBottom: '1.5rem' }}
                    >
                      <Ad adInfo={adInfo} type={AdTypes.mobileBanner} />
                    </FlexContainer>
                  ) : null}
                  {rowsSelected.length > 0 ? (
                    <PrimarySubtitle
                      mobile
                      style={{ margin: 0, overflowWrap: 'break-word' }}
                    >
                      {rowsSelected.length} seleccionados
                    </PrimarySubtitle>
                  ) : (
                    <PrimarySubtitle mobile style={{ overflowWrap: 'break-word' }}>
                      {title}
                    </PrimarySubtitle>
                  )}
                  <Searchbar rows={rows} columns={columns} onSearch={handleSearch} />
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: '0.5rem' }}>
                  <FlexContainer
                    direction='column'
                    justifyContent='flex-end'
                    alignItems='flex-end'
                    alignContent='flex-end'
                  >
                    {canCreate ? (
                      <PrimaryButton loading={false} onClick={onCreate}>
                        Nuevo
                      </PrimaryButton>
                    ) : null}
                  </FlexContainer>
                </Grid>
              </Grid>
              <Grid
                container
                direction='row'
                style={{
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  padding: '0.5rem 1rem',
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {canDelete ? (
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              indeterminate={
                                rowsSelected.length > 0 &&
                                rowsSelected.length < displayedRows.length
                              }
                              checked={
                                displayedRows.length > 0 &&
                                rowsSelected.length === displayedRows.length
                              }
                              onChange={handleSelectAllClick}
                              inputProps={{
                                'aria-label': 'selecciona todas las filas',
                              }}
                            />
                          </TableCell>
                        ) : null}
                        {columns.map((column) => (
                          <TableCell
                            key={`COL-${column.name}`}
                            align='left'
                            padding='normal'
                            sortDirection={orderBy === column.name ? order : false}
                          >
                            <TableSortLabel
                              hideSortIcon={!column.sortable}
                              active={orderBy === column.name}
                              direction={orderBy === column.name ? order : 'asc'}
                              onClick={() => handleRequestSort(column.name)}
                            >
                              <b>{column.displayName}</b>
                              {orderBy === column.name ? (
                                <Box component='span' sx={visuallyHidden}>
                                  {order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {displayedRows
                        .sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(index);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          const rowKey = `ROW-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={() => (onRowClick ? onRowClick(row) : {})}
                              role='checkbox'
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={rowKey}
                              selected={isItemSelected}
                            >
                              {canDelete ? (
                                <TableCell padding='checkbox'>
                                  <Checkbox
                                    color='primary'
                                    checked={isItemSelected}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    onClick={(e) => handleClick(e, index)}
                                  />
                                </TableCell>
                              ) : null}
                              {Object.values(row).map((rowData, columnNumber) => {
                                const key = `RowNo${index}Col${columnNumber}`;
                                const returnValue = () => {
                                  switch (columns[columnNumber].type) {
                                    case GenericColumnTypes.Action:
                                      return (
                                        <PrimaryButton
                                          onClick={() =>
                                            columns[columnNumber] &&
                                            columns[columnNumber].onActionButtonClick &&
                                            canEdit // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              ? // @ts-ignore
                                                columns[columnNumber].onActionButtonClick(
                                                  index,
                                                )
                                              : {}
                                          }
                                          loading={false}
                                        >
                                          {rowData}
                                        </PrimaryButton>
                                      );
                                    case GenericColumnTypes.Boolean:
                                      return rowData ? 'Si' : 'No';
                                    case GenericColumnTypes.Date: {
                                      return moment(rowData)
                                        .locale('ES')
                                        .format('DD MMMM YYYY h:mm:ss a');
                                    }
                                    case GenericColumnTypes.ShowProfile:
                                      return (
                                        <NoPaddingButton
                                          onClick={() => {
                                            const currentColumn = columns[columnNumber];
                                            const rowWithId = row as { id: number };
                                            if (
                                              currentColumn &&
                                              currentColumn.documentType &&
                                              rowWithId.id
                                            ) {
                                              setInfoForDoctorProfile({
                                                documentId: rowWithId.id,
                                                documentType: currentColumn.documentType,
                                              });
                                            }
                                          }}
                                        >
                                          {(rowData as string).toUpperCase()}
                                        </NoPaddingButton>
                                      );
                                    case GenericColumnTypes.Text:
                                      return (rowData as string).toUpperCase();
                                    default:
                                      return rowData;
                                  }
                                };
                                return (
                                  <TableCell key={key} align='left'>
                                    {returnValue()}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                      {displayedRows
                        .sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .length <= 0 ? (
                        <TableRow>
                          <TableCell colSpan={12}>
                            <StandardText style={{ textAlign: 'center' }}>
                              Actualmente no hay datos
                            </StandardText>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15, 25]}
                  component='div'
                  count={displayedRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={(e, newPage) => handleChangePage(newPage)}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </motion.div>
          </AnimatePresence>
        </div>
        {adInfo && !mobile ? (
          <div style={{ width: '160px' }}>
            <Ad adInfo={adInfo} type={AdTypes.wideSkyscraper} />
          </div>
        ) : null}
      </FlexContainer>
    </PageContainer>
  );
}

GenericTablePage.defaultProps = {
  canDelete: false,
  onDelete: EmptyClosure,
  canEdit: false,
  onEdit: EmptyClosure,
  canCreate: false,
  onCreate: EmptyClosure,
  onRowClick: EmptyClosure,
};

export default GenericTablePage;
