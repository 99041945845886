/* eslint-disable no-nested-ternary */

import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Dialog, DialogContent, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { UserTypes } from '../../interfaces/User';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import { getBase64 } from '../../utils/fns';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { postWithToken } from '../../utils/server';
import { StyledButton } from '../../components/PrimaryButton/PrimaryButton.Styled';
import { mainTheme } from '../../utils/theme/theme';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import { DocumentEntity } from '../../interfaces/Documents';
import { DisclaimerText } from '../../components/DisclaimerText/DisclaimerText';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: DocumentEntity;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: DocumentEntity = {
  titulo: '',
  url: '',
};

function DocumentsForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setOpenAlert,
    setAlertType,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [transformedFile, setTransformedFiles] = useState<string | ArrayBuffer | null>(
    null,
  );
  const [error, setError] = useState<string>('');
  const [fileTitle, setFileTitle] = useState<string>('');
  const [fileExtensions, setFileExtension] = useState<string>('');
  const fieldsDisabled = useMemo(() => {
    if (user?.typeId === UserTypes.PatientType) {
      return mode === CRUDModes.Update && !!formValues.id_medico;
    }
    return mode === CRUDModes.Update && user?.id !== formValues.id_medico;
  }, [user, mode, formValues.id_medico]);

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  const handleUpload = async (e: any) => {
    try {
      const maximumSize = 10 * 1024 * 1024; // In MegaBytes
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files[0].size > maximumSize) {
          setAlertType(AlertTypes.Error);
          setAlertMessage('El peso del archivo es mayor al permitido');
          setOpenAlert(true);
          return;
        }
        const base64 = await getBase64(e.target.files[0]);
        setTransformedFiles(base64);
        setFileTitle(e.target.files[0].name);
        setFileExtension(e.target.files[0].type.split('/')[1]);
      }
    } catch (err) {
      handleLogout(e);
      setAlertType(AlertTypes.Error);
      setAlertMessage('Error al subir el archivo');
      setOpenAlert(true);
    }
  };

  const formik = useFormik<DocumentEntity>({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: async (documentValues) => {
      try {
        if ((!transformedFile || !fileExtensions) && !documentValues.url) {
          setError('Por favor sube los archivos requeridos');
          return;
        }
        setError('');
        setLoading(true);
        const documentResult = await postWithToken<
          {
            document: DocumentEntity;
            patientId?: number;
            file?: string | ArrayBuffer | null;
            extension?: string;
          },
          DocumentEntity
        >(
          `/documents/${mode === CRUDModes.Create ? 'save' : 'update'}-document`,
          {
            document: {
              fecha: moment
                .utc()
                .utcOffset(-300)
                .toISOString(true)
                .slice(0, 19)
                .replace('T', ' '),
              ...documentValues,
            },
            patientId:
              user?.typeId === UserTypes.PatientType
                ? user?.id
                : currentPatient?.id ?? undefined,
            file: transformedFile,
            extension: fileExtensions,
          },
          handleLogout,
        );
        if (documentResult.success) {
          setLoading(false);
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar documento: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setError('');
          setFormValues(initialValues);
          setTransformedFiles(null);
          setFileTitle('');
          setFileExtension('');
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='Documentos'
      />

      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Agregar nuevo documento:</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1rem 0' }}>
                  Recuerda llenar la información correctamente
                </PrimaryDescription>

                <Grid item xs={12} md={12}>
                  <CMTextfield
                    id='titulo'
                    label='Título'
                    placeholder='Título'
                    value={formik.values.titulo}
                    name='titulo'
                    onChange={formik.handleChange}
                    disabled={fieldsDisabled}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{ marginBottom: '0.5rem', marginTop: '3rem' }}
              >
                <>
                  <label htmlFor='upload-photo'>
                    <input
                      style={{ display: 'none' }}
                      id='upload-photo'
                      name='upload-photo'
                      type='file'
                      accept='image/png, image/jpeg, */dicom,.dcm, image/dcm, */dcm, .dicom, application/pdf, .pdf'
                      onChange={handleUpload}
                      disabled={fieldsDisabled}
                    />
                    {!fieldsDisabled ? (
                      <>
                        <StyledButton
                          component='span'
                          style={{
                            width: mobile ? '100%' : 'initial',
                            marginBottom: mobile ? '1rem' : 'initial',
                          }}
                        >
                          Seleccionar archivo
                        </StyledButton>
                        <span
                          style={{
                            marginLeft: mobile ? 'initial' : '2%',
                            color: mainTheme.colors.standardText,
                            display: mobile ? 'block' : 'initial',
                          }}
                        >
                          {fileTitle || formik.values.url}
                        </span>
                      </>
                    ) : null}
                  </label>
                </>
              </Grid>
              {!fieldsDisabled ? (
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: '1rem',
                  }}
                >
                  <span
                    style={{
                      color: mainTheme.colors.standardText,
                      display: mobile ? 'block' : 'initial',
                    }}
                  >
                    El peso máximo del archivo es 10 MB. los formatos permitidos son .png,
                    .jpg, jpeg, dcm, pdf
                  </span>
                </Grid>
              ) : null}
              {error ? (
                <DisclaimerText
                  style={{ color: 'red', textAlign: 'center', marginBottom: '1rem' }}
                >
                  {error}
                </DisclaimerText>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

DocumentsForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};
export default DocumentsForm;
