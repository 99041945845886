export const mainTheme = {
  fonts: {
    text: {
      family:
        "'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      weight: 400,
    },
    title: {
      family:
        "'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      weight: 600,
    },
  },
  colors: {
    primaryButton: {
      normalText: 'white',
      selectedText: 'white',
      disabledText: 'black',
      normalBackground: '#5FAAD3',
      disabledBackground: 'gray',
      selectedBackground: '#235179',
    },
    standardText: '#4d4d4d',
    uiElements: '#2f557e',
    primaryTitle: '#4d4d4d',
    primaryDescription: '#808080',
    navbarItems: '#4d4d4d',
    loggedInBackground: '#F0F0F0',
    links: '#5FAAD3',
    cmRed: '#842E2E',
    cmBlue: '#00BCFF',
    cmLightBlue: '#CFF6FF',
    cmSand: '#F3EED9',
    cmPink: '#FFB3AB',
    cmGray: '#557B97',
  },
  strings: {},
};
