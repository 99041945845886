import React, { useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import DialogCustomTitle from '../DialogCustomTitle/DialogCustomTitle';
import { CRUDModes } from '../../interfaces/CRUD';

interface ImageViewerPopupProps {
  link: string;
  open: boolean;
  onClose: () => void;
}

enum MediaTypes {
  image = 1,
  pdf,
  other,
}

const ImageViewerPopup = ({
  link,
  onClose,
  open,
}: ImageViewerPopupProps): JSX.Element => {
  const [mediaType, setMediaType] = React.useState<MediaTypes>(MediaTypes.image);

  useEffect(() => {
    if (link.endsWith('.pdf')) {
      setMediaType(MediaTypes.pdf);
    } else if (link.endsWith('.png') || link.endsWith('.jpg') || link.endsWith('.jpeg')) {
      setMediaType(MediaTypes.image);
    } else {
      setMediaType(MediaTypes.other);
    }
  }, [link]);

  /*
  <iframe
            style={{
              width: '100%',
              height: '80vh',
            }}
            src={link}
            title='PDF'
          />
   */

  return (
    <Dialog fullWidth maxWidth='xl' open={open} onClose={onClose}>
      <DialogCustomTitle mode={CRUDModes.Update} onClose={onClose} title='Archivo' />
      <DialogContent>
        {mediaType === MediaTypes.image && (
          <img
            style={{
              width: '100%',
              objectFit: 'contain',
            }}
            src={link}
            alt='Imagen'
          />
        )}
        {mediaType === MediaTypes.pdf && (
          <iframe
            style={{
              width: '100%',
              height: '80vh',
            }}
            src={link}
            title='PDF'
          />
        )}
        {mediaType === MediaTypes.other && <p>Archivo no soportado</p>}
      </DialogContent>
    </Dialog>
  );
};

export default ImageViewerPopup;
