import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import moment from 'moment';
import { Dialog, DialogContent, Grid, MenuItem, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { useReactToPrint } from 'react-to-print';
import { CRUDModes } from '../../interfaces/CRUD';
import { useMainProvider } from '../../context/MainProvider';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { PreOperationNote } from '../../interfaces/PreOperationNote';
import { postWithToken } from '../../utils/server';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import {
  CMTextfield,
  CMTextfieldHalfRight,
  CMTextfieldHalfLeft,
} from '../../components/Forms/CMTextfield';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import PreOperationNoteDocument from './PreOperationNoteDocument';
import { Cie10 } from '../../interfaces/Cie10';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { RequiredMessage } from '../../utils/validationMessages';
import { canPrintNote } from '../../utils/fns';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';
import EmptyClosure from '../../utils/closures';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: PreOperationNote;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: PreOperationNote = {
  info: '',
  cirugia: '',
  diagnostico: [],
  tipo_cirugia: '',
  plan_quirurgico: '',
  ta: '',
  fc: '',
  fr: '',
  temperatura: '',
  saturacion: '',
  riesgo: '',
  estudio_pre: '',
  cuidados_plan: '',
  pronostico: '',
  fecha_cirugia: moment
    .utc()
    .utcOffset(-300)
    .toISOString(true)
    .slice(0, 19)
    .replace('T', ' '),
};

function PreOperationNotesForm({
  open,
  setOpen,
  mode,
  values,
  onSuccess,
  closeFunction,
}: FormProps): JSX.Element {
  const {
    loading,
    setLoading,
    setAlertMessage,
    cie10,
    getCie10,
    setOpenAlert,
    setAlertType,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const [formValues, setFormValues] = useState(initialValues);
  const [doctorInformation, setDoctorInformation] = useState<Doctor | null>(null);
  const [patientInformation, setPatientInformation] = useState<Patient | null>(null);
  const [diag, setDiag] = useState<Cie10[]>([]);
  const printRef = useRef<HTMLInputElement>(null);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.id_medico !== user?.id,
    [mode, formValues, user?.id],
  );

  const getDoctorInformation = useCallback(async () => {
    try {
      const doctorResult = await postWithToken<
        {
          doctorId?: number;
        },
        Doctor
      >(
        '/doctor/get-doctor',
        {
          doctorId: user?.id,
        },
        handleLogout,
      );
      if (doctorResult.success) {
        setDoctorInformation(doctorResult.data);
      }
    } catch (e) {
      handleLogout(e);
      console.log(e);
    }
  }, [handleLogout, user?.id]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  useEffect(() => {
    getCie10();
  }, [getCie10]);

  useEffect(() => {
    setDiag(cie10.slice(0, 100));
  }, [cie10]);

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
        getDoctorInformation().then(EmptyClosure).catch(EmptyClosure);
      }
    } else {
      setPatientInformation(values.paciente as Patient);
      setDoctorInformation(values.medico as Doctor);
      setFormValues(values);
    }
  }, [currentPatient, getDoctorInformation, values]);

  const validationSchema = Yup.object({
    ta: Yup.string().required(RequiredMessage),
    fc: Yup.string().required(RequiredMessage),
    fr: Yup.string().required(RequiredMessage),
    temperatura: Yup.string().required(RequiredMessage),
    saturacion: Yup.string().required(RequiredMessage),
  });

  const formik = useFormik<PreOperationNote>({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (preOperationNoteValues) => {
      try {
        setLoading(true);
        const preOperationNoteResult = await postWithToken<
          {
            preOperationNote: PreOperationNote;
            doctorId?: number;
            patientId?: number;
          },
          PreOperationNote
        >(
          `/notes/${mode === CRUDModes.Create ? 'save' : 'update'}-pre-operation-note`,
          {
            preOperationNote: {
              ...preOperationNoteValues,
              id_paciente:
                currentPatient && mode === CRUDModes.Create
                  ? currentPatient.id
                  : undefined,
              id_medico: user?.id,
            },
            doctorId: user?.id,
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          handleLogout,
        );
        if (preOperationNoteResult.success) {
          setLoading(false);
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar nota preoperatoría: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleDiagnosisInput = (value: string) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    setDiag(filteredCie);
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='nota preoperatoria'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={1}
            >
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Agregar nueva nota preoperatoria</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0 0 1.5rem 0' }}>
                  Recuerda llenar la información correctamente
                </PrimaryDescription>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    disabled={fieldsDisabled}
                    label='Fecha de la operación'
                    value={formik.values.fecha_cirugia}
                    onChange={(value): void => {
                      formik.setFieldValue('fecha_cirugia', value);
                    }}
                    inputFormat='DD/MM/yyyy'
                    renderInput={(params) => (
                      <CMTextfield
                        onBlur={formik.handleBlur}
                        {...params}
                        name='fecha_cirugia'
                        required
                      />
                    )}
                  />
                </LocalizationProvider>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    disabled={fieldsDisabled}
                    openOnFocus
                    multiple
                    id='diagnostico'
                    options={diag}
                    noOptionsText='No hay opciones disponibles'
                    getOptionLabel={(option) =>
                      `${option.cie_clave} ${option.cie_descripcion}`
                    }
                    onInputChange={(_event, value) => handleDiagnosisInput(value)}
                    value={formik.values.diagnostico}
                    onChange={(_e, value) => {
                      formik.setFieldValue('diagnostico', value);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <CMTextfield
                        {...params}
                        label='Diagnósticos'
                        placeholder='Diagnósticos'
                        name='diagnostico'
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Tipo de cirugía</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='tipo_cirugia'
                    minRows={2}
                    placeholder='Tipo de cirugía'
                    value={formik.values.tipo_cirugia}
                    name='tipo_cirugia'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Plan quirúrgico</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='plan_quirurgico'
                    minRows={2}
                    placeholder='Plan quirúrgico'
                    onBlur={formik.handleBlur}
                    value={formik.values.plan_quirurgico}
                    name='plan_quirurgico'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    id='ta'
                    label='TA (mmHH)'
                    placeholder='TA (mmHH)'
                    value={formik.values.ta}
                    onBlur={formik.handleBlur}
                    name='ta'
                    onChange={formik.handleChange}
                    error={formik.touched.ta && Boolean(formik.errors.ta)}
                    helperText={formik.touched.ta && formik.errors.ta}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    id='fc'
                    label='FC (LPM)'
                    placeholder='FC (LPM)'
                    value={formik.values.fc}
                    name='fc'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fc && Boolean(formik.errors.fc)}
                    helperText={formik.touched.fc && formik.errors.fc}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfieldHalfLeft
                    disabled={fieldsDisabled}
                    id='fr'
                    label='FR (RPM)'
                    placeholder='FR (RPM)'
                    value={formik.values.fr}
                    name='fr'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fr && Boolean(formik.errors.fr)}
                    helperText={formik.touched.fr && formik.errors.fr}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                  <CMTextfieldHalfRight
                    disabled={fieldsDisabled}
                    id='temperatura'
                    label='Temperatura °C'
                    placeholder='Temperatura °C'
                    onBlur={formik.handleBlur}
                    value={formik.values.temperatura}
                    name='temperatura'
                    onChange={formik.handleChange}
                    error={
                      formik.touched.temperatura && Boolean(formik.errors.temperatura)
                    }
                    helperText={formik.touched.temperatura && formik.errors.temperatura}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfield
                    disabled={fieldsDisabled}
                    id='saturacion'
                    label='Saturacion (%)'
                    placeholder='saturacion (%)'
                    onBlur={formik.handleBlur}
                    value={formik.values.saturacion}
                    name='saturacion'
                    onChange={formik.handleChange}
                    error={formik.touched.saturacion && Boolean(formik.errors.saturacion)}
                    helperText={formik.touched.saturacion && formik.errors.saturacion}
                    type='number'
                    inputProps={{
                      inputMode: 'decimal',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>Riesgo quirúrgico</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='riesgo'
                    minRows={2}
                    placeholder='Riesgo quirúrgico'
                    onBlur={formik.handleBlur}
                    value={formik.values.riesgo}
                    name='riesgo'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>
                    Resultados de estudios preoperatorios
                  </CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='estudio_pre'
                    minRows={2}
                    onBlur={formik.handleBlur}
                    placeholder='Resultados de estudios preoperatorios'
                    value={formik.values.estudio_pre}
                    name='estudio_pre'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMInputLabel mobile>
                    Cuidados y plan terapéutico preoperatorios
                  </CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    id='cuidados_plan'
                    minRows={2}
                    onBlur={formik.handleBlur}
                    placeholder='Cuidados y plan terapéutico preoperatorios'
                    value={formik.values.cuidados_plan}
                    name='cuidados_plan'
                    onChange={formik.handleChange}
                    style={{ marginBottom: '1rem', width: 'calc(100% - 1rem)' }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CMTextfield
                    disabled={fieldsDisabled}
                    select
                    id='pronostico'
                    label='Pronóstico'
                    placeholder='Pronóstico'
                    onBlur={formik.handleBlur}
                    value={formik.values.pronostico}
                    name='pronostico'
                    onChange={formik.handleChange}
                  >
                    <MenuItem value='-1' disabled>
                      Seleccione una opción
                    </MenuItem>
                    <MenuItem value='Bueno'>Bueno</MenuItem>
                    <MenuItem value='Regular'>Regular</MenuItem>
                    <MenuItem value='Malo'>Malo</MenuItem>
                    <MenuItem value='Reservado'>Reservado</MenuItem>
                  </CMTextfield>
                </Grid>
              </Grid>
              {values &&
              values.medico &&
              mode !== CRUDModes.Create &&
              values.paciente &&
              values.id_medico &&
              user &&
              canPrintNote(values.paciente, values.id_medico, user) ? (
                <Grid item xs={12}>
                  <div style={{ display: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <PreOperationNoteDocument
                        patient={values.paciente}
                        doctor={values.medico}
                        values={values as PreOperationNote}
                      />
                    </Grid>
                  </div>

                  {!isIOSApp() ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      style={{ width: '100%' }}
                      onClick={() => handlePrint()}
                    >
                      Imprimir
                    </PrimaryButton>
                  ) : (
                    <MobileDownloadMessage />
                  )}
                </Grid>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
}

PreOperationNotesForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};

export default PreOperationNotesForm;
