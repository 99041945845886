/* eslint-disable react-hooks/exhaustive-deps */
import React, { SyntheticEvent, useEffect } from 'react';
import { FaUserCircle, IoCloseCircleSharp } from 'react-icons/all';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NativeSelect } from '@mui/material';
import axios from 'axios';
import { useMainProvider } from '../../../context/MainProvider';
import { mainTheme } from '../../../utils/theme/theme';
import HMenuItem from './HMenuItem';
import { TemporaryUser, UserTypes } from '../../../interfaces/User';
import EmptyClosure from '../../../utils/closures';
import { setHeaders } from '../../../utils/headerSetter';
import { getPatientFullName, getUserImage } from '../../../utils/fns';
import { Patient } from '../../../interfaces/Patients';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';
import fallbackImage from '../../../assets/img/profile-user.png';
import { QRModes } from '../../../interfaces/QRCodes';
import { previewUserID } from '../../../utils/server';

const HamburgerMenu = (): JSX.Element => {
  const {
    isHMOpen,
    setIsHMOpen,
    user,
    currentPatient,
    setCurrentPatient,
    setTemporaryUsers,
    temporaryUsers,
    setLoading,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    setQrCode,
    setQrModeOpen,
    qrCode,
    handleLogout,
  } = useMainProvider();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchTemporaryPatients = async () => {
      if (user && user.typeId !== UserTypes.PatientType) {
        try {
          const userFetch = await axios.get<TemporaryUser[]>(
            `${process.env.REACT_APP_SERVER_URL}/temporaryAccess/getAvailablePatients`,
            setHeaders(false, user?.token || ''),
          );
          setTemporaryUsers(userFetch.data);
        } catch (e) {
          handleLogout(e);
          // No c hace nada
        }
      }
    };
    fetchTemporaryPatients().then(EmptyClosure).catch(EmptyClosure);
  }, [setTemporaryUsers, user]);

  const handleNewCode = async () => {
    try {
      setLoading(true);
      const codeResults = await axios.get<{ accessCode: string }>(
        `${process.env.REACT_APP_SERVER_URL}/temporaryAccess/generateAccessCode`,
        setHeaders(false, user?.token || ''),
      );
      setQrCode(codeResults.data.accessCode);
      setLoading(false);
      setIsHMOpen(false);
      setQrModeOpen(QRModes.createPatient);
    } catch (e: any) {
      handleLogout(e);
      if (JSON.stringify(e.response.data)) {
        setLoading(false);
        setIsHMOpen(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error al generar código : ${JSON.stringify(e.response.data)} `);
        setOpenAlert(true);
      } else {
        setLoading(false);
        setIsHMOpen(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`Error en el servidor: ${e}`);
        setOpenAlert(true);
      }
    }
  };

  const handleOpenDoctorScanner = () => {
    setIsHMOpen(false);
    setQrModeOpen(QRModes.scanPatient);
  };

  useEffect(() => {
    const fetchPatientWithCode = async () => {
      if (qrCode && user && user.typeId !== UserTypes.PatientType) {
        try {
          setLoading(true);
          const patientFetch = await axios.post<TemporaryUser>(
            `${process.env.REACT_APP_SERVER_URL}/temporaryAccess/validateQRCode`,
            { code: qrCode },
            setHeaders(false, user?.token || ''),
          );
          setQrCode('');
          setTimeout(() => {
            const newTemporaryUsers = [...temporaryUsers];
            if (
              !temporaryUsers.find((tempUser) => tempUser.id === patientFetch.data.id)
            ) {
              setSearchParams({}, { replace: true });
              searchParams.delete('historyId');
              newTemporaryUsers.push(patientFetch.data);
              setTemporaryUsers(newTemporaryUsers);
              setCurrentPatient(patientFetch.data);
              setIsHMOpen(true);
              setQrModeOpen(undefined);
              setLoading(false);
            }
          }, 100);
        } catch (e: any) {
          handleLogout(e);
          if (JSON.stringify(e.response.data)) {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(
              `Error al validar código : ${JSON.stringify(e.response.data)} `,
            );
            setOpenAlert(true);
          } else {
            setLoading(false);
            setAlertType(AlertTypes.Error);
            setAlertMessage(`Error en el servidor: ${e}`);
            setOpenAlert(true);
          }
        }
      }
    };
    fetchPatientWithCode().then(EmptyClosure).catch(EmptyClosure);
  }, [
    qrCode,
    setAlertMessage,
    setAlertType,
    setCurrentPatient,
    setIsHMOpen,
    setLoading,
    setOpenAlert,
    setQrCode,
    setQrModeOpen,
    setTemporaryUsers,
    temporaryUsers,
    user,
  ]);

  return (
    <div
      style={{
        position: 'fixed',
        width: '15rem',
        top: 0,
        bottom: 0,
        zIndex: 8000,
        left: isHMOpen ? '0' : '-15rem',
        transition: 'left 0.5s',
        transitionTimingFunction: 'ease-out',
        backgroundColor: '#f5f7f6',
        overflow: 'scroll',
      }}
    >
      <HMenuItem
        style={{
          textAlign: 'end',
          padding: '1rem 1.5rem',
        }}
        onClick={() => setIsHMOpen(false)}
      >
        <IoCloseCircleSharp style={{ fontSize: '1.5rem', marginLeft: '0.5rem' }} />
      </HMenuItem>
      <div
        style={{
          width: 'calc(100% - 3rem)',
          padding: '1.5rem',
          border: 'none',
          borderBottom: '1px solid #e6eaea',
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        {currentPatient || user ? (
          <img
            onError={(e: SyntheticEvent<HTMLImageElement>) => {
              const target = e.target as EventTarget & HTMLImageElement;
              target.src = fallbackImage;
            }}
            src={getUserImage(currentPatient || user)}
            alt='foto de perfil del usuario'
            style={{ width: '5rem', height: '5rem', borderRadius: '2.5rem' }}
          />
        ) : (
          <FaUserCircle
            style={{
              color: mainTheme.colors.uiElements,
              fontSize: '5rem',
              marginBottom: '1rem',
            }}
          />
        )}
        {user && user.typeId === UserTypes.PatientType ? (
          <p
            style={{
              width: '100%',
              textAlign: 'center',
              marginBottom: '1rem',
              wordWrap: 'break-word',
            }}
          >
            {user.username}
          </p>
        ) : (
          <>
            <p
              style={{
                backgroundColor: 'transparent',
                color: mainTheme.colors.uiElements,
                fontSize: '1rem',
                fontFamily: mainTheme.fonts.text.family,
                border: 'none',
              }}
            >
              Paciente Seleccionado:
            </p>
            <NativeSelect
              style={{ marginBottom: '1rem', width: '100%' }}
              id='currentPatient'
              value={currentPatient?.id || ''}
              name='currentPatient'
              onChange={(e) => {
                if (!e.target.value) {
                  setCurrentPatient(undefined);
                } else {
                  const foundPatient = temporaryUsers.find(
                    (patient) => patient.id === Number(e.target.value),
                  );
                  if (foundPatient) {
                    setCurrentPatient(foundPatient);
                  }
                }
              }}
            >
              <option value=''>Ninguno seleccionado</option>
              {temporaryUsers.map((patient) => {
                return (
                  <option value={patient.id} key={patient.id}>
                    {' '}
                    {getPatientFullName(patient.initialData as Patient)}
                  </option>
                );
              })}
            </NativeSelect>
          </>
        )}
        {user && user.id !== previewUserID ? (
          <button
            type='button'
            style={{
              backgroundColor: 'transparent',
              color: mainTheme.colors.uiElements,
              fontSize: '1rem',
              fontFamily: mainTheme.fonts.text.family,
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={
              user && user.typeId === UserTypes.PatientType
                ? handleNewCode
                : handleOpenDoctorScanner
            }
          >
            {user?.typeId === UserTypes.PatientType
              ? 'Mostrar mi QR'
              : '+ Añadir un paciente temporal'}
          </button>
        ) : null}
      </div>
      {user?.typeId !== UserTypes.PatientType ? (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            setQrModeOpen(QRModes.createDoctor);
          }}
          style={{ color: mainTheme.colors.uiElements }}
        >
          QR de invitación
        </HMenuItem>
      ) : null}
      {currentPatient && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/profile');
          }}
        >
          Perfil del paciente
        </HMenuItem>
      )}
      {user?.typeId === UserTypes.AdminType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/user-validation');
          }}
        >
          Validación de usuarios
        </HMenuItem>
      )}
      <HMenuItem
        onClick={() => {
          setIsHMOpen(false);
          navigate('/clinic-histories');
        }}
      >
        Historia Clínica
      </HMenuItem>
      <HMenuItem
        onClick={() => {
          setIsHMOpen(false);
          navigate('/pediatric-clinic-histories');
        }}
      >
        Historia Clínica Pediátrica
      </HMenuItem>
      <HMenuItem
        onClick={() => {
          setIsHMOpen(false);
          navigate('/prescriptions');
        }}
      >
        Receta
      </HMenuItem>
      <HMenuItem
        onClick={() => {
          setIsHMOpen(false);
          navigate('/estudios-indicados');
        }}
      >
        Estudios Indicados
      </HMenuItem>
      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/consulting-notes');
          }}
        >
          Nota de Consulta
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/evolution-notes');
          }}
        >
          Nota de Evolución
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/indication-notes');
          }}
        >
          Nota de Indicaciones
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/pre-operation-notes');
          }}
        >
          Nota Preoperatoria
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/surgical-notes');
          }}
        >
          Nota Postoperatoria
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/nurse-notes');
          }}
        >
          Nota de Enfermería
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/radiographies');
          }}
        >
          Resultados de Estudios de Gabinete
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/laboratory');
          }}
        >
          Resultados de Estudios de Laboratorio
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/simple-cards');
          }}
        >
          Consentimiento Informado Simple
        </HMenuItem>
      )}

      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/special-cards');
          }}
        >
          Consentimientos Informados Especiales
        </HMenuItem>
      )}
      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/imaging');
          }}
        >
          Imagenología
        </HMenuItem>
      )}
      {user?.typeId !== UserTypes.PatientType && (
        <HMenuItem
          onClick={() => {
            setIsHMOpen(false);
            navigate('/documents');
          }}
        >
          Documentos
        </HMenuItem>
      )}
    </div>
  );
};

export default HamburgerMenu;
