import React from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';
import { StandardText } from '../StandardText/StandardText';
import { Patient } from '../../interfaces/Patients';

interface PatientDataProps {
  patientInformation?: Patient;
  forceDesktop?: boolean;
}

const PatientData = ({
  patientInformation,
  forceDesktop,
}: PatientDataProps): JSX.Element => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      flexWrap='nowrap'
      spacing={2}
      style={{ marginBottom: '1.5rem' }}
    >
      <Grid item xs={forceDesktop ? 6 : 12} md={6}>
        <StandardText>
          <b>Expediente:</b> {patientInformation ? patientInformation.pac_id : ''}
        </StandardText>
        <StandardText>
          <b>Nombre:</b>{' '}
          {patientInformation ? (
            <>
              {patientInformation?.pac_nombre.toUpperCase()}{' '}
              {patientInformation?.pac_apellidop.toUpperCase()}{' '}
              {patientInformation?.pac_apellidom.toUpperCase()}
            </>
          ) : (
            ''
          )}
        </StandardText>
        <StandardText>
          <b>Fecha de nacimiento:</b>{' '}
          {patientInformation
            ? moment(patientInformation.pac_fechanacimiento)
                .locale('ES')
                .format('DD/MM/YYYY')
            : ''}
        </StandardText>
        <StandardText>
          <b>Edad:</b>{' '}
          {patientInformation
            ? parseInt(
                moment()
                  .diff(patientInformation.pac_fechanacimiento, 'years', true)
                  .toString(),
                10,
              )
            : ''}{' '}
          Años
        </StandardText>
      </Grid>
      <Grid item xs={forceDesktop ? 6 : 12} md={6}>
        <StandardText>
          <b>Sexo:</b>{' '}
          {patientInformation ? patientInformation.pac_sexo?.cat_sexo_desc : ''}
        </StandardText>
        <StandardText>
          <b>Curp:</b>{' '}
          {patientInformation ? patientInformation.pac_curp?.toUpperCase() : ''}
        </StandardText>
        <StandardText>
          <b>Grupo sanguíneo RH:</b>{' '}
          {patientInformation ? patientInformation.pac_tipo_sangre : ''}
        </StandardText>
      </Grid>
    </Grid>
  );
};

PatientData.defaultProps = {
  patientInformation: undefined,
  forceDesktop: false,
};

export default PatientData;
