export const formatMoney = (
  amount: number | string,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
): string => {
  let numericAmount = amount;
  if (typeof numericAmount === 'string') {
    numericAmount = parseFloat(numericAmount);
  }
  if (Number.isNaN(numericAmount)) {
    return '-';
  }
  const formatted = numericAmount.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  });
  const [intPart, decimalPart] = formatted.split('.');
  const intPartFormat = intPart.replace(/,/g, thousands);
  return `$${intPartFormat}${decimalPart ? decimal + decimalPart : ''}`;
};
