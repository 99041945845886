import { Grid, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { useSponsorsProvider } from '../../../Context/SponsorsMainContext';
import { RequiredMessage } from '../../../../../utils/validationMessages';
import { AlertTypes } from '../../../../../interfaces/MainContextInitialValues';
import { postWithToken } from '../../../../../utils/server';
import { PageContainer } from '../../../../Register/Register.styled';
import CenteredForm from '../../../../../index.styled';
import {
  CMTextfield,
  CMTextfieldHalfLeft,
  CMTextfieldHalfRight,
} from '../../../../../components/Forms/CMTextfield';
import { DisclaimerText } from '../../../../../components/DisclaimerText/DisclaimerText';
import PrimaryButton from '../../../../../components/PrimaryButton/PrimaryButton';
import { initialSponsorSegment, SponsorSegment } from '../SponsorSegments.Interfaces';
import { setSponsorHeaders } from '../../../../../utils/headerSetter';

interface Props {
  values?: SponsorSegment;
  onSuccess?: () => void;
  mode?: CRUDModes;
}

const SponsorSegmentsForm = ({ values, onSuccess, mode }: Props): JSX.Element => {
  const {
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setLoading,
    handleLogoutError,
    token,
  } = useSponsorsProvider();

  const [formValues, setFormValues] = useState<SponsorSegment>(initialSponsorSegment);

  const [error, setError] = useState<string>('');

  const validationSchema = Yup.object({
    name: Yup.string().required(RequiredMessage).max(255, 'Máximo 255 caracteres'),
    sql_query: Yup.string().required(RequiredMessage).max(2000, 'Máximo 2000 caracteres'),
    comments: Yup.string().required(RequiredMessage).max(255, 'Máximo 255 caracteres'),
    type_id: Yup.number()
      .required(RequiredMessage)
      .min(1, 'Selecciona un tipo de segmento')
      .max(3, 'Selecciona un tipo de segmento'),
  });

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    validate: () => {
      setError('');
    },
    onSubmit: async (form) => {
      try {
        setLoading(true);
        await axios.post<SponsorSegment, null>(
          `${process.env.REACT_APP_SERVER_URL}/sponsors/${
            mode === CRUDModes.Create ? '' : 'update-'
          }segments`,
          {
            ...form,
          },
          setSponsorHeaders(true, token),
        );
        setAlertType(AlertTypes.Success);
        setAlertMessage(`Información guardada con éxito`);
        setOpenAlert(true);
        formik.resetForm();
        if (onSuccess) {
          onSuccess();
        }
        setLoading(false);
      } catch (e: any) {
        handleLogoutError(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setError(e.response.data);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
        setLoading(false);
      }
    },
  });
  return (
    <PageContainer style={{ paddingTop: '1.5rem' }}>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='segments'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <CMTextfieldHalfLeft
                  id='outlined-basic'
                  label='Nombre del segmento'
                  value={formik.values.name}
                  name='name'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <CMTextfieldHalfRight
                  id='outlined-basic'
                  required
                  select
                  label='Público objetivo'
                  value={formik.values.type_id}
                  name='type_id'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.type_id && Boolean(formik.errors.type_id)}
                  helperText={formik.touched.type_id && formik.errors.type_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem disabled value={-1}>
                    Selecciona una opción
                  </MenuItem>
                  <MenuItem value={1}>Doctores</MenuItem>
                  <MenuItem value={2}>Pacientes</MenuItem>
                  <MenuItem value={3}>Enfermeras</MenuItem>
                </CMTextfieldHalfRight>
                <CMTextfield
                  id='outlined-basic'
                  label='Descripción'
                  value={formik.values.comments}
                  name='comments'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.comments && Boolean(formik.errors.comments)}
                  helperText={formik.touched.comments && formik.errors.comments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <CMTextfield
                  id='outlined-basic'
                  label='Query SQL para segmentación'
                  value={formik.values.sql_query}
                  name='sql_query'
                  required
                  multiline
                  rows={10}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.sql_query && Boolean(formik.errors.sql_query)}
                  helperText={formik.touched.sql_query && formik.errors.sql_query}
                  onChange={formik.handleChange}
                />
                {error ? (
                  <DisclaimerText
                    style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}
                  >
                    {error}
                  </DisclaimerText>
                ) : null}
                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  {mode === CRUDModes.Create ? 'Crear' : 'Guardar'} segmento
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

SponsorSegmentsForm.defaultProps = {
  values: undefined,
  mode: CRUDModes.Create,
  onSuccess: () => {},
};

export default SponsorSegmentsForm;
