/* eslint-disable react/require-default-props */
import React, { CSSProperties, PropsWithChildren } from 'react';
import { CircularProgress } from '@mui/material';
import { DisclaimerText } from '../DisclaimerText/DisclaimerText';
import StyledPrimaryButton from './PrimaryButton.Styled';

interface PrimaryButtonProps {
  style?: CSSProperties;
  loading: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  id?: string;
  valid?: boolean;
  customInvalid?: string;
}

const PrimaryButton = ({
  style,
  loading,
  onClick,
  children,
  type,
  disabled,
  id,
  valid,
  customInvalid,
}: PropsWithChildren<PrimaryButtonProps>): JSX.Element => {
  return (
    <>
      <StyledPrimaryButton
        id={id}
        style={style}
        onClick={!loading ? onClick : () => {}}
        type={type}
        disabled={disabled || loading}
        $loading={loading}
      >
        {loading ? (
          <CircularProgress
            variant='indeterminate'
            style={{
              color: 'white',
              animationDuration: '300ms',
            }}
            size={20}
            thickness={2}
            value={100}
          />
        ) : (
          children
        )}
      </StyledPrimaryButton>
      {!valid && (
        <DisclaimerText
          style={{ color: 'red', marginTop: '0.5rem', textAlign: 'center' }}
        >
          {customInvalid}
        </DisclaimerText>
      )}
    </>
  );
};

PrimaryButton.defaultProps = {
  style: {},
  onClick: () => {},
  type: 'button',
  disabled: false,
  valid: true,
  customInvalid: 'Completa todos los campos para continuar',
};

export default PrimaryButton;
