import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoCloseCircleSharp } from 'react-icons/all';
import { useSponsorsProvider } from '../Context/SponsorsMainContext';
import HMenuItem from '../../../components/Navbar/HamburgerMenu/HMenuItem';
import { SponsorAccountType } from '../Interfaces/ContextInterfaces';

const SponsorsMenu = (): JSX.Element => {
  const { menuOpen, setMenuOpen, user, sponsor } = useSponsorsProvider();
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: 'fixed',
        width: '15rem',
        top: 0,
        bottom: 0,
        zIndex: 8000,
        left: menuOpen ? '0' : '-15rem',
        transition: 'left 0.5s',
        transitionTimingFunction: 'ease-out',
        backgroundColor: '#f5f7f6',
        overflow: 'scroll',
      }}
    >
      <HMenuItem
        style={{
          textAlign: 'end',
          padding: '1rem 1.5rem',
        }}
        onClick={() => setMenuOpen(false)}
      >
        <IoCloseCircleSharp style={{ fontSize: '1.5rem', marginLeft: '0.5rem' }} />
      </HMenuItem>

      {user?.typeId !== SponsorAccountType.superAdmin ? (
        <HMenuItem
          onClick={() => {
            setMenuOpen(false);
            navigate('/sponsors/myprofile');
          }}
        >
          Mi cuenta
        </HMenuItem>
      ) : null}
      {user?.typeId !== SponsorAccountType.regular ? (
        <HMenuItem
          onClick={() => {
            setMenuOpen(false);
            navigate('/sponsors/mysponsor');
          }}
        >
          Mi empresa
        </HMenuItem>
      ) : null}

      {user?.typeId === SponsorAccountType.superAdmin && (
        <HMenuItem
          onClick={() => {
            setMenuOpen(false);
            navigate('/sponsors/list');
          }}
        >
          Patrocinadores
        </HMenuItem>
      )}
      <HMenuItem
        onClick={() => {
          setMenuOpen(false);
          navigate('/sponsors/accounts');
        }}
      >
        Cuentas de usuario
      </HMenuItem>
      {user?.typeId === SponsorAccountType.superAdmin && (
        <HMenuItem
          onClick={() => {
            setMenuOpen(false);
            navigate('/sponsors/payments');
          }}
        >
          Pagos
        </HMenuItem>
      )}
      {user?.typeId === SponsorAccountType.superAdmin && (
        <HMenuItem
          onClick={() => {
            setMenuOpen(false);
            navigate('/sponsors/segments');
          }}
        >
          Segmentos
        </HMenuItem>
      )}
      <HMenuItem
        onClick={() => {
          setMenuOpen(false);
          navigate('/sponsors/campaigns');
        }}
      >
        Campañas
      </HMenuItem>
    </div>
  );
};

export default SponsorsMenu;
