import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useMainProvider } from '../../context/MainProvider';
import { CRUDModes } from '../../interfaces/CRUD';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { setHeaders } from '../../utils/headerSetter';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import { NotaConsulta } from '../../interfaces/NotaConsulta';
import { Patient } from '../../interfaces/Patients';
import { Doctor } from '../../interfaces/Doctors';
import NotaConsultaDocument from './NotaConsultaDocument';
import { postWithToken } from '../../utils/server';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { RequiredMessage } from '../../utils/validationMessages';
import { Cie10 } from '../../interfaces/Cie10';
import { canPrintNote } from '../../utils/fns';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';
import EmptyClosure from '../../utils/closures';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  values?: NotaConsulta;
  onSuccess: () => void;
  closeFunction?: () => void;
}

const initialValues: NotaConsulta = {
  primera_consulta: false,
  consulta_programada: false,
  comentarios_subjetivos: '',
  peso: '',
  altura: '',
  presion_arterial: '',
  frecuencia_cardiaca: '',
  frecuencia_respiratoria: '',
  temperatura: '',
  habitus_exterior: '',
  piel_anexos: '',
  cabeza_cuello: '',
  torax: '',
  cardiovascular: '',
  abdomen: '',
  genitales: '',
  extremidades: '',
  sistema_nervioso: '',
  examenes_previos: '',
  examenes_laboratorio_imagenologia: '',
  analisis: '',
  pronostico: '',
  indicacion_terapeutica: '',
  saturacion_oxigeno: '',
  nota_consulta_diagnosticos: [],
};

const NotaConsultaForm = ({
  open,
  setOpen,
  mode,
  onSuccess,
  values,
  closeFunction,
}: FormProps): JSX.Element => {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    currentPatient,
    cie10,
    getCie10,
    handleLogout,
  } = useMainProvider();
  const [formValues, setFormValues] = useState(initialValues);
  const [doctorInformation, setDoctorInformation] = useState<Doctor | null>(null);
  const [patientInformation, setPatientInformation] = useState<Patient | null>(null);
  const printRef = useRef<HTMLInputElement>(null);
  const [cie10Search, setCie10Search] = useState<Cie10[]>([]);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.doctor_id !== user?.id,
    [mode, formValues, user?.id],
  );

  const getDoctorInformation = useCallback(async () => {
    try {
      const doctorResult = await postWithToken<
        {
          doctorId?: number;
        },
        Doctor
      >(
        '/doctor/get-doctor',
        {
          doctorId: user?.id,
        },
        handleLogout,
      );
      if (doctorResult.success) {
        setDoctorInformation(doctorResult.data);
      }
    } catch (e) {
      handleLogout(e);
      console.log(e);
    }
  }, [handleLogout, user?.id]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  useEffect(() => {
    setCie10Search(cie10.slice(0, 100));
  }, [cie10]);

  useEffect(() => {
    getCie10();
  }, [getCie10]);

  useEffect(() => {
    setFormValues(initialValues);
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
        getDoctorInformation().then(EmptyClosure).catch(EmptyClosure);
      }
    } else {
      setPatientInformation(values.patientInformation as Patient);
      setDoctorInformation(values.doctorInformation as Doctor);
      setFormValues(values);
    }
  }, [cie10, currentPatient, getCie10, getDoctorInformation, open, values]);

  const validationSchema = Yup.object({
    peso: Yup.string().required(RequiredMessage),
    altura: Yup.string().required(RequiredMessage),
    presion_arterial: Yup.string().required(RequiredMessage),
    frecuencia_cardiaca: Yup.string().required(RequiredMessage),
    frecuencia_respiratoria: Yup.string().required(RequiredMessage),
    temperatura: Yup.string().required(RequiredMessage),
    saturacion_oxigeno: Yup.string().required(RequiredMessage),
  });

  const formik = useFormik<NotaConsulta>({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (form) => {
      try {
        setLoading(true);
        const creationResult = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/notaConsulta/${
            mode === CRUDModes.Create ? 'post' : 'update'
          }`,
          {
            entry: {
              ...form,
              imc:
                form.peso && form.altura
                  ? Number((Number(form.peso) / Number(form.altura) ** 2).toFixed(2))
                  : 0,
            },
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          setHeaders(true, user ? user.token : undefined),
        );
        if (creationResult.status === 200) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `¡Nota de consulta ${
              mode === CRUDModes.Create ? 'creada' : 'actualizada'
            } exitosamente!`,
          );
          setLoading(false);
          setOpenAlert(true);
          if (closeFunction) closeFunction();
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          onSuccess();
        } else {
          setLoading(false);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al guardar nota de consulta: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  const handleDiagnosisInput = (value: string) => {
    const filteredCie = cie10
      .filter((singleCie) => {
        return `${singleCie.cie_clave} ${singleCie.cie_descripcion}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
      .slice(0, 100);
    setCie10Search(filteredCie);
  };

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          formik.resetForm();
          setFormValues(initialValues);
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='nota de consulta'
      />

      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Información general</PrimarySubtitle>
                <PrimaryDescription mobile style={{ margin: '0' }}>
                  Llena la información general del paciente. El IMC se calculará en
                  automático al ingresar el peso y altura.
                </PrimaryDescription>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      name='primera_consulta'
                      id='primera_consulta'
                      checked={formik.values.primera_consulta}
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                    />
                  }
                  label='¿Es primera consulta?'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      name='consulta_programada'
                      id='consulta_programada'
                      checked={formik.values.consulta_programada}
                      onChange={formik.handleChange}
                      disabled={fieldsDisabled}
                    />
                  }
                  label='¿Consulta programada?'
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Subjetivos</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='comentarios_subjetivos'
                  name='comentarios_subjetivos'
                  value={formik.values.comentarios_subjetivos}
                  onChange={formik.handleChange}
                  minRows={2}
                  placeholder='Subjetivos'
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              {/* validationSchema */}
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='peso'
                  label='Peso(Kg)'
                  placeholder='Peso(Kg)'
                  value={formik.values.peso}
                  name='peso'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  error={formik.touched.peso && Boolean(formik.errors.peso)}
                  helperText={formik.touched.peso && formik.errors.peso}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                  id='altura'
                  label='Altura(m)'
                  placeholder='Altura(m)'
                  value={formik.values.altura}
                  name='altura'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  error={formik.touched.altura && Boolean(formik.errors.altura)}
                  helperText={formik.touched.altura && formik.errors.altura}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              {formik.values.peso && formik.values.altura ? (
                <Grid item xs={12}>
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    exit={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    transition={{ duration: 0.3 }}
                    key='bodyMassIndex-div'
                  >
                    <PrimaryDescription style={{ margin: '0 0 1rem 0' }}>
                      <b>Índice de masa corporal:</b>
                      {(
                        Number(formik.values.peso) /
                        Number(formik.values.altura) ** 2
                      ).toFixed(2)}
                    </PrimaryDescription>
                  </motion.div>
                </Grid>
              ) : null}
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  type='text'
                  id='presion_arterial'
                  value={formik.values.presion_arterial}
                  name='presion_arterial'
                  label='Presión Arterial(mmHg)'
                  placeholder='Presión Arterial(mmHg)'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={fieldsDisabled}
                  error={
                    formik.touched.presion_arterial &&
                    Boolean(formik.errors.presion_arterial)
                  }
                  helperText={
                    formik.touched.presion_arterial && formik.errors.presion_arterial
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  id='frecuencia_cardiaca'
                  value={formik.values.frecuencia_cardiaca}
                  name='frecuencia_cardiaca'
                  label='Frecuencia Cardiaca(LPM)'
                  placeholder='Frecuencia Cardiaca(LPM)'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={fieldsDisabled}
                  error={
                    formik.touched.frecuencia_cardiaca &&
                    Boolean(formik.errors.frecuencia_cardiaca)
                  }
                  helperText={
                    formik.touched.frecuencia_cardiaca &&
                    formik.errors.frecuencia_cardiaca
                  }
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  id='frecuencia_respiratoria'
                  value={formik.values.frecuencia_respiratoria}
                  name='frecuencia_respiratoria'
                  label='Frecuencia Respiratoria(RPM)'
                  placeholder='Frecuencia Respiratoria(RPM)'
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.frecuencia_respiratoria &&
                    Boolean(formik.errors.frecuencia_respiratoria)
                  }
                  helperText={
                    formik.touched.frecuencia_respiratoria &&
                    formik.errors.frecuencia_respiratoria
                  }
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  id='temperatura'
                  value={formik.values.temperatura}
                  name='temperatura'
                  label='Temperatura(°C)'
                  placeholder='Temperatura(°C)'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  disabled={fieldsDisabled}
                  error={formik.touched.temperatura && Boolean(formik.errors.temperatura)}
                  helperText={formik.touched.temperatura && formik.errors.temperatura}
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CMTextfield
                  $nomargin
                  id='saturacion_oxigeno'
                  value={formik.values.saturacion_oxigeno}
                  name='saturacion_oxigeno'
                  label='Saturación oxígeno(%)'
                  placeholder='95.0'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={fieldsDisabled}
                  error={
                    formik.touched.saturacion_oxigeno &&
                    Boolean(formik.errors.saturacion_oxigeno)
                  }
                  helperText={
                    formik.touched.saturacion_oxigeno && formik.errors.saturacion_oxigeno
                  }
                  type='number'
                  inputProps={{
                    inputMode: 'decimal',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>Estado del paciente</PrimarySubtitle>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Habitus exterior</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='habitus_exterior'
                  name='habitus_exterior'
                  value={formik.values.habitus_exterior}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Piel y anexos</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='piel_anexos'
                  name='piel_anexos'
                  value={formik.values.piel_anexos}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Cabeza y cuello</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='cabeza_cuello'
                  name='cabeza_cuello'
                  value={formik.values.cabeza_cuello}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Tórax</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='torax'
                  name='torax'
                  value={formik.values.torax}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Cardiovascular</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='cardiovascular'
                  name='cardiovascular'
                  value={formik.values.cardiovascular}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Abdomen</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='abdomen'
                  name='abdomen'
                  value={formik.values.abdomen}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Genitales</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='genitales'
                  name='genitales'
                  value={formik.values.genitales}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Extremidades</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='extremidades'
                  name='extremidades'
                  value={formik.values.extremidades}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Sistema nervioso</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='sistema_nervioso'
                  name='sistema_nervioso'
                  value={formik.values.sistema_nervioso}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Exámenes de laboratorio previos</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='examenes_previos'
                  name='examenes_previos'
                  value={formik.values.examenes_previos}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Exámenes de laboratorio e imagenología</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='examenes_laboratorio_imagenologia'
                  name='examenes_laboratorio_imagenologia'
                  value={formik.values.examenes_laboratorio_imagenologia}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Análisis</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='analisis'
                  name='analisis'
                  value={formik.values.analisis}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={fieldsDisabled}
                  multiple
                  id='nota_consulta_diagnosticos'
                  options={cie10Search}
                  noOptionsText='No hay opciones disponibles'
                  getOptionLabel={(option) =>
                    `${option.cie_clave} ${option.cie_descripcion}`
                  }
                  onInputChange={(_event, value) => handleDiagnosisInput(value)}
                  value={formik.values.nota_consulta_diagnosticos}
                  onChange={(_e, value) => {
                    formik.setFieldValue('nota_consulta_diagnosticos', value);
                  }}
                  renderInput={(params) => (
                    <CMTextfield
                      $nomargin
                      {...params}
                      onBlur={formik.handleBlur}
                      label='Diagnóstico(s)'
                      placeholder='Diagnóstico(s)'
                      name='nota_consulta_diagnosticos'
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <CMTextfield
                  $nomargin
                  select
                  id='pronostico'
                  name='pronostico'
                  value={formik.values.pronostico}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Descripción'
                  label='Pronóstico'
                  disabled={fieldsDisabled}
                >
                  <MenuItem disabled value=''>
                    Seleccione una opción
                  </MenuItem>
                  <MenuItem value='Bueno'>Bueno</MenuItem>
                  <MenuItem value='Regular'>Regular</MenuItem>
                  <MenuItem value='Malo'>Malo</MenuItem>
                  <MenuItem value='Reservado'>Reservado</MenuItem>
                </CMTextfield>
              </Grid>
              <Grid item xs={12}>
                <CMInputLabel mobile>Indicación terapéutica</CMInputLabel>
                <CMTextArea
                  $spacing={2}
                  id='indicacion_terapeutica'
                  name='indicacion_terapeutica'
                  value={formik.values.indicacion_terapeutica}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  minRows={2}
                  placeholder='Descripción'
                  disabled={fieldsDisabled}
                />
              </Grid>
              {values &&
              values.doctorInformation &&
              mode !== CRUDModes.Create &&
              values.patientInformation &&
              values.doctor_id &&
              user &&
              canPrintNote(values.patientInformation, values.doctor_id, user) ? (
                <Grid item xs={12}>
                  <div style={{ display: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <NotaConsultaDocument
                        patient={values.patientInformation}
                        doctor={values.doctorInformation}
                        values={values as NotaConsulta}
                      />
                    </Grid>
                  </div>
                  {!isIOSApp() ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      style={{ width: '100%' }}
                      onClick={() => handlePrint()}
                    >
                      Imprimir
                    </PrimaryButton>
                  ) : (
                    <MobileDownloadMessage />
                  )}
                </Grid>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

NotaConsultaForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};

export default NotaConsultaForm;
