import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSponsorsProvider } from '../Context/SponsorsMainContext';

function SponsorPrivate({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}): JSX.Element {
  const { user, sponsor, initialLoad, loadInitialData, token } = useSponsorsProvider();
  const location = useLocation();

  /**
   *
   * @desc checks if user is already set in the context if
   * thats the case then checks if user already filled initial data
   */
  const authenticated = () => {
    if (!initialLoad) {
      loadInitialData();
    }

    if (user && token) {
      return true;
    }

    return false;
  };

  /**
   *
   * @desc checks if initial dat is already filled and redirects user if not
   * @returns the component where the usr will be redireced
   */
  const checkInitialDataFill = (): JSX.Element => {
    if (path !== '/new-sponsor' && !sponsor) {
      return <Navigate to='/sponsors/new-sponsor' />;
    }
    if (path === '/new-sponsor' && sponsor) {
      return <Navigate to='/sponsors/login' />;
    }
    return children;
  };

  return !initialLoad ? (
    <></>
  ) : (
    <>
      {authenticated() ? (
        checkInitialDataFill()
      ) : (
        <Navigate
          to={{
            pathname: '/sponsors/login',
            search: location.search,
          }}
        />
      )}
    </>
  );
}

export default SponsorPrivate;
