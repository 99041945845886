import { Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { SponsorAccountsTableEntry } from '../../../Interfaces/Sponsors';
import DialogCustomTitle from '../../../../../components/DialogCustomTitle/DialogCustomTitle';
import SponsorAccountForm from '../../SponsorAccountForm/AdminForm.Component';

export interface SponsorsAccountsFormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  onSuccess: () => void;
  values?: SponsorAccountsTableEntry;
  closeFunction?: () => void;
}

const SponsorAccountsPopupForm = ({
  open,
  setOpen,
  mode,
  onSuccess,
  values,
  closeFunction,
}: SponsorsAccountsFormProps): JSX.Element => {
  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false);
          if (closeFunction) closeFunction();
        }
      }}
    >
      <DialogCustomTitle
        onClose={() => {
          setOpen(false);
          if (closeFunction) closeFunction();
        }}
        mode={mode}
        title='cuenta de patrocinador'
      />
      <DialogContent>
        {open ? (
          <SponsorAccountForm
            onSuccess={() => {
              setOpen(false);
              if (closeFunction) closeFunction();
              onSuccess();
            }}
            mode={mode}
            values={values}
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default SponsorAccountsPopupForm;
