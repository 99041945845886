import { User } from './User';

export enum IOSHandlerFunctions {
  SavedLoggedUser,
  Logout,
  DownloadData,
}

export interface IOSHandlerParams {
  function: IOSHandlerFunctions;
  loggedUser?: User;
  download?: {
    url: string;
    fileName: string;
  };
}

export interface WebkitWindow extends Window {
  webkit?: {
    messageHandlers?: {
      iOSMessageHandler?: {
        postMessage: (params: string) => void;
      };
    };
  };
}

export const postWebkitMessage = (params: IOSHandlerParams): void => {
  const webkitWindow = window as WebkitWindow;
  if (
    webkitWindow.webkit &&
    webkitWindow.webkit.messageHandlers &&
    webkitWindow.webkit.messageHandlers.iOSMessageHandler
  ) {
    webkitWindow.webkit.messageHandlers.iOSMessageHandler.postMessage(
      JSON.stringify(params),
    );
  }
};

export const isIOSApp = (): boolean => {
  const webkitWindow = window as WebkitWindow;
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (
    !!(
      webkitWindow.webkit &&
      webkitWindow.webkit.messageHandlers &&
      webkitWindow.webkit.messageHandlers.iOSMessageHandler
    ) || userAgent.includes('wv')
  );
};

export const isAndroid = (): boolean => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.includes('wv');
};
