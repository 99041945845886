import React from 'react';
import { Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/all';
import Logo from '../../assets/img/zivot.svg';
import { useMainProvider } from '../../context/MainProvider';
import OptionsDropdown from './OptionsDropdown/OptionsDropdown';
import { NoPaddingButton } from '../NoPaddingButton/NoPaddingButton';

interface NavbarProps {
  backButton?: boolean;
  handleBack?: () => void;
}

const Navbar = ({ backButton, handleBack }: NavbarProps): JSX.Element => {
  const { user, isHMOpen, setIsHMOpen } = useMainProvider();
  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const location = useLocation();
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: isHMOpen ? '15rem' : '0',
        transition: 'left 0.5s, right 0.5s',
        transitionTimingFunction: 'ease-out',
        right: isHMOpen ? '-15rem' : '0',
        zIndex: 1000,
      }}
    >
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={{
          backgroundColor: 'white',
          border: '1px solid #DFE1E3',
          boxShadow: '0px 2px 10px rgba(0, 24, 102, 0.05)',
          position: 'relative',
        }}
      >
        <Grid item xs={3}>
          {user && user.initialDataFilled && user.validated ? (
            <NoPaddingButton
              style={{ marginLeft: '1.5rem' }}
              onClick={() => setIsHMOpen(true)}
            >
              <GiHamburgerMenu
                style={{ color: 'black', fontSize: '1.5rem', marginRight: '1rem' }}
              />
            </NoPaddingButton>
          ) : null}
        </Grid>

        <Grid item xs={6} style={{ textAlign: 'center' }}>
          <Tooltip title='Ir a inicio' placement='bottom'>
            <Link
              to={{
                pathname: '/',
                search: location.search,
              }}
            >
              <img
                src={Logo}
                alt='Logo codigo médico'
                style={{ height: mobile ? '3.5rem' : '5rem', margin: '0.5rem' }}
              />
            </Link>
          </Tooltip>
        </Grid>

        <Grid item xs={3}>
          <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
            {user ? <OptionsDropdown /> : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Navbar.defaultProps = {
  backButton: false,
  handleBack: () => {},
};

export default Navbar;
