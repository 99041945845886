import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import {
  BiClipboard,
  BsFillShieldLockFill,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  MdOutlineWatchLater,
} from 'react-icons/all';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Logo from '../../assets/img/zivot2.svg';
import bgImg from '../../assets/img/hero-background.png';
import gradient from '../../assets/img/gradient-hero.svg';
import secondBg from '../../assets/img/second-bg.svg';
import step1 from '../../assets/img/step1landing.jpg';
import step2 from '../../assets/img/paso2landing.png';
import step3 from '../../assets/img/step3landing.jpg';
import { NoPaddingButton } from '../../components/NoPaddingButton/NoPaddingButton';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { StandardText } from '../../components/StandardText/StandardText';
import { mainTheme } from '../../utils/theme/theme';
import { LandingFeatureCard } from './subcomponents/featureCard';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import CenteredForm from '../../index.styled';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { EmailMessage, RequiredMessage } from '../../utils/validationMessages';
import { useMainProvider } from '../../context/MainProvider';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import {
  IOSHandlerFunctions,
  isIOSApp,
  isAndroid,
  postWebkitMessage,
} from '../../interfaces/WebkitWindow';
import { FlexContainer } from '../../components/FlexContainer/FlexContainer';

interface ContactForm {
  email: string;
  cellphone: string;
  name: string;
  message: string;
}

const LandingPage = (): JSX.Element => {
  const theme = useTheme();
  const large = useMediaQuery(theme.breakpoints.down('lg'));
  const middle = useMediaQuery(theme.breakpoints.down('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const { setAlertType, setAlertMessage, setOpenAlert } = useMainProvider();

  const validationSchema = Yup.object({
    email: Yup.string().email(EmailMessage).required(RequiredMessage),
    cellphone: Yup.string(),
    name: Yup.string().required(RequiredMessage),
    message: Yup.string().required(RequiredMessage),
  });

  const formik = useFormik<ContactForm>({
    initialValues: {
      email: '',
      cellphone: '',
      name: '',
      message: '',
    },
    validationSchema,
    onSubmit: async (form) => {
      try {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact`, form);
        setAlertType(AlertTypes.Success);
        setAlertMessage(`¡Correo enviado exitosamente! Pronto te contactaremos`);
        setOpenAlert(true);
        formik.resetForm();
      } catch (e) {
        setAlertType(AlertTypes.Error);
        setAlertMessage(JSON.stringify(e));
        setOpenAlert(true);
      }
    },
  });

  return (
    <div style={{ width: '100%', margin: 0 }}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          key='background-image'
          style={{
            width: '100%',
            position: 'fixed',
            top: middle ? '0' : '5rem',
            left: '0',
          }}
        >
          <img
            src={bgImg}
            alt='foto de un celular con un estetoscopio'
            style={{
              width: '100%',
            }}
          />
        </motion.div>
        <div
          style={{
            position: 'relative',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <img
            src={gradient}
            alt='gradiente que cubre la foto de fondo'
            style={{
              width: '100%',
              marginTop: middle ? '0' : '5rem',
            }}
          />
          {!middle ? (
            <motion.div
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
              key='title'
              style={{
                position: 'absolute',
                top: 'calc(5rem + 3%)',
                left: '6%',
                width: '50%',
              }}
            >
              <StandardText
                style={{
                  fontSize: large ? '3rem' : '4rem',
                  color: 'white',
                  textAlign: 'left',
                  fontWeight: 300,
                }}
              >
                Tu historial médico <b>al alcance de tu mano</b>
              </StandardText>
              <StandardText
                style={{
                  marginTop: large ? '0.5rem' : '1rem',
                  fontSize: large ? '1.5rem' : '2rem',
                  color: 'white',
                  textAlign: 'left',
                  fontWeight: 200,
                }}
              >
                ¿No sería increíble que pudieras tener un registro de todas las veces que
                has ido a consulta médica en tu vida? Con <b>Zivot</b>, esto se vuelve
                realidad.
              </StandardText>
              <PrimaryButton
                loading={false}
                style={{
                  backgroundColor: 'white',
                  color: '#1c1c1c',
                  marginTop: large ? '1rem' : '1.5rem',
                }}
                onClick={() => navigate('/home')}
              >
                Comenzar
              </PrimaryButton>
            </motion.div>
          ) : null}
          <div
            style={{
              width: '100%',
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <motion.div
              key='content'
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
              style={{
                width: mobile ? 'calc(100% - 4rem)' : 'calc(100% - 10rem)',
                marginBottom: middle ? '3rem' : '5rem',
              }}
            >
              {middle ? (
                <>
                  <motion.div
                    key='content'
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
                    style={{
                      width: '100%',
                    }}
                  >
                    <StandardText
                      style={{
                        fontSize: '2.5rem',
                        color: '#1c1c1c',
                        textAlign: 'left',
                        fontWeight: mobile ? 200 : 300,
                      }}
                    >
                      Tu historial médico <b>al alcance de tu mano</b>
                    </StandardText>
                    <StandardText
                      style={{
                        marginTop: '1rem',
                        fontSize: '1.5rem',
                        color: '#1c1c1c',
                        textAlign: 'left',
                        fontWeight: 200,
                      }}
                    >
                      ¿No sería increíble que pudieras tener un registro de todas las
                      veces que has ido a consulta médica en tu vida? Con <b>Zivot</b>,
                      esto se vuelve realidad.
                    </StandardText>
                    <PrimaryButton
                      loading={false}
                      style={{
                        marginTop: '1.5rem',
                        marginBottom: '3rem',
                      }}
                      onClick={() => navigate('/home')}
                    >
                      Comenzar
                    </PrimaryButton>
                  </motion.div>
                </>
              ) : null}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                }}
              >
                <LandingFeatureCard $mobile={mobile}>
                  <BiClipboard
                    style={{
                      borderRadius: '2rem',
                      padding: '1rem',
                      fontSize: '3.5rem',
                      backgroundColor: 'rgba(132, 46, 46, 0.4)',
                      color: 'rgba(132, 46, 46, 1)',
                      marginBottom: '1rem',
                    }}
                  />
                  <StandardText
                    style={{
                      marginBottom: '0.5rem',
                      fontSize: '1.5rem',
                      color: '#1c1c1c',
                      textAlign: 'center',
                    }}
                  >
                    <b>Todo en un solo lugar</b>
                  </StandardText>
                  <StandardText
                    style={{
                      fontSize: '1rem',
                      fontWeight: 300,
                      color: '#1c1c1c',
                      textAlign: 'center',
                    }}
                  >
                    Accede a tu cuenta y consulta todos los documentos clínicos que los
                    médicos hayan creado a los largo de tu vida.
                  </StandardText>
                </LandingFeatureCard>
                <LandingFeatureCard $mobile={mobile}>
                  <MdOutlineWatchLater
                    style={{
                      borderRadius: '2rem',
                      padding: '1rem',
                      fontSize: '3.5rem',
                      backgroundColor: 'rgba(85, 123, 151, 0.4)',
                      color: mainTheme.colors.cmGray,
                      marginBottom: '1rem',
                    }}
                  />
                  <StandardText
                    style={{
                      marginBottom: '0.5rem',
                      fontSize: '1.5rem',
                      color: '#1c1c1c',
                      textAlign: 'center',
                    }}
                  >
                    <b>Acceso temporal</b>
                  </StandardText>
                  <StandardText
                    style={{
                      fontSize: '1rem',
                      fontWeight: 300,
                      color: '#1c1c1c',
                      textAlign: 'center',
                    }}
                  >
                    Cualquier profesional de la salud que tenga consulta contigo podrá
                    acceder a tu historial y publicar entradas temporalmente
                  </StandardText>
                </LandingFeatureCard>
                <LandingFeatureCard $mobile={mobile}>
                  <BsFillShieldLockFill
                    style={{
                      borderRadius: '2rem',
                      padding: '1rem',
                      fontSize: '3.5rem',
                      backgroundColor: 'rgba(0, 188, 255, 0.3)',
                      color: mainTheme.colors.cmBlue,
                      marginBottom: '1rem',
                    }}
                  />
                  <StandardText
                    style={{
                      marginBottom: '0.5rem',
                      fontSize: '1.5rem',
                      color: '#1c1c1c',
                      textAlign: 'center',
                    }}
                  >
                    <b>Máxima seguridad</b>
                  </StandardText>
                  <StandardText
                    style={{
                      fontSize: '1rem',
                      fontWeight: 300,
                      color: '#1c1c1c',
                      textAlign: 'center',
                    }}
                  >
                    Contamos con los estándares de seguridad más altos, por lo que tus
                    datos estan a salvo. Cumplimos con la <b>NOM-004-SSA3-2012</b>, del
                    expediente clínico.
                  </StandardText>
                </LandingFeatureCard>
              </div>
            </motion.div>
            <div
              style={{
                width: '100%',
                position: 'relative',
                marginBottom: middle ? '3rem' : '5rem',
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img
                src={secondBg}
                alt=''
                style={{
                  width: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
              <div
                style={{
                  width: mobile ? 'calc(100% - 4rem)' : 'calc(100% - 10rem)',
                  position: 'static',
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <StandardText
                    style={{
                      color: '#1c1c1c',
                      fontSize: '2rem',
                      fontWeight: 600,
                      textAlign: 'center',
                      marginBottom: middle ? '3rem' : '5rem',
                    }}
                  >
                    ¿Cómo funciona?
                  </StandardText>
                  <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={4}
                    style={{ marginBottom: middle ? '5rem' : '8rem' }}
                  >
                    {mobile ? (
                      <Grid item xs={12} md={6}>
                        <img
                          src={step1}
                          alt='foto de una persona con un smartphone'
                          style={{ width: '100%', borderRadius: 7 }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={6}>
                      <StandardText
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          textAlign: 'left',
                          marginBottom: '1rem',
                        }}
                      >
                        Paso 1: Crea tu cuenta
                      </StandardText>
                      <StandardText
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.2rem',
                          fontWeight: 200,
                          textAlign: 'left',
                        }}
                      >
                        Llena la información de tu perfil para crear tu expediente médico.
                        Con tu cuenta podrás tener un registro vitalicio de todo lo que
                        los expertos de la salud llenen sobre tí
                      </StandardText>
                    </Grid>
                    {!mobile ? (
                      <Grid item xs={12} md={6}>
                        <img
                          src={step1}
                          alt='foto de una persona con un smartphone'
                          style={{ width: '100%', borderRadius: 7 }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={6}>
                      <img
                        src={step2}
                        alt='foto la función de QR de codigo medico'
                        style={{
                          width: '100%',
                          borderRadius: 7,
                          marginTop: '3.5rem',
                          marginBottom: mobile ? '0' : '3.5rem',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StandardText
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          textAlign: 'left',
                          marginBottom: '1rem',
                        }}
                      >
                        Paso 2: Visita a tu médico
                      </StandardText>
                      <StandardText
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.2rem',
                          fontWeight: 200,
                          textAlign: 'left',
                          marginBottom: mobile ? '3.5rem' : '0',
                        }}
                      >
                        Enseña tu QR personal para que pueda ver todo tu expediente y
                        realizar nuevas notas de esa consulta. De esta manera los
                        profesionales podrán conocer tu historial completo y dar mejores
                        diagnósticos.
                      </StandardText>
                    </Grid>
                    {mobile ? (
                      <Grid item xs={12} md={6}>
                        <img
                          src={step3}
                          alt='foto de una persona en consulta con el doctor'
                          style={{ width: '100%', borderRadius: 7 }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12} md={6}>
                      <StandardText
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          textAlign: 'left',
                          marginBottom: '1rem',
                        }}
                      >
                        Paso 3: ¡Disfruta!
                      </StandardText>
                      <StandardText
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.2rem',
                          fontWeight: 200,
                          textAlign: 'left',
                        }}
                      >
                        Asi de simple es usar Zivot. El médico quien te atendió tendrá
                        acceso a tu expediente únicamente por tiempo limitado, de esta
                        manera tus datos quedan protegidos.
                      </StandardText>
                    </Grid>
                    {!mobile ? (
                      <Grid item xs={12} md={6}>
                        <img
                          src={step3}
                          alt='foto de una persona en consulta con el doctor'
                          style={{ width: '100%', borderRadius: 7 }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid
                    container
                    direction='row'
                    justifyContent='center'
                    alignItems='flex-start'
                    spacing={4}
                  >
                    <Grid item xs={12} md={6}>
                      <StandardText
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          textAlign: 'center',
                          marginBottom: '1.5rem',
                        }}
                      >
                        En tu cuenta podrás guardar:
                      </StandardText>
                      <LandingFeatureCard $mobile style={{ width: 'calc(100% - 3rem)' }}>
                        <StandardText
                          style={{
                            color: '#1c1c1c',
                            fontSize: '1.2rem',
                            fontWeight: 300,
                            textAlign: 'left',
                            lineHeight: '2rem',
                          }}
                        >
                          <ul>
                            <li>Historias clínicas</li>
                            <li>Historias clínicas pediátricas</li>
                            <li>Historias clíinicas ginecológicas</li>
                            <li>Notas de evolución</li>
                            <li>Notas de consulta</li>
                            <li>Notas preoperatorias</li>
                            <li>Notas quirúrgicas</li>
                            <li>Notas de indicaciones</li>
                            <li>Notas de enfermería</li>
                            <li>Radiografías</li>
                            <li>Recetas</li>
                            <li>Resultados de Laboratorio</li>
                            <li>Cartas de consentimiento</li>
                          </ul>
                        </StandardText>
                      </LandingFeatureCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StandardText
                        id='contactForm'
                        style={{
                          color: '#1c1c1c',
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          textAlign: 'center',
                          marginBottom: '1.5rem',
                        }}
                      >
                        ¿Dudas? Contáctanos
                      </StandardText>
                      <CenteredForm onSubmit={formik.handleSubmit}>
                        <CMTextfield
                          id='name'
                          label='Nombre y apellido'
                          value={formik.values.name}
                          name='name'
                          style={{ marginBottom: '1rem' }}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name}
                          onChange={formik.handleChange}
                        />
                        <CMTextfield
                          id='email'
                          label='Email'
                          value={formik.values.email}
                          name='email'
                          style={{ marginBottom: '1rem' }}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                          onChange={formik.handleChange}
                        />
                        <CMTextfield
                          id='cellphone'
                          label='Teléfono'
                          value={formik.values.cellphone}
                          name='cellphone'
                          style={{ marginBottom: '1rem' }}
                          onChange={formik.handleChange}
                        />
                        <CMTextArea
                          id='message'
                          name='message'
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          minRows={4}
                          placeholder='Mensaje'
                        />
                        <PrimaryButton
                          loading={false}
                          type='submit'
                          style={{ width: '100%', marginTop: '1rem' }}
                        >
                          Enviar
                        </PrimaryButton>
                      </CenteredForm>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
            <div
              style={{
                width: 'calc(100% - 3rem)',
                textAlign: 'center',
                backgroundColor: '#F7F8FA',
                padding: '1.5rem',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              <div
                style={{
                  width: mobile ? '50%' : '25%',
                }}
              >
                <a href='tel:3318505834' target='_blank' rel='noreferrer'>
                  <StandardText
                    style={{
                      marginTop: '0.5rem',
                    }}
                  >
                    Teléfono: 3318505834
                  </StandardText>
                </a>
                <a href='mailto: contacto@zivot.com.mx' target='_blank' rel='noreferrer'>
                  <StandardText
                    style={{
                      marginTop: '0.5rem',
                    }}
                  >
                    Correo Electrónico: contacto@zivot.com.mx
                  </StandardText>
                </a>
              </div>
              <div
                style={{
                  width: '50%',
                  textAlign: 'center',
                }}
              >
                <StandardText>2023 Zivot</StandardText>
                <a
                  id='terms'
                  href={`${
                    isIOSApp() && !isAndroid()
                      ? '#terms'
                      : `${process.env.REACT_APP_SERVER_URL}/files/Terminos y Condiciones.pdf`
                  }`}
                  target={isIOSApp() && !isAndroid() ? '_self' : '_blank'}
                  rel='noreferrer'
                  style={{
                    marginTop: '0.5rem',
                  }}
                  onClick={() => {
                    const href = `${process.env.REACT_APP_SERVER_URL}/files/Terminos y Condiciones.pdf`;
                    const fileName = 'Terminos y Condiciones.pdf';
                    if (isIOSApp() && !isAndroid()) {
                      postWebkitMessage({
                        function: IOSHandlerFunctions.DownloadData,
                        download: {
                          url: href,
                          fileName,
                        },
                      });
                    }
                  }}
                >
                  <StandardText
                    style={{
                      marginTop: '0.5rem',
                    }}
                  >
                    Terminos y condiciones
                  </StandardText>
                </a>
                <a
                  id='aviso'
                  href={`${
                    isIOSApp() && !isAndroid()
                      ? '#aviso'
                      : `${process.env.REACT_APP_SERVER_URL}/files/Aviso de Privacidad.pdf`
                  }`}
                  target={isIOSApp() && !isAndroid() ? '_self' : '_blank'}
                  rel='noreferrer'
                  onClick={() => {
                    const href = `${process.env.REACT_APP_SERVER_URL}/files/Aviso de Privacidad.pdf`;
                    const fileName = 'Aviso de Privacidad.pdf';
                    if (isIOSApp() && !isAndroid()) {
                      postWebkitMessage({
                        function: IOSHandlerFunctions.DownloadData,
                        download: {
                          url: href,
                          fileName,
                        },
                      });
                    }
                  }}
                >
                  <StandardText
                    style={{
                      marginTop: '0.5rem',
                    }}
                  >
                    Aviso de privacidad
                  </StandardText>
                </a>
              </div>
              <div
                style={{
                  width: mobile ? '100%' : '25%',
                  marginTop: mobile ? '1.5rem' : '0',
                }}
              >
                Redes sociales
                <FlexContainer
                  justifyContent='space-evenly'
                  style={{ marginTop: '1rem' }}
                >
                  <FaFacebook style={{ fontSize: '2rem' }} />{' '}
                  <FaInstagram
                    onClick={() =>
                      window.open('https://www.instagram.com/zivot_expediente/', '_blank')
                    }
                    style={{ fontSize: '2rem' }}
                  />{' '}
                  <FaLinkedin style={{ fontSize: '2rem' }} />{' '}
                  <FaTwitter style={{ fontSize: '2rem' }} />
                </FlexContainer>
              </div>
            </div>
          </div>
        </div>
        <motion.div
          initial={{ opacity: 0, top: '-5rem' }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1, top: 0 }}
          transition={{ duration: 1 }}
          key='navbar'
          style={{
            padding: '1rem 2rem',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
            width: 'calc(100% - 4rem)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 5000,
          }}
        >
          <img src={Logo} alt='Logo Zivot' style={{ height: '3rem' }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!middle ? (
              <NoPaddingButton
                style={{
                  marginRight: '1rem',
                  color: '#1c1c1c',
                  fontSize: '1rem',
                  fontWeight: 500,
                }}
                onClick={() => {
                  const element = document.getElementById('contactForm');
                  if (element) element.scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Contacto
              </NoPaddingButton>
            ) : null}
            <PrimaryButton loading={false} onClick={() => navigate('/home')}>
              Comenzar
            </PrimaryButton>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default LandingPage;
