import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SponsorsMainProvider from '../Context/SponsorsMainContext';
import SponsorsMainLayout from '../Layout/SponsorsMainLayout';
import SponsorsLoginPage from '../Pages/Login/SponsorsLoginPage';
import SponsorsHomePage from '../Pages/Home/SponsorsHome';
import SponsorPrivate from './SponsorPrivateRoute';
import SponsorAccountForm from '../Pages/SponsorAccountForm/AdminForm.Component';
import SponsorForm from '../Pages/SponsorForm/SponsorForm';
import SponsorForgotPassword from '../Pages/ForgotPassword/SponsorForgotPassword';
import SponsorResetPassword from '../Pages/ResetPassword/SponsorResetPassword';
import SponsorsTable from '../Pages/SponsorsCRUD/Table/SponsorsTable';
import SponsorAccountsTable from '../Pages/SponsorAccountsCRUD/Table/SponsorAccountsTable';
import SponsorPaymentsTable from '../Pages/SponsorPayments/Table/SponsorPaymentsTable';
import SponsorSegmentsTable from '../Pages/SponsorSegments/Table/SponsorSegmentTable';
import SponsorCampaignsTable from '../Pages/SponsorCampaigns/Table/SponsorCampaignTable';
import { CRUDModes } from '../../../interfaces/CRUD';

const SponsorsRouter = (): JSX.Element => {
  return (
    <SponsorsMainProvider>
      <SponsorsMainLayout>
        <Routes>
          <Route path='/login' element={<SponsorsLoginPage />} />
          <Route path='/forgot' element={<SponsorForgotPassword />} />
          <Route path='/reset/:accountId/:token' element={<SponsorResetPassword />} />
          <Route path='/register' element={<SponsorAccountForm />} />
          <Route
            path='/new-sponsor'
            element={
              <SponsorPrivate path='/new-sponsor'>
                <SponsorForm />
              </SponsorPrivate>
            }
          />
          <Route
            path='/home'
            element={
              <SponsorPrivate path='/home'>
                <SponsorsHomePage />
              </SponsorPrivate>
            }
          />
          <Route
            path='/mysponsor'
            element={
              <SponsorPrivate path='/mysponsor'>
                <SponsorForm mode={CRUDModes.Update} />
              </SponsorPrivate>
            }
          />
          <Route
            path='/myprofile'
            element={
              <SponsorPrivate path='/myprofile'>
                <SponsorAccountForm mode={CRUDModes.Update} />
              </SponsorPrivate>
            }
          />
          <Route
            path='/list'
            element={
              <SponsorPrivate path='/list'>
                <SponsorsTable />
              </SponsorPrivate>
            }
          />
          <Route
            path='/accounts'
            element={
              <SponsorPrivate path='/accounts'>
                <SponsorAccountsTable />
              </SponsorPrivate>
            }
          />
          <Route
            path='/payments'
            element={
              <SponsorPrivate path='/payments'>
                <SponsorPaymentsTable />
              </SponsorPrivate>
            }
          />
          <Route
            path='/segments'
            element={
              <SponsorPrivate path='/segments'>
                <SponsorSegmentsTable />
              </SponsorPrivate>
            }
          />
          <Route
            path='/campaigns'
            element={
              <SponsorPrivate path='/campaigns'>
                <SponsorCampaignsTable />
              </SponsorPrivate>
            }
          />
          <Route path='*' element={<h1>URL no encontrado</h1>} />
        </Routes>
      </SponsorsMainLayout>
    </SponsorsMainProvider>
  );
};

export default SponsorsRouter;
