import { FunctionResponse } from '../interfaces/Responses';

export const saveLocalstorageData = <T>(key: string, data: T): FunctionResponse<null> => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
    return {
      success: true,
      data: null,
    };
  } catch (e) {
    return {
      success: false,
      error: 'Cookies blocked',
    };
  }
};

export const removeLocalstorageData = (key: string): FunctionResponse<null> => {
  try {
    localStorage.removeItem(key);
    return {
      success: true,
      data: null,
    };
  } catch (e) {
    return {
      success: false,
      error: 'Cookies blocked',
    };
  }
};

export const getLocalstorageData = <T>(key: string): FunctionResponse<T> => {
  try {
    const retrievedData = localStorage.getItem(key);
    if (!retrievedData) {
      return {
        success: false,
        error: 'Key does not exist',
      };
    }
    const parsedData: T = JSON.parse(retrievedData);
    return {
      success: true,
      data: parsedData,
    };
  } catch (e) {
    return {
      success: false,
      error: 'Cookies blocked',
    };
  }
};
