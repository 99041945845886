import React, { useEffect } from 'react';
import { MenuItem, useMediaQuery, useTheme } from '@mui/material';
import {
  CMTextfield,
  CMTextfieldHalfLeft,
  CMTextfieldHalfRight,
} from '../../../../components/Forms/CMTextfield';
import { BloodTypes } from '../../../../interfaces/BloodTypes';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../../../components/PrimaryTitle/PrimaryTitle';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import { FormikHook } from '../../../../interfaces/formikInterfaces';
import { Patient } from '../../../../interfaces/Patients';
import { useMainProvider } from '../../../../context/MainProvider';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';

interface PatientStepTwoProps {
  formik: FormikHook<Patient>;
}

const PatientStepTwo = ({ formik }: PatientStepTwoProps): JSX.Element => {
  const { scholarDegrees, getScholarDegrees, states } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  useEffect(() => {
    getScholarDegrees();
  }, [getScholarDegrees]);

  return (
    <>
      <PrimaryTitle mobile={mobile}>Datos adicionales</PrimaryTitle>
      <PrimaryDescription mobile={mobile}>
        Para empezar a usar la plataforma no es necesario que los llenes en este momento,
        pero se recomiendan para que los médicos tengan tu expdiente completo
      </PrimaryDescription>
      <CMTextfield
        select
        id='outlined-basic'
        label='Escolaridad'
        value={formik.values.pac_escolaridad_id}
        name='pac_escolaridad_id'
        style={{ marginBottom: '1rem' }}
        error={
          formik.touched.pac_escolaridad_id && Boolean(formik.errors.pac_escolaridad_id)
        }
        helperText={formik.touched.pac_escolaridad_id && formik.errors.pac_escolaridad_id}
        onChange={formik.handleChange}
      >
        <MenuItem value='-1'>Seleccione una opción</MenuItem>
        {scholarDegrees.map((singleScholarDegree) => {
          return (
            <MenuItem value={singleScholarDegree.cat_escolaridad_id}>
              {singleScholarDegree.cat_escolaridad_desc}
            </MenuItem>
          );
        })}
      </CMTextfield>
      <CMTextfieldHalfLeft
        id='outlined-basic'
        label='Dirección'
        value={formik.values.pac_direccion}
        name='pac_direccion'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_direccion && Boolean(formik.errors.pac_direccion)}
        helperText={formik.touched.pac_direccion && formik.errors.pac_direccion}
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Colonia'
        value={formik.values.pac_colonia}
        name='pac_colonia'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_colonia && Boolean(formik.errors.pac_colonia)}
        helperText={formik.touched.pac_colonia && formik.errors.pac_colonia}
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfLeft
        id='outlined-basic'
        label='Código postal'
        value={formik.values.pac_cp}
        name='pac_cp'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_cp && Boolean(formik.errors.pac_cp)}
        helperText={formik.touched.pac_cp && formik.errors.pac_cp}
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Municipio'
        value={formik.values.pac_municipio}
        name='pac_municipio'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_municipio && Boolean(formik.errors.pac_municipio)}
        helperText={formik.touched.pac_municipio && formik.errors.pac_municipio}
        onChange={formik.handleChange}
      />
      <CMTextfield
        select
        id='outlined-basic'
        label='Estado de nacimineto'
        value={formik.values.pac_estadonacimiento_id}
        name='pac_estadonacimiento_id'
        style={{ marginBottom: '1rem' }}
        error={
          formik.touched.pac_estadonacimiento_id &&
          Boolean(formik.errors.pac_estadonacimiento_id)
        }
        helperText={
          formik.touched.pac_estadonacimiento_id && formik.errors.pac_estadonacimiento_id
        }
        onChange={formik.handleChange}
      >
        <MenuItem value='-1'>Seleccione una opción</MenuItem>
        {states.map((singleState) => {
          return (
            <MenuItem value={singleState.cat_estado_id}>
              {singleState.cat_estado_desc}
            </MenuItem>
          );
        })}
      </CMTextfield>
      <CMTextfieldHalfLeft
        id='outlined-basic'
        label='País'
        value={formik.values.pac_pais}
        name='pac_pais'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_pais && Boolean(formik.errors.pac_pais)}
        helperText={formik.touched.pac_pais && formik.errors.pac_pais}
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Correo'
        value={formik.values.pac_correo}
        name='pac_correo'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_correo && Boolean(formik.errors.pac_correo)}
        helperText={formik.touched.pac_correo && formik.errors.pac_correo}
        onChange={formik.handleChange}
      />
      <CMTextfield
        id='outlined-basic'
        label='Teléfono'
        value={formik.values.pac_telefono}
        name='pac_telefono'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_telefono && Boolean(formik.errors.pac_telefono)}
        helperText={formik.touched.pac_telefono && formik.errors.pac_telefono}
        onChange={formik.handleChange}
      />
      <CMTextfield
        select
        id='outlined-basic'
        label='Tipo de sangre'
        value={formik.values.pac_tipo_sangre}
        name='pac_tipo_sangre'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_tipo_sangre && Boolean(formik.errors.pac_tipo_sangre)}
        helperText={formik.touched.pac_tipo_sangre && formik.errors.pac_tipo_sangre}
        onChange={formik.handleChange}
        required
      >
        <MenuItem disabled value={BloodTypes.Unknown}>
          No sabe
        </MenuItem>
        <MenuItem value={BloodTypes.A1Negative}>{BloodTypes.A1Negative}</MenuItem>
        <MenuItem value={BloodTypes.A1Positive}>{BloodTypes.A1Positive}</MenuItem>
        <MenuItem value={BloodTypes.A2Negative}>{BloodTypes.A2Negative}</MenuItem>
        <MenuItem value={BloodTypes.A2Positive}>{BloodTypes.A2Positive}</MenuItem>
        <MenuItem value={BloodTypes.ABNegative}>{BloodTypes.ABNegative}</MenuItem>
        <MenuItem value={BloodTypes.ABPositive}>{BloodTypes.ABPositive}</MenuItem>
        <MenuItem value={BloodTypes.BNegative}>{BloodTypes.BNegative}</MenuItem>
        <MenuItem value={BloodTypes.BPositive}>{BloodTypes.BPositive}</MenuItem>
        <MenuItem value={BloodTypes.ONegative}>{BloodTypes.ONegative}</MenuItem>
        <MenuItem value={BloodTypes.OPositive}>{BloodTypes.OPositive}</MenuItem>
        <MenuItem value={BloodTypes.RHNegative}>{BloodTypes.RHNegative}</MenuItem>
      </CMTextfield>
      <CMTextfieldHalfLeft
        id='outlined-basic'
        label='Nombre del padre'
        value={formik.values.pac_nombrepadre}
        name='pac_nombrepadre'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_nombrepadre && Boolean(formik.errors.pac_nombrepadre)}
        helperText={formik.touched.pac_nombrepadre && formik.errors.pac_nombrepadre}
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Nombre de la madre'
        value={formik.values.pac_nombremadre}
        name='pac_nombremadre'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_nombremadre && Boolean(formik.errors.pac_nombremadre)}
        helperText={formik.touched.pac_nombremadre && formik.errors.pac_nombremadre}
        onChange={formik.handleChange}
      />
      <PrimaryTitleStart mobile={mobile}>
        Datos del familiar o responsable
      </PrimaryTitleStart>
      <CMTextfieldHalfLeft
        id='outlined-basic'
        label='Nombre de responsable'
        value={formik.values.pac_nombreresponsable}
        name='pac_nombreresponsable'
        style={{ marginBottom: '1rem' }}
        error={
          formik.touched.pac_nombreresponsable &&
          Boolean(formik.errors.pac_nombreresponsable)
        }
        helperText={
          formik.touched.pac_nombreresponsable && formik.errors.pac_nombreresponsable
        }
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Relación de responsable'
        value={formik.values.pac_relacionresponsable}
        name='pac_relacionresponsable'
        style={{ marginBottom: '1rem' }}
        error={
          formik.touched.pac_relacionresponsable &&
          Boolean(formik.errors.pac_relacionresponsable)
        }
        helperText={
          formik.touched.pac_relacionresponsable && formik.errors.pac_relacionresponsable
        }
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfLeft
        id='outlined-basic'
        label='Dirección de responsable'
        value={formik.values.pac_direccionresponsable}
        name='pac_direccionresponsable'
        style={{ marginBottom: '1rem' }}
        error={
          formik.touched.pac_direccionresponsable &&
          Boolean(formik.errors.pac_direccionresponsable)
        }
        helperText={
          formik.touched.pac_direccionresponsable &&
          formik.errors.pac_direccionresponsable
        }
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Teléfono de responsable'
        value={formik.values.pac_telefonoresponsable}
        name='pac_telefonoresponsable'
        style={{ marginBottom: '1rem' }}
        error={
          formik.touched.pac_telefonoresponsable &&
          Boolean(formik.errors.pac_telefonoresponsable)
        }
        helperText={
          formik.touched.pac_telefonoresponsable && formik.errors.pac_telefonoresponsable
        }
        onChange={formik.handleChange}
      />
    </>
  );
};

export default PatientStepTwo;
