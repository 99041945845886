import React from 'react';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Form, useFormik } from 'formik';
import { FaSearch } from 'react-icons/all';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import { CMTextfield } from '../Forms/CMTextfield';
import { GenericTableColumn, GenericTableRow } from '../../interfaces/GenericTable';
import { NoPaddingButton } from '../NoPaddingButton/NoPaddingButton';

export interface SearchbarForm {
  columnName: string;
  value: string;
}

interface SearchbarProps {
  onSearch: (search: SearchbarForm) => void;
  rows: GenericTableRow[];
  columns: GenericTableColumn[];
}

const Searchbar = ({ onSearch, rows, columns }: SearchbarProps): JSX.Element => {
  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));

  const formik = useFormik<SearchbarForm>({
    initialValues: {
      columnName: 'id',
      value: '',
    },
    enableReinitialize: true,
    /**
     * @desc logins the user with the given information
     * @param form
     */
    onSubmit: onSearch,
  });

  return (
    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
      <FlexContainer
        style={{ width: '100%' }}
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      >
        <CMTextfield
          select
          id='columnName'
          label=''
          placeholder='Selecciona una columna'
          value={formik.values.columnName}
          name='columnName'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          style={{ width: '30%', margin: '0' }}
        >
          <MenuItem value='-1' disabled>
            Seleccione una opción
          </MenuItem>
          {columns.map((column) => (
            <MenuItem key={column.name} value={column.name}>
              {column.displayName}
            </MenuItem>
          ))}
        </CMTextfield>
        <CMTextfield
          style={{ width: '70%', margin: '0' }}
          id='outlined-basic'
          label='Buscar'
          value={formik.values.value}
          name='value'
          onChange={formik.handleChange}
          InputProps={{
            endAdornment: (
              <NoPaddingButton type='submit'>
                <FaSearch />
              </NoPaddingButton>
            ),
          }}
        />
      </FlexContainer>
    </form>
  );
};

export default Searchbar;
