import styled from 'styled-components';
import { StandardText } from '../StandardText/StandardText';
import { mainTheme } from '../../utils/theme/theme';
import { MobileProps } from '../../interfaces/GeneralProps';

export const PrimaryDescription = styled(StandardText)<MobileProps>`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: ${mainTheme.colors.primaryDescription};
  text-align: ${(props) => (props.mobile ? 'start' : 'center')};
`;

export const PrimaryDescriptionStart = styled(StandardText)`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: ${mainTheme.colors.primaryDescription};
  text-align: start;
`;
