import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMainProvider } from '../../context/MainProvider';
import NurseInitialForm from './NurseInitialForm';
import DoctorInitialForm from './DoctorInitialForm';
import PatientInitialForm from './PatientInitialForm';
import PatientInitialFormV2 from './V2/PatientInitialFormV2/PatientInitialFormV2';

interface InitialFormProps {
  initialDataMode?: boolean;
  myProfile?: boolean;
  externalProfile?: boolean;
}

function InitialForm({
  initialDataMode,
  myProfile,
  externalProfile,
}: InitialFormProps): JSX.Element {
  const { user, currentPatient, setIsHMOpen } = useMainProvider();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/home') {
      setIsHMOpen(true);
    }
  }, [location.pathname, setIsHMOpen]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const formType = (): JSX.Element => {
    if (!user) {
      return <></>;
    }
    switch ((myProfile ? user : currentPatient || user).typeId) {
      case 1:
        return (
          <DoctorInitialForm
            existingUser={myProfile ? undefined : currentPatient || undefined}
            initialDataMode={initialDataMode}
          />
        );
      case 2:
        return initialDataMode ? (
          <PatientInitialFormV2 />
        ) : (
          <PatientInitialForm
            existingUser={myProfile ? undefined : currentPatient || undefined}
            initialDataMode={initialDataMode}
            externalProfile={externalProfile}
          />
        );
      case 3:
        return (
          <NurseInitialForm
            existingUser={myProfile ? undefined : currentPatient || undefined}
            initialDataMode={initialDataMode}
          />
        );
      default:
        return (
          <DoctorInitialForm
            existingUser={myProfile ? undefined : currentPatient || undefined}
            initialDataMode={initialDataMode}
          />
        );
    }
  };

  return <div>{formType()}</div>;
}

InitialForm.defaultProps = {
  initialDataMode: false,
  myProfile: false,
  externalProfile: false,
};

export default InitialForm;
