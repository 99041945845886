import styled from 'styled-components';
import { TextField } from '@mui/material';

interface CMTextfieldProps {
  $nomargin?: boolean;
  capitalized?: boolean;
}

export const CMTextfield = styled(TextField)<CMTextfieldProps>`
  width: 100%;
  margin-bottom: ${(props) => (props.$nomargin ? '0' : '1rem')};

  input {
    text-transform: ${(props) => (!props.capitalized ? 'uppercase' : 'initial')};
  }

  .MuiOutlinedInput-root {
    border-radius: 12px;
    background-color: #fafafa;
  }
`;

export const CMTextfieldHalfRight = styled(TextField)<CMTextfieldProps>`
  width: 47.5%;
  margin-left: 2.5%;
  margin-bottom: 1rem;

  input {
    text-transform: ${(props) => (!props.capitalized ? 'uppercase' : 'initial')};
  }

  .MuiOutlinedInput-root {
    border-radius: 12px;
    background-color: #fafafa;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const CMTextfieldHalfLeft = styled(TextField)<CMTextfieldProps>`
  width: 47.5%;
  margin-right: 2.5%;
  margin-bottom: 1rem;

  input {
    text-transform: ${(props) => (!props.capitalized ? 'uppercase' : 'initial')};
  }

  .MuiOutlinedInput-root {
    border-radius: 12px;
    background-color: #fafafa;
  }
`;
