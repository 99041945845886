import React, { SyntheticEvent, useEffect } from 'react';
import { Button, Grid, MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { FaUserCircle, IoIosAddCircle } from 'react-icons/all';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { FormikHook } from '../../../../interfaces/formikInterfaces';
import { Patient } from '../../../../interfaces/Patients';
import {
  PrimaryTitle,
  PrimaryTitleStart,
} from '../../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../../components/PrimaryDescription/PrimaryDescription';
import fallbackImage from '../../../../assets/img/profile-user.png';
import { mainTheme } from '../../../../utils/theme/theme';
import {
  CMTextfield,
  CMTextfieldHalfLeft,
  CMTextfieldHalfRight,
} from '../../../../components/Forms/CMTextfield';
import { useMainProvider } from '../../../../context/MainProvider';

interface PatientFormOneProps {
  formik: FormikHook<Patient>;
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  imagePreview: string;
}

const PatientStepOne = ({
  formik,
  handleUpload,
  imagePreview,
}: PatientFormOneProps): JSX.Element => {
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const {
    titles,
    sexes,
    civilStatuses,
    getCivilStatuses,
    getSexes,
    getStates,
    ocupations,
    getOcupations,
    states,
    getTitles,
  } = useMainProvider();

  useEffect(() => {
    getStates();
    getSexes();
    getCivilStatuses();
    getOcupations();
    getTitles();
  }, [getStates, getSexes, getCivilStatuses, getOcupations, getTitles]);

  return (
    <>
      <PrimaryTitle mobile={mobile}>Completa la información de tu perfil</PrimaryTitle>
      <PrimaryDescription mobile={mobile}>
        Antes de comenzar necesitamos saber un poco más de ti. Añade una imagen para tu
        foto de perfil.
      </PrimaryDescription>
      <Grid item xs={12} md={12} style={{ justifyContent: 'center', display: 'flex' }}>
        <>
          <label htmlFor='upload-photo'>
            <input
              style={{ display: 'none' }}
              id='upload-photo'
              name='upload-photo'
              type='file'
              accept='image/png, image/jpeg'
              onChange={handleUpload}
            />
            <Button
              component='span'
              style={{
                width: mobile ? '100%' : 'initial',
                marginBottom: '1rem',
              }}
            >
              {imagePreview ? (
                <div style={{ position: 'relative' }}>
                  <img
                    src={imagePreview}
                    onError={(e: SyntheticEvent<HTMLImageElement>) => {
                      const target = e.target as EventTarget & HTMLImageElement;
                      target.src = fallbackImage;
                    }}
                    alt='img'
                    style={{
                      width: '15rem',
                    }}
                  />
                  <IoIosAddCircle
                    style={{
                      color: 'black',
                      fontSize: '3rem',
                      position: 'absolute',
                      right: '0',
                      bottom: '0',
                    }}
                  />
                </div>
              ) : (
                <div style={{ position: 'relative' }}>
                  <FaUserCircle
                    style={{
                      color: mainTheme.colors.uiElements,
                      fontSize: '10rem',
                    }}
                  />
                  <IoIosAddCircle
                    style={{
                      color: 'black',
                      fontSize: '3rem',
                      position: 'absolute',
                      right: '0',
                      bottom: '0',
                    }}
                  />
                </div>
              )}
            </Button>
            <span
              style={{
                marginLeft: mobile ? 'initial' : '2%',
                color: mainTheme.colors.standardText,
                display: mobile ? 'block' : 'initial',
              }}
            />
          </label>
        </>
      </Grid>
      <PrimaryTitleStart mobile={mobile}>Datos del paciente</PrimaryTitleStart>
      <CMTextfield
        select
        id='outlined-basic'
        label='Titulo'
        value={formik.values.pac_titulo_id}
        name='pac_titulo_id'
        required
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_titulo_id && Boolean(formik.errors.pac_titulo_id)}
        helperText={formik.touched.pac_titulo_id && formik.errors.pac_titulo_id}
        onChange={formik.handleChange}
      >
        <MenuItem value='-1'>Seleccione una opción</MenuItem>
        {titles.map((singleTitle) => {
          return (
            <MenuItem value={singleTitle.cat_titulo_id}>
              {singleTitle.cat_titulo_desc}
            </MenuItem>
          );
        })}
      </CMTextfield>
      <CMTextfield
        id='outlined-basic'
        label='Nombre'
        value={formik.values.pac_nombre}
        name='pac_nombre'
        required
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_nombre && Boolean(formik.errors.pac_nombre)}
        helperText={formik.touched.pac_nombre && formik.errors.pac_nombre}
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfLeft
        id='outlined-basic'
        label='Apellido paterno'
        value={formik.values.pac_apellidop}
        name='pac_apellidop'
        required
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_apellidop && Boolean(formik.errors.pac_apellidop)}
        helperText={formik.touched.pac_apellidop && formik.errors.pac_apellidop}
        onChange={formik.handleChange}
      />
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Apellido materno'
        value={formik.values.pac_apellidom}
        name='pac_apellidom'
        required
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_apellidom && Boolean(formik.errors.pac_apellidom)}
        helperText={formik.touched.pac_apellidom && formik.errors.pac_apellidom}
        onChange={formik.handleChange}
      />
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DesktopDatePicker
          label='Fecha de nacimiento'
          value={formik.values.pac_fechanacimiento}
          onChange={(value): void => {
            formik.setFieldValue('pac_fechanacimiento', value);
          }}
          inputFormat='DD/MM/yyyy'
          renderInput={(params) => (
            <CMTextfield
              {...params}
              name='pac_fechanacimiento'
              required
              error={
                formik.touched.pac_fechanacimiento &&
                Boolean(formik.errors.pac_fechanacimiento)
              }
              helperText={
                formik.touched.pac_fechanacimiento && formik.errors.pac_fechanacimiento
              }
            />
          )}
        />
      </LocalizationProvider>
      <CMTextfield
        select
        id='outlined-basic'
        label='Sexo'
        value={formik.values.pac_sexo_id}
        name='pac_sexo_id'
        required
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_sexo_id && Boolean(formik.errors.pac_sexo_id)}
        helperText={formik.touched.pac_sexo_id && formik.errors.pac_sexo_id}
        onChange={formik.handleChange}
      >
        <MenuItem value='-1'>Seleccione una opción</MenuItem>
        {sexes.map((singleSex) => {
          return (
            <MenuItem value={singleSex.cat_sexo_id}>{singleSex.cat_sexo_desc}</MenuItem>
          );
        })}
      </CMTextfield>
      <CMTextfield
        id='outlined-basic'
        label='CURP'
        value={formik.values.pac_curp}
        name='pac_curp'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_curp && Boolean(formik.errors.pac_curp)}
        helperText={formik.touched.pac_curp && formik.errors.pac_curp}
        onChange={formik.handleChange}
        required
      />
      <CMTextfieldHalfLeft
        select
        id='outlined-basic'
        label='Estado civil'
        value={formik.values.pac_estadocivil_id}
        name='pac_estadocivil_id'
        style={{ marginBottom: '1rem' }}
        error={
          formik.touched.pac_estadocivil_id && Boolean(formik.errors.pac_estadocivil_id)
        }
        helperText={formik.touched.pac_estadocivil_id && formik.errors.pac_estadocivil_id}
        onChange={formik.handleChange}
        required
      >
        <MenuItem value='-1'>Seleccione una opción</MenuItem>
        {civilStatuses.map((singleCivilStatus) => {
          return (
            <MenuItem value={singleCivilStatus.cat_edo_civil_id}>
              {singleCivilStatus.cat_edo_civil_desc}
            </MenuItem>
          );
        })}
      </CMTextfieldHalfLeft>
      <CMTextfieldHalfRight
        id='outlined-basic'
        label='Ciudad'
        value={formik.values.pac_ciudad}
        name='pac_ciudad'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_ciudad && Boolean(formik.errors.pac_ciudad)}
        helperText={formik.touched.pac_ciudad && formik.errors.pac_ciudad}
        onChange={formik.handleChange}
        required
      />
      <CMTextfieldHalfLeft
        select
        id='outlined-basic'
        label='Estado'
        value={formik.values.pac_estado_id}
        name='pac_estado_id'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_estado_id && Boolean(formik.errors.pac_estado_id)}
        helperText={formik.touched.pac_estado_id && formik.errors.pac_estado_id}
        onChange={formik.handleChange}
        required
      >
        <MenuItem value='-1'>Seleccione una opción</MenuItem>
        {states.map((singleState) => {
          return (
            <MenuItem value={singleState.cat_estado_id}>
              {singleState.cat_estado_desc}
            </MenuItem>
          );
        })}
      </CMTextfieldHalfLeft>
      <CMTextfieldHalfRight
        select
        id='outlined-basic'
        label='Ocupación'
        value={formik.values.pac_ocupacion_id}
        name='pac_ocupacion_id'
        style={{ marginBottom: '1rem' }}
        error={formik.touched.pac_ocupacion_id && Boolean(formik.errors.pac_ocupacion_id)}
        helperText={formik.touched.pac_ocupacion_id && formik.errors.pac_ocupacion_id}
        onChange={formik.handleChange}
        required
      >
        <MenuItem value='-1'>Seleccione una opción</MenuItem>
        {ocupations.map((singleOcupation) => {
          return (
            <MenuItem value={singleOcupation.cat_ocup_id}>
              {singleOcupation.cat_ocup_desc}
            </MenuItem>
          );
        })}
      </CMTextfieldHalfRight>
    </>
  );
};

export default PatientStepOne;
