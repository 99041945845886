import styled from 'styled-components';

interface Props {
  $mobile: boolean;
}

export const LandingFeatureCard = styled.div<Props>`
  width: ${(props) => (props.$mobile ? 'calc(80%)' : 'calc(25%)')};
  padding: 1.5rem;
  border-radius: 7px;
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.3s;
  margin-bottom: ${(props) => (props.$mobile ? '1rem' : '0')};

  :hover {
    box-shadow: 0px 1px 30px 0px rgba(112, 112, 112, 0.15);
    border-color: transparent;
    transition: all 0.3s;
  }
`;
