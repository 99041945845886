import { Dispatch, SetStateAction } from 'react';
import { CivilStatuses } from './CivilStatuses';
import { Specialities } from './DoctorSpecialities';
import { NursesSpecialities } from './NurseSpecialities';
import { Ocupations } from './Ocupations';
import { ScholarDegree } from './ScholarDegree';
import { Sexes } from './Sexes';
import { States } from './States';
import { Titles } from './Titles';
import { TemporaryUser, User } from './User';
import { Cie10 } from './Cie10';
import { SpecialCardsType } from './SpecialCardsTypes';
import { GenericTableRow } from './GenericTable';
import { InfoViewerRow } from './InfoViewer';
import { QRModes } from './QRCodes';
import { InfoForDoctorProfile } from './DoctorProfile';

export enum AlertTypes {
  Error,
  Success,
  Warning,
  Info,
}

export interface MainContextInitialValues {
  user?: User;
  openAlert: boolean;
  alertMessage: string;
  alertType: AlertTypes;
  setUser: (user: User | undefined) => void;
  setOpenAlert: (openAlertMessage: boolean) => void;
  setAlertMessage: (alertMessage: string) => void;
  setAlertType: (setAlertType: AlertTypes) => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  initialLoad: boolean;
  setInitialLoad: (setInitialLoad: boolean) => void;
  loadInitialData: () => void;
  nurseSpecialities: NursesSpecialities[];
  getNurseSpecialities: () => void;
  getStates: () => void;
  states: States[];
  getDoctorSpecialities: () => void;
  specialities: Specialities[];
  titles: Titles[];
  getTitles: () => void;
  sexes: Sexes[];
  getSexes: () => void;
  civilStatuses: CivilStatuses[];
  getCivilStatuses: () => void;
  ocupations: Ocupations[];
  getOcupations: () => void;
  scholarDegrees: ScholarDegree[];
  getScholarDegrees: () => void;
  cie10: Cie10[];
  getCie10: () => void;
  isHMOpen: boolean;
  setIsHMOpen: Dispatch<SetStateAction<boolean>>;
  getSpecialCardTypes: () => void;
  specialCardTypes: SpecialCardsType[];
  currentPatient?: TemporaryUser;
  setCurrentPatient: (user: TemporaryUser | undefined) => void;
  temporaryUsers: TemporaryUser[];
  setTemporaryUsers: (users: TemporaryUser[]) => void;
  qrModeOpen?: QRModes;
  setQrModeOpen: (mode?: QRModes) => void;
  qrCode: string;
  setQrCode: (code: string) => void;
  infoViewerInfo: InfoViewerRow;
  setInfoViewerInfo: (info: InfoViewerRow) => void;
  infoViewerOpen: boolean;
  setInfoViewerOpen: (open: boolean) => void;
  restartTemporayUsers: () => void;
  infoForDoctorProfile?: InfoForDoctorProfile;
  setInfoForDoctorProfile: (info: InfoForDoctorProfile | undefined) => void;
  loginPopupOpen: boolean;
  setLoginPopupOpen: (open: boolean) => void;
  handleLogout: (e: any) => void;
}
