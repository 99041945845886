import React from 'react';
import {
  Alert,
  AlertColor,
  CircularProgress,
  Snackbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useMainProvider } from '../../context/MainProvider';
import { mainTheme } from '../../utils/theme/theme';
import Navbar from '../../components/Navbar/Navbar';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import HamburgerMenu from '../../components/Navbar/HamburgerMenu/HamburgerMenu';
import QRPopup from '../../components/QRPopup/QRPopup';
import { removeLocalstorageData } from '../../utils/localStorage';
import DoctorProfile from '../../components/DoctorProfile/DoctorProfile';
import LoginPopup from '../../components/LoginPopup/LoginPopup';

function MainLayout({ children }: { children: React.ReactNode }): JSX.Element {
  const {
    openAlert,
    setOpenAlert,
    alertMessage,
    alertType,
    loading,
    isHMOpen,
    setIsHMOpen,
    user,
    setUser,
    setCurrentPatient,
    setTemporaryUsers,
    setQrCode,
  } = useMainProvider();

  const muiTheme = useTheme();
  const mobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const navigate = useNavigate();
  /**
   * closes alert message
   */
  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  /**
   *
   * @desc logouts a user of the application
   */
  const handleLogout = () => {
    setUser(undefined);
    setCurrentPatient(undefined);
    setTemporaryUsers([]);
    setQrCode('');
    removeLocalstorageData('CODIGO_MEDICO_USER_INFORMATION');
    navigate({
      pathname: `/login`,
    });
  };

  const handleOnIdle = () => {
    handleLogout();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    crossTab: true,
    timers: workerTimers,
  });

  const location = useLocation();

  const mapAlertTypeToSeverity = (type: AlertTypes): AlertColor => {
    switch (type) {
      case AlertTypes.Error:
        return 'error';
      case AlertTypes.Success:
        return 'success';
      case AlertTypes.Warning:
        return 'warning';
      default:
        return 'info';
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <HamburgerMenu />
      <QRPopup />
      <LoginPopup />
      <DoctorProfile />
      <div
        style={{
          width: '100%',
          marginLeft: isHMOpen ? '15rem' : '0',
          transition: 'margin-left 0.5s',
          transitionTimingFunction: 'ease-out',
        }}
      >
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 5000,
              height: '200vh',
              backgroundColor: 'white',
            }}
          >
            <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
              <CircularProgress
                variant='determinate'
                style={{
                  color: 'lightgray',
                  animationDuration: '550ms',
                  position: 'absolute',
                  top: 'calc(50% - 35px)',
                  left: 'calc(50% - 35px)',
                }}
                size={70}
                thickness={4}
                value={100}
              />
              <CircularProgress
                variant='indeterminate'
                disableShrink
                style={{
                  strokeLinecap: 'round',
                  color: mainTheme.colors.uiElements,
                  animationDuration: '550ms',
                  position: 'absolute',
                  top: 'calc(50% - 35px)',
                  left: 'calc(50% - 35px)',
                }}
                size={70}
                thickness={4}
              />
            </div>
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => setIsHMOpen(false)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: isHMOpen ? '100%' : '0',
            zIndex: 6000,
            height: '200vh',
            backgroundColor: isHMOpen ? 'rgba(0,0,0,0.6)' : 'transparent',
            transition: 'background 0.5s, background-color 0.5s',
            transitionTimingFunction: 'ease-out',
          }}
        />
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={() => {
            handleAlertClose();
          }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            variant='outlined'
            severity={mapAlertTypeToSeverity(alertType)}
            onClose={() => {
              handleAlertClose();
            }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        {location.pathname !== '/' ? <Navbar /> : null}
        {location.pathname !== '/' ? (
          <div style={{ width: '100%', marginTop: mobile ? '4.5rem' : '6rem' }}>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export default MainLayout;
