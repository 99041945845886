import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import {
  Autocomplete,
  Dialog,
  DialogContent,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import axios from 'axios';
import { AnimatePresence } from 'framer-motion';
import { IoMdCloseCircle } from 'react-icons/all';
import { useReactToPrint } from 'react-to-print';
import { useMainProvider } from '../../context/MainProvider';
import { CRUDModes } from '../../interfaces/CRUD';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import { PrimaryDescription } from '../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../components/Forms/CMTextfield';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import CenteredForm from '../../index.styled';
import { PrimarySubtitle } from '../../components/PrimarySubtitle/PrimarySubtitle';
import { setHeaders } from '../../utils/headerSetter';
import { CMTextArea } from '../../components/Forms/CMTextArea';
import { CMInputLabel } from '../../components/Forms/InputLabel';
import { EstudioPost, EstudiosTypes } from '../../interfaces/Receta';
import { NoPaddingButton } from '../../components/NoPaddingButton/NoPaddingButton';
import { Patient } from '../../interfaces/Patients';
import DialogCustomTitle from '../../components/DialogCustomTitle/DialogCustomTitle';
import PatientData from '../../components/PatientData/PatientData';
import { canPrintNote } from '../../utils/fns';
import { isIOSApp } from '../../interfaces/WebkitWindow';
import MobileDownloadMessage from '../../components/MobileDownloadMessage';
import {
  EstudiosIndicados,
  EstudiosIndicadosPost,
  ExtendedEstudiosIndicados,
} from '../../interfaces/EstudiosIndicados';
import EstudiosIndicadosPrintableDocument from './EstudiosIndicadosPrintableDocument';

interface FormProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  mode: CRUDModes;
  onSuccess: () => void;
  values?: ExtendedEstudiosIndicados;
  closeFunction?: () => void;
}

const initialValues: EstudiosIndicados = {
  paciente_id: 0,
  doctor_id: 0,
  estudios_fuera_cuadro: '',
};

const EstudiosIndicadosForm = ({
  open,
  setOpen,
  mode,
  onSuccess,
  values,
  closeFunction,
}: FormProps): JSX.Element => {
  const {
    loading,
    setLoading,
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    currentPatient,
    handleLogout,
  } = useMainProvider();
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formValues, setFormValues] = useState(initialValues);
  const [showExtraStudyField, setShowExtraStudyField] = useState(false);
  const [estudios, setEstudios] = useState<EstudioPost[]>([]);
  const [selectedEstudios, setSelectedEstudios] = useState<EstudioPost[]>([]);
  const [search, setSearch] = useState('');
  const [patientInformation, setPatientInformation] = useState<Patient>();
  const printRef = useRef<HTMLInputElement>(null);
  const fieldsDisabled = useMemo(
    () => mode === CRUDModes.Update && formValues.doctor_id !== user?.id,
    [mode, formValues, user?.id],
  );

  useEffect(() => {
    if (!values) {
      if (currentPatient) {
        setPatientInformation(currentPatient.initialData as Patient);
      }
    } else {
      console.log(values);
      setPatientInformation(values.patientInformation as Patient);
      setFormValues(values);
      setSelectedEstudios(values.estudiosResults || []);
      if (values.estudios_fuera_cuadro) {
        setShowExtraStudyField(true);
      }
    }
  }, [currentPatient, values]);

  const formik = useFormik<EstudiosIndicados>({
    initialValues: formValues,
    enableReinitialize: true,
    onSubmit: async (form) => {
      try {
        setLoading(true);
        const entry: EstudiosIndicadosPost = {
          nota: {
            ...form,
            fecha: moment
              .utc()
              .utcOffset(-300)
              .toISOString(true)
              .slice(0, 19)
              .replace('T', ' '),
          },
          estudios: selectedEstudios,
        };
        const creationResult = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/estudiosIndicados${
            mode === CRUDModes.Create ? '' : '/update'
          }`,
          {
            entry,
            patientId:
              currentPatient && mode === CRUDModes.Create ? currentPatient.id : undefined,
          },
          setHeaders(true, user ? user.token : undefined),
        );
        if (creationResult.status === 200) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `¡Estudios ${
              mode === CRUDModes.Create ? 'creados' : 'actualizados'
            } exitosamente!`,
          );
          setOpenAlert(true);
          setLoading(false);
          setSelectedEstudios([]);
          setShowExtraStudyField(false);
          setOpen(false);
          onSuccess();
          if (closeFunction) closeFunction();
        } else {
          setLoading(false);
        }
      } catch (e) {
        handleLogout(e);
        setLoading(false);
        setAlertType(AlertTypes.Error);
        setAlertMessage(`${e}`);
        setOpenAlert(true);
      }
    },
  });

  const addEstudio = () => {
    setSearch('');
    if (!selectedEstudios.find((estudio) => estudio.id_estudio === ''))
      setSelectedEstudios([
        ...selectedEstudios,
        { id_estudio: '', nombre: '', tipo: EstudiosTypes.Regulares },
      ]);
  };

  const searchOptions = async (searchterm: string) => {
    try {
      const results = await axios.post<EstudioPost[]>(
        `${process.env.REACT_APP_SERVER_URL}/receta/search-estudios`,
        {
          searchterm,
        },
        setHeaders(true, user ? user.token : undefined),
      );
      setEstudios(results.data);
    } catch (e) {
      handleLogout(e);
      setAlertType(AlertTypes.Error);
      setAlertMessage(`${e}`);
      setOpenAlert(true);
    }
  };

  const searchCallback = useCallback(searchOptions, [
    setAlertMessage,
    setAlertType,
    setOpenAlert,
    user,
    handleLogout,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchCallback(search);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [search, searchCallback]);

  const updateSelectionEstudio = (
    index: number,
    updatedSelection: EstudioPost | null,
  ) => {
    const newArray = [...selectedEstudios];
    newArray[index] = updatedSelection || {
      id_estudio: '',
      nombre: '',
      tipo: EstudiosTypes.Regulares,
    };
    setSelectedEstudios(newArray);
  };

  const deleteEstudio = (index: number) => {
    const newEntries = [...selectedEstudios];
    newEntries.splice(index, 1);
    setSelectedEstudios(newEntries);
  };

  const resetForm = () => {
    formik.resetForm();
    setFormValues(initialValues);
    setSearch('');
    setSelectedEstudios([]);
    setShowExtraStudyField(false);
    setOpen(false);
    if (closeFunction) closeFunction();
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: 'background: white !important;',
  });

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          resetForm();
        }
      }}
    >
      <DialogCustomTitle
        onClose={resetForm}
        mode={mode}
        title='nota de estudios indicados'
      />
      <DialogContent>
        <AnimatePresence exitBeforeEnter>
          {' '}
          <CenteredForm onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs={12}>
                <PatientData patientInformation={patientInformation || undefined} />
              </Grid>
              <Grid item xs={12}>
                <PrimarySubtitle mobile>
                  Estudios de laboratorio y gabinete indicados
                </PrimarySubtitle>
                {!fieldsDisabled ? (
                  <PrimaryDescription mobile style={{ margin: '0' }}>
                    {mobile ? 'Toca el' : 'Haz click en el'} botón de &quot;Añadir
                    estudio&quot; para capturar un nuevo estudio.
                  </PrimaryDescription>
                ) : null}
              </Grid>
              {selectedEstudios.length
                ? selectedEstudios.map((estudio, index) => {
                    const key = `inputEstudio#${estudio.id_estudio}${index}`;
                    return (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: '100%',
                          flexWrap: 'nowrap',
                          padding: '1rem 0 0 1rem',
                        }}
                        key={key}
                      >
                        <Autocomplete
                          disabled={fieldsDisabled}
                          style={{ width: 'calc(100% - 2rem)' }}
                          id={`estudio#${estudio.id_estudio}`}
                          options={[
                            estudio,
                            ...estudios.filter(
                              (std) => std.id_estudio !== estudio.id_estudio,
                            ),
                          ]}
                          noOptionsText='Busca un estudio por nombre'
                          getOptionLabel={(option) =>
                            option.id_estudio
                              ? `${option.id_estudio}: ${option.nombre}`
                              : ''
                          }
                          onInputChange={(_event, value) => setSearch(value)}
                          value={estudio}
                          onChange={(_e, value) => {
                            updateSelectionEstudio(index, value);
                          }}
                          renderInput={(params) => (
                            <CMTextfield
                              $nomargin
                              {...params}
                              label='Estudio'
                              placeholder='Estudio'
                              name={`estudio#${estudio.id_estudio}`}
                            />
                          )}
                        />
                        {mode === CRUDModes.Create ? (
                          <NoPaddingButton
                            type='button'
                            onClick={() => deleteEstudio(index)}
                          >
                            <IoMdCloseCircle
                              style={{
                                fontSize: '1.5rem',
                                marginLeft: '0.5rem',
                                cursor: 'pointer',
                              }}
                            />
                          </NoPaddingButton>
                        ) : null}
                      </div>
                    );
                  })
                : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <NoPaddingButton
                    type='button'
                    onClick={addEstudio}
                    style={{ padding: '0.5rem', fontSize: '1rem' }}
                  >
                    + Añadir estudio
                  </NoPaddingButton>
                </Grid>
              ) : null}
              {showExtraStudyField ? (
                <Grid item xs={12}>
                  <CMInputLabel mobile>Estudios fuera del cuadro médico</CMInputLabel>
                  <CMTextArea
                    disabled={fieldsDisabled}
                    $spacing={2}
                    id='estudios_fuera_cuadro'
                    name='estudios_fuera_cuadro'
                    value={formik.values.estudios_fuera_cuadro}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    minRows={2}
                    placeholder='Información de los estudios'
                  />
                </Grid>
              ) : (
                <>
                  {!fieldsDisabled ? (
                    <Grid item xs={12}>
                      <NoPaddingButton
                        type='button'
                        onClick={() => setShowExtraStudyField(true)}
                        style={{
                          padding: '0.5rem',
                          fontSize: '1rem',
                          textAlign: 'start',
                        }}
                      >
                        + Añadir estudio fuera del catálogo médico
                      </NoPaddingButton>
                    </Grid>
                  ) : null}
                </>
              )}
              {values &&
              values.doctorInformation &&
              mode !== CRUDModes.Create &&
              values.patientInformation &&
              values.doctor_id &&
              user &&
              canPrintNote(values.patientInformation, values.doctor_id, user) ? (
                <Grid item xs={12}>
                  <div style={{ display: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      id='sipleCardDiv'
                      ref={printRef}
                      style={{
                        marginBottom: '1rem',
                        background: 'rgba(0,0,0,0.1)',
                        padding: '.8rem',
                        borderRadius: '10px',
                      }}
                    >
                      <EstudiosIndicadosPrintableDocument
                        patient={values.patientInformation}
                        doctor={values.doctorInformation}
                        values={values}
                        selectedEstudios={selectedEstudios}
                      />
                    </Grid>
                  </div>
                  {!isIOSApp() ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      style={{ width: '100%' }}
                      onClick={() => handlePrint()}
                    >
                      Imprimir
                    </PrimaryButton>
                  ) : (
                    <MobileDownloadMessage />
                  )}
                </Grid>
              ) : null}
              {!fieldsDisabled ? (
                <Grid item xs={12}>
                  <PrimaryButton
                    loading={loading}
                    type='button'
                    onClick={formik.handleSubmit}
                    style={{ width: '100%' }}
                  >
                    {mode === CRUDModes.Update ? 'Actualizar' : '+ Crear'}
                  </PrimaryButton>
                  {mode === CRUDModes.Update ? (
                    <PrimaryButton
                      loading={loading}
                      type='button'
                      onClick={async () => {
                        await formik.setFieldValue('isActive', false);
                        formik.handleSubmit();
                      }}
                      style={{ width: '100%', background: 'red', marginTop: '4rem' }}
                    >
                      Eliminar
                    </PrimaryButton>
                  ) : null}
                </Grid>
              ) : null}
            </Grid>
          </CenteredForm>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  );
};

EstudiosIndicadosForm.defaultProps = {
  values: undefined,
  closeFunction: () => {},
};

export default EstudiosIndicadosForm;
