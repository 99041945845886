import React, { useEffect } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { IoCloseCircleSharp } from 'react-icons/all';
import { useMainProvider } from '../../context/MainProvider';
import { DoctorProfileResponse } from '../../interfaces/DoctorProfile';
import { gettWithToken } from '../../utils/server';
import { AlertTypes } from '../../interfaces/MainContextInitialValues';
import EmptyClosure from '../../utils/closures';
import { StandardText } from '../StandardText/StandardText';
import { FlexContainer } from '../FlexContainer/FlexContainer';
import { StandardDivider } from '../Divider/StandardDivider';

const DoctorProfile = (): JSX.Element => {
  const {
    infoForDoctorProfile,
    setInfoForDoctorProfile,
    setAlertType,
    setAlertMessage,
    setOpenAlert,
    handleLogout,
  } = useMainProvider();
  const [doctorInfo, setDoctorInfo] = React.useState<DoctorProfileResponse>();

  useEffect(() => {
    const getDoctorInfo = async () => {
      if (infoForDoctorProfile) {
        const result = await gettWithToken<DoctorProfileResponse>(
          `/doctor-info?documentId=${infoForDoctorProfile.documentId}&documentType=${infoForDoctorProfile.documentType}`,
          handleLogout,
        );
        if (result.success) {
          setDoctorInfo(result.data);
        } else {
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al obtener perfil del médico: ${result.error} `);
          setOpenAlert(true);
        }
      } else {
        setDoctorInfo(undefined);
      }
    };
    getDoctorInfo().then(EmptyClosure).catch(EmptyClosure);
  }, [infoForDoctorProfile, setAlertMessage, setAlertType, setOpenAlert, handleLogout]);

  return (
    <Dialog
      maxWidth='xl'
      open={!!infoForDoctorProfile}
      onClose={() => setInfoForDoctorProfile(undefined)}
    >
      <DialogContent style={{ position: 'relative' }}>
        <IoCloseCircleSharp
          style={{
            fontSize: '2rem',
            cursor: 'Pointer',
            color: '#1c1c1c',
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          }}
          onClick={() => setInfoForDoctorProfile(undefined)}
        />
        {doctorInfo ? (
          <FlexContainer
            direction='row'
            justifyContent='center'
            alignContent='center'
            alignItems='flex-start'
            wrap='wrap'
          >
            <FlexContainer direction='column' alignItems='center' alignContent='center'>
              {doctorInfo.image ? (
                <img
                  src={doctorInfo.image}
                  alt='Foto de perfil del médico'
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '50%',
                    marginBottom: '1rem',
                  }}
                />
              ) : null}
              <StandardText
                style={{
                  textAlign: 'center',
                  fontWeight: 500,
                  fontSize: '1.2rem',
                }}
              >
                {doctorInfo.completeName}
              </StandardText>
              <StandardDivider />
            </FlexContainer>
            <FlexContainer
              style={{ width: '50%', marginBottom: '1rem' }}
              direction='column'
            >
              <StandardText
                style={{ textAlign: 'center', fontWeight: 500, fontSize: '1rem' }}
              >
                Cédula profesional
              </StandardText>
              <StandardText
                style={{ textAlign: 'center', fontWeight: 400, fontSize: '0.8rem' }}
              >
                {doctorInfo.cedula} - {doctorInfo.cedulaInstitution}
              </StandardText>
            </FlexContainer>
            <FlexContainer
              style={{ width: '50%', marginBottom: '1rem' }}
              direction='column'
            >
              <StandardText
                style={{ textAlign: 'center', fontWeight: 500, fontSize: '1rem' }}
              >
                Especialidad
              </StandardText>
              <StandardText
                style={{ textAlign: 'center', fontWeight: 400, fontSize: '0.8rem' }}
              >
                {doctorInfo.speciality} - {doctorInfo.specialityCedula || 'N/A'}
              </StandardText>
            </FlexContainer>
            <FlexContainer
              style={{ width: '50%', marginBottom: '1rem' }}
              direction='column'
            >
              <StandardText
                style={{ textAlign: 'center', fontWeight: 500, fontSize: '1rem' }}
              >
                Teléfono
              </StandardText>
              <StandardText
                style={{ textAlign: 'center', fontWeight: 400, fontSize: '0.8rem' }}
              >
                {doctorInfo.phone || 'N/A'}
              </StandardText>
            </FlexContainer>
            <FlexContainer
              style={{ width: '50%', marginBottom: '1rem' }}
              direction='column'
            >
              <StandardText
                style={{ textAlign: 'center', fontWeight: 500, fontSize: '1rem' }}
              >
                Email
              </StandardText>
              <StandardText
                style={{
                  textAlign: 'center',
                  fontWeight: 400,
                  fontSize: '0.8rem',
                  wordWrap: 'break-word',
                }}
              >
                {doctorInfo.email || 'N/A'}
              </StandardText>
            </FlexContainer>
          </FlexContainer>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default DoctorProfile;
