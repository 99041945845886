import React from 'react';
import { PrimaryDescription } from './PrimaryDescription/PrimaryDescription';

const MobileDownloadMessage = (): JSX.Element => {
  return (
    <PrimaryDescription>
      Si requiere imprimir esta nota, favor de hacerlo desde nuestro sitio web
      https://zivot.com.mx
    </PrimaryDescription>
  );
};

export default MobileDownloadMessage;
