import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout/MainLayout';
import LandingPage from '../pages/LandingPage/LandingPage';
import LoginPage from '../pages/Login/LoginPage';
import RegisterPage from '../pages/Register/RegisterPage';
import ForgotPasswordPage from '../pages/ForgotPassword/Forgot/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ForgotPassword/Reset/ResetPasswordPage';
import Private from '../components/Private/Private';
import InitialForm from '../pages/InitialForm/InitialForm';
import HomePage from '../pages/Home/HomePage';
import ClinicHistoryPage from '../pages/HistoriaClinica/ClinicHistoryPage';
import ClinicPediatricHistoryPage from '../pages/ClinicPediatricHistory/ClinicPediatricHistoryPage';
import RadiographiesPage from '../pages/Radiographies/RadiographiesPage';
import EvolutionNotePage from '../pages/EvolutionNotes/EvolutionNotePage';
import RecetaPage from '../pages/Receta/RecetaPage';
import NotaConsultaPage from '../pages/NotaConsulta/NotaConsultaPage';
import PreOperationNotePage from '../pages/PreOperationNotes/PreOperationNotePage';
import SurgicalNotePage from '../pages/SurgicalNotes/SurgicalNotePage';
import SimpleCardsPage from '../pages/SimpleCards/SimpleCardsPage';
import SpecialCardsPage from '../pages/SpecialCards/SpecialCardsPage';
import NotaIndicacionesPage from '../pages/NotaIndicaciones/NotaIndicacionesPage';
import NotaEnfermeriaPage from '../pages/NotaEnfermeria/NotaEnfermeriaPage';
import ImagingPage from '../pages/Imaging/ImagingPage';
import DocumentsPage from '../pages/Documents/DocumentsPage';
import MainProvider from '../context/MainProvider';
import Laboratory from '../pages/Laboratory/LaboratoryPage';
import EstudiosIndicadosPage from '../pages/EstudiosIndicados/EstudiosIndicadosPage';
import ValidatePage from '../pages/Validate/ValidatePage';
import UserValidationPage from '../pages/UserValidation/UserValidationPage';

const UsersRouter = (): JSX.Element => {
  return (
    <MainProvider>
      <MainLayout>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/login/:userData' element={<LoginPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/forgot' element={<ForgotPasswordPage />} />
          <Route path='/reset/:userId/:token' element={<ResetPasswordPage />} />
          <Route
            path='/initial-form'
            element={
              <Private path='/initial-form'>
                <InitialForm initialDataMode />
              </Private>
            }
          />
          <Route
            path='/validate'
            element={
              <Private path='/validate'>
                <ValidatePage />
              </Private>
            }
          />
          <Route
            path='/profile'
            element={
              <Private path='/profile'>
                <InitialForm externalProfile />
              </Private>
            }
          />
          <Route
            path='/myprofile'
            element={
              <Private path='/myprofile'>
                <InitialForm myProfile />
              </Private>
            }
          />
          <Route
            path='/user-validation'
            element={
              <Private path='/user-validation'>
                <UserValidationPage />
              </Private>
            }
          />
          <Route
            path='/home'
            element={
              <Private path='/home'>
                <HomePage />
              </Private>
            }
          />
          <Route
            path='/clinic-histories'
            element={
              <Private path='/clinic-histories'>
                <ClinicHistoryPage />
              </Private>
            }
          />
          <Route
            path='/pediatric-clinic-histories'
            element={
              <Private path='/pediatric-clinic-histories'>
                <ClinicPediatricHistoryPage />
              </Private>
            }
          />
          <Route
            path='/radiographies'
            element={
              <Private path='/radiographies'>
                <RadiographiesPage />
              </Private>
            }
          />
          <Route
            path='/estudios-indicados'
            element={
              <Private path='/estudios-indicados'>
                <EstudiosIndicadosPage />
              </Private>
            }
          />
          <Route
            path='/evolution-notes'
            element={
              <Private path='/evolution-notes'>
                <EvolutionNotePage />
              </Private>
            }
          />
          <Route
            path='/prescriptions'
            element={
              <Private path='/prescriptions'>
                <RecetaPage />
              </Private>
            }
          />
          <Route
            path='/consulting-notes'
            element={
              <Private path='/consulting-notes'>
                <NotaConsultaPage />
              </Private>
            }
          />
          <Route
            path='/pre-operation-notes'
            element={
              <Private path='/pre-operation-notes'>
                <PreOperationNotePage />
              </Private>
            }
          />
          <Route
            path='/surgical-notes'
            element={
              <Private path='/surgical-notes'>
                <SurgicalNotePage />
              </Private>
            }
          />
          <Route
            path='/laboratory'
            element={
              <Private path='/laboratory'>
                <Laboratory />
              </Private>
            }
          />
          <Route
            path='/simple-cards'
            element={
              <Private path='/simple-cards'>
                <SimpleCardsPage />
              </Private>
            }
          />
          <Route
            path='/special-cards'
            element={
              <Private path='/special-cards'>
                <SpecialCardsPage />
              </Private>
            }
          />
          <Route
            path='/indication-notes'
            element={
              <Private path='/indication-notes'>
                <NotaIndicacionesPage />
              </Private>
            }
          />
          <Route
            path='/nurse-notes'
            element={
              <Private path='/nurse-notes'>
                <NotaEnfermeriaPage />
              </Private>
            }
          />
          <Route
            path='/imaging'
            element={
              <Private path='/imaging'>
                <ImagingPage />
              </Private>
            }
          />
          <Route
            path='/documents'
            element={
              <Private path='/documents'>
                <DocumentsPage />
              </Private>
            }
          />
          <Route path='*' element={<h1>URL no encontrado</h1>} />
        </Routes>
      </MainLayout>
    </MainProvider>
  );
};

export default UsersRouter;
