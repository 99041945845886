import { useNavigate } from 'react-router-dom';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useSponsorsProvider } from '../../../Context/SponsorsMainContext';
import { RequiredMessage } from '../../../../../utils/validationMessages';
import { AlertTypes } from '../../../../../interfaces/MainContextInitialValues';
import { postWithToken } from '../../../../../utils/server';
import { SponsorAccountType } from '../../../Interfaces/ContextInterfaces';
import { CRUDModes } from '../../../../../interfaces/CRUD';
import { PageContainer } from '../../../../Register/Register.styled';
import CenteredForm from '../../../../../index.styled';
import { CMTextfield } from '../../../../../components/Forms/CMTextfield';
import { DisclaimerText } from '../../../../../components/DisclaimerText/DisclaimerText';
import PrimaryButton from '../../../../../components/PrimaryButton/PrimaryButton';
import { initialSponsorPayment, SponsorPayment } from '../SponsorPayments.Interfaces';

interface Props {
  values?: SponsorPayment;
  onSuccess?: () => void;
  mode?: CRUDModes;
}

const SponsorPaymentForm = ({ values, onSuccess, mode }: Props): JSX.Element => {
  const {
    setOpenAlert,
    setAlertMessage,
    setAlertType,
    setLoading,
    handleLogoutError,
    sponsor,
    user,
  } = useSponsorsProvider();
  const [formValues, setFormValues] = useState<SponsorPayment>(initialSponsorPayment);

  const [error, setError] = useState<string>('');

  const validationSchema = Yup.object({
    amount: Yup.number().required(RequiredMessage).min(0, 'El monto debe ser mayor a 0'),
  });

  useEffect(() => {
    if (values) {
      setFormValues(values);
    }
  }, [values]);

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    validate: () => {
      setError('');
    },
    onSubmit: async (form) => {
      try {
        setLoading(true);
        const payload = { ...form };
        if (user?.typeId !== SponsorAccountType.superAdmin) {
          payload.sponsor_id = sponsor?.id ?? 0;
        }
        const result = await postWithToken<SponsorPayment, null>(
          `/sponsors/${mode === CRUDModes.Create ? '' : 'update-'}payments`,
          {
            ...payload,
          },
          handleLogoutError,
          true,
        );
        if (result.success) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(`Información guardada con éxito`);
          setOpenAlert(true);
          formik.resetForm();
          if (onSuccess) {
            onSuccess();
          }
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error al guardar información`);
          setOpenAlert(true);
        }
        setLoading(false);
      } catch (e: any) {
        handleLogoutError(e);
        if (JSON.stringify(e.response.data)) {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(
            `Error al ${
              mode === CRUDModes.Update ? 'editar' : 'guardar'
            } patrocinador: ${JSON.stringify(e.response.data)} `,
          );
          setOpenAlert(true);
        } else {
          setLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
        setLoading(false);
      }
    },
  });
  return (
    <PageContainer style={{ paddingTop: '1.5rem' }}>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              exit={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='login'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <CMTextfield
                  id='outlined-basic'
                  label='Monto del pago'
                  value={formik.values.amount}
                  name='amount'
                  type='number'
                  required
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {error ? (
                  <DisclaimerText
                    style={{ color: 'red', textAlign: 'center', marginTop: '1rem' }}
                  >
                    {error}
                  </DisclaimerText>
                ) : null}
                <PrimaryButton
                  loading={false}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  {mode === CRUDModes.Create ? 'Crear' : 'Guardar'} pago
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

SponsorPaymentForm.defaultProps = {
  values: undefined,
  mode: CRUDModes.Create,
  onSuccess: () => {},
};

export default SponsorPaymentForm;
