import React, { useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import { useMainProvider } from '../../../context/MainProvider';
import CenteredForm from '../../../index.styled';
import { PrimaryTitle } from '../../../components/PrimaryTitle/PrimaryTitle';
import { PrimaryDescription } from '../../../components/PrimaryDescription/PrimaryDescription';
import { CMTextfield } from '../../../components/Forms/CMTextfield';
import { RouterLink } from '../../../components/RouterLink/RouterLink';
import { PageContainer } from '../../Register/Register.styled';
import { EmailMessage, RequiredMessage } from '../../../utils/validationMessages';
import { User } from '../../../interfaces/User';
import { ForgotPasswordForm } from '../../../interfaces/ForgotPassword';
import { AlertTypes } from '../../../interfaces/MainContextInitialValues';

const ForgotPasswordPage = (): JSX.Element => {
  const { setOpenAlert, setAlertMessage, setAlertType, handleLogout } = useMainProvider();
  const [buttonLoading, setButtonLoading] = useState(false);
  const mobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string().email(EmailMessage).required(RequiredMessage),
  });

  const formik = useFormik<ForgotPasswordForm>({
    initialValues: {
      email: '',
    },
    validationSchema,
    /**
     * @desc logins the user with the given information
     * @param form
     */
    onSubmit: async (form) => {
      try {
        setButtonLoading(true);
        const emailResult = await axios.post<{ user: User; token: string }>(
          `${process.env.REACT_APP_SERVER_URL}/forgot`,
          {
            email: form.email,
          },
        );
        if (emailResult.status === 200) {
          setAlertType(AlertTypes.Success);
          setAlertMessage(
            `¡Correo enviado exitosamente a ${form.email}! Revisa tu bandeja de entrada.`,
          );
          setOpenAlert(true);
          setButtonLoading(false);
          navigate('/login');
        } else {
          setButtonLoading(false);
        }
      } catch (e: any) {
        handleLogout(e);
        if (JSON.stringify(e.response.data)) {
          setButtonLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error: ${JSON.stringify(e.response.data)} `);
          setOpenAlert(true);
        } else {
          setButtonLoading(false);
          setAlertType(AlertTypes.Error);
          setAlertMessage(`Error en el servidor: ${e}`);
          setOpenAlert(true);
        }
      }
    },
  });

  return (
    <PageContainer>
      <Grid container direction='row' justifyContent='center'>
        <Grid item xs={12} md={5} lg={3}>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              exit={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3 }}
              key='forgot'
            >
              <CenteredForm onSubmit={formik.handleSubmit}>
                <PrimaryTitle mobile={mobile}>Recuperar contraseña</PrimaryTitle>
                <PrimaryDescription mobile={mobile}>
                  Ingresa tu correo y recibirás un correo con instrucciones sobre cómo
                  recuperar tu contraseña
                </PrimaryDescription>
                <CMTextfield
                  capitalized
                  id='outlined-basic'
                  label='Email'
                  value={formik.values.email}
                  name='email'
                  style={{ marginBottom: '1rem' }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onChange={formik.handleChange}
                />
                <RouterLink to='/login'>Regresar a inicio de sesión</RouterLink>
                <PrimaryButton
                  loading={buttonLoading}
                  type='submit'
                  style={{ width: '100%', marginTop: '1rem' }}
                >
                  Recuperar contraseña
                </PrimaryButton>
              </CenteredForm>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ForgotPasswordPage;
