import React, { useState } from 'react';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CMTextfield } from './CMTextfield';

const PasswordTextfield = (props: TextFieldProps): JSX.Element => {
  const [showText, setShowText] = useState(false);

  return (
    <CMTextfield
      capitalized
      type={showText ? 'text' : 'password'}
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowText(!showText)}
              edge='end'
            >
              {showText ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextfield;
